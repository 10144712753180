import {notification, Select} from "antd";
import {useEffect, useState} from "react";
import ClaimApi from "../../../repository/ticket";
import lang from "../../../resources/localization/Localization";

const SelectOfficers = ({onChange, value, disabled, type = "select"}) => {
    const [loading, setLoading] = useState(false)
    const [officers, setOfficer] = useState([])
    useEffect(() => {
        setLoading(true)
        ClaimApi.getStaffMistake({type: "COLLECTIVE"}).then(r => {
            setOfficer(r.data)
        }).catch((e) => {
            notification.error({message: e.message})
        }).finally(() => {
            setLoading(false)
        })
    }, [])
    if (type === "string") {
        return <div>{officers.find(officer => value === officer.id)?.name || ""}</div>
    }
    return <Select disabled={disabled} onChange={onChange} value={loading ? undefined : value} placeholder={lang.officer_selector.select}
                   loading={loading}
                   className={"_select-officer"}>
        {officers.map(officer =>
            <Select.Option key={officer.code} value={officer.id} className={"_officer _officer-" + officer.code}>
                {officer.name} - {officer.code}
            </Select.Option>)}
    </Select>
}

export default SelectOfficers
