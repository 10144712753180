import React, { useState } from 'react'
import './float-label.scss'


type FloatLabelProps = {
    children?:any
    label:string
    value:any
    className?: string
}

function FloatLabel (props: FloatLabelProps) {
    const [focus, setFocus] = useState(false)
    const { children, label, value, className } = props

    const labelClass =
        focus || (value && value.length !== 0) ? 'label label-float' : 'label'

    return (
        <div
            className={`float-label ${className ? className : ''}`}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
        >
            {children}
            <label className={labelClass}>{label}</label>
        </div>
    )
}

export default FloatLabel;
