import { Tabs } from 'antd'
import { Tab } from 'rc-tabs/lib/interface'
import React, { useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { TICKETS_SCOPES } from 'src/utils/api/Scopes'
import { checkPermission } from 'src/utils/helper'
import lang, { trans } from '../../resources/localization/Localization'
import { Financials } from './Financials'
import ListStaffMistake from './StaffMistake'

export const ConfigurationTabs: React.FC = (props) => {
    const params: { activeTab: string } = useParams()
    const history = useHistory()

    const items: Tab[] = []

    items.push({
        label: trans('config_finance.title'),
        key: 'finance',
        children: <Financials />,
    })

    if (checkPermission(TICKETS_SCOPES.CONFIG_MISTAKE_PERSON)) {
        items.push({
            label: lang.conclusion.offender,
            key: 'offender',
            children: <ListStaffMistake />,
        })
    }

    const activeTab: string | undefined = useMemo(() => {
        const index = items.findIndex((item) => item.key === params.activeTab)

        return index !== -1 ? params.activeTab : items[0]?.key
    }, [params.activeTab, items])

    const onChangeTab = (activeKey: string) => {
        history.push(`/configurations/${activeKey}`)
    }

    return (
        <div className={'staffs-container pdbt20'}>
            <Tabs
                className={activeTab === 'finance' ? 'tab-in-tab' : undefined}
                defaultActiveKey="finance"
                activeKey={activeTab}
                tabPosition="left"
                items={items}
                onChange={onChangeTab}
            />
        </div>
    )
}
