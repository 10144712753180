import { Avatar, Empty, Skeleton, Table, TableProps, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import Link from 'antd/lib/typography/Link';
import { default as _, default as lodash } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import PaginationCustom from 'src/components/v2/Pagination';
import SkeletonTable from 'src/components/v2/SkeletonTable';
import { ClaimStateTag, OrderTypeTag } from 'src/components/v2/Tag/Tag';
import { useProjectsQuery } from 'src/hooks/query/useProjectsQuery';
import { useProjectAssigned } from 'src/hooks/useProjectAssigned';
import { useWindowDimension } from 'src/hooks/useWindowDimension';
import { ClaimView } from 'src/interface/ClaimInterface';
import Utils from 'src/utils';
import storageUtil from 'src/utils/LocalStore/LocalStores';
import EmptySearchImg from '../../../resources/images/empty/amico.svg';
import NoDataImg from '../../../resources/images/empty/no-data.svg';
import lang from "../../../resources/localization/Localization";
import { renderIconDeadline, renderKNNBLog } from './ClaimUtils';
import ColumnConfigModal from './ColumnConfigModal';
import { currencyUnitFormat } from 'src/utils/currency';
import { ClaimDetailContext } from '../Detail/context';

interface ClaimDataViewProps extends TableProps<any> {
    claimQuery: any
    columnsConfigQuery: any
    pagination: any
    displayColumnsConfig: boolean
    changeConfigModalVisible: (visible: boolean) => void;
    updateHiddenColumns: any
    othersInformationQueries: any[]
}

function ClaimDataView(props: ClaimDataViewProps) {
    const {
        claimQuery, columnsConfigQuery, displayColumnsConfig, changeConfigModalVisible, updateHiddenColumns, othersInformationQueries
    } = props;
    const location = useLocation();
    const currentLoggedUser = storageUtil.getCurrentUser();
    const { width } = useWindowDimension();
    const { currentProject } = useProjectAssigned()

    const tenantProjects = useProjectsQuery();
    const project = storageUtil.getItem('selectedProject')

    const currencyCondition = useMemo(() => {
        const projectList = _.cloneDeep(tenantProjects?.data)
        const currentProject = projectList?.find((p: any) => p.code === project)
        // console.log(currentProject)
        if (currentProject) {
            // console.log('currency', currentProject?.currency)
            return currentProject?.currency
        }
        else return ''
    }, [tenantProjects, project])

    // console.log(currencyCondition)


    const columns: ColumnProps<ClaimView>[] = [
        {
            title: 'Thông tin khiếu nại',
            key: 'claim-info',
            width: '30%',
            render: (record: ClaimView) => {
                const applyTruncateStyle = columns.filter((column: any) => !columnsConfigQuery.data?.hideColumns?.includes(column.key)).length > 4
                return <>
                    <div className=''>
                        <div className='flex'>
                            <Avatar
                                className='col-span-1'
                                src={record.imageUrl || Utils.getAvatarUser(record.id)}
                                size={62}
                            />
                            <div className='flex-col px-3 flex gap-[2px] col-span-3'>
                                <div className='flex items-center'>
                                    <Tooltip title={lang.claim_detail.ticket_code}>
                                        <Link
                                            className='text-sm font-medium hover:!text-[#339190]'
                                            target='_blank'
                                            href={`/#/tickets/${record.code}?project=${record.project}`}>
                                            #{record.code}
                                        </Link>
                                    </Tooltip>
                                    <div className='mx-2'>
                                        <OrderTypeTag orderType={record.ticketType} />
                                    </div>
                                    {
                                        Utils.isUpdateTicket(record) && (record.modifiedBy !== currentLoggedUser.username) &&
                                        <Tooltip placement="top" title={lang.message.warning_ticket}>
                                            <i className="fas fa-bell text-xs ml-2 text-rose-500" />
                                        </Tooltip>
                                    }
                                </div>

                                <div className={applyTruncateStyle ? 'claim-title-truncate' : ''}>
                                    <Tooltip title={<span>{lang.claim_detail.ticket_title}:<span> {record.claimName}</span></span>}>
                                        <Link
                                            className='text-md hover:!text-[#339190]'
                                            target='_blank'
                                            href={`/#/tickets/${record.code}?project=${record.project}`}>
                                            {record.claimName}
                                        </Link>
                                    </Tooltip>
                                </div>
                                <div className='flex items-center text-secondary-1 text-xs'>
                                    <Tooltip title='Khách hàng'>
                                        <span className='mr-1'>{record.customer}: </span>
                                    </Tooltip>
                                    <Tooltip title={lang.ticket_add.claim_reason + ': ' + record.reasonName}>
                                        <span className={`white-space-pre ${applyTruncateStyle ? 'claim-title-truncate' : ''}`}>{record.reasonName}</span>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        },
        {
            title: 'Người tiếp nhận',
            key: 'assignee',
            width: '5%',
            render: (record: ClaimView) => {
                return <>
                    <div className='flex max-w-[100px]'>
                        <span>{record.assignee || '---'}</span>
                    </div>
                </>
            }
        },
        {
            title: 'Thời gian',
            key: 'deadline',
            align: 'right',
            width: columnsConfigQuery.data?.hideColumns?.length >= 3 ? '25%' : undefined,
            render: (record: ClaimView) => {
                // if (record.deadline) {
                return <>
                    <div className={`flex flex-col text-md gap-2 min-w-[170px]`}>
                        <div className='flex gap-1 justify-end text-secondary-1 '>
                            <span className='flex gap-1'>
                                <Tooltip title={'Thời gian tạo khiếu nại'}>
                                    <span>Tạo: </span>
                                    <span>{record.createdAt ? moment(record.createdAt).format('HH:mm DD/MM/YYYY') : '---'}</span>
                                </Tooltip>
                            </span>
                        </div>
                        {/* <Tooltip title={'Hạn xử lý'}> */}
                        <div className='flex gap-1 justify-end items-center'>
                            <span>Xử lý: </span>
                            {record.deadline ? <span>
                                <span className='mr-1'>{renderIconDeadline(record)}</span>
                                <span>{moment(record.deadline).format("HH:mm DD/MM/YYYY")}</span>
                            </span> : <div className='text-secondary-1 text-md'>
                                (Chưa xác định)
                            </div>

                            }

                        </div>
                        {/* </Tooltip> */}

                    </div>
                </>
            }
        },
        {
            title: <div>Đề xuất{currentProject.komodoComplaintDetailUrl && `/Hoàn Alipay`}</div>,
            key: 'totalSuggest-alipay',
            align: 'right',
            // width: '15%',
            render: (record: ClaimView) => {
                let alipayItem = othersInformationQueries[0].data && othersInformationQueries[0].data.find((ele: any) => ele.relatedOrder === record.relatedOrder);
                // console.log(alipayItem)
                return (<>
                    <div className='flex flex-col gap-2'>
                        <Tooltip title={'Tiền đề xuất'}>
                            <span className='text-primary font-medium'>{lodash.get(record, 'totalSuggest') ? currencyUnitFormat(Utils.currencyFormat(record.totalSuggest), currencyCondition) : currencyUnitFormat(undefined, currencyCondition) + '---'}</span>
                        </Tooltip>
                        {
                            currentProject.komodoComplaintDetailUrl && <Skeleton loading={othersInformationQueries[0].isLoading} active title={false} paragraph={{ rows: 2 }}>
                                <Tooltip title={'Tiền hoàn Alipay'}>
                                    <div className={'txt-right _row-alipay text-14 flex justify-end'}>
                                        <p style={{ color: "#EA2A3F" }} className='font-medium'>
                                            {(lodash.get(alipayItem, 'exchangedCollectAmount') && currencyCondition)
                                                ? currencyUnitFormat(Utils.currencyFormat(alipayItem.exchangedCollectAmount), currencyCondition)
                                                : (lodash.get(alipayItem, 'exchangedCollectAmount')) ? currencyUnitFormat(Utils.currencyFormat(alipayItem.exchangedCollectAmount), currencyCondition)
                                                    : '---₫'}
                                        </p>
                                        <p className='text-secondary-2 ml-1'>
                                            ({lodash.get(alipayItem, 'collectAmount', '---') || '---'}¥)
                                        </p>
                                    </div>
                                </Tooltip>
                            </Skeleton>
                        }
                    </div>
                </>)
            }
        },
        {
            title: lang.claim_detail.order_status,
            key: 'state',
            // width: '5%',
            render: (record: ClaimView) => {
                return <div className=''>
                    <ClaimStateTag state={record.state} children={lang.state_ticket[record.state]} />
                </div>
            }
        }
    ]

    if (currentProject.komodoComplaintDetailUrl) {
        columns.splice(4, 0,
            {
                title: lang.list_ticket.seller_complaint_log,
                key: 'knnb-log',
                // width: '25%',
                render: (record: ClaimView) => {
                    let logItem = othersInformationQueries[1]?.data && othersInformationQueries[1]?.data.find((ele: any) => ele.relatedOrder === record.relatedOrder);
                    // console.log(logItem?.complaintLog);
                    return <div className='min-w-[150px]'>
                        <Skeleton loading={othersInformationQueries[1].isLoading} active title={false} paragraph={{ rows: 3 }}>
                            <div>
                                {(logItem && logItem?.complaintLog && logItem?.complaintLog?.logMessage.length > 0) ? <div className={'_log-knnb'}>
                                    <span
                                        className={'txt-color-black txt-size-h7'}>Mã #{logItem?.complaintLog.id}</span> {logItem?.complaintLog?.logMessage[0]?.creator.name}
                                    <span> {renderKNNBLog(logItem?.complaintLog?.logMessage[0])}</span>
                                </div> : "--"}
                            </div>
                        </Skeleton>
                    </div>
                }
            })
    }


    const renderColumns = useMemo(() => {
        return columns.filter((column: any) => !columnsConfigQuery.data?.hideColumns?.includes(column.key))
    }, [columnsConfigQuery])


    const renderEmptyContent = useMemo(() => {
        let searchParams = Utils.getQueryFromLocation(location);
        delete searchParams.page;
        delete searchParams.size;
        delete searchParams.orderBy;
        let emptyText = (!searchParams || lodash.isEmpty(searchParams)) ? 'Không có dữ liệu về khiếu nại' : 'Không tìm thấy kết quả phù hợp';
        let emptyImg = (!searchParams || lodash.isEmpty(searchParams)) ? NoDataImg : EmptySearchImg;
        return <React.Fragment>
            <Empty image={emptyImg} description={emptyText} />
        </React.Fragment>
    }, [location])

    return (
        <div>
            {
                claimQuery.isLoading ? <SkeletonTable
                    // scroll={{ y: 'calc(100vh - 120px)' }}
                    columns={renderColumns}
                    rowCount={10} />
                    : <Table
                        {...props}
                        locale={{
                            emptyText: renderEmptyContent
                        }}
                        columns={renderColumns}
                        dataSource={claimQuery.data}
                        rowKey={'id'}
                        pagination={false}
                        // scroll={{ y: 'calc(100vh - 330px)' }}
                        scroll={{ x: 1080 }}
                    />
            }
            <div className='flex justify-end mt-3'>
                <PaginationCustom {...props.pagination} />
            </div>

            {displayColumnsConfig && <ColumnConfigModal
                onCancel={() => changeConfigModalVisible(false)}
                open={displayColumnsConfig}
                hiddenColumns={columnsConfigQuery.data.hideColumns}
                tableColumns={columns}
                updateHiddenColumns={updateHiddenColumns}
            />}
        </div>
    )
}

export default ClaimDataView

