import { Button, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import Scroll from 'react-scroll';

const scroller = Scroll.animateScroll;

function ScrollButton() {
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        // Hàm xử lý sự kiện cuộn trang
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        // Gắn hàm xử lý sự kiện vào sự kiện cuộn trang
        window.addEventListener('scroll', handleScroll);

        // Xóa hàm xử lý sự kiện khi component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScrollToTop = () => {
        scroller.scrollToTop({
            smooth: true
        });
    }

    const handleScrollToBottom = () => {
        scroller.scrollToBottom({
            smooth: true
        });
    }

    return (
        <div className='flex flex-col gap-2 scroll-buttons'>
            {/* <p>{scrollY}</p> */}
            {
                scrollY > 1 && <Tooltip title={'Lên đầu trang'} className='' arrowPointAtCenter placement='left'>
                    <Button onClick={handleScrollToTop}>
                        <i className="fa-solid fa-up"></i>
                    </Button>
                </Tooltip>
            }
            {
                scrollY < document.documentElement.scrollHeight - window.innerHeight && <Tooltip title={'Xuống cuối trang'} arrowPointAtCenter placement='left'>
                    <Button onClick={handleScrollToBottom}>
                        <i className="fa-solid fa-down"></i>
                    </Button>
                </Tooltip>
            }


        </div>
    )
}

export default ScrollButton