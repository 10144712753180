import lang from '../resources/localization/Localization'

export const PARAM_STATE = [
    {
        stateUnchanged: 'NEW',
        claimStatus: 'createdAt',
        orderBy: 'desc'
    },
    {
        stateUnchanged: 'PROCESSING',
        claimStatus: 'processingAt',
        orderBy: 'desc'
    },
    {
        stateUnchanged: 'ACCEPTED',
        claimStatus: 'acceptedAt',
        orderBy: 'desc'
    },
    {
        stateUnchanged: 'REJECT',
        claimStatus: 'rejectedAt',
        orderBy: 'desc'
    },
    {
        stateUnchanged: 'REFUND',
        claimStatus: 'refundedAt',
        orderBy: 'desc'
    }
]

export const STATES_COLOR = [
    {
        state: 'PROCESSING',
        color: 'txtprocessing',
        dotColor: 'bgprocessing'
    },
    {
        state: 'CREATED',
        color: 'txtcreated',
        dotColor: 'bgcreated'
    },
    {
        state: 'WAITING',
        color: 'txtwaiting',
        dotColor: 'bgwaiting'
    },
    {
        state: 'RECEIVE',
        color: 'txtreceive',
        dotColor: 'bgreceive'
    },
    {
        state: 'COMPLAINT_COMMENT',
        color: 'txtcomplaintcomment',
        dotColor: 'bgcomplaintcomment'
    },
    {
        state: 'ADD_TRANSACTION',
        color: 'txtaddtransaction',
        dotColor: 'bgaddtransaction'
    },
    {
        state: 'ORIGIN_CODE',
        color: 'txtorigincode',
        dotColor: 'bgorigincode'
    },
    {
        state: 'RENEW',
        color: 'txtrenew',
        dotColor: 'bgrenew'
    },
    {
        state: 'UPDATE_REASON',
        color: 'txtupdatereason',
        dotColor: 'bgupdatereason'
    },
    {
        state: 'UPDATE_NOTE',
        color: 'txtupdatenote',
        dotColor: 'bgupdatenote'
    },
    {
        state: 'DELETE_TRANSACTION',
        color: 'txtdeletetransaction',
        dotColor: 'bgdeletetransaction'
    },
    {
        state: 'FAILURE',
        color: 'txtfailure',
        dotColor: 'bgfailure'
    },
    {
        state: 'SUCCESS',
        color: 'txtsuccess',
        dotColor: 'bgsuccess'
    },
    {
        state: 'NEW',
        color: '',
        dotColor: 'bgnew'
    },
    {
        state: 'ACCEPTED',
        color: '',
        dotColor: 'bgaccepted'
    },
    {
        state: 'REJECT',
        color: '',
        dotColor: 'bgreject'
    },
    {
        state: 'REFUND',
        color: '',
        dotColor: 'bgrefund'
    }


]

export const TICKET_TYPE = [
    {
        type: 'order',
        textColor: '',
        name: ''
    },
    {
        type: 'shipment',
        textColor: '',
        name: lang.list_ticket.SHIPMENT
    },
    {
        type: 'peerpayment',
        textColor: '',
        name: lang.list_ticket.PEERPAYMENT
    }
]

export const RATING_DATA = [
    {
        id: '0',
        name: lang.rating.unrate
    },
    {
        id: '2',
        name: `1 ${lang.rating.star}`
    },
    {
        id: '4',
        name: `2 ${lang.rating.stars}`
    },
    {
        id: '6',
        name: `3 ${lang.rating.stars}`
    },
    {
        id: '8',
        name: `4 ${lang.rating.stars}`
    },
    {
        id: '10',
        name: `5 ${lang.rating.stars}`
    }
]

export const CURRENCY_TYPES = [
    {
        key: "totalSuggest",
        name: lang.list_claim.amount_money
    }, {
        name: lang.conclusion.damage_countMoney,
        key: "totalDamage"
    }, {
        name: lang.ticket_add.count_money,
        key: "totalRefund"
    }
]
