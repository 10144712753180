import { Select } from 'antd'
import { useEffect, useState } from 'react'
import UserApi from '../../../repository/user'
import lang from '../../../resources/localization/Localization'
import { useProjects } from 'src/hooks/useProjects'

let timeout = null
let currentValue = ''

const SelectStaff = ({ onChange, value, disabled, placeholder = lang.ticket_detail.assigner, typeValue = 'id', className = '', currentProject = '' }) => {
    const [loading, setLoading] = useState(false)
    const [staffs, setStaffs] = useState([])

    const { currentProjectId } = useProjects()

    const fetch = (newValue) => {
        if (timeout) {
            clearTimeout(timeout)
            timeout = null
        }
        currentValue = newValue

        const fake = () => {
            setLoading(true)
            const params = {
                projects: currentProject || currentProjectId,
                query: currentValue,
            }

            UserApi.getListStaffNotPermission(params)
                .then((r) => {
                    const newStaff = r?.data?.filter((res) => res[typeValue])
                    setStaffs(newStaff)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
        timeout = setTimeout(fake, 300)
    }

    const handleSearch = (newValue) => {
        fetch(newValue || '')
    }

    useEffect(() => {
        handleSearch('')
    }, [])

    return (
        <Select
            disabled={disabled}
            showSearch
            onChange={(value, option) => !disabled && onChange(value, option)}
            value={value}
            placeholder={placeholder}
            loading={loading}
            onSearch={handleSearch}
            filterOption={false}
            className={'_select-reason ' + className}
            options={(staffs || []).map((staff) => ({
                value: staff[typeValue].toString(),
                label: `${staff.username || staff.email} ${staff.fullname ? `- (${staff.fullname})` : ''}`,
            }))}
        />
    )
}

export default SelectStaff
