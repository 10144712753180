import { Checkbox, Modal, ModalProps } from 'antd'
import React, { useEffect, useState } from 'react'
import lang from 'src/resources/localization/Localization'


interface ColumnConfigModalProps extends ModalProps {
    tableColumns: any[]
    hiddenColumns: string[]
    updateHiddenColumns: any
}

function ColumnConfigModal(props: ColumnConfigModalProps) {
    const {
        tableColumns, hiddenColumns, updateHiddenColumns
    } = props;
    const [displayColumns, setDisplayColumns] = useState<string[]>()

    useEffect(() => {
        let displayColumnsTemp: string[] = [];
        tableColumns.forEach((column: any) => {
            if (!hiddenColumns.includes(column.key)) {
                displayColumnsTemp.push(column.key)
            }
        })
        setDisplayColumns(displayColumnsTemp)
    }, [])

    const handleSelectCheckbox = (data: any) => {
        setDisplayColumns(data)
    }

    const handleSelectAll = (checked: boolean) => {
        let temp = tableColumns.map((col: any) => col.key)
        if (checked) {
            setDisplayColumns(temp)
        } else {
            setDisplayColumns(['claim-info'])
        }
    }

    const handleSubmitColumnsConfig = () => {
        if (displayColumns) {
            updateHiddenColumns.mutate(
                {
                    hideColumns: tableColumns.filter((c: any) => !displayColumns.includes(c.key)).map((t: any) => t.key),
                    entity: 'COMPLAINT_LIST'
                }
            )
        }
    }

    return (
        <Modal
            {...props}
            centered
            title={<div className='px-3 py-1'>{lang.claims.config_table_columns}</div>}
            destroyOnClose
            onOk={handleSubmitColumnsConfig}
            okButtonProps={{
                loading: updateHiddenColumns.isLoading,
                icon: <i className="fa-solid fa-check mr-2"></i>,
                type: 'primary',
            }}
            okText={lang.claims.config_table_columns_confirm_btn}
            cancelText={lang.claims.config_table_columns_cancel_btn}
            cancelButtonProps={{
                disabled: updateHiddenColumns.isLoading,
                type: 'ghost',
            }}
            closeIcon={<i className="fa-solid fa-xmark"></i>}
            width={'59%'}
        >
            <div className='px-3 py-1 mb-2'>
                <div className='flex items-center justify-between mb-3'>
                    <Checkbox
                        checked={displayColumns?.length === tableColumns?.length}
                        className='config-column-checkbox'
                        onChange={(event) => handleSelectAll(event.target.checked)}
                    >{lang.claims.config_table_columns_select_all}</Checkbox>
                    <span>
                        <span className='mr-1 text-secondary-1'>{lang.claims.config_table_columns_display_count}:</span>
                        <span className='text-primary'> {displayColumns?.length}/{tableColumns.length}</span>
                    </span>
                </div>

                <Checkbox.Group
                    className='grid grid-cols-3 gap-3'
                    onChange={handleSelectCheckbox}
                    options={tableColumns.map((column: any, index) => ({ value: column.key || index, label: column.title, disabled: column.key === 'claim-info' || column.key === 'customer' }))}
                    value={displayColumns}
                >

                </Checkbox.Group>
            </div>
        </Modal>
    )
}

export default ColumnConfigModal