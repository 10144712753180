import loadingIcon from "../../../resources/images/Drawer-load.png"
import errorIcon from "../../../resources/images/Drawer-er.png"
import FailIcon from "../../../resources/images/Drawer-Fail.png"
import lang from "../../../resources/localization/Localization";

const TableEmpty = ({loading, errorCode, emptyText}) => {
    if (loading) {
        return <div className={"flex flex-col justify-center align-items-center"}>
            <img alt={"loading"} src={loadingIcon}/>
            <div>
                {lang.message.fetching_data}
            </div>
        </div>
    }
    if (errorCode === 502) {
        return <div className={"flex flex-col justify-center align-items-center"}>
            <img alt={"error"} src={errorIcon}/>
            <div>
                {lang.message.fetching_error}
            </div>
        </div>
    }
    return <div className={"flex flex-col justify-center align-items-center"}>
        <img alt={"failed"} src={FailIcon}/>
        <div>
            {emptyText || `${lang.message.empty}`}
        </div>
    </div>
}
export default TableEmpty
