import React from "react";
import {TenantInterface} from "../interface/TenantInterface";

export interface AppContextValueInterface {
    state: {
        tenant: TenantInterface,
        projects: any
        assignedProjects: any
    },
    func: any
}

export const defaultAppContextValue: AppContextValueInterface = {
    state: {
        tenant: {
            code: 'vn',
            name: 'M3',
            tenantConfig: {},
        },
        projects: [],
        assignedProjects: []
    },
    func: {}
}

const AppContext = React.createContext<AppContextValueInterface>({...defaultAppContextValue});

export {AppContext};
