import copyToClipboard from "../../../utils/copyToClipboard";
import {message} from "antd";
import lang from "../../../resources/localization/Localization";

const CopyToClipboard = ({text}) => {
    const onClipboardCopy = () => {

        if (!text) return;
        if (Array.isArray(text)) {
            text = text[0]
        }
        text = text.toString();
        copyToClipboard(text).then(r => r && message && message.success(`${lang.button.copied}`))
        // navigator?.clipboard?.writeText(text).then(r => message.success(`Copy thành công`))
    }
    return <i className="fal fa-copy icon-copy cursor-pointer mgr-6"
              onClick={() => onClipboardCopy()}/>
}
export default CopyToClipboard
