import PolimClient from "../utils/api/PolimClient";
import {LocalStore} from "../utils/LocalStore";
import {updateQueryStringParameter} from "../utils/stringUtils";
import storageUtil from "../utils/LocalStore/LocalStores";
import { localStorageKeys } from "../constants/keyName";
import { setToken } from "../helpers/localStorage";

export default class UserService {

    /**
     * lấy user đang đăng nhập
     * @param cb
     */
    static getCurrentUser(cb) {
        PolimClient.request({
            endpoint: "/profile",
            method: "GET",
        }, cb);
    }

    /**
     * lấy danh sách users
     * @param filter
     * @param type INTERNAL với EXTERNAL
     * @param cb
     */
    static getUsersList(filter, type, cb) {
        let queryString = '';
        const currentTenant = LocalStore.getInstance().read('currentTenant') || {};

        if (type) {
            queryString = updateQueryStringParameter(queryString, 'type', type);
        }

        if (filter) {
            queryString = updateQueryStringParameter(queryString, 'query', filter);
        }

        queryString = updateQueryStringParameter(queryString, 'size', 10);

        PolimClient.request({
            endpoint: `/users${queryString}`,
            method: "GET"
        }, cb);
    }

    /**
     * lấy danh sách customers
     * @param filter
     * @param cb
     */
    static getCustomersList(filter, cb) {
        let queryString = '';
        const currentTenant = LocalStore.getInstance().read('currentTenant') || {};

        if (filter) {
            queryString = updateQueryStringParameter(queryString, 'query', filter);
        }

        queryString = updateQueryStringParameter(queryString, 'size', 10);
        queryString = updateQueryStringParameter(queryString, 'tenant', currentTenant?.tenant);

        PolimClient.request({
            endpoint: `/customers${queryString}`,
            method: "GET"
        }, cb);
    }

    /**
     * lấy danh sách staffs
     * @param filter
     * @param cb
     */
    static getStaffsList(filter, cb) {
        let queryString = '';
        const currentTenant = LocalStore.getInstance().read('currentTenant') || {};

        if (filter) {
            queryString = updateQueryStringParameter(queryString, 'query', filter);
        }

        queryString = updateQueryStringParameter(queryString, 'size', 10);
        queryString = updateQueryStringParameter(queryString, 'tenant', currentTenant?.tenant);
        PolimClient.request({
            endpoint: `/staffs${queryString}`,
            method: "GET"
        }, cb);
    }

    /**
     * đăng xuất
     * @param cb
     */
    static logout(cb) {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;
        setToken(null)
        storageUtil.removeItem("newVersion");
        storageUtil.removeItem("redirect_uri");
        window.location = process.env.REACT_APP_LOGOUT_URL
    }

    static isAllowed(permissions) {
        let permissionToCheck = [];

        if (!Array.isArray(permissions)) {
            if (typeof permissions === 'object') {
                for (let key in permissions) {
                    if (permissions.hasOwnProperty(key))
                        permissionToCheck.push(permissions[key]);
                }
            } else {
                permissionToCheck = [permissions];
            }
        } else {
            permissionToCheck = permissions;
        }
        
        let session = storageUtil.getCurrentUser()
        if (session) {
            if (!Array.isArray(session.authorities)) {
                session.authorities = [];
            }

            for (let permission of permissionToCheck) {
                if (session.authorities.indexOf(permission) >= 0) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * assign customer potential
     * @param data
     * @param cb
     */
    static createStaff(data, cb) {
        PolimClient.request({
            endpoint: `/staffs`,
            method: 'POST',
            body: data
        }, cb)
    }

    /**
     * Thay đổi số tiền duyệt khiếu nại của nhân viên
     * @param data
     * @param cb
     */
    static createThreshold(data, cb) {
        PolimClient.request({
            endpoint: `/staffs/${data.id}`,
            method: 'PATCH',
            body: {threshold: parseFloat(data.threshold)}
        }, cb)
    }

    /**
     * Thay đổi số tiền duyệt khiếu nại của nhân viên
     * @param data
     * @param cb
     */
    static editFinancialAccount(data, cb) {
        PolimClient.request({
            endpoint: `/financeTypes/${data['code']}`,
            method: 'PATCH',
            body: data
        }, cb)
    }

    /**
     * assign customer potential
     * @param data
     * @param cb
     */
    static changePin(data, cb) {
        PolimClient.request({
            endpoint: `/profile/change_pin`,
            method: 'POST',
            body: data
        }, cb)
    }

    /**
     * lấy danh sách log
     * @param page
     * @param username
     * @param cb
     */
    static getStaffConfigLogs(page, username, cb) {
        PolimClient.request({
            endpoint: `/config/logs?sort=timestamp&order=DESC&page=${page}&size=25`,
            method: 'GET',
        }, cb)
    }

    /**
     * assign customer potential
     * @param id
     * @param cb
     */
    static deleteStaff(id, cb) {
        PolimClient.request({
            endpoint: `/staffs/${id}`,
            method: 'DELETE',
        }, cb)
    }
}
