import { FC, MutableRefObject, useRef, useState } from 'react'
import { Button, Modal, notification, Tooltip } from 'antd'
import Utils from '../../../utils'
import moment from 'moment'
import CopyToClipboard from '../../../components/v2/Button/CopyToClipboard'
import { checkPermission } from '../../../utils/helper'
import { TICKETS_SCOPES } from '../../../utils/api/Scopes'
import { isMobile } from 'react-device-detect'
import lang, { trans } from '../../../resources/localization/Localization'
import UserApi from '../../../repository/user'
import FilterForm from './FilterForm'
import { ColumnsType } from 'antd/lib/table'
import { AddOrUpdateStaff } from './AddOrUpdateStaff'
import { useProjects } from '../../../hooks/useProjects'
import { uniqueId } from 'lodash'
import { AxiosError } from 'axios'
import { IStaff, IStaffAssignment } from 'src/interface/Staff'
import { TableCommon, TableCommonRefMethod } from 'src/components/base/TableCommon'
import { RouteChildrenProps } from 'react-router'
import SecurityService from '../../../utils/SecurityService'

interface IProps extends RouteChildrenProps {
    onShowLogClick: any
}

export const StaffList: FC<IProps> = ({ ...props }) => {
    const listRef: MutableRefObject<TableCommonRefMethod | undefined> = useRef()

    const [isShowCreateStaffModal, setIsShowCreateStaffModal] = useState<boolean>(false)
    const [staffToUpdateThreshold, setStaffToUpdateThreshold] = useState<IStaff | undefined>()

    const { projectNameMapping } = useProjects()

    const onDelete = (email: IStaff) => {
        UserApi.remove(email)
            .then(() => {
                notification.success({ message: trans('staff.delete_success') })
                listRef.current?.refreshData()
            })
            .catch((error: AxiosError) => {
                const errorKey: string = error.response?.data?.title || 'delete_failed'
                const errorMessageMapping: { [key in string]: string } = {
                    staff_not_found: trans('staff.staff_not_found_message'),
                    delete_failed: trans('staff.delete_failed'),
                }
                notification.error({ message: errorMessageMapping[errorKey] })
            })
    }

    const confirmDelete = (email: any) => {
        Modal.confirm({
            title: trans('staff.delete_confirm'),
            onOk: () => onDelete(email),
        })
    }

    const columns: ColumnsType<any> = [
        {
            title: lang.staff.name,
            dataIndex: 'username',
            fixed: isMobile ? 'left' : undefined,
            width: isMobile ? 160 : undefined,
            render: (text, row) => {
                return row.username ? (
                    <div className={'_username'}>
                        {row.fullname} ({row.username})
                    </div>
                ) : (
                    <div>
                        <a> --- </a>
                    </div>
                )
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: isMobile ? 250 : undefined,
            render: (text) => {
                return (
                    <div className={'flex flex-row align-items-center _email'}>
                        <CopyToClipboard text={text} />
                        <a href={'mailto:' + text}>{text}</a>
                    </div>
                )
            },
        },
        {
            title: lang.list_ticket.created_date,
            dataIndex: 'timestamp',
            align: 'right',
            render: (text) => {
                return <div className={'_createdAt'}>{moment(text).format('HH:mm:ss DD/MM/YYYY')}</div>
            },
        },
        {
            title: lang.ticket_detail.status,
            dataIndex: 'enabled',
            align: 'center',
            width: '5%',
            render: (enabled) => (
                <div>
                    <Tooltip
                        trigger={'hover'}
                        title={enabled ? lang.staff.active_staff : lang.staff.inactive_staff}>
                        <span className={`${enabled ? 'dot-active' : 'dot'}`} />
                    </Tooltip>
                </div>
            ),
        },
        {
            title: lang.staff.complaint_number,
            align: 'left',
            key: 'totalTickets',
            width: '15%',
            render: (record) => {
                return <div className={'_totalTickets'}>
                    {record.assignments?.length > 0 ? record.assignments?.map((assignment: IStaffAssignment) => (
                        <span
                            key={uniqueId()}
                            className="txt-nowrap">
                            {projectNameMapping[assignment.project]}: <span className="font-medium">{Utils.currencyFormat(assignment.totalTickets)}</span>
                            <br />
                        </span>
                    )) : '---'}
                </div>
            },
        },
        {
            title: lang.conclusion.max_approval,
            dataIndex: 'assignments',
            width: '15%',
            render: (assignments: IStaffAssignment[] | undefined) => {
                return (
                    <span className={'_threshold'}>
                        {assignments?.map((assignment: IStaffAssignment, index: number) => (
                            <span
                                key={uniqueId()}
                                className="txt-nowrap">
                                {projectNameMapping[assignment.project]}: <span className="font-medium">{Utils.currencyFormatVND(assignment.threshold)}</span>
                                <br />
                            </span>
                        ))}
                    </span>
                )
            },
        },
        {
            title: 'Log',
            key: 'log',
            render: (_, row) => {
                return (
                    <span
                        className={'_log cursor-pointer txt-color-primary txt-nowrap'}
                        onClick={() => {
                            if (props.onShowLogClick) props.onShowLogClick(row)
                        }}>
                        <i className="far fa-file-alt" /> Log
                    </span>
                )
            },
        },
        {
            title: '',
            dataIndex: 'action',
            render: (_, row: IStaff) => {
                return (
                    <div className="txt-nowrap">
                        {SecurityService.can(TICKETS_SCOPES.CONFIG_STAFF_UPDATE) && (
                            <Tooltip
                                title={lang.staff.threshold_number_change_from}
                                arrowPointAtCenter>
                                <a
                                    className={'_edit mgr-6'}
                                    onClick={() => {
                                        setStaffToUpdateThreshold(row)
                                    }}>
                                    <i className="fal fa-edit txt-color-blue" />
                                </a>
                            </Tooltip>
                        )}
                        {checkPermission(TICKETS_SCOPES.CONFIG_STAFF_DELETE) && (
                            <Tooltip
                                title={lang.staff.delete_staff_action}
                                arrowPointAtCenter>
                                <a
                                    className={'_remove'}
                                    onClick={() => {
                                        confirmDelete(row.email)
                                    }}>
                                    <i className="fas fa-trash txt-color-red2" />
                                </a>
                            </Tooltip>
                        )}
                    </div>
                )
            },
        },
    ]

    return (
        <>
            <FilterForm
                {...props}
                handleFilter={(q: any) => {
                    listRef.current?.fetchData(q)
                }}
            />

            <TableCommon
                divClassName={'staff-list'}
                ref={listRef}
                actions={
                    SecurityService.can(TICKETS_SCOPES.CONFIG_STAFF_ADD)
                        ? [
                            <Button
                                key={'add'}
                                onClick={() => {
                                    setIsShowCreateStaffModal(true)
                                }}>
                                <i className="fa-solid fa-plus mr-2" />
                                {lang.staff.add_staff}
                            </Button>,
                        ]
                        : undefined
                }
                tableTitle={lang.staff.list_staff}
                removeProjectDefault
                fetchApiService={({ ...p }) => UserApi.getListStaff({ ...p })}
                columns={columns}
                emptyText={lang.staff.empty_staff_list}
            />

            <AddOrUpdateStaff
                isShow={isShowCreateStaffModal}
                onCancel={() => {
                    setIsShowCreateStaffModal(false)
                }}
                onSuccess={() => {
                    listRef.current?.refreshData()
                }}
            />

            <AddOrUpdateStaff
                type="update"
                isShow={!!staffToUpdateThreshold}
                onCancel={() => {
                    setStaffToUpdateThreshold(undefined)
                }}
                onSuccess={() => {
                    listRef.current?.refreshData()
                }}
                initialValues={staffToUpdateThreshold}
            />
        </>
    )
}
