import { Badge } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import lang from 'src/resources/localization/Localization';
import Utils from 'src/utils';
import storageUtil from 'src/utils/LocalStore/LocalStores';

interface ClaimDataHeaderProps {
    total: number
    actions?: React.ReactNode[]
}

function ClaimDataHeader(props: ClaimDataHeaderProps) {
    const {
        total, actions
    } = props;
    const location = useLocation();
    const history = useHistory();
    const currentLoggedUser = storageUtil.getCurrentUser();
    const [searchParams, setSearchParams] = useState<any>({})

    useEffect(() => {
        setSearchParams({ ...Utils.getQueryFromLocation(location) })
    }, [location])

    const handleFilterByMe = () => {
        let search = Utils.getQueryFromLocation(location)
        if (search.assignee !== currentLoggedUser.username) {
            Utils.pushCleanQueryToHistory(history, location, {
                ...search,
                assignee: currentLoggedUser.username
            })
        } else {
            Utils.pushCleanQueryToHistory(history, location, {
                ...search,
                assignee: undefined
            })
        }
    }

    return (
        <React.Fragment>
            <div className='lg:flex lg:items-center lg:justify-between xs:flex-wrap xs:gap-3'>
                <div className='flex items-center flex-wrap '>
                    <span className='heading-4 font-medium mr-2'>{lang.ticket_add.list_claims}</span>
                    <Badge count={total || 0} overflowCount={99999} showZero />
                    <div className='ml-4 md:!mgt-0'>
                        {searchParams.stateUnchanged !== 'NEW' &&
                            <span
                                className={`claim-state-tag-filter ${currentLoggedUser?.username === searchParams?.assignee ? 'claim-state-tag-filter-selected' : ''} py-2 px-4 font-medium`}
                                onClick={() => handleFilterByMe()}
                            >
                                Khiếu nại của tôi
                                {
                                    currentLoggedUser?.username === searchParams?.assignee && <i className="fa-solid fa-xmark ml-2"></i>
                                }
                            </span>
                        }
                    </div>
                </div>
                <div className='lg:flex flex gap-2 mt-3 lg:!mt-0 flex-wrap'>
                    {actions && actions.map((ele: React.ReactNode, index) => <React.Fragment key={`_action-btn-${index}`}>{ele}</React.Fragment>)}
                </div>
            </div>

        </React.Fragment>
    )
}

export default ClaimDataHeader