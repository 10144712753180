import { Button, Input, notification, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useContext, useEffect, useState, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import ClaimApi from '../../../repository/ticket'
import Utils from '../../../utils'
import SelectMistake from '../../../components/v2/Select/SelectMistake'
import SelectStaffMistake from '../../../components/v2/Select/SelectStaffMistake'
import SelectTrueFalse from '../../../components/v2/Select/SelectTrueFalse'
import ModalConfirm from '../../../components/v2/Modal/Confirm'
import lang from '../../../resources/localization/Localization'
import { checkPermission } from '../../../utils/helper'
import { TICKETS_SCOPES } from '../../../utils/api/Scopes'
import lodash from 'lodash'
import { InputMoney } from '@gobizvn/homo'
import TableEmpty from '../../../components/v2/Error/TableEmpty'
import { ClaimDetailContext } from './context'
import CurrencyInput from 'src/components/CurrencyInput'
import { useProjectsQuery } from 'src/hooks/query/useProjectsQuery'
import { currencyLocaleFormatter, currencyParser, currencyUnitFormat } from 'src/utils/currency'

const internalCode = 'internal'

const Conclusion = ({ code, detail, errorCode }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [isShowConfirm, setShowConfirm] = useState(false)
    const [indexConfirm, setIndexConfirm] = useState('')
    const [mistakeList, setMistakeList] = useState([])

    const ticketState = detail.stateViewClaim?.code

    const { project } = useContext(ClaimDetailContext)

    const tenantProjects = useProjectsQuery()

    const currencyCondition = useMemo(() => {
        const projectList = _.cloneDeep(tenantProjects?.data)
        const currentProject = projectList?.find((p) => p.code === project)
        // console.log(currentProject)
        if (currentProject) {
            // console.log('currency', currentProject?.currency)
            return currentProject?.currency
        } else return ''
    }, [tenantProjects, project])

    useEffect(() => {
        getConclusion()
    }, [code])
    const getConclusion = () => {
        setLoading(true)
        ClaimApi.getConclusion(code, project)
            .then((res) => {
                setData(res.data)
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false)
            })
    }
    const columns = [
        {
            title: lang.conclusion.mistake,
            dataIndex: 'mistakeView',
            fixed: isMobile ? 'left' : null,
            render: (mistake, record, index) => {
                return record?.edit ? (
                    <div>
                        <SelectMistake
                            value={record.data?.mistake}
                            onChange={(value) => onChangeSelect(value, index, 'mistake')}
                            data={mistakeList}
                            setData={setMistakeList}
                        />
                        {record?.valid?.mistake?.message && <div className={'txt-color-red2 _message-valid mgt-5'}>{record?.valid?.mistake?.message}</div>}
                    </div>
                ) : (
                    <div className={'_mistake'}>{mistake?.name || '---'}</div>
                )
            },
        },
        {
            title: lang.conclusion.offender,
            dataIndex: 'officerView',
            width: isMobile ? 200 : null,
            render: (offender, record, index) => {
                return record?.edit ? (
                    <div className={'width100'}>
                        <SelectStaffMistake
                            value={record?.data?.offender}
                            uniqueKey="id"
                            disabled={record?.data?.mistake !== internalCode}
                            tenant={detail.tenant}
                            className={'_conclusion-select-damage'}
                            onChange={(value) => onChangeSelect(value, index, 'offender')}
                            currentProject={project}
                        />
                        {record?.valid?.offender?.message && <div className={'txt-color-red2 _message-valid mgt-5'}>{record?.valid?.offender?.message}</div>}
                    </div>
                ) : (
                    <div className={'_offender'}>{offender ? offender.code + ' - ' + offender.name : '---'}</div>
                )
            },
        },
        {
            title: lang.conclusion.damaged,
            dataIndex: 'damage',
            render: (damage, record, index) => {
                return record?.edit ? (
                    <div className={'_conclusion-damage'}>
                        <SelectTrueFalse
                            value={record?.data?.damage}
                            disabled={!record?.data?.mistake}
                            name={'damage'}
                            placeholder={lang.conclusion.damaged_selector}
                            className={'_conclusion-select-damage'}
                            onChange={(value) => onChangeSelect(value, index, 'damage')}
                        />
                        {record?.valid?.damage?.message && <div className={'txt-color-red2 _message-valid mgt-5'}>{record?.valid?.damage?.message}</div>}
                    </div>
                ) : (
                    <div className={'_damage'}>{damage ? lang.conclusion.yes : lang.conclusion.no}</div>
                )
            },
        },
        {
            title: lang.conclusion.error_description,
            dataIndex: 'description',
            render: (mistakeNote, record, index) => {
                return record?.edit ? (
                    <Input
                        value={record?.data?.mistakeNote}
                        loading={record.loading}
                        disabled={!record?.data?.mistake || record.loading}
                        placeholder={lang.conclusion.error_description}
                        className={'_input-mistakeNote'}
                        maxLength={255}
                        onPressEnter={() => onSubmit(index)}
                        onChange={(e) => onChangeInput(e.target.value, index, 'mistakeNote')}
                    />
                ) : (
                    <div className={'_mistakeNote'}>{mistakeNote || '---'} </div>
                )
            },
        },
        {
            title: lang.conclusion.damage_countMoney,
            dataIndex: 'damageValue',
            align: 'right',
            render: (damageValue, record, index) => {
                const regex = new RegExp(/^[a-z0-9]+$/i)
                return record?.edit ? (
                    <div>
                        {' '}
                        {['CNH', 'CNY'].includes(currencyCondition) ? (
                            <CurrencyInput
                                value={currencyLocaleFormatter(currencyParser(record?.data?.damageValue))}
                                placeholder={lang.conclusion.damaged_value_placeholder}
                                disabled={record?.data?.damage === 'false' || !record?.data?.damage || record.loading}
                                className={'_input-damageValue'}
                                onChange={(e) => onChangeInput(currencyParser(e.target.value), index, 'damageValue')}
                                onPressEnter={() => onSubmit(index)}
                            />
                        ) : (
                            <InputMoney
                                value={record?.data?.damageValue}
                                placeholder={lang.conclusion.damaged_value_placeholder}
                                disabled={record?.data?.damage === 'false' || !record?.data?.damage || record.loading}
                                loading={record.loading}
                                decimalSeparator={','}
                                className={'_input-damageValue'}
                                maxLength={255}
                                onPressEnter={() => onSubmit(index)}
                                onChange={(value) => onChangeInput(value.replace(/[^0-9]/g, ''), index, 'damageValue')}
                            />
                        )}
                        {record?.valid?.damageValue?.message && (
                            <div className={'txt-color-red2 _message-valid mgt-5'}>{record?.valid?.damageValue?.message}</div>
                        )}
                    </div>
                ) : (
                    <div className={'_damageValue'}>{damageValue ? currencyUnitFormat(Utils.currencyFormat(damageValue), currencyCondition) : '---'} </div>
                )
            },
        },
        {
            title: '',
            dataIndex: 'action',
            align: 'right',
            render: (_, record, index) => {
                if (ticketState === 'REFUND' || ticketState === 'REJECT') {
                    return null
                }
                return record?.edit ? (
                    <div className={'flex flex-row flex-content-end'}>
                        <div
                            className={'mgr-12 txt-color-primary text-12 font-medium cursor-pointer _btn-conclusion-save'}
                            onClick={() => !record.loading && onSubmit(index)}>
                            {lang.button.save}
                        </div>
                        <div
                            className={'txt-color-red2 text-12 font-medium cursor-pointer _btn-conclusion-cancel'}
                            onClick={() => !record.loading && removeRow(index)}>
                            {lang.button.cancel}
                        </div>
                    </div>
                ) : (
                    <div className={'flex flex-row flex-content-end'}>
                        {checkPermission(TICKETS_SCOPES.TICKET_CONCLUSION_UPDATE) && (
                            <div
                                className={'mgr-12 txt-color-primary text-12 font-medium cursor-pointer _btn-conclusion-edit'}
                                onClick={() => !record.loading && editRow(index)}>
                                {lang.button.edit}
                            </div>
                        )}

                        {checkPermission(TICKETS_SCOPES.TICKET_CONCLUSION_DELETE) && (
                            <div
                                className={'txt-color-red2 text-12 font-medium cursor-pointer _btn-conclusion-delete'}
                                onClick={() => !record.loading && deleteRecord(index)}>
                                {lang.button.delete}
                            </div>
                        )}
                    </div>
                )
            },
        },
    ]

    const onSubmit = (index) => {
        const values = data[index]
        const newData = [].concat(data)
        if (values?.data?.mistake && !lodash.isUndefined(values?.data?.damage)) {
            if (values?.data?.mistake === internalCode && !values.data?.offender) {
                values.valid = { ...values.valid, offender: { message: lang.message.not_empty_allowed } }
            }
            if (String(values?.data?.damage) === 'true' && !values?.data?.damageValue) {
                values.valid = { ...values.valid, damageValue: { message: lang.message.not_empty_allowed } }
            } else if (String(values.data?.damage) === 'true' && values?.data?.damageValue && parseFloat(values?.data?.damageValue) <= 0) {
                values.valid = { ...values.valid, damageValue: { message: lang.conclusion.loss_money_minium } }
            }
            if (Object.keys(values.valid || {}).length > 0) {
                newData[index] = values
                setData(newData)
                return
            }
            setLoading(true)
            if (values?.local) {
                const payload = {
                    project,
                    mistake: values.data.mistake,
                    officerId: values.data.offender,
                    damage: values.data.damage,
                    damageValue: values.data.damageValue,
                    description: values.data.mistakeNote,
                }

                ClaimApi.addConclusion(code, payload)
                    .then((res) => {
                        notification.success({ message: lang.message.success })
                        const newData = data?.map((d, i) => (i === index ? res.data : d))
                        setData(newData)
                        // getConclusion()
                    })
                    .catch((err) => {
                        if (err?.response?.data?.title === 'conclusion_damage_value_error') {
                            notification.error({ message: lang.conclusion.count_money_type })
                        } else if (err?.response?.data?.title === 'conclusion_existed') {
                            notification.error({ message: lang.message.conclusion_existed })
                        } else if (err?.response?.data?.title === 'claim_closed') {
                            notification.error({ message: lang.conclusion.cannot_manipulation })
                        } else if (lang.error[err?.response?.data?.title]) {
                            notification.error({ message: lang.error[err?.response?.data?.title] })
                        } else {
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            } else {
                newData[index].loading = true
                const payload = {
                    project,
                    mistake: values.data.mistake,
                    officerId: values.data.offender,
                    damage: values.data.damage,
                    damageValue: values.data.damageValue,
                    description: values.data.mistakeNote,
                }
                ClaimApi.editConclusion(code, newData[index].id, payload)
                    .then((res) => {
                        notification.success({ message: lang.message.success })
                        const newData = data?.map((d, i) => (i === index ? res.data : d))
                        setData(newData)
                    })
                    .catch((err) => {
                        newData[index].loading = false
                        setData(newData)
                        if (err?.response?.status === 400) {
                            if (err?.response?.data?.title === 'conclusion_damage_value_error') {
                                notification.error({ message: lang.conclusion.count_money_type })
                            } else if (err?.response?.data?.title === 'conclusion_existed') {
                                notification.error({ message: lang.message.conclusion_existed })
                            } else if (err?.response?.data?.title === 'claim_closed') {
                                notification.error({ message: lang.conclusion.cannot_manipulation })
                            } else if (lang.error[err?.response?.data?.title]) {
                                notification.error({ message: lang.error[err?.response?.data?.title] })
                            } else {
                            }
                        } else {
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        } else {
            if (!values?.data?.mistake) {
                values.valid = { ...values.valid, mistake: { message: lang.message.not_empty_allowed } }
            }
            if (values?.data?.mistake === internalCode && !values.data?.offender) {
                values.valid = { ...values.valid, offender: { message: lang.message.not_empty_allowed } }
            }
            if (!values.data?.damage) {
                values.valid = { ...values.valid, damage: { message: lang.message.not_empty_allowed } }
            }
            if (String(values.data?.damage) === 'true' && parseFloat(values.data?.damageValue || '')) {
                values.valid = { ...values.valid, damageValue: { message: lang.message.not_empty_allowed } }
            } else if (String(values.data?.damage) === 'true' && values?.data?.damageValue && parseFloat(values?.data?.damageValue) <= 0) {
                values.valid = { ...values.valid, damageValue: { message: lang.conclusion.loss_money_minium } }
            }
            newData[index] = values
            setData(newData)
        }
    }

    const editRow = (index) => {
        const newData = [].concat(data)
        const values = data[index]
        console.log('values', values)
        values.edit = true
        values.data = {
            mistake: values?.mistakeView?.code,
            damage: !!values?.damage,
            damageValue: values?.damageValue ? values?.damageValue?.toString() : undefined,
            mistakeNote: values?.description,
            offender: values?.officerView?.id,
        }
        newData[index] = values
        setData(newData)
    }

    const deleteRecord = (index) => {
        setShowConfirm(true)
        setIndexConfirm(index)
    }
    const removeRow = (index) => {
        const newData = [].concat(data)
        if (newData[index].changeValue) {
            if (!newData[index].local) {
                newData[index].edit = false
                setData(newData)
                return
            }
            setShowConfirm(true)
            setIndexConfirm(index)
            return
        }
        if (!newData[index].local) {
            newData[index].edit = false
            setData(newData)
            return
        }
        newData.splice(index, 1)
        setData(newData)
    }
    const onRemoveFinancial = () => {
        const newData = [].concat(data)
        if (newData[indexConfirm].local) {
            newData.splice(parseInt(indexConfirm), 1)
            setData(newData)
            setShowConfirm(false)
        } else {
            newData[indexConfirm].loading = true
            ClaimApi.deleteConclusion({ code, id: newData[indexConfirm].id, project })
                .then((res) => {
                    notification.success({ message: lang.message.success })
                    newData.splice(parseInt(indexConfirm), 1)
                    setData(newData)
                })
                .catch((err) => {
                    newData[indexConfirm].loading = false
                    setData(newData)
                    if (err?.response?.data?.title === 'conclusion_existed') {
                        notification.error({ message: lang.message.conclusion_existed })
                    } else if (lang.error[err?.response?.data?.title]) {
                        notification.error({ message: lang.error[err?.response?.data?.title] })
                    } else if (err?.response?.data?.title === 'state_in_valid') {
                        notification.error({ message: lang.conclusion.cannot_manipulation })
                    }
                })
                .finally(() => {
                    setShowConfirm(false)
                })
        }
    }
    const onChangeInput = (value, index, field) => {
        const newData = [].concat(data)
        newData[index]['data'][field] = value
        newData[index].changeValue = true
        if (newData[index]?.valid?.[field]?.message.length > 0) {
            delete newData[index]['valid'][field]
        }
        setData(newData)
        // }
    }
    const onChangeSelect = (value, index, field) => {
        const newData = [].concat(data)
        const record = newData[index]
        let dataEdit = { ...record.data }
        if (field === 'mistake' && value !== internalCode) {
            dataEdit.offender = null
            if (record?.valid?.offender?.message.length > 0) {
                delete record['valid']['offender']
            }
        }
        if (field === 'damage' && !value) {
            dataEdit.damageValue = null
            if (record?.valid?.damageValue?.message.length > 0) {
                delete record['valid']['damageValue']
            }
        }
        dataEdit = { ...dataEdit, [field]: value }

        record.data = dataEdit
        if (record?.valid?.[field]?.message.length > 0) {
            delete record['valid'][field]
        }
        newData[index] = record
        newData[index].changeValue = true
        setData(newData)
    }
    const addRow = () => {
        setData([{ edit: true, id: data.length + 1, local: true }].concat(data))
    }

    return (
        <div className={'bg-white pd-24 box'}>
            <div className={'flex flex-row space-between'}>
                <div className={'box-title m-0'}>{lang.conclusion.mistake}</div>
                {checkPermission(TICKETS_SCOPES.TICKET_CONCLUSION_CREATE) && ticketState !== 'REFUND' && ticketState !== 'REJECT' && (
                    <div>
                        <Button
                            className={'button buttonm3 _create-conclusion'}
                            onClick={addRow}
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <PlusOutlined />
                            {lang.claim_detail.add}
                        </Button>
                    </div>
                )}
            </div>
            <div className={'mgt16'}>
                <Table
                    loading={loading}
                    columns={columns}
                    className={'_table-conclusion'}
                    dataSource={data}
                    rowKey={(row) => row?.id}
                    scroll={{ x: isMobile ? 'calc(850px + 50%)' : '' }}
                    locale={{
                        emptyText: (
                            <TableEmpty
                                loading={loading}
                                errorCode={errorCode}
                                emptyText={lang.conclusion.mistakes_empty}
                            />
                        ),
                    }}
                    pagination={{
                        current: 1,
                        total: data.length,
                        pageSize: 100,
                        hideOnSinglePage: true,
                    }}
                />
            </div>
            <ModalConfirm
                visible={isShowConfirm}
                onOk={onRemoveFinancial}
                content={lang.conclusion.confirm_delete}
                title={lang.conclusion.warning}
                onCancel={() => {
                    setShowConfirm(false)
                }}
            />
        </div>
    )
}
export default Conclusion
