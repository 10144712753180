import { PlusOutlined } from '@ant-design/icons'
import { Table, Button, notification } from 'antd'
import { isMobile } from 'react-device-detect'
import React, { FC, MutableRefObject, useRef, useState } from 'react'
import moment from 'moment/moment'
import TableEmpty from '../../../components/v2/Error/TableEmpty'
import lang from '../../../resources/localization/Localization'
import { ITableCommonFilter, TableCommon, TableCommonRef, TableCommonRefMethod } from '../../../components/base/TableCommon'
import ClaimApi from '../../../repository/ticket'
import CreateStaffMistake from './CreateStaffMistake'
import storageUtil from '../../../utils/LocalStore/LocalStores'
import { useOfficers } from 'src/hooks/useOfficers'

const ListStaffMistake: FC<any> = () => {
    const [showCreateStaffMistake, setShowCreateStaffMistake] = useState(false)
    // const [officers, set]
    const ref: TableCommonRef = useRef()

    const { officersNameMapping } = useOfficers('COLLECTIVE')

    const onCreateStaffMistake = (values: any) => {
        const currentTenant = storageUtil.getJson('currentTenant')
        const data = { ...values, tenant: currentTenant?.tenant }
        ClaimApi.createStaffMistake(data)
            .then(() => {
                setShowCreateStaffMistake(false)
                notification.success({ message: lang.conclusion.select_offender_success })
                ref.current?.refreshData()
            })
            .catch((err) => {
                if (err.response?.data?.title === 'individual_officer_duplicate') {
                    notification.error({ message: lang.staff.staff_existed, key: 'staff_exist' })
                } else {
                    notification.error({ message: lang.conclusion.select_offender_failed, key: 'failed' })
                }
            })
    }

    const columns = [
        {
            title: lang.staff.staff_code,
            dataIndex: 'code',
            key: 'code',
            render: (text: string) => {
                return <div className={'_code'}>{text}</div>
            },
        },
        {
            title: lang.staff.staff_name,
            dataIndex: 'name',
            key: 'name',
            render: (text: string) => {
                return <div className={'_name'}>{text}</div>
            },
        },
        {
            title: lang.staff.staff_office,
            dataIndex: 'departmentId',
            key: 'departmentId',
            render: (departmentId: string) => {
                return <div className={'_parent'}>{officersNameMapping[departmentId]}</div>
            },
        },
        {
            title: lang.config.staff_createAt,
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (text: string) => {
                return text ? <div className={'_createdAt'}>{moment(text).format('HH:mm DD/MM/YYYY')}</div> : '---'
            },
        },
    ]

    return (
        <>
            <TableCommon
                ref={ref}
                tableTitle={lang.conclusion.offender}
                actions={[
                    <Button
                        key={'add_complaint'}
                        className={'button buttonm3 _create-complain-seller'}
                        onClick={() => setShowCreateStaffMistake(true)}
                        style={{ display: 'flex', alignItems: 'center' }}>
                        <PlusOutlined />
                        {lang.config.create_complaint_seller}
                    </Button>,
                ]}
                columns={columns}
                className={'_table-complain-seller'}
                emptyText={lang.config.empty_offender_list}
                fetchApiService={({ project, ...p }: ITableCommonFilter) => {
                    return ClaimApi.getStaffMistake({ ...p, type: 'INDIVIDUAL' })
                }}
            />

            <CreateStaffMistake
                visible={showCreateStaffMistake}
                onCreate={onCreateStaffMistake}
                onCancel={() => setShowCreateStaffMistake(false)}
            />
        </>
    )
}
export default ListStaffMistake
