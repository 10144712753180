import lang from '../resources/localization/Localization'

export enum SubjectEnums {
    Shipment = 'shipment',
    Order = 'order',
    Peerpayment = 'peerpayment',
}

// cho file js
export const FinanceOrderTypes = {
    shipment: 'shipment',
    order: 'order',
    peerpayment: 'peerpayment',
}

export const subjectMapping = {
    [SubjectEnums.Shipment]: lang.config.order_type,
    [SubjectEnums.Order]: lang.type_order_selector.type_shipment,
    [SubjectEnums.Peerpayment]: lang.type_order_selector.type_peerpayment,
}

export interface IOrder {
    code: string
    relatedProduct: null
    thumbnail: string
    status: {
        code: string
        name: string
    }
    supplySource: string
    purchaseAccount: null
    services: [
        {
            code: string
            name: string
        },
        {
            code: string
            name: string
        }
    ]
    warehouse: string
    customer: {
        id: null
        code: string
        username: string
        fullname: string
        email: string
        phone: string
        avatar: string
    }
    maxRefund: number
    purchasingStaff: null
    totalPaid: number
    totalRefund: null
    address: string
    customerLevelView: null
}
