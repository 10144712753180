import { Tooltip } from "antd";
import moment from "moment";
import { STATES_COLOR } from "src/constants/config";
import { ClaimView } from "src/interface/ClaimInterface";
import lang from "src/resources/localization/Localization";

export const fromCriteraValues: string[] = ['createdAtFrom', 'deadlineFrom', 'processingAtFrom', 'acceptedAtFrom', 'refundedAtFrom', 'rejectedAtFrom'];
export const toCriteriaValues: string[] = ['deadlineTo', 'createdAtTo', 'processingAtTo', 'acceptedAtTo', 'refundedAtTo', 'rejectedAtTo'];
export const advancedParams: string[] = ['archived', 'hasCollect', 'damage', 'complaintState', 'inspectionService', 'state', 'stateFrom', 'stateTo',
    'supplySourceCode', 'ticketType', 'rating', 'mistake', 'solutionCode', 'reasonCode', 'claimMoney', 'refunder', 'merchant', 'assignee', 'customer', 'code']

export const QUICKFILTER_ITEMS = [
    { label: lang.order_filter_selector.create_at, value: 'createdAt', key: 'createdAt' },
    { label: lang.order_filter_selector.deadline, value: 'deadline', key: 'deadline' },
    { label: lang.order_filter_selector.processingAt, value: "processingAt", key: "processingAt" },
    { label: lang.order_filter_selector.acceptedAt, value: 'acceptedAt', key: "acceptedAt", },
    { label: lang.order_filter_selector.refundedAt, value: 'refundedAt', key: "refundedAt", },
    { label: lang.order_filter_selector.rejectedAt, value: 'rejectedAt', key: "rejectedAt" },
]

export const CLAIM_STATES = [
    {
        label: 'Tất cả',
        value: undefined
    },
    {
        value: "NEW",
        label: lang.state_ticket.NEW,
    }, {
        label: lang.state_ticket.PROCESSING,
        value: "PROCESSING"
    }, {
        label: lang.state_ticket.RECEIVED,
        value: "ACCEPTED"
    }, {
        label: lang.state_ticket.REJECT,
        value: "REJECT"
    }, {
        label: lang.state_ticket.REFUND,
        value: "REFUND"
    },
]

export const CURRENCY_TYPES = [
    {
        value: "totalSuggest",
        label: lang.list_claim.amount_money
    }, {
        label: lang.conclusion.damage_countMoney,
        value: "totalDamage"
    }, {
        label: lang.ticket_add.count_money,
        value: "totalRefund"
    }
]

export const RATING_DATA = [
    {
        id: "0",
        key: '0',
        value: '',
        name: `Chưa đánh giá`
    },
    {
        id: "2",
        key: '2',
        value: 2,
        name: `1 ${lang.rating.star}`,
    },
    {
        id: "4",
        key: '4',
        value: 4,
        name: `2 ${lang.rating.stars}`,
    },
    {
        id: "6",
        key: '6',
        value: 6,
        name: `3 ${lang.rating.stars}`,
    },
    {
        id: "8",
        key: '8',
        value: 8,
        name: `4 ${lang.rating.stars}`,
    },
    {
        id: "10",
        key: '10',
        value: 10,
        name: `5 ${lang.rating.stars}`,
    },
];

export const renderStateColor = (state: any) => {
    let temp = STATES_COLOR.find((i) => i.state === state);
    if (temp) return temp.color
    else return '#333'
}

export const renderKNNBLog = (log: any) => {
    if (!log.action) return;
    switch (log.action) {
        case "COMPLAINT.CHANGE_STATE":
            if (log?.payload?.attributes?.newValue === "SUCCESS") {
                return <span>
                    {lang.claim_detail_log.change_status_from}
                    <span
                        className={renderStateColor(log?.payload?.attributes?.oldValue)}> {lang.state_ticket[log?.payload?.attributes?.oldValue]} </span>
                    {lang.claim_detail_log.change_status_to}
                    <span
                        className={renderStateColor(log?.payload?.attributes?.newValue)}> {lang.state_ticket[log?.payload?.attributes?.newValue]} </span>
                    ({moment(log.time).format("YYYY-MM-DD HH:mm")})
                    {log?.payload?.attributes?.amount && <span>
                        {lang.claim_detail_log.amount_claimed}
                        <span className={"amount-knnb"}>{log?.payload?.attributes?.amount} ¥ &rbrace</span></span>}
                </span>;
            }
            return <span className={'txt-size=h7 txt-color-black3'}>
                {lang.claim_detail_log.change_status_from}
                <span
                    className={renderStateColor(log?.payload?.attributes?.oldValue)}> {lang.state_ticket[log?.payload?.attributes?.oldValue]} </span>
                {lang.claim_detail_log.change_status_to}
                <span
                    className={renderStateColor(log?.payload?.attributes?.newValue)}> {lang.state_ticket[log?.payload?.attributes?.newValue]} </span>
                ({moment(log.time).format("YYYY-MM-DD HH:mm")})
            </span>
        case "COMPLAINT.CREATE":
            return <span
                className={renderStateColor("CREATED")}>{lang.claim_detail_log.seller_complaint_created}
                <span>({moment(log.time).format("YYYY-MM-DD HH:mm")})</span></span>
        case "COMPLAINT.RECEIVE":
            return <span
                className={renderStateColor("RECEIVE")}>{lang.claim_detail_log.receive_claim}<span>({moment(log.time).format("YYYY-MM-DD HH:mm")})</span></span>
        case "COMPLAINT_COMMENT":
            return <span
                className={renderStateColor("COMPLAINT_COMMENT")}>{lang.claim_detail_log.comment_claim}<span>({moment(log.time).format("YYYY-MM-DD HH:mm")})</span> ,
                {lang.claim_detail_log.comment_claim_message}: <span>{log?.message}
                </span></span>
        case "COMPLAINT.ADD_TRANSACTION":
            return <span
                className={renderStateColor("ADD_TRANSACTION")}>
                {lang.claim_detail_log.add_new_transaction}
                <span>({moment(log.time).format("YYYY-MM-DD HH:mm")})</span> <span>, {lang.claim_detail_log.transaction_code}:
                    <span className={"robotomedium"}> {log?.payload?.attributes?.transactionCode}</span></span></span>
        case "COMPLAINT.ORIGIN_CODE":
            return <span
                className={renderStateColor("ORIGIN_CODE")}>{lang.claim_detail_log.update_claim_code}
                <span>({moment(log.time).format("YYYY-MM-DD HH:mm")})</span></span>
        case "COMPLAINT.RENEW":
            return <span
                className={renderStateColor("RENEW")}>{lang.claim_detail_log.change_process_state}
                <span>({moment(log.time).format("YYYY-MM-DD HH:mm")})</span></span>
        case "COMPLAINT.CREATE_PACKAGE":
            return <span
                className={renderStateColor("CREATE_PACKAGE")}>{lang.claim_detail_log.add_package}
                <span>, {lang.claim_detail_log.new_package_code} : <span
                    className={"robotomedium txt-color-black2"}>{log?.payload?.attributes?.packageCode}<span>({moment(log.time).format("YYYY-MM-DD HH:mm")})</span></span></span></span>
        case "COMPLAINT.UPDATE_REASON":
            return <span
                className={renderStateColor("UPDATE_REASON")}>{lang.claim_detail_log.reason_change_from} <span
                    className={"robotomedium txt-color-black2"}>{log?.payload?.attributes?.noteOld}</span> {lang.claim_detail_log.reason_change_to}
                <span
                    className={"robotomedium txt-color-black2"}>{log?.payload?.attributes?.noteNew}</span> <span>({moment(log.time).format("YYYY-MM-DD HH:mm")})</span></span>
        case "COMPLAINT.UPDATE_NOTE":
            if (log.payload.attributes.noteOld === '') {
                return <span className={renderStateColor("UPDATE_NOTE")}>
                    {lang.log_detail.add_new_note}
                    <span
                        className={"robotomedium txt-color-black2"}> {log?.payload?.attributes?.noteNew} </span> <span>({moment(log.time).format("YYYY-MM-DD HH:mm")})</span>
                </span>
            } else if (log.payload.attributes.noteNew === '') {
                return <span className={renderStateColor("UPDATE_NOTE")}>
                    {lang.log_detail.remove_old_note}
                    <span
                        className={"robotomedium txt-color-black2"}> {log?.payload?.attributes?.noteOld} </span> <span>({moment(log.time).format("YYYY-MM-DD HH:mm")})</span>
                </span>
            } else
                return <span
                    className={renderStateColor("UPDATE_NOTE")}>{lang.claim_detail_log.note_change_from} <span
                        className={"robotomedium txt-color-black2"}>{log?.payload?.attributes?.noteOld}</span> {lang.claim_detail_log.note_change_to}
                    <span
                        className={"robotomedium txt-color-black2"}>{log?.payload?.attributes?.noteNew}</span> <span>({moment(log.time).format("YYYY-MM-DD HH:mm")})</span></span>

        case "COMPLAINT.DELETE_TRANSACTION":
            return <span
                className={renderStateColor("DELETE_TRANSACTION")}>{lang.claim_detail_log.remove_transactioin}
                <span className={"robotomedium txt-color-black2"}> {log?.payload?.attributes?.transactionCode} </span>
                <span>({moment(log.time).format("YYYY-MM-DD HH:mm")})</span>
                {/*<span>, {lang.claim_detail_log.transaction_code}:</span>*/}
            </span>
        default:
            return "--";
    }
}

export const renderIconDeadline = ({ state, deadline }: ClaimView) => {
    switch (state) {
        case "PROCESSING":
        case "NEW":
        case "ACCEPTED":
            return moment(deadline).isBefore(new Date()) ?
                <Tooltip placement="top" title={lang.message.overdue}>
                    <i className="fas fa-info-circle txt-color-red" />
                </Tooltip> : moment(deadline).subtract(1, "days").isBefore(new Date()) ?
                    <Tooltip placement="top" title={lang.message.due_soon}>
                        <i className="fas fa-info-circle txt-color-yellow" />
                    </Tooltip> : null;
        case "REFUND":
        case "REJECT":
            return <Tooltip placement="top" title={lang.message.processed}>
                <i className="fas fa-check-circle txt-color-green" />
            </Tooltip>;
        default:
            return;
    }
}