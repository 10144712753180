import { get } from 'lodash'
import moment from 'moment'
import { FC, ReactNode, useState } from 'react'
import { ClaimLogEvents, ILog, StaffLogEvents } from 'src/interface/Log'
import lang from 'src/resources/localization/Localization'
import { ModalCommon } from '../base/ModalCommon'
import { ITableCommonFilter, TableCommon } from '../base/TableCommon'

interface IStaffLog extends ILog<StaffLogEvents> {}

interface IProps {
    title: string | ReactNode
    open: boolean
    onCancel: () => void
    renderActions: (row: any) => JSX.Element
    fetchApiService: (filter: ITableCommonFilter) => Promise<any>
}

export const LogModal: FC<IProps> = ({ title, open, renderActions, fetchApiService, onCancel }: any) => {
    const columns = [
        {
            title: lang.table.row_user_action,
            dataIndex: 'actor',
            render: (_: string, row: IStaffLog) => {
                return <div className={'_actor'}>{row.actor.username}</div>
            },
        },
        {
            title: lang.table.row_time,
            dataIndex: 'timestamp',
            render: (text: string) => {
                return <div className={'_timestamp'}>{moment(text).format('HH:mm:ss DD/MM/yyyy')}</div>
            },
        },
        {
            title: lang.table.row_note,
            render: (row: any) => renderActions(row),
        },
    ]

    const filterData = (row: ILog) => {
        const changedValues = get(row, 'change.valueChange.changedValues')
        const updateEvent = [StaffLogEvents.UPDATED_ASSIGNMENT, ClaimLogEvents.CLAIM_UPDATED_CONCLUSION, ClaimLogEvents.CLAIM_UPDATED_FINANCE]
        if (updateEvent.includes(row.activity as any) && changedValues?.length < 1) return false

        return true
    }

    return (
        <ModalCommon
            title={<div className={'txt-color-green4 bold text-16'}>{title}</div>}
            open={open}
            closeIcon={<i className={'fal fa-times-circle '} />}
            onCancel={onCancel}
            destroyOnClose
            width={800}
            footer={null}
            className="log-modal">
            {open && (
                <TableCommon
                    filterData={filterData}
                    columns={columns}
                    noPagination
                    className={'_log'}
                    fetchApiService={fetchApiService}
                />
            )}
        </ModalCommon>
    )
}
