import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, InputNumber, notification, Row, Upload } from 'antd'
import React, { useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory, useLocation } from 'react-router'
import { ModalCommon } from 'src/components/base/ModalCommon'
import CurrencyInput from 'src/components/CurrencyInput'
import SelectReason from '../../../components/v2/Select/SelectReason'
import SelectSolution from '../../../components/v2/Select/SelectSolution'
import SelectTypeTicket from '../../../components/v2/Select/SelectTypeOrder'
import ClaimApi from '../../../repository/ticket'
import lang from '../../../resources/localization/Localization'
import storageUtil from '../../../utils/LocalStore/LocalStores'
import { InputMoney } from '@gobizvn/homo'
import _ from 'lodash'
import { useProjectsQuery } from 'src/hooks/query/useProjectsQuery'
import { currencyFomartter, currencyLocaleFormatter, currencyParser } from 'src/utils/currency'

const UPLOAD_MAX_SIZE = 10 * 1024 * 1024;

type ClaimCreateProps = {
    createIsOpen: boolean;
    handleChangeCreateVisible: (visible: boolean) => void;
    refetchData?: any
}

type ClaimCreateStates = {
    orderCode: string,
    productCode: string,
    previewVisible: boolean,
    previewImage: string,
    previewTitle: string,
    fileList: any[],
    loading: boolean,
}

function ClaimCreate(props: ClaimCreateProps) {
    const tenantProjects = useProjectsQuery();

    const currencyCondition = useMemo(() => {
        const selectedProject = storageUtil.getItem('selectedProject');
        const projectList = _.cloneDeep(tenantProjects?.data)
        const currentProject = projectList?.find((p: any) => p.code === selectedProject)
        // console.log(currentProject)
        if (currentProject) {
            // console.log('currency', currentProject?.currency)
            return currentProject?.currency
        }
        else return ''
    }, [tenantProjects])

    const [claimCreateStates, setClaimCreateStates] = React.useState<ClaimCreateStates | any>({
        orderCode: '',
        productCode: '',
        previewVisible: false,
        previewImage: '',
        previewTitle: '',
        fileList: [],
        loading: false
    });
    const {
        createIsOpen, handleChangeCreateVisible, refetchData
    } = props;
    const [form] = Form.useForm();
    const history = useHistory();
    const location = useLocation();
    const [disabledSubmit, setDisableSubmit] = React.useState(true);
    const suggest = Form.useWatch('suggest', form)

    const getBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setClaimCreateStates({
            ...claimCreateStates,
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        })
    };

    const beforeUpload = (file: any, list: any) => {
        let newFileList: any = []
        for (const l of list) {
            if (l.size <= UPLOAD_MAX_SIZE) {
                newFileList.push(l)
            } else {
                notification.error({
                    message: lang.ticket_add.oversize_10mb
                })
                return Upload.LIST_IGNORE
            }
        }

        newFileList = newFileList.concat(claimCreateStates.fileList).map((file: any, index: number) => {
            file.index = index;
            return file
        })
        setClaimCreateStates({
            ...claimCreateStates, fileList: newFileList
        })
        form.setFieldsValue({
            upload: newFileList
        })
        return false;
    };

    const renderName = (field: any, value: any) => {
        switch (field) {
            case "orderCode":
                return value ? (form.getFieldValue("ticketType") === "peerpayment" ? lang.ticket_add.type_PEERPAYMENT : lang.ticket_add.order) + " " + value : "";
            case "productCode":
                return value ? `${lang.ticket_detail.product} ` + value : " ";
            default:
                return ""
        }
    }

    const onBlurInput = (field: any, e: any) => {
        if (e.target.value !== claimCreateStates[field]) {
            setClaimCreateStates({
                ...claimCreateStates,
                [field]: e.target.value
            });
            switch (field) {
                case "orderCode":
                    form.setFieldsValue({
                        name: renderName("orderCode", e.target.value) + (e.target.value && claimCreateStates.productCode && ", ") + renderName("productCode", form.getFieldValue("relatedProduct"))
                    })
                    break;
                case "productCode":
                    form.setFieldsValue({
                        name: renderName("orderCode", form.getFieldValue("relatedOrder")) + (e.target.value && claimCreateStates.orderCode && ", ") + renderName("productCode", e.target.value)
                    })
                    break;
                default:
                    break;
            }
        }
    }

    const onRemoveImage = (e: any) => {
        const newFileList = claimCreateStates.fileList.filter((file: any) => file.index !== e.index)
        setClaimCreateStates({
            ...claimCreateStates,
            fileList: newFileList
        })
    }

    const onChangeTypeTicket = (value: any) => {
        if (form.getFieldValue("reasonCode")) {
            form.setFieldsValue({ reasonCode: undefined })
        }
        if (form.getFieldValue("solutionCode")) {
            form.setFieldsValue({ solutionCode: undefined })
        }
        if (value === "peerpayment" && form.getFieldValue("relatedProduct")) {
            form.setFieldsValue({
                relatedProduct: undefined,
                name: renderName("orderCode", form.getFieldValue("relatedOrder")).replace("Đơn hàng", "YCTT")
            })
        }
    }

    const handleCancel = () => setClaimCreateStates({ ...claimCreateStates, previewVisible: false });

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const onReset = () => {
        form.resetFields();
    };

    const onFinish = (values: any) => {
        if (!claimCreateStates.loading) {
            const project = storageUtil.getItem("selectedProject");
            const payload = { ...values };
            let formData = new FormData();
            delete payload.upload;
            payload.project = project

            payload.reasonData = {
                // reasonDetail: values.reason,
                notReceived: values.notReceived
            }
            if (values.suggest) {
                payload.suggest = currencyParser(values.suggest)
            }
            console.log('payload', payload)
            if (claimCreateStates.fileList && claimCreateStates.fileList.length > 0) {
                claimCreateStates.fileList.forEach((file: any) => {
                    formData.append('attachments', new Blob([file]), file.name);
                });
            }
            formData.append('claim', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
            setClaimCreateStates({ ...claimCreateStates, loading: true })
            ClaimApi.createTicket(formData).then(() => {
                notification.success({ message: lang.ticket_add.create_claim_success });
                if (location.pathname === '/tickets') {
                    refetchData && refetchData()
                }
                else {
                    history.push('/tickets')
                }
                handleChangeCreateVisible(false);
            }).catch(e => {
                if (e.response?.data?.title.replace("\"", '') === "order_not_found") {
                    notification.error({ message: lang.error.order_not_found })
                } else if (e.response?.data?.title.replace("\"", '') === "shipment_not_found") {
                    notification.error({ message: lang.error.shipment_not_found })
                } else if (e.response?.data?.title.replace("\"", '') === "peer_payment_not_found" || e.response?.data?.title === "\"peer_payment_not_found\"") {
                    notification.error({ message: lang.error.peerpayment_not_found })
                } else if (e.response?.data?.title === "invalid_order_type") {
                    notification.error({ message: lang.error.wrong_order_type })
                } else if (e.response?.data?.title === "product_not_fount" || e.response?.data?.title === "product_not_found") {
                    notification.error({ message: lang.message.product_not_fount })
                } else if (e.response?.data?.title === "number_of_files") {
                    notification.error({ message: lang.error.upload_picture })
                } else if (e.response?.data?.title === "suggestion_exceed_exchanged_amount") {
                    notification.error({ message: lang.error.suggest_money_overload })
                }
                else if (e.response?.data?.title === "not_received_must_not_be_null") {
                    notification.error({ message: lang?.error.not_received_must_not_be_null })
                }
                else if (e.response?.data?.title === "invalid_not_received_quantity") {
                    let invalidNumber = e.response.data.message.replace(/[^\d.]/g, '');
                    let message = lang?.error?.invalid_not_received_quantity.replace('%number%', invalidNumber)
                    notification.error({ message })
                }
                else if (e.response?.data?.title === "reason_not_found") {
                    notification.error({ message: lang?.error?.reason_not_found })
                }
                else if (e.response?.data.title === 'suggest_not_allow') {
                    notification['error']({ message: 'Số tiền đề xuất không được nhỏ hơn 0' })
                }
                else {
                    notification.error({ message: e?.response?.data?.detail || lang.ticket_add.create_claim_failed })
                }
            }
            ).finally(() => {
                setClaimCreateStates({ ...claimCreateStates, loading: false })
            })
        }
    };

    const handleFinishForm = (values: any) => {
        form.validateFields().then(() => {
            onFinish(values)
        })

    }

    const handleFormChange = () => {
        let hasErrors = true;
        if (form.isFieldsTouched(true)) {
            hasErrors = false;
        }
        else {
            if (!form.getFieldValue('ticketType') || !form.getFieldValue('solutionCode') || !form.getFieldValue('reasonCode') || !form.getFieldValue('relatedOrder')) {
                hasErrors = true;
            }
            else if (form.getFieldValue('reasonCode') === 'other') {
                hasErrors = !form.getFieldValue('description');
            }
            else if (form.getFieldValue('solutionCode') === 'compensate') {
                hasErrors = !form.getFieldValue('suggest');
            }
            else {
                hasErrors = false;
            }
        }
        setDisableSubmit(hasErrors);
    }

    const renderUploadButton = (
        <div className={"_upload-btn"}>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const validateNotReceived = (value: any) => {
        if (parseInt(value) > 9999) {
            return
        }
    }

    return (
        <ModalCommon
            title={lang.ticket_add.create_claims}
            open={createIsOpen}
            centered
            footer={null}
            maskClosable={false}
            onCancel={() => handleChangeCreateVisible(false)}
            closeIcon={<span><i className="fa-solid fa-xmark" /></span>}
            width={!isMobile ? 650 : undefined}
            className={''}
        >
            <div className={"_create-form"}>
                <Form form={form}
                    onFinish={handleFinishForm}
                    labelCol={{ span: 6 }}
                    labelAlign={'left'}
                    wrapperCol={{ span: 18 }}
                    onFieldsChange={handleFormChange}
                >
                    <div className={'items'}>
                        <Form.Item name="ticketType" label={lang.type_order_selector.title} required
                            rules={[
                                {
                                    required: true,
                                    message: lang.type_order_selector.select
                                }
                            ]}>
                            <SelectTypeTicket onChange={onChangeTypeTicket}
                                disabled={claimCreateStates.loading}
                                value={form.getFieldValue('ticketType')}
                            />
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) =>
                                <Form.Item name="relatedOrder"
                                    required
                                    label={getFieldValue("ticketType") === "peerpayment" ? lang.type_order_selector.type_peerpayment : lang.ticket_add.order_code}
                                    rules={
                                        [
                                            {
                                                required: true,
                                                message: getFieldValue("ticketType") === "peerpayment" ? lang.ticket_add.peerpayment_input : lang.ticket_add.order_code_type
                                            },
                                            {
                                                pattern: new RegExp(/^[A-Za-z0-9_]+$/i),
                                                message: lang.staff.staff_code_input_validation
                                            }]}>
                                    <Input className={"_relatedOrder"}
                                        placeholder={getFieldValue("ticketType") === "peerpayment" ? lang.ticket_add.peerpayment_input : lang.ticket_add.order_code_type}
                                        onBlur={(e) => onBlurInput("orderCode", e)}
                                        disabled={!getFieldValue("ticketType") || claimCreateStates.loading}
                                    // onChange={this.onChangeInput.bind(this, "orderCode")}
                                    />
                                </Form.Item>
                            }
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate
                        >
                            {({ getFieldValue }) => getFieldValue('ticketType') !== 'peerpayment' && <Form.Item name="relatedProduct" label={lang.ticket_add.product_code}
                                rules={[
                                    {
                                        pattern: new RegExp(/^[A-Za-z0-9_]+$/i),
                                        message: lang.staff.staff_code_input_validation
                                    }]}>
                                <Input className={"_relatedProduct"} placeholder={lang.ticket_add.product_code_type}
                                    onBlur={(e) => onBlurInput("productCode", e)}
                                    disabled={!getFieldValue("ticketType") || getFieldValue("ticketType") === "peerpayment" || claimCreateStates.loading}
                                // onChange={this.onChangeInput.bind(this, "productCode")}
                                />
                            </Form.Item>}
                        </Form.Item>
                        <Form.Item name="name" label={lang.ticket_add.claim_title}>
                            <Input className={"_name"} placeholder={lang.ticket_add.claim_title} disabled />
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate
                        >
                            {({ getFieldValue }) => <Form.Item
                                name="reasonCode"
                                required
                                label={lang.ticket_add.claim_reason_select_title}
                                rules={
                                    [{ required: true, message: lang.ticket_add.claim_reason_select }]}
                            >
                                <SelectReason type={getFieldValue("ticketType")}
                                    disabled={!claimCreateStates.orderCode || claimCreateStates.loading}
                                    onChange={() => {
                                    }}
                                    value={getFieldValue("reasonCode")}
                                    relatedProduct={getFieldValue('relatedProduct')}
                                />
                            </Form.Item>}
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate
                        >
                            {({ getFieldValue, setFieldsValue }) =>
                                (getFieldValue('reasonCode') === 'shipping_not_received'
                                    || getFieldValue('reasonCode') === "not_received"
                                    || getFieldValue('reasonCode') === 'shipment_not_received') ? (
                                    <div className={"flex flex-col mgb-18"}>
                                        <Form.Item name="notReceived" className={"mgb-0"}
                                            label={lang.ticket_add.missing_number}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: lang.ticket_add.missing_number_input
                                                },
                                                {
                                                    pattern: /^\d+$/,
                                                    message: lang.ticket_add.missing_number_input_type
                                                },
                                                {
                                                    type: 'number',
                                                    // max: 9999,
                                                    // message: lang.message.exceed_allowed_quantity
                                                },
                                            ]}>
                                            <InputNumber
                                                type={'number'}
                                                max={9999}
                                                controls={false}
                                                style={{ width: '100%' }}
                                                // formatter={value => {
                                                //     return`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                // }}
                                                // parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                                                className={"_notReceived"}
                                                disabled={claimCreateStates.loading}
                                                onChange={(value: any) => {
                                                    if (value === 0) setFieldsValue({ notReceived: 0 })
                                                    console.log(form.getFieldsValue(true))
                                                }}
                                                // value={form.getFieldValue('notReceived')}
                                                placeholder={lang.ticket_add.missing_number_type} />
                                        </Form.Item>
                                        <Row justify={"end"}>
                                            <Col span={18}>
                                                <span className={'txt-size-h8 txt-color-black3'}>
                                                    <span
                                                        className={'txt-color-red'}>* </span>{lang.ticket_add.entirely_missing}
                                                </span>
                                            </Col>
                                        </Row>
                                    </div>
                                ) : null
                            }
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('reasonCode') === 'other' ? (
                                    <Form.Item name="description" label={lang.ticket_add.descriptions}
                                        rules={[{ required: true, message: lang.ticket_add.descriptions_input }, {
                                            // pattern: new RegExp("^[^\\s]+(\\s+[^\\s]+)*$"),
                                            message: lang.ticket_add.descriptions_error
                                        }]}>
                                        <Input.TextArea className={"_description"}
                                            placeholder={lang.ticket_add.descriptions_input}
                                            disabled={claimCreateStates.loading}
                                        />
                                    </Form.Item>
                                ) : <Form.Item name="description" label={lang.ticket_add.descriptions}
                                // rules={[{pattern: new RegExp("^[^\\s]+(\\s+[^\\s]+)*$"), message: lang.ticket_add.descriptions_error}]}
                                >
                                    <Input.TextArea className={"_description rounded-lg"} disabled={claimCreateStates.loading}
                                        placeholder={lang.ticket_add.descriptions_input} />
                                </Form.Item>
                            }
                        </Form.Item>
                        <Form.Item
                            noStyle shouldUpdate
                        // shouldUpdate={(prevValues, currentValues) => prevValues.reasonCode !== currentValues.reasonCode}
                        >
                            {({ getFieldValue }) =>
                                <Form.Item
                                    name="upload"
                                    label={lang.ticket_add.description_img_upload}
                                    valuePropName="fileList"
                                    // getValueFromEvent={normFile}
                                    required={getFieldValue('reasonCode') === "shipment_faulty_product" || getFieldValue('reasonCode') === "faulty_product"}
                                    rules={[{
                                        required: getFieldValue('reasonCode') === "shipment_faulty_product" || getFieldValue('reasonCode') === "faulty_product",
                                        message: lang.ticket_add.description_img_upload_required
                                    }]}
                                >
                                    <Upload listType="picture-card"
                                        // action={null}
                                        multiple={true}
                                        // fileList={fileList}
                                        disabled={claimCreateStates.loading}
                                        accept=".png, .jpg"
                                        className={"_upload"}
                                        onPreview={handlePreview}
                                        beforeUpload={beforeUpload}
                                        onRemove={onRemoveImage}
                                    // onChange={(v)=> {
                                    //     setClaimCreateStates({...claimCreateStates,fileList: v.fileList})
                                    // }}
                                    >
                                        {claimCreateStates.fileList.length >= 10 ? null : renderUploadButton}
                                    </Upload>
                                    <div>
                                        <span className={'txt-size-h8 txt-color-black3'}>
                                            <span
                                                className={'txt-color-red'}>* </span> {lang.ticket_add.description_img_upload_required}
                                        </span>
                                    </div>
                                    <span className={'txt-size-h8 txt-color-black3'}>
                                        <span
                                            className={'txt-color-red'}>* </span>{lang.ticket_add.description_img_upload_size_limit}
                                    </span>
                                </Form.Item>}
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) =>
                                <Form.Item
                                    name="solutionCode" required
                                    label={lang.ticket_add.solutions}
                                    rules={
                                        [{ required: true, message: lang.message.solution_must_not_be_null }]}
                                >
                                    <SelectSolution disabled={claimCreateStates.loading || !getFieldValue("ticketType")}
                                        type={getFieldValue("ticketType")}
                                        value={getFieldValue('solutionCode')}
                                        onChange={() => { }}
                                    />
                                </Form.Item>
                            }</Form.Item>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                                if (getFieldValue('solutionCode') === 'compensate') {
                                    if (currencyCondition && ['CNY', 'CNH'].includes(currencyCondition)) {
                                        return (<Form.Item
                                            name="suggest" label={lang.ticket_add.count_money} required
                                            dependencies={['solutionCode']}
                                            // hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: lang.message.suggest_must_not_be_null
                                                },
                                                ({ }) => ({
                                                    validator(_, value) {
                                                        if (value && (!/^[0-9]\d*(\.\d{0,2})?$/.test(value))) {
                                                            return Promise.reject(new Error('Số tiền đề xuất không hợp lệ'));
                                                        }
                                                        if (value && Number(value) === 0) {
                                                            return Promise.reject(new Error('Số tiền đề xuất không được bằng 0'));
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                    transform(value) { return currencyParser(value) }
                                                }),
                                            ]}
                                        >
                                            <CurrencyInput
                                                className={"_suggest w-full rounded-lg"} placeholder={lang.ticket_add.count_money_type}
                                            />
                                        </Form.Item>)

                                    } else {
                                        return (
                                            <Form.Item name="suggest" label={lang.ticket_add.count_money} required
                                                rules={[
                                                    {
                                                        pattern: /^[0-9]+$/,
                                                        message: lang.ticket_add.missing_number_input_type
                                                    },
                                                    {
                                                        required: true,
                                                        message: lang.message.suggest_must_not_be_null
                                                    }
                                                ]}>
                                                <InputMoney
                                                    disabled={claimCreateStates.loading} className={"_suggest"} placeholder={lang.ticket_add.count_money_type}
                                                />
                                            </Form.Item>
                                        )
                                    }

                                }
                            }
                            }
                        </Form.Item>
                    </div>
                    <div className={"actions"}>
                        <Form.Item>
                            <Button className={"_cancel mgr8"} disabled={claimCreateStates.loading} type={'ghost'}
                                onClick={() => handleChangeCreateVisible(false)}>
                                <i className="fal fa-times mgr-6" />{lang.button.cancel}
                            </Button>
                        </Form.Item>
                        <Form.Item shouldUpdate>
                            {() =>
                                <Button className={"mgr15 _create"} type={'primary'}
                                    htmlType="submit"
                                    loading={claimCreateStates.loading}
                                    disabled={
                                        disabledSubmit || (form.getFieldValue('reasonCode') === 'shipping_not_received' || form.getFieldValue('reasonCode') === "not_received")
                                        && (form.getFieldValue('notReceived') === undefined || form.getFieldValue('notReceived') === null)
                                    }
                                >
                                    <i className="fal fa-check mgr-6" />
                                    {lang.ticket_add.create_claims}
                                </Button>
                            }
                        </Form.Item>
                    </div>
                </Form>
            </div>
            <ModalCommon
                open={claimCreateStates.previewVisible}
                title={claimCreateStates.previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img alt="example" style={{ width: '100%' }} src={claimCreateStates.previewImage} />
            </ModalCommon>
        </ModalCommon>
    )
}

export default ClaimCreate;
