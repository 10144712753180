import queryString from 'query-string'
import lodash from 'lodash'
import numeral from 'numeral'
import md5 from 'blueimp-md5'

class Utils {
    static getQueryFromLocation = (location) => {
        return queryString.parse(location.search, { arrayFormat: 'bracket' })
    }
    static getUrlQueryByName = (name, location) => {
        const queryFromLocation = Utils.getQueryFromLocation(location)
        queryFromLocation[name] = queryFromLocation[name] + location.hash
        return lodash.get(queryFromLocation, name)
    }
    static pushCleanQueryToHistory = (history, location, filter = {}) => {
        filter = lodash.omitBy(filter, (value) => value === '' || value === null || value === undefined)
        Utils.pushQueryToHistory(history, location, filter)
    }

    static pushQueryToHistory = (history, location, filter = {}) => {
        history.push(location.pathname + '?' + queryString.stringify(filter, { arrayFormat: 'bracket' }))
    }

    static currencyFormat = (amount, format = '0,0[.]00') => {
        return numeral(amount).format(format)
    }

    static currencyFormatVND = (amount) => {
        if (amount === 0 || amount === '0') {
            return '0₫'
        }

        return amount ? numeral(amount).format('0,0[.]0') + '₫' : '---'
    }

    static currencyFormatCNY = (amount) => {
        if (amount === 0 || amount === '0') {
            return '0₫'
        }

        return amount ? numeral(amount).format('0,0[.]00') + '¥' : '---'
    }

    static numberFormat = (amount) => {
        if (Utils.isFloat(amount)) {
            return amount.toFixed(2)
        }
        return amount
    }

    static isFloat = (n) => {
        return Number(n) === n && n % 1 !== 0
    }

    static getAvatarUser(email) {
        return '//www.gravatar.com/avatar/' + md5(email || '') + '?d=retro&s=40&r=g'
    }

    static isUpdateTicket = (item) => {
        if (item.lastView !== null) {
            let lastView = new Date(item.lastView)
            let lastActivity = new Date(item.lastActivity)
            if (lastActivity > lastView) {
                return true
            }
            return false
        }
        return false
    }

    static replaceURLs = (message) => {
        if (!message) return
        const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
        message = message.replace(/\n/g, '<br/>')
        return message.replace(urlRegex, function (url) {
            let hyperlink = url
            if (!hyperlink.match('^https?://')) {
                hyperlink = 'http://' + hyperlink
            }
            return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
        })
    }

    static detectUrl = (message) => {
        if (!message) return
        const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
        return message.match(urlRegex)
    }
}

export default Utils
