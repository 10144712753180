import { Pagination, PaginationProps, Select } from 'antd'
import React from 'react'


const PAGE_SIZES = [10, 25, 50]

interface PaginationCustomProps extends PaginationProps {
}

function PaginationCustom(props: PaginationCustomProps) {
    const {
        onChange, pageSize
    } = props;

    const onSizeChange = (size: number) => {
        if (onChange) {
            onChange(1, size)
        }
    }

    return (
        <React.Fragment>
            <div className='flex items-center'>
                <Pagination
                    className='custom-pagination'
                    simple
                    {...props}
                />
                <Select value={pageSize} className='ml-3' onChange={onSizeChange}>
                    {PAGE_SIZES.map((size) => <Select.Option key={size} value={size}>{`${size} / trang`}</Select.Option>)}
                </Select>
            </div>
        </React.Fragment>
    )
}

export default PaginationCustom