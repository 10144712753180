import { SubjectEnums } from './Orders'

// claim và ticket là 1. đều chỉ 1 khiếu nại. claim là theo cách gọi của BE, ticket là cách gọi của FE

// enums các trạng thái của khiếu nại
export enum TicketStateEnum {
    ACCEPTED = 'ACCEPTED',
    WAITING = 'WAITING',
    PENDING = 'PENDING',
    NEW = 'NEW',
    CLOSE = 'CLOSE',
    RECEIVED = 'RECEIVED',
    renegotiate = 'renegotiate',
    PROCESSING = 'PROCESSING',
    DONE = 'DONE',
    REFUND = 'REFUND',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    REJECT = 'REJECT',
}

export interface ITicket {
    createdAt: string
    id: string
    tenant: string
    project: string
    code: string
    name: string
    orderDetailUrl: string
    description: string
    relatedOrder: string
    relatedProduct: null
    suggest: number
    inspectionService: boolean
    thumbnail: null
    assignmentView: null
    reasonView: {
        id: string
        tenant: string
        project: string
        ticketType: string
        code: string
        name: string
        description: null
        detailType: string
    }
    solutionView: {
        id: string
        tenant: string
        project: string
        subject: string
        code: string
        name: string
        description: null
    }
    notReceived: null
    rating: null
    ratingComment: null
    attachments: [
        {
            id: string
            name: string
            type: string
            hash: null
            size: number
            s3Bucket: string
            presignedUrl: string
            presignedExpire: null
            image: boolean
            s3Key: string
        }
    ]
    archived: false
    archiveAt: null
    deadline: null
    estimatedRefundValue: null
    stateViewClaim: {
        id: string
        tenant: string
        code: string
        name: string
        lastState: false
        beginState: boolean
    }
    totalRefund: null
    ticketType: SubjectEnums
    komodoComplaintCreateUrl: string
    processingAt: null
    acceptedAt: null
    refundedAt: null
    rejectedAt: null
}

export interface ITicketLog extends ITicket {
    assignment: {
        assignee: string
        claimId: string
        createdAt: string
        id: string
        project: string
        tenant: string
    }
}

export interface IClaimFinancial {
    claimId: null
    createdAt: string
    id: string
    project: string
    reason: string
    tenant: string
    type: {
        code: string
        description: null
        financialAccount: {
            code: string
            description: string
            id: string
            name: string
            project: string
            subject: string
            tenant: string
        }
        id: string
        name: string
        position: number
        project: string
        subject: string
        tenant: string
    }
    value: number
}

export interface IClaimConclusion {
    createdAt: string
    damage: boolean
    damageValue: null | number
    description: null | string
    id: string
    mistakeView: {
        code: string
        description: null | string
        id: string
        name: string
        tenant: string
    }
    officerView: {
        code: string
        departmentId: null | string
        id: string
        name: string
        prefix: null | string
        tenant: string
        timestamp: string
        type: string
    }
    tenant: null | string
}

export interface TicketCriteria {}

export type TClaimView = {
    claimProductViews?: any
} & ITicket

export type TClaimProductView = {
    id: string
    tenant: string
    project: string
    claimId: string
    relatedProduct: string
    notReceived: number
    imageUrl: string

    dataSnapshot: {
        originalId: string
        name: string
        productImage: string
        variantImage: string
        actualPrice: number
        originalPrice: number
        salePrice: number
        exchangedSalePrice: number
        purchasedQuantity: number
        quantity: number
        variantProperties: {
            id: string
            name: string
            value: string
            originalName: string
            originalValue: string
        }[]
        currency: string
        exchangedActualPrice: number
    }
    numbersFaultyProduct: any
}

export type TClaimProductViewExtended = {
    originalId: string
    name: string
    productImage: string
    skus: TClaimProductView[]
}
