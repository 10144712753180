export const TICKETS_SCOPES = {
    TICKETS_CREATE: 'ticket:create',
    TICKETS_CREATE_FOR_CUSTOMER: 'ticket:create:for_customer',

    TICKETS_VIEW: 'ticket:view',
    TICKETS_VIEW_ALL: 'ticket:view:all',
    TICKETS_VIEW_CREATED: 'ticket:view:created',
    TICKETS_VIEW_ASSIGN: 'ticket:view:assign',

    TICKETS_ASSIGN: 'ticket:assign',
    TICKETS_ASSIGN_ALL: 'ticket:assign:all',
    TICKETS_ASSIGN_SELF: 'ticket:assign:self',
    TICKETS_ASSIGN_TRANSFER: 'ticket:assign:transfer',
    TICKETS_ASSIGN_REMOVE: 'ticket:assign:remove',

    TICKETS_UPDATE: 'ticket:update',
    TICKETS_UPDATE_FILE: 'ticket:update:file',
    TICKETS_UPDATE_NAME: 'ticket:update:name',
    TICKETS_UPDATE_STATUS: 'ticket:update:status',
    TICKETS_UPDATE_DEADLINE: 'ticket:update:deadline',
    TICKETS_UPDATE_TYPE: 'ticket:update:type',
    TICKETS_UPDATE_DESC: 'ticket:update:desc',
    TICKETS_UPDATE_APPROVE: 'ticket:update:approve',

    TICKETS_COMMENT: 'ticket:comment',
    TICKETS_COMMENT_CREATE: 'ticket:comment:create',
    TICKETS_COMMENT_VIEW: 'ticket:comment:view',
    TICKETS_COMMENT_DELETE: 'ticket:comment:delete',
    TICKETS_COMMENT_DELETE_ALL: 'ticket:comment:delete_all',

    TICKETS_LOG_VIEW: 'ticket:log:view',
    
    TICKETS_MILESTONE_VIEW: 'ticket:milestone:view',

    TICKETS_ARCHIVE_CREATED: 'ticket:archive:created',
    TICKETS_ARCHIVE_ALL: 'ticket:archive',

    TICKET_FINANCE_VIEW: 'ticket:finance:view',
    TICKET_FINANCE_CREATE: 'ticket:finance:create',
    TICKET_FINANCE_DELETE: 'ticket:finance:delete',
    TICKET_FINANCE_UPDATE: 'ticket:finance:update',
    TICKET_FINANCE_DELETE_ALL: 'ticket:finance:delete_all',

    TICKETS_DECISION: 'ticket:decision',
    TICKETS_DECISION_VIEW: 'ticket:decision:view',
    TICKETS_DECISION_CREATE: 'ticket:decision:create',
    TICKETS_DECISION_DELETE: 'ticket:decision:delete',
    TICKETS_DECISION_DELETE_ALL: 'ticket:decision:delete_all',

    TICKETS_COMMENT_INTERNAL: 'ticket:internal_comment',
    TICKETS_COMMENT_INTERNAL_CREATE: 'ticket:internal_comment:create',
    TICKETS_COMMENT_INTERNAL_VIEW: 'ticket:internal_comment:view',
    TICKETS_COMMENT_INTERNAL_DELETE: 'ticket:internal_comment:delete',
    TICKETS_COMMENT_INTERNAL_DELETE_ALL: 'ticket:internal_comment:delete_all',

    TICKETS_SIMILAR: 'ticket:similar_ticket',
    TICKETS_SIMILAR_VIEW_CREATE: 'ticket:similar_ticket:view_created',
    TICKETS_SIMILAR_VIEW_ALL: 'ticket:similar_ticket:view_all',

    TICKET_CONCLUSION_DAMAGE: 'ticket:conclusion:set_damage',
    TICKET_CONCLUSION_VIEW: 'ticket:conclusion:view',
    TICKET_CONCLUSION_CREATE: 'ticket:conclusion:add',
    TICKET_CONCLUSION_UPDATE: 'ticket:conclusion:update',
    TICKET_CONCLUSION_DELETE: 'ticket:conclusion:delete',
    TICKET_CONCLUSION: 'ticket:conclusion',
    TICKETS_EXPORT_CSV: 'ticket:export:ticket_list',

    TICKETS_MERCHANTS_VIEW: 'ticket:merchants:view',
    TICKETS_SUBSCRIPTION: 'ticket:subscription',

    CONFIG_STAFF_ADD: 'config:staff:add',
    CONFIG_STAFF_UPDATE: 'config:staff:update',
    CONFIG_STAFF_DELETE: 'config:staff:delete',
    CONFIG_STAFF_VIEW: 'config:staff:view_list',

    CONFIG_GENERAL: 'config:general',
    CONFIG_MISTAKE_PERSON: 'config:mistake_person',
    CONFIG_STAFF_MONEY_APPROVE: 'config:staff:money_approve',
};
