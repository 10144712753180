import apiClientV2 from "../utils/ApiClient";

export default class TenantApi {
    static getCurrent = () => {
        return apiClientV2.get(`/tenants/current`);
    };

    static getProjects = () => {
        return apiClientV2.get(`/projects`,{
            params: {
                offset: 0,
                limit: 999,
            }
        });
    }

    static getAssignedProjects = () => {
        return apiClientV2.get(`staffs/assignment`,{
            params: {
                offset: 0,
                limit: 999,
            }
        })
    }
}
