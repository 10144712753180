import React from 'react'
import { Select } from "antd";
import lang from "../../../resources/localization/Localization";
import ClaimApi from "../../../repository/ticket";
import storageUtil from "../../../utils/LocalStore/LocalStores";

const SelectTypeOrder = ({ onChange, value, disabled }) => {
    const [claimsTypes, setClaimTypes] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const project = storageUtil.getItem('selectedProject');

    React.useEffect(() => {
        if (project) {
            setLoading(true);
            ClaimApi.getClaimType(project).then((response) => {
                setClaimTypes(response.data)
            }).catch((err) => console.log(err)).finally(() => setLoading(false))
        }
    }, [])

    return <Select disabled={disabled} onChange={onChange} value={value}
        loading={loading}
        placeholder={lang.type_order_selector.select}
        className={"_select-type-order"}>
        {claimsTypes.map(type =>
            <Select.Option key={type.code} value={type.code} className={"_type-ticket _type-ticket-" + type.code}>
                {type.name}
            </Select.Option>)}
    </Select>
}

export default SelectTypeOrder
