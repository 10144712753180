import { Tabs, notification, Modal } from 'antd'
import { Component } from 'react'
import { ModalCommon } from 'src/components/base/ModalCommon'
import CommentBox from 'src/components/v2/CommentBox'
import { localStorageKeys } from 'src/constants/keyName'
import { ICommentClaim } from 'src/interface/ClaimInterface'
import CommentApi from 'src/repository/comment'
import lang from 'src/resources/localization/Localization'
import Utils from 'src/utils'
import { TICKETS_SCOPES } from 'src/utils/api/Scopes'
import { checkPermission } from 'src/utils/helper'
import storageUtil from 'src/utils/LocalStore/LocalStores'

type BoxChatName = 'customer' | 'internal'

interface IState {
    commentLoading: boolean
    comments: ICommentClaim[]
    paginationComment: {
        total: number
        currentPage: number
    }

    commentInternalLoading: boolean
    internalComments: []
    paginationInternalComment: {
        total: number
        currentPage: number
    }

    isModalCommentVisible: boolean
    isModalCommentInternalVisible: boolean

    currentLoggedUser: any
    collapse: undefined | BoxChatName
}

class Comments extends Component<any, IState> {
    project: string | null = ''
    code = ''
    constructor(props: any) {
        super(props)
        this.state = {
            commentLoading: false,
            comments: [],
            paginationComment: {
                total: 0,
                currentPage: 0,
            },

            commentInternalLoading: false,
            internalComments: [],
            paginationInternalComment: {
                total: 0,
                currentPage: 0,
            },

            isModalCommentVisible: false,
            isModalCommentInternalVisible: false,

            currentLoggedUser: {},
            collapse: undefined,
        }
        const queryParams = Utils.getQueryFromLocation(this.props.location)
        this.project = queryParams.project || storageUtil.getItem('selectedProject')
        this.code = this.props.match.params.id
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = () => {
        if (checkPermission(TICKETS_SCOPES.TICKETS_COMMENT_VIEW)) {
            this.loadComments()
        }

        if (checkPermission(TICKETS_SCOPES.TICKETS_COMMENT_INTERNAL_VIEW)) {
            this.loadInternalComments()
        }
        const currentLoggedUser = storageUtil.getJson(localStorageKeys.currentUser)
        this.setState({ currentLoggedUser })
    }

    onSubmitComment: any = ({ comment, files }: any, cb: any) => {
        let formData = new FormData()
        const commentObj = {
            content: comment,
            project: this.project,
            internal: false,
        }
        formData.append('comment', new Blob([JSON.stringify(commentObj)], { type: 'application/json' }))

        if (files && files.length > 0) {
            files.forEach((file: File) => {
                formData.append('attachments', new Blob([file]), file.name)
            })
        }

        this.setState({ commentLoading: true })
        CommentApi.createComment(this.code, formData)
            .then(() => {
                this.loadComments()
                cb()
            })
            .catch((err) => {
                if (err.response?.data?.title === 'number_of_files') {
                    notification.error({ message: lang.error.max_10_file })
                } else {
                    notification.error({ message: err?.response?.data?.detail || err.message })
                }
            })
            .finally(() => {
                this.setState({ commentLoading: false })
            })
    }

    loadComments = (page = 0) => {
        if (isNaN(page)) {
            return
        }
        this.setState({ commentLoading: true })
        const filter = {
            offset: Number(page) * 25,
            limit: 25,
            sort: 'createdAt:desc',
            project: this.project,
        }
        CommentApi.getComments(this.code, filter)
            .then((res) => {
                const comments = this.state.comments
                let newComments = page === 0 ? res.data : comments.concat(res.data)
                newComments = newComments.map((comment: any) => ({ ...comment, right: !comment?.customer }))
                this.setState({
                    comments: newComments,
                    paginationComment: {
                        currentPage: Number(res.headers['x-page-number']),
                        total: Number(res.headers['x-total-count']),
                    },
                })
            })
            .finally(() => {
                this.setState({ commentLoading: false })
            })
    }

    onSubmitCommentInternal: any = ({ comment, files }: any, cb: any) => {
        let formData = new FormData()
        const commentObj = {
            content: comment,
            project: this.project,
            internal: true,
        }
        formData.append('comment', new Blob([JSON.stringify(commentObj)], { type: 'application/json' }))

        if (files && files.length > 0) {
            files.forEach((file: File) => {
                formData.append('attachments', new Blob([file]), file.name)
            })
        }

        this.setState({ commentInternalLoading: true })
        CommentApi.createComment(this.code, formData)
            .then(() => {
                this.loadInternalComments()
                if (cb) cb()
            })
            .catch((err) => {
                if (err.response?.data?.title === 'number_of_files') {
                    notification.error({ message: lang.error.max_10_file })
                } else {
                    notification.error({ message: err?.response?.data?.detail || err.message })
                }
            })
            .finally(() => {
                this.setState({ commentInternalLoading: false })
            })
    }

    loadInternalComments = (page = 0) => {
        this.setState({ commentInternalLoading: true })
        const filter = {
            offset: 0,
            limit: 25,
            sort: 'createdAt:desc',
            project: this.project,
        }
        CommentApi.getInternalComments(this.code, filter)
            .then((res) => {
                const comments = this.state.internalComments
                let newComments = page === 0 ? res.data : comments.concat(res.data)
                newComments = newComments.map((comment: any) => ({
                    ...comment,
                    right: comment?.createdBy === this.state.currentLoggedUser?.username,
                }))
                this.setState({
                    internalComments: newComments,
                    paginationInternalComment: {
                        currentPage: Number(res.headers['x-page-number']),
                        total: Number(res.headers['x-total-count']),
                    },
                })
            })
            .finally(() => {
                this.setState({ commentInternalLoading: false })
            })
    }

    onCollapseCustomer: any = () => {
        const {collapse} =this.state
        this.setState({collapse: collapse === 'internal' ? undefined : 'internal'})
    }

    
    onCollapseIntercal: any = () => {
        const {collapse} =this.state
        this.setState({collapse: collapse === 'customer' ? undefined : 'customer'})
    }


    render() {
        const {
            comments,
            commentInternalLoading,
            paginationComment,
            commentLoading,
            internalComments,
            paginationInternalComment,
            collapse,
            isModalCommentVisible,
            isModalCommentInternalVisible,
        } = this.state
        
        return (
            <>
            <div className="comments">
                {checkPermission(TICKETS_SCOPES.TICKETS_COMMENT_VIEW) && (
                    <CommentBox
                        title={lang.comment.customer_comment}
                        data={comments}
                        loading={commentLoading}
                        pagination={paginationComment}
                        keyValue={'comment'}
                        disableInput={!checkPermission(TICKETS_SCOPES.TICKETS_COMMENT_CREATE)}
                        isCollapse={checkPermission(TICKETS_SCOPES.TICKETS_COMMENT_INTERNAL_VIEW)}
                        showFull={collapse !== 'customer'}
                        iconCollapse={collapse !== 'internal'}
                        onLoadData={this.loadComments}
                        onSubmitComment={this.onSubmitComment}
                        shortcut={119 as any}
                        onCollapse={this.onCollapseCustomer}
                    />
                )}
                {checkPermission(TICKETS_SCOPES.TICKETS_COMMENT_INTERNAL_VIEW) && (
                    <CommentBox
                        title={lang.comment.internal_comment}
                        data={internalComments}
                        keyValue={'commentInternal'}
                        loading={commentInternalLoading}
                        pagination={paginationInternalComment}
                        disableInput={!checkPermission(TICKETS_SCOPES.TICKETS_COMMENT_INTERNAL_CREATE)}
                        isCollapse={checkPermission(TICKETS_SCOPES.TICKETS_COMMENT_VIEW)}
                        onLoadData={this.loadComments}
                        onSubmitComment={this.onSubmitCommentInternal}
                        showFull={collapse !== 'internal'}
                        iconCollapse={collapse !== 'customer'}
                        iconUnder={true}
                        shortcut={120 as any}
                        onCollapse={this.onCollapseIntercal}
                    />
                )}
</div>
                {(checkPermission(TICKETS_SCOPES.TICKETS_COMMENT_VIEW) || checkPermission(TICKETS_SCOPES.TICKETS_COMMENT_INTERNAL_VIEW)) && (
                    <div className={'mobile-comment'}>
                        <div className={'icon-list'}>
                            {checkPermission(TICKETS_SCOPES.TICKETS_COMMENT_VIEW) && (
                                <div
                                    className={'icon'}
                                    onClick={() => this.setState({ isModalCommentVisible: true })}>
                                    <i className="fas fa-comments-alt" />
                                </div>
                            )}
                            {checkPermission(TICKETS_SCOPES.TICKETS_COMMENT_INTERNAL_VIEW) && (
                                <div
                                    className={'icon'}
                                    onClick={() => this.setState({ isModalCommentInternalVisible: true })}>
                                    <i className="fas fa-comment-alt-lines" />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <ModalCommon
                    title={null}
                    footer={null}
                    closeIcon={<div />}
                    bodyStyle={{ padding: 0 }}
                    open={isModalCommentVisible}
                    onCancel={() => {
                        this.setState({ isModalCommentVisible: false })
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            height: '70vh',
                        }}>
                        <CommentBox
                            title={lang.comment.customer_comment}
                            data={comments}
                            loading={commentLoading}
                            pagination={paginationComment}
                            keyValue={'comment'}
                            disableInput={!checkPermission(TICKETS_SCOPES.TICKETS_COMMENT_CREATE)}
                            isCollapse={checkPermission(TICKETS_SCOPES.TICKETS_COMMENT_INTERNAL_VIEW)}
                            onLoadData={this.loadComments}
                            onSubmitComment={this.onSubmitComment}
                            shortcut={119 as any}
                            isCloseIcon
                        />
                    </div>
                </ModalCommon>

                <ModalCommon
                    title={null}
                    footer={null}
                    closeIcon={<div />}
                    bodyStyle={{ padding: 0 }}
                    open={isModalCommentInternalVisible}
                    onCancel={() => {
                        this.setState({ isModalCommentInternalVisible: false })
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            height: '70vh',
                        }}>
                        <CommentBox
                            title={lang.comment.internal_comment}
                            data={internalComments}
                            keyValue={'commentInternal'}
                            loading={commentInternalLoading}
                            pagination={paginationInternalComment}
                            disableInput={!checkPermission(TICKETS_SCOPES.TICKETS_COMMENT_INTERNAL_CREATE)}
                            isCollapse={checkPermission(TICKETS_SCOPES.TICKETS_COMMENT_VIEW)}
                            onLoadData={this.loadComments}
                            onSubmitComment={this.onSubmitCommentInternal}
                            isCloseIcon
                            shortcut={120 as any}
                        />
                    </div>
                </ModalCommon>
            </>
        )
    }
}

export default Comments
