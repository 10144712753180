import { FC, Fragment, useEffect, useState } from 'react'
import { Button, Form, Input, notification } from 'antd'
import { InputMoney } from '@gobizvn/homo'
import lang, { trans } from '../../../resources/localization/Localization'
import UserApi from '../../../repository/user'
import { useForm, useWatch } from 'antd/es/form/Form'
import { SelectProject } from '../../../components/v2/Select/SelectProjects'
import { useProjects } from '../../../hooks/useProjects'
import { IStaff, IStaffAssignment } from '../../../interface/Staff'
import { ModalCommon } from 'src/components/base/ModalCommon'
import _, { get } from 'lodash'
import { useHistory } from 'react-router'

interface IProps {
    isShow: boolean
    initialValues?: IStaff
    type?: 'create' | 'update'
    onCancel: () => void
    onSuccess: () => void
}

interface IDataProject {
    project: string
    threshold: string
}

export const AddOrUpdateStaff: FC<IProps> = ({ isShow, initialValues, type = 'create', onSuccess, onCancel }: IProps) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [form] = useForm()

    const projects = useWatch('projects', form) || []
    const [collapsed, setCollapsed] = useState(true)
    const [disableParent, setDisableParent] = useState(false)

    const { projectNameMapping } = useProjects()

    const onSubmitToService = (data: any) => {
        delete data.projects
        setLoading(true)

        const submitToService = type === 'create' ? UserApi.create : UserApi.updateAssignment

        submitToService(data)
            .then(() => {
                if (type === 'create') {
                    notification.success({ message: lang.staff.staff_create_success })
                    onSuccess()
                } else {
                    notification.success({ message: lang.staff?.staff_update_success })
                    onSuccess()
                }
                onCancel()
            })
            .catch((err) => {
                if (err?.response?.data?.title === 'staff_duplicate') {
                    notification.error({ message: lang.staff.staff_duplicate })
                } else if (err?.response?.data?.title === 'staff_not_found') {
                    notification.error({ message: lang.staff.staff_not_exist })
                } else {
                    notification.error({ message: err.message })
                }
            })
            .finally(() => setLoading(false))
    }

    const onFinish = () => {
        if (loading) return
        const formValue = form.getFieldsValue(true)
        form.validateFields().then(() => {
            let tempData: any = {
                ...formValue
            }
            delete tempData.threshold
            delete tempData.projects
            // console.log(tempData)
            onSubmitToService(tempData)
        })
    }

    const onChangeProject = (values: any) => {
        let formValue = { ...form.getFieldsValue(true) }
        let dataTemp: any = []
        // dataTemp = formValue.data?.filter((d: any) => values.includes(d.project))
        switch (values?.length) {
            case 0:
                setDisableParent(true)
                break
            case 1:
                setDisableParent(false)
                break
            default:
                setCollapsed(false)
        }
        // if(formValue.data?.filter((data: IDataProject) => values.includes(data.project))) {
        if (formValue.data?.length > 0) {
            dataTemp = formValue.data?.filter((data: IDataProject) => values.includes(data.project))
        } else {
            dataTemp = values.map((v: any) => {
                return {
                    project: v
                }
            })
        }

        formValue.data = dataTemp
        if (values.length < 2) {
            formValue.threshold = formValue.data[0]?.threshold
        }
        if (values.length >= 2) {
            formValue.threshold = undefined
        }
        // console.log(formValue)
        form.setFieldsValue(formValue)
    }

    useEffect(() => {
        if (!isShow) {
            form.resetFields()
        }

        if (initialValues) {
            const projectInitials: string[] = []
            const assignments = initialValues.assignmentsPrev?.map((assignment: IStaffAssignment) => {
                projectInitials.push(assignment.project)
                const assignmentValues: any = { project: assignment.project }

                if (assignment.threshold || String(assignment.threshold) === '0') {
                    assignmentValues.threshold = String(assignment.threshold)
                }
                return assignmentValues
            })

            const formValue = {
                email: initialValues?.email,
                data: assignments,
                projects: projectInitials,
                threshold: assignments?.length === 1 && assignments[0]?.threshold
            }
            if (formValue.data?.length > 1) setCollapsed(false)
            form.setFieldsValue(formValue)
        }
    }, [isShow, initialValues])

    const handleHideChildrenThreshold = (value: number) => {
        let tempArr: any[]
        tempArr = form.getFieldValue('data')?.map((i: any) => {
            return {
                ...i,
                threshold: value
            }
        })
        form.setFieldValue('data', tempArr)
    }

    const handleHideParentThreshold = (value: number) => {
        if (!value) {
            setDisableParent(false)
        } else setDisableParent(true)
    }

    return (
        <ModalCommon
            title={<div
                className={'txt-color-green4 bold text-16'}>{type === 'create' ? lang.staff.add_staff : trans('staff.update_assignments')}</div>}
            open={isShow}
            onCancel={onCancel}
            closeIcon={<i className={'fal fa-times-circle '} />}
            confirmLoading={loading}
            destroyOnClose
            footer={null}
        >
            <Form
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                labelAlign={'left'}
                onFinish={onFinish}
                initialValues={{ threshold: undefined }}>
                <Form.Item
                    label='Email'
                    name={'email'}
                    rules={[
                        {
                            required: true,
                            message: lang.forget_pin.email_input
                        },
                        {
                            type: 'email',
                            message: lang.forget_pin.email_input_validation
                        }
                    ]}>
                    <Input
                        placeholder={'Email'}
                        className={'_email'}
                        disabled={type === 'update'}
                    />
                </Form.Item>

                <Form.Item
                    label={trans('staff.project')}
                    name={'projects'}
                    rules={[{ type: 'array' }]}>
                    <SelectProject onChange={onChangeProject} />
                </Form.Item>

                <Form.Item
                    name={'threshold'}
                    label={<span>{lang.conclusion.max_money_refund}</span>}>
                    <InputMoney
                        disabled={disableParent || (!form.getFieldValue('projects'))}
                        onChange={(value) => handleHideChildrenThreshold(value)}
                        className={'_threshold'}
                        placeholder={lang.conclusion.max_money_required}
                    />
                </Form.Item>

                <div className={'staff-insertion_list-threshold-title mgbt5'}
                    hidden={form.getFieldValue('projects')?.length < 2 || !form.getFieldValue('projects')}
                    onClick={() => setCollapsed(!collapsed)}>
                    <span>{lang.staff.config_threhold_title}</span>
                    <span>{collapsed ? <i className='fa-solid fa-angle-down' /> :
                        <i className='fa-solid fa-angle-up' />}</span>
                </div>

                <Form.Item shouldUpdate className={'staff-insertion'}
                    hidden={form.getFieldValue('projects')?.length < 2 || !form.getFieldValue('projects') || collapsed}>
                    {(form) => {
                        if (form.getFieldValue('projects') && form.getFieldValue('projects').length > 1)
                            return (
                                <>
                                    <div className={'staff-insertion_list-threshold'}>
                                        <Form.List name='data'>
                                            {() =>
                                                projects.map((project: string, index: number) => (
                                                    <Fragment key={`${project}_${index}`}>
                                                        <Form.Item
                                                            name={[index, 'project']}
                                                            initialValue={project}
                                                            hidden
                                                        />
                                                        <Form.Item
                                                            key={project}
                                                            // label={`${lang.conclusion.max_money} (${projectNameMapping[project]})`}
                                                            label={projectNameMapping[project]}
                                                            name={[index, 'threshold']}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: lang.conclusion.max_money_required
                                                                },
                                                                {
                                                                    pattern: new RegExp(/^[0-9]+$/i),
                                                                    message: lang.conclusion.max_money_validation
                                                                }
                                                            ]}>
                                                            <InputMoney
                                                                className={'_threshold'}
                                                                placeholder={lang.conclusion.max_money_refund}
                                                                onChange={(value) => handleHideParentThreshold(value)}
                                                            />
                                                        </Form.Item>
                                                    </Fragment>
                                                ))
                                            }
                                        </Form.List>
                                    </div>
                                </>
                            )
                    }}
                </Form.Item>

                <Form.Item shouldUpdate wrapperCol={{}}>
                    {() => <div className={'dpl-flex justify-content-end'}>
                        <Button type='default'
                            className={'_onCancel txt-capitalize'} onClick={onCancel} icon={<i className="fa-solid fa-check mr-2" />}>
                            {lang.button.no}
                        </Button>
                        <Button type={'primary'}
                            className={'_onSubmit mgl10 txt-capitalize'}
                            htmlType={'submit'}
                            icon={<i className="fa-solid fa-xmark mr-2" />}
                            disabled={
                                (!form.isFieldTouched('email') && type === 'create') ||
                                !!form.getFieldsError().filter(({ errors }) => errors.length).length
                            }
                        >
                            {lang.button.yes}
                        </Button>
                    </div>}
                </Form.Item>
            </Form>
        </ModalCommon>
    )
}
