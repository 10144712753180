import { IFinancialTypeCreate, IFinancialTypeUpdate } from '../interface/FinancialType'
import apiClientV2 from '../utils/ApiClient'
import storageUtil from '../utils/LocalStore/LocalStores'

class FinanceTypesClass {
    create(payload: IFinancialTypeCreate) {
        return apiClientV2.post(`/finance_types`, payload)
    }

    update(id: string, payload: IFinancialTypeUpdate) {
        return apiClientV2.patch(`/finance_types/${id}`, payload)
    }
}

export const FinanceTypesService = new FinanceTypesClass()
