import { Button, Col, Form, Row } from 'antd'
import { FC, useMemo } from 'react'
import SelectStaff from 'src/components/v2/Select/SelectStaff'
import { ITicket, TicketStateEnum } from 'src/interface/Ticket'
import lang from 'src/resources/localization/Localization'
import { TICKETS_SCOPES } from 'src/utils/api/Scopes'
import { checkPermission } from 'src/utils/helper'

interface IProps {
    detail: ITicket
    project: string
    onAssignUserToIssue: any
    assignmentView: any
    ticketState: any
    issueLoading: boolean
    checkStatusRequirePassword: any
    ticketStates: any
    assignUserToMe: any
    loading: boolean
    checkShowCloseBtn: any
    archiveTicket: () => void
}

export const StateInfo: FC<IProps> = ({
    detail,
    project,
    onAssignUserToIssue,
    assignmentView,
    ticketState,
    issueLoading,
    checkStatusRequirePassword,
    ticketStates,
    assignUserToMe,
    loading,
    checkShowCloseBtn,
    archiveTicket,
}) => {
    const isDisableAssign = useMemo(() => {
        if (ticketState === TicketStateEnum.REFUND || ticketState === TicketStateEnum.REJECT || issueLoading) return true

        return !(
            // checkPermission(TICKETS_SCOPES.TICKETS_ASSIGN_ALL) ||
            checkPermission(TICKETS_SCOPES.TICKETS_ASSIGN_TRANSFER)
        )
    }, [ticketState, TICKETS_SCOPES])

    const checkShowChangeStateBtn = (state: string) => {
        if (!checkPermission(TICKETS_SCOPES.TICKETS_UPDATE_STATUS)) {
            return false
        }

        const stateCheck: any = ticketStates.find((x: any) => x.code === state) || {}
        if (stateCheck.permission) {
            return checkPermission(stateCheck.permission)
        }
        return true
    }

    const renderActionByState = () => {
        const currentState = ticketStates.find((x: any) => x.code === ticketState) || {}

        if (currentState.permission) {
            if (!checkPermission(currentState.permission)) return null
        }

        switch (ticketState) {
            case 'NEW':
                return (
                    checkPermission(TICKETS_SCOPES.TICKETS_ASSIGN_SELF) && (
                        <Button
                            className={'buttonm3 flex-1 _btn-assign-me'}
                            htmlType="submit"
                            type="primary"
                            onClick={() => assignUserToMe()}
                            disabled={loading}>
                            <i className="fal fa-check mgr-6" />
                            {lang.ticket_detail.accept_claim}
                        </Button>
                    )
                )
            case 'PROCESSING':
                return (
                    checkShowChangeStateBtn('ACCEPTED') && (
                        <Button
                            className={'buttonm3 flex-1 _btn-accept-ticket'}
                            type="primary"
                            htmlType="submit"
                            onClick={() => checkStatusRequirePassword('ACCEPTED')}
                            disabled={loading}>
                            <i className="fal fa-check mgr-6" />
                            {lang.ticket_detail.accept}
                        </Button>
                    )
                )
            case 'ACCEPTED':
                return (
                    <div className={'flex flex-row align-items-center'}>
                        {checkPermission(TICKETS_SCOPES.TICKETS_UPDATE_APPROVE) && (
                            <Button
                                className={'buttonm3 flex-1 _btn-refund-ticket mgr-8'}
                                htmlType="submit"
                                onClick={() => checkStatusRequirePassword('REFUND')}
                                disabled={loading}>
                                {lang.button.refund}
                            </Button>
                        )}

                        {checkShowChangeStateBtn('PROCESSING') && (
                            <Button
                                className={'button-primary _btn-processingAgain'}
                                htmlType="submit"
                                onClick={() => checkStatusRequirePassword('PROCESSING')}
                                disabled={loading}>
                                {lang.state_ticket.renegotiate}
                            </Button>
                        )}
                    </div>
                )
            case 'REFUND':
            case 'REJECT':
                return (
                    checkShowCloseBtn() && (
                        <Button
                            className={'button-primary _btn-close-ticket'}
                            htmlType="submit"
                            onClick={archiveTicket}
                            disabled={loading || detail.archived}>
                            {lang.ticket_detail.close}
                        </Button>
                    )
                )
            default:
                return
        }
    }

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col
                    xs={24}
                    md={16}
                    className="flex align-items-center gap-3">
                    <span className="text-secondary-2">{lang.ticket_detail.receiver}:</span>
                    <SelectStaff
                        onChange={onAssignUserToIssue}
                        typeValue={'username'}
                        className={'flex-1'}
                        value={assignmentView?.assignee}
                        disabled={isDisableAssign}
                        placeholder={undefined}
                        currentProject={project}
                    />
                </Col>

                <Col
                    xs={24}
                    md={8}>
                    <div
                        className={'flex flex-row gap-2 justify-content-end width-100'}
                        style={{ flexWrap: 'wrap' }}>
                        {ticketState !== 'REJECT' && ticketState !== 'REFUND' && checkPermission(TICKETS_SCOPES.TICKETS_UPDATE_STATUS) && (
                            <Button
                                className={'buttonm3 flex-1 buttonm3__reset _btn-REJECT flex-1'}
                                onClick={() => checkStatusRequirePassword('REJECT')}
                                disabled={ticketState === 'REFUND' || ticketState === 'REJECT' || issueLoading}>
                                <div>
                                    <i className="fal fa-times mgr-6 " />
                                    {lang.button.reject}
                                </div>
                            </Button>
                        )}

                        {renderActionByState()}
                    </div>
                </Col>
            </Row>
        </>
    )
}
