import { PlusOutlined } from '@ant-design/icons'
import React, { useRef } from 'react'
import { Table, Button, notification } from 'antd'
import { isMobile } from 'react-device-detect'
import { FC, useEffect, useState } from 'react'
import TableEmpty from '../../../components/v2/Error/TableEmpty'
import lang, { trans } from '../../../resources/localization/Localization'
import { SubjectEnums } from '../../../interface/Orders'
import ClaimApi from '../../../repository/ticket'
import { IPagination } from '../../../interface/Antd'
import { AddOrUpdateFinancialType } from './AddOrUpdate'
import storageUtil from '../../../utils/LocalStore/LocalStores'
import { IFinancialType } from '../../../interface/FinancialType'
import { TableCommon, TableCommonRef } from 'src/components/base/TableCommon'
import { uniqueId } from 'lodash'
import { checkPermission } from 'src/utils/helper'
import { TICKETS_SCOPES } from 'src/utils/api/Scopes'
import { ColumnsType } from 'antd/lib/table'

interface IProps {
    orderType: SubjectEnums
    errorCode?: string | number
}

const ListFinancialType: FC<IProps> = ({ orderType = SubjectEnums.Shipment, errorCode }) => {
    const [isShowAddModal, setIsShowAddModal] = useState(false)
    const [financeTypeToEdit, setFinanceTypeToEdit] = useState<IFinancialType>()
    const tableRef: TableCommonRef = useRef()

    const columns: ColumnsType<any> = [
        {
            title: trans('config_finance.code'),
            dataIndex: 'code',
            key: 'code',
            render: (text: string) => text || '---',
        },
        {
            title: trans('config_finance.name'),
            dataIndex: 'name',
            key: 'position',
            render: (text: string) => text || '---',
        },
        {
            title: lang.edit_config_finance.finance_type,
            dataIndex: 'financialAccount',
            key: 'financialAccount',
            render: (text: any) => text?.name || '---',
        },
        {
            title: trans('config_finance.description'),
            dataIndex: 'description',
            key: 'description',
            render: (text: string) => text || '---',
        },
        {
            title: lang.edit_config_finance.prioty,
            dataIndex: 'position',
            key: 'position',
            render: (text: string) => (text !== null ? text : '---'),
        },
    ]

    if (checkPermission(TICKETS_SCOPES.TICKET_FINANCE_UPDATE)) {
        columns.push({
            title: lang.conclusion.actions,
            dataIndex: '',
            key: 'edit',
            render: (_: string, record: any) => (
                <div className={'flex flex-row'}>
                    <div
                        className={'cursor-pointer txt-color-primary text-12'}
                        onClick={() => setFinanceTypeToEdit(record)}>
                        {lang.conclusion.edit}
                    </div>
                </div>
            ),
        })
    }

    return (
        <div>
            <TableCommon
                ref={tableRef}
                tableTitle={lang.config.finance_config}
                actions={
                    checkPermission(TICKETS_SCOPES.TICKET_FINANCE_CREATE)
                        ? [
                              <Button
                                  key={uniqueId()}
                                  className={'button buttonm3 _create-complain-seller'}
                                  onClick={() => setIsShowAddModal(true)}
                                  style={{ display: 'flex', alignItems: 'center' }}>
                                  <PlusOutlined />
                                  {lang.config.create_complaint_seller}
                              </Button>,
                          ]
                        : undefined
                }
                columns={columns}
                emptyText={lang.conclusion.finance_empty_data}
                fetchApiService={(p) => ClaimApi.getFinanceTypes({ ...p, subject: orderType })}
            />

            <AddOrUpdateFinancialType
                open={isShowAddModal || !!financeTypeToEdit}
                onCancel={() => {
                    setIsShowAddModal(false)
                    setFinanceTypeToEdit(undefined)
                }}
                financeTypeToEdit={financeTypeToEdit}
                subject={orderType}
                onSuccess={() => tableRef.current?.refreshData()}
            />
        </div>
    )
}
export default ListFinancialType
