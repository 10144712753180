import { Tooltip } from 'antd'
import { isMobile } from 'react-device-detect'
import storageUtil from '../../../../utils/LocalStore/LocalStores'
import Utils from '../../../../utils'
import lang from '../../../../resources/localization/Localization'
import moment from 'moment'
import { TableCommon } from '../../../../components/base/TableCommon'
import ClaimApi from '../../../../repository/ticket'
import { FC, useContext, useEffect } from 'react'
import { get } from 'lodash'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { ClaimDetailContext } from '../context'

interface IProps {
    setComplaintServiceTotal: (total: number | undefined) => void
}

const ComplaintService: FC<IProps> = ({setComplaintServiceTotal}) => {
    const { code } = useContext(ClaimDetailContext)

    const columns: ColumnsType<any> = [
        {
            title: lang.claim_detail.ticket_code,
            dataIndex: 'code',
            render: (text: any, row: any) => {
                const currentLoggedUser = storageUtil.getCurrentUser()
                return (
                    <div>
                        <div className={'flex flex-row align-items-center'}>
                            <a
                                target="_blank"
                                className={'_service-code text-normal '}
                                href={'/#/tickets/' + text +'?project=' + row?.project}>
                                #{text}
                            </a>
                            {Utils.isUpdateTicket(row) && row.modifiedBy !== currentLoggedUser.username && (
                                <Tooltip
                                    placement="top"
                                    title={lang.message.warning_ticket}>
                                    <i className="fas fa-bell text-10 mgl-6 txt-color-green4 cursor-pointer" />
                                </Tooltip>
                            )}
                        </div>
                        <p
                            style={{ color: '#707070', fontSize: 12 }}
                            className={'_service-shipment'}>
                            {row.shipment ? 'KYGUI' : ''}
                        </p>
                    </div>
                )
            },
        },
        {
            title: lang.claim_detail.info_claim,
            dataIndex: 'name',
            width: isMobile ? 240 : undefined,
            render: (text: any, row: any) => {
                return (
                    <div>
                        <a
                            target="_blank"
                            className={'_service-row-name text-normal'}
                            href={'/#/tickets/' + row.code +'?project=' + row.project}>
                            <span className={'dpl-flex'}>
                                <span className={''}>
                                    <img
                                        alt={'img'}
                                        src={row.imageUrl || Utils.getAvatarUser(row.id)}
                                        style={{ width: 40, height: 40, marginTop: 6 }}
                                        className={' border-radius4 mgr8'}
                                    />
                                </span>
                                <span className={'align-self-center'}>{text}</span>
                            </span>
                        </a>
                    </div>
                )
            },
        },
        {
            title: lang.ticket_add.claim_reason_select_title,
            dataIndex: 'reasonView',
            render: (reasonView: any) => <div className={'flex text-14 _service-reason align-self-center'}>{reasonView?.name}</div>,
        },
        {
            title: lang.claim_detail.customer,
            dataIndex: 'customerName',
            render: (customerName: any) => (
                <div
                    // TODO: mapping api để hiển thị cả fullname
                    // className={"flex text-14 _service-reason align-self-center"}>{row ? (row.customerName || "---") + " (" + (row.username || '---') + ")" : "---"}</div>,
                    className={'flex text-14 _service-reason align-self-center'}>
                    {customerName || '---'}
                </div>
            ),
        },
        {
            title: lang.list_ticket.created_time,
            dataIndex: 'createdAt',
            render: (text: any) => (
                <div className={'_service-createdAt'}>{text ? <span className={'text-14'}>{moment(text).format('DD/MM/YYYY')}</span> : '--'}</div>
            ),
        },
        {
            title: lang.ticket_detail.status,
            dataIndex: 'stateView',
            render: (stateView: any) => {
                return (
                    <div className={'dpl-flex _service-row-state'}>
                        <div
                            className={'align-self-center mgr-5 status-dot '}
                            style={{ background: stateView?.color }}
                        />
                        <span className={'txt-nowrap text-14'}>{get(lang.state_ticket, stateView?.code)}</span>
                    </div>
                )
            },
        },
    ]

    return (
        <div className='mt-3 mb-3'>
            {code && (
                <TableCommon
                    rowPerPage={5}
                    emptyText={lang.claim_detail.ticket_similars_empty}
                    columns={columns as ColumnsType<any>}
                    className={'_table-complain-service'}
                    fetchApiService={(...p) => ClaimApi.getRelative(code, ...p)}
                    scroll={{ x: 'calc(580px + 50%)' }}
                    setPaginationExtenal={({ total }: TablePaginationConfig) => setComplaintServiceTotal(total)}

                />
            )}
        </div>
    )
}
export default ComplaintService
