import { Col, message, Row, Table } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import lang from '../../../resources/localization/Localization'
import moment from 'moment'
import Utils from '../../../utils'
import copyToClipboard from '../../../utils/copyToClipboard'
import { TableCommon } from '../../../components/base/TableCommon'
import ClaimApi from '../../../repository/ticket'
import { ClaimDetailContext } from './context'
import { useProjectAssigned } from 'src/hooks/useProjectAssigned'
import { SubjectEnums } from 'src/interface/Orders'

const AlipayTransactions = () => {
    const { code, detail, project } = useContext(ClaimDetailContext)
    const { currentProject } = useProjectAssigned(project)

    if (detail.ticketType === SubjectEnums.Shipment || !currentProject.komodoComplaintDetailUrl) return null
    // if (detail.ticketType === SubjectEnums.Shipment) return null

    const [data, setData] = useState([])

    const columnsTransactionStatistics = [
        {
            title: lang.claim_detail.transaction_statistic,
            dataIndex: 'name',
            fixed: isMobile ? 'left' : null,
        },
        {
            title: '',
            dataIndex: 'amount',
            align: 'right',
            render: (amount, record) => {
                if (record.key === 3) {
                    return (
                        <div className={'_transAmount text-14 font-medium ' + (amount >= 0 ? 'txt-color-green4' : 'txt-color-red2')}>
                            {Utils.numberFormat(amount) + '¥'}
                        </div>
                    )
                }
                return (
                    <div className={'_transAmount text-14 font-medium ' + (record.key === 1 ? 'txt-color-green4' : 'txt-color-red2')}>
                        {Utils.numberFormat(amount) + '¥'}
                    </div>
                )
            },
        },
        {
            title: '',
            align: 'right',
            dataIndex: 'exchangedAmount',
            render: (exchangedAmount, record) => {
                if (record.key === 3) {
                    return (
                        <div className={'_transExchangedAmount text-14 font-medium ' + (exchangedAmount >= 0 ? 'txt-color-green4' : 'txt-color-red2')}>
                            {Utils.currencyFormatVND(exchangedAmount)}
                        </div>
                    )
                }
                return (
                    <div className={'_transExchangedAmount text-14 font-medium ' + (record.key === 1 ? 'txt-color-green4' : 'txt-color-red2')}>
                        {Utils.currencyFormatVND(exchangedAmount)}
                    </div>
                )
            },
        },
    ]

    const [dataTransactionStatistics, setDataTransactionStatistics] = useState([
        {
            name: lang.claim_detail.payback_transaction,
            amount: 0,
            key: 1,
            exchangedAmount: 0,
        },
        {
            name: lang.claim_detail.spend_transaction,
            amount: 0,
            key: 2,
            exchangedAmount: 0,
        },
        {
            name: lang.claim_detail.transaction_total_count,
            amount: 0,
            key: 3,
            exchangedAmount: 0,
        },
    ])

    useEffect(() => {
        let totalAmount = 0
        let totalAmountCollectionHelp = 0
        let totalExchangedAmount = 0
        let totalExchangedAmountCollectionHelp = 0
        let newDataTransactionStatistics = [].concat(dataTransactionStatistics)
        data.forEach((d) => {
            if (d.collectSpend === 'COLLECT') {
                totalAmountCollectionHelp = d.amount + totalAmountCollectionHelp
                totalExchangedAmountCollectionHelp = d.exchangedAmount + totalExchangedAmountCollectionHelp
            } else {
                totalAmount = d.amount + totalAmount
                totalExchangedAmount = d.exchangedAmount + totalExchangedAmount
            }
        })
        newDataTransactionStatistics[0].amount = totalAmountCollectionHelp
        newDataTransactionStatistics[0].exchangedAmount = totalExchangedAmountCollectionHelp
        newDataTransactionStatistics[1].amount = totalAmount
        newDataTransactionStatistics[1].exchangedAmount = totalExchangedAmount
        newDataTransactionStatistics[2].amount = _.subtract(newDataTransactionStatistics[0].amount, newDataTransactionStatistics[1].amount)
        newDataTransactionStatistics[2].exchangedAmount = newDataTransactionStatistics[0].exchangedAmount - newDataTransactionStatistics[1].exchangedAmount
        setDataTransactionStatistics(newDataTransactionStatistics)
    }, [data])

    const columns = [
        {
            title: lang.claim_detail.time_alipay,
            dataIndex: 'transactionTime',
            fixed: isMobile ? 'left' : null,
            render: (text) => {
                return <div className={'_transactionTime'}>{text ? moment(text).format('HH:mm DD/MM/YYYY') : '---'}</div>
            },
        },
        {
            title: lang.claim_detail.code_alipay,
            dataIndex: 'code',
            width: isMobile ? 200 : null,
            render: (text) => (
                <div
                    onClick={() => copyToClipboard(text).then(() => message.success(`Copy thành công`))}
                    className={'flex text-14 _code align-self-center text-mb-120 cursor-pointer'}>
                    {text}
                </div>
            ),
        },
        {
            title: lang.claim_detail.type_alipay,
            dataIndex: 'type',
            render: (text) => {
                return <div className={'dpl-flex _row-type'}>{lang.alipay_type[text]}</div>
            },
        },
        {
            title: 'CNY',
            align: 'right',
            dataIndex: 'amount',
            render: (text, record) => {
                return (
                    <div className={'_amount font-medium '}>
                        {text ? (
                            <span className={'text-14 ' + (record.collectSpend === 'SPEND' ? 'txt-color-red2' : 'txt-color-green4')}>
                                {record.collectSpend === 'SPEND' ? '-' : ''}
                                {Utils.numberFormat(text) + '¥'}
                            </span>
                        ) : (
                            '--'
                        )}
                    </div>
                )
            },
        },
        {
            title: 'VND',
            dataIndex: 'exchangedAmount',
            align: 'right',
            render: (text, record) => {
                return (
                    <div className={'_exchangedAmount text-14 font-medium ' + (record.collectSpend === 'SPEND' ? 'txt-color-red2' : 'txt-color-green4')}>
                        {record.collectSpend === 'SPEND' ? '-' : ''}
                        {Utils.currencyFormat(text) + '₫'}
                    </div>
                )
            },
        },
    ]
    return (
        <div className={'box'}>
            <div className={'box-title'}>{lang.claim_detail.title_alipay}</div>
            <div className={'mgt16 '}>
                <TableCommon
                    columns={columns}
                    className={'_table-alipay-transaction'}
                    fetchApiService={(...p) => ClaimApi.getAlipayTransaction(code, ...p)}
                    dataSource={data}
                    setDataSource={setData}
                    emptyText={lang.claim_detail.alipay_empty}
                />
            </div>

            <Row
                className={'mgt-12 mb-mgt-50'}
                justify={'end'}>
                <Col
                    xs={24}
                    lg={13}>
                    <Table
                        className={'_table-alipay-transaction-total'}
                        columns={columnsTransactionStatistics}
                        dataSource={dataTransactionStatistics}
                        rowKey={(row) => row.name}
                        scroll={{ x: isMobile ? 'calc(150px + 50%)' : null }}
                        pagination={{
                            hideOnSinglePage: true,
                        }}
                    />
                </Col>
            </Row>
        </div>
    )
}
export default AlipayTransactions
