import { Button, Col, Form, Input, Radio, Row, Select } from 'antd'
import Utils from '../../../utils'
import lang from '../../../resources/localization/Localization'
import { ObjectUtils } from '../../../utils/objectUtils'
import React, { ChangeEvent, Component, useState } from 'react'
import { RouteChildrenProps } from 'react-router'
import { TableCommonRef } from '../../../components/base/TableCommon'
import { SelectProject } from 'src/components/v2/Select/SelectProjects'

interface IProps extends RouteChildrenProps {
    handleFilter: (query: any) => void
}

export default function FilterForm(props: IProps) {
    const query = Utils.getQueryFromLocation(props.location) || {}

    const [filter, setFilter] = useState<any>({
        name: query.name,
        thresholdFrom: query.thresholdFrom,
        thresholdTo: query.thresholdTo,
        email: query.email,
        enabled: query.enabled,
        project: query.project,
    })

    const handleChangeInput = (field: string, e: ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [field]: e?.target?.value,
        })
    }

    const handleChangeThreshold = (values: any) => {
        let data = values?.split(',')
        let thresholdFrom = data && data[0]
        let thresholdTo = (data && data[1] !== 'undefined') ? data[1] : undefined
        setFilter({ ...filter, thresholdFrom, thresholdTo })
    }

    const clearFilter = () => {
        setFilter({})
        Utils.pushCleanQueryToHistory(props.history, props.location, {})
    }

    const handleFilter = () => {
        let newFilter = {
            ...ObjectUtils.removeEmptyValues(filter),
        }
        if(filter.projects) {
            newFilter.projects = filter.projects.toString()
        }
        Utils.pushCleanQueryToHistory(props.history, props.location, newFilter)
    }

    const handleChangeRadio = (e: any) => {
        setFilter({
            ...filter,
            enabled: e.target.value,
        })
    }

    let approve_limit = undefined

    if (filter.thresholdFrom || filter.thresholdTo) {
        approve_limit = [filter.thresholdFrom, filter.thresholdTo].toString()
    }

    return (
        <div className={'staff-filter-form'}>
            <p className={'title'}>{lang.filter_staff}</p>
            <Form className={'_filter_form'}>
                <Row gutter={12}>
                    <Col
                        xs={24}
                        sm={12}
                        md={12}
                        lg={8}
                        xl={8}>
                        <Form.Item>
                            <Input
                                placeholder={lang.staff.staff_name}
                                className={'_username'}
                                value={filter.name}
                                onChange={(e) => handleChangeInput('name', e)}
                            />
                        </Form.Item>
                    </Col>

                    <Col
                        xs={24}
                        sm={12}
                        md={12}
                        lg={8}
                        xl={8}>
                        <Form.Item>
                            <Input
                                placeholder={'Email'}
                                className={'_email'}
                                value={filter.email}
                                onChange={(e) => handleChangeInput('email', e)}
                            />
                        </Form.Item>
                    </Col>

                    <Col
                        xs={24}
                        sm={12}
                        lg={8}
                        xl={8}>
                        <Form.Item>
                            <Select
                                placeholder={lang.staff.threshold_number}
                                allowClear
                                className={'_threshold'}
                                value={approve_limit}
                                onChange={(value) => {
                                    handleChangeThreshold(value)
                                }}
                                options={[
                                    {
                                        label: '0-1M',
                                        value: '0,1000000',
                                    },
                                    {
                                        label: '1M-10M',
                                        value: '1000000,10000000',
                                    },
                                    {
                                        label: '10M-100M',
                                        value: '10000000,100000000',
                                    },
                                    {
                                        label: '>100M',
                                        value: '100000000,',
                                    },
                                ]}></Select>
                        </Form.Item>
                    </Col>

                    <Col
                        xs={24}
                        sm={12}
                        lg={8}>
                        <Form.Item>
                            <SelectProject
                                value={filter.projects}
                                mode={'multiple'}
                                onChange={(value) => setFilter({ ...filter, projects: value})}
                            />
                        </Form.Item>
                    </Col>

                    <Col
                        xs={24}
                        sm={12}
                        lg={16}>
                        <Form.Item>
                            <span>Trạng thái : </span>
                            <Radio.Group
                                value={filter.enabled}
                                onChange={handleChangeRadio}>
                                <Radio
                                    key={''}
                                    value={undefined}>
                                    Tất cả
                                </Radio>
                                <Radio
                                    key={1}
                                    value={'true'}>
                                    {lang.staff.active_staff}
                                </Radio>
                                <Radio
                                    key={0}
                                    value={'false'}>
                                    {lang.staff.inactive_staff}
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <div className={'filter-footer mgt8 pdbt24 dpl-flex justify-content-end'}>
                    <div className={'left-content'}></div>
                    <div className={'right-content '}>
                        <Button
                            className={'__reset _reset-filter mgr8'}
                            onClick={clearFilter}>
                            <div>
                                <i className="fal fa-undo mgr-6" />
                                {lang.list_ticket.clear_filter}
                            </div>
                        </Button>
                        <Button
                            className={' _search'}
                            htmlType="submit"
                            onClick={handleFilter}>
                            <i className="fal fa-search mgr-6" />
                            {lang.staff.filter_action}
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    )
}
