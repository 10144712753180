import {Button} from "antd";
import { ModalCommon } from "src/components/base/ModalCommon";
import lang from "../../../resources/localization/Localization";

const ModalConfirm = ({visible, onOk, onCancel, title, content}) => {
    return <ModalCommon open={visible} onOk={onOk} onCancel={onCancel} footer={null} header={null}
                  closable={false}
                  closeIcon={<i className="fal fa-times-circle"/>}>
        <div>
            <div className={"flex flex-row space-between mgbt8 align-items-center"}>
                <div className={"bold text-16 "}>{title}</div>
                <i className="fal fa-times-circle txt-color-gray2 cursor-pointer" onClick={onCancel}/>
            </div>
            <div className={"border-line width100"}/>
            <div className={"mgt-24 mgb-24"}>
                {content}
            </div>
            <div className={"flex flex-row width100 flex-content-end"}>
                <Button onClick={onCancel} className={"buttonm3__reset mgr-8 _btn-cf-cancel"}>
                    {lang.button.cancel}
                </Button>
                <Button onClick={onOk} className={"buttonm3 _btn-cf-submit"}>
                    <i className="fas fa-check mgr-5"/>
                    {lang.button.yes}
                </Button>
            </div>
        </div>
    </ModalCommon>
}
export default ModalConfirm
