const Footer = () => {
    return <div className={"bg-white footer ticket-footer"}>
        <div className={"flex flex-row justify-content-end mgr-24"}>
            <div className={"txt-color-gray2 txt-size-h8"}>
                2021© GoBiz
            </div>
        </div>
    </div>
}
export default Footer
