import React, {Component} from "react";
import MainLayout from "../../components/v2/Layout/MainLayout";
import Spiner from "../../components/Spiner";
import {FilterUtils} from "../../utils/filterUtils";
import {LocalStore} from "../../utils/LocalStore";
import lang from "../../resources/localization/Localization";
import { getToken } from "../../helpers/localStorage";

let pageSize = 25;

let currentProjectInfo = null;

class Report extends Component {
    constructor(props) {
        super(props);
        this.isUnmounted = false;
        this.state = {
            isTablet: window.innerWidth <= 1400,
            listEstimatedCost: [],
            pageSize: pageSize,
            openModal: false,
            openShow: false,
            openEdit: false,
            openEditSale: false,
            breadCrumb: [
                {title: 'Báo cáo'}
            ],
            record: {},
            phaseEdit: {},
            recordEdit: {},
            listFinanceTypes: [],
            formula: {},
            name_config: '',
            time: '',
            timeEdit: '',
            code: '',
            isShow: false,
            isLoading: false,
            typeOrder: false,
            errors: {},
            offier: {},
        }
    };

    componentDidMount() {
        // window.addEventListener('resize', this.windowResize);

        if (typeof LocalStore.getInstance().read('currentProjectInfo') === 'object') {
            currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo');
        }
    }

    /**
     * Khi unmount ra thì bỏ sự kiện popstate
     */
    componentWillUnmount() {
        this.isUnmounted = true;
        if (typeof LocalStore.getInstance().read('currentProjectInfo') === 'object') {
            currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo');
        }
        window.removeEventListener('resize', this.windowResize);
    }

    /**
     * bắt sự kiện resize window
     */
    windowResize = () => {
        if (!this.isUnmounted) this.setState({
            isTablet: window.innerWidth < 1200
        });
    };

    render() {
        let token = getToken()

        if (typeof LocalStore.getInstance().read('currentProjectInfo') === 'object') {
            currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo');
        }

        let targetUrl = currentProjectInfo && currentProjectInfo.domain ? currentProjectInfo.domain : null;
        return <MainLayout title={lang.sidebar.reporter}>
            <div className="statistics" style={{'textAlign': 'center'}}>
                {
                    targetUrl && token ?
                        <iframe
                            allowFullScreen
                            frameBorder="0"
                            src={`https://datastudio.google.com/embed/reporting/5d61f0b5-066c-4594-8720-158a40045e71/page/P4BqB?params={"1stToken":"${token}","1stToken_direction":"${targetUrl}","2ndToken":"${token}","2ndToken_direction":"${targetUrl}"}`}
                            style={{
                                width: '90%',
                                height: window.innerHeight + 500,
                                border: 0,
                                marginLeft: '10px'

                            }}
                            title="statistic"
                        /> :
                        <Spiner/>
                }

            </div>
        </MainLayout>
    }
}

export default Report
