const md5 = require('blueimp-md5')

/**
 * Lấy module hiện tại trên URL
 * @returns {string}
 */
export function getAvatarUser(email) {
    return '//www.gravatar.com/avatar/' + md5(email || '') + '?d=retro&s=40&r=g'
}

/**
 * Parse query string to an object
 * @param queryString
 * @returns {{}}
 */
export function parseQueryStringToObject(queryString) {
    if (queryString && queryString.indexOf('?') > -1) {
        queryString = queryString.replace('?', '')
    }

    let params = {},
        queries,
        temp,
        i,
        l
    // Split into key/value pairs
    queries = queryString.split('&')
    // Convert the array of strings into an object
    for (i = 0, l = queries.length; i < l; i++) {
        temp = queries[i].split('=')
        params[temp[0]] = temp[1]
    }
    return params
}

/**
 * thêm mới hoặc sửa lại param của query string
 * @param uri
 * @param key
 * @param value
 * @returns {*}
 */
export function updateQueryStringParameter(uri, key, value) {
    if (uri === undefined || uri === null) return ''
    let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
    let separator = uri.indexOf('?') !== -1 ? '&' : '?'
    if (value === null) {
        return uri.replace(re, '$1' + '$2')
    }
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + encodeURIComponent(value) + '$2')
    } else {
        return uri + separator + key + '=' + encodeURIComponent(value)
    }
}

/**
 * format số, số lượng có dấu , phân cách hàng nghìn
 * @param value
 * @param unit
 * @returns {string}
 */
export const quantityFormat = (value, unit = '') => {
    if (value === null || value === undefined || value === '') return '---'
    //loại bỏ tất cả các ký tự ',' trong value
    let temp = value.toString().replace(/[,-]/g, '')
    if (unit) temp = value.toString().replace(unit, '')
    //thay dấu thập phân bằng ','
    temp = temp.replace(/[.-]/g, ',')
    let tempArray = temp.split(',')
    //tách hàng nghìn bằng ký tự '.'
    return tempArray[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + (tempArray[1] ? `,${tempArray[1]}` : '') + unit
}

/**
 * format tiền, số lượng có dấu , phân cách hàng nghìn, có đơn vị tiền tệ
 * @param value
 * @param unit
 * @param noNegative
 * @returns {string}
 */
export const moneyFormat = (value, unit, noNegative) => {
    if (value === null || value === '' || value === undefined) return '---'
    let unitTxt = ''
    switch (unit) {
        case 'CNY':
            unitTxt = '¥'
            break
        case 'USD':
            unitTxt = '$'
            break
        default:
            unitTxt = '₫'
            break
    }
    return (!noNegative && parseFloat(value) < 0 ? '-' : '') + quantityFormat(value) + unitTxt
}

/**
 * format tiền, số lượng có dấu , phân cách hàng nghìn, có đơn vị tiền tệ
 * @param value
 */
export const moneyFormatV2 = (value) => {
    if (value === null || value === '' || value === undefined) return 0
    return value
}

/**
 * format tiền, số lượng có dấu , phân cách hàng nghìn, có đơn vị tiền tệ
 * @param value
 * @param unit
 * @param noNegative
 * @returns {string}
 */
export const moneyFormatV3 = (value, unit, noNegative) => {
    let unitTxt = ''
    switch (unit) {
        case 'CNY':
            unitTxt = '¥'
            break
        case 'USD':
            unitTxt = '$'
            break
        default:
            unitTxt = '₫'
            break
    }
    if (value === null || value === '' || value === undefined) return moneyFormatV2(value) + unitTxt

    return (!noNegative && parseFloat(value) < 0 ? '-' : '') + quantityFormat(value) + unitTxt
}

/**
 *
 * @param text
 * @returns {string}
 */
export const linkify = (text) => {
    if (!text) return ''
    let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
    return text.replace(urlRegex, function (url) {
        return '<a target="_blank" href="' + url + '">' + url + '</a>'
    })
}

/**
 * Tính số giây đến hạn xử lý
 * @param dateString
 * @return boolean
 */
export const secondsDeadline = (dateString) => {
    if (!dateString) return

    let currentTime = new Date()
    let deadlineTime = new Date(dateString)
    let seconds = deadlineTime - currentTime
    return seconds
}

/**
 * Tính số giờ đến hạn xử lý
 * @param dateString
 * @return boolean
 */
export const hoursDeadline = (dateString) => {
    if (!dateString) return

    let currentTime = new Date()
    let deadlineTime = new Date(dateString)
    let seconds = deadlineTime - currentTime
    let hours = Math.ceil(seconds / (3600 * 1000))
    return hours
}

export const capitalizeFirstLetter = (str) => {
    if (!str) return ''
    return str.toString().charAt(0).toUpperCase() + str.toString().slice(1)
}

export function removeAccents(str) {
    return str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/đ/g, 'd')
        .replace(/Đ/g, 'D')
}

export const formatterNumber = (val) => {
    if (!val) return '0'
    return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.').replace(/\.(?=\d{0,2}$)/g, ',')
}

export const formatterNumberUsingComma = (val) => {
    if (!val) return '0'
    return quantityFormat(val).replace('.', ',')
}

export const parserNumber = (val) => {
    if (!val) return '0'
    return Number.parseFloat(val.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.')).toFixed(2)
}
