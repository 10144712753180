import { Table, Skeleton, TableProps } from 'antd';

interface SkeletonTableProps extends TableProps<any> {
    rowCount?: number,
}

const SkeletonTable = ({ columns = [], rowCount }: SkeletonTableProps) => {
  return (
    <Table
      rowKey="key"
      pagination={false}
      dataSource={[...Array(rowCount)].map((_, index) => ({
        key: `key${index}`,
      }))}
      columns={columns.map((column) => {
        return {
          ...column,
          render: function renderPlaceholder() {
            return (
              <Skeleton
                key={column.key}
                title={false}
                paragraph={{
                    rows: 3
                }}
              />
            );
          },
        };
      })}
    />
  );
};

export default SkeletonTable;