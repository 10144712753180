import { notification, Select } from "antd";
import { useEffect, useState } from "react";
import ClaimApi from "../../../repository/ticket";
import lang from "../../../resources/localization/Localization";
import storageUtil from "../../../utils/LocalStore/LocalStores";

const SelectStaffMistake = ({ data, setData = () => null, onChange, value, disabled, currentProject, uniqueKey = "code" }) => {
    const [loading, setLoading] = useState(false)
    const [officers, setOfficer] = useState([])

    const project = currentProject || storageUtil.getItem('selectedProject');

    useEffect(() => {
        if (data?.length > 0) {
            setOfficer(data)
            return
        }

        setLoading(true)
        ClaimApi.getStaffMistakeNoPermission({ offset: 0, limit: 1000, project }).then(r => {
            setOfficer(r.data)
            setData(r.data)
        }).catch((e) => {
            notification.error({ message: e.message })
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return <Select
        disabled={disabled}
        onChange={(value, option) => !disabled && onChange(value, option)}
        value={loading ? undefined : value}
        placeholder={lang.conclusion.select_offender}
        loading={loading}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => option?.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0 || (option?.children[2] || "").toLowerCase().indexOf(input.toLowerCase()) >= 0}
        className={"_select-officer width100"}>
        {officers.map(officer =>
            <Select.Option key={officer[uniqueKey]}
                value={officer[uniqueKey]}
                className={"_officer _officer" + officer[uniqueKey]}>
                {officer.name} - {officer.code}
            </Select.Option>)}
    </Select>
}

export default SelectStaffMistake
