import { Table, TablePaginationConfig, TableProps } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'
import { uniqueId } from 'lodash'
import { forwardRef, ForwardRefExoticComponent, MutableRefObject, ReactNode, RefAttributes, useEffect, useImperativeHandle, useState } from 'react'
import { DataTableCriteria } from '../../interface/ServiceApi'
import lang, { trans } from '../../resources/localization/Localization'
import storageUtil from '../../utils/LocalStore/LocalStores'
import TableEmpty from '../v2/Error/TableEmpty'
import Utils from '../../utils'
import { useLocation } from 'react-router'

interface IProps extends TableProps<any> {
    tableTitle?: string
    emptyText?: string
    actions?: JSX.Element[] | false
    noPagination?: boolean
    divClassName?: string
    rowPerPage?: number
    removeProjectDefault?: boolean
    fetchApiService?: (filter: ITableCommonFilter) => Promise<any>
    setDataSource?: (data: any) => void
    setPaginationExtenal?: (p: TablePaginationConfig) => void
    renderFilterForm?: (...p: any) => ReactNode
    filterData?: (row: any) => boolean
}

export interface TableCommonRefMethod {
    refreshData: () => void
    fetchData: (filterForm: { [key: string]: string }) => void
}

export type TableCommonRef = MutableRefObject<TableCommonRefMethod | undefined>

export type ITableCommonFilter = DataTableCriteria

export const LIMIT_DEFAULT = 25

export const TableCommon: ForwardRefExoticComponent<IProps & RefAttributes<TableCommonRefMethod | undefined>> = forwardRef(
    (
        {
            fetchApiService,
            renderFilterForm,
            setDataSource = () => null,
            setPaginationExtenal = () => null,
            filterData,
            removeProjectDefault = false,
            rowPerPage = LIMIT_DEFAULT,
            actions,
            tableTitle,
            emptyText,
            dataSource,
            noPagination = false,
            divClassName,
            ...props
        }: IProps,
        ref: any
    ) => {
        const { columns = [] } = props
        const [loading, setLoading] = useState<boolean>()
        const [pagination, setPagination] = useState<TablePaginationConfig>({
            current: 1,
            pageSize: rowPerPage,
        })
        const [data, setData] = useState<any>()
        const [errorCode, setErrorCode] = useState<number>()

        const project = storageUtil.getItem('selectedProject')

        const location = useLocation()
        const queryParamsFilter = Utils.getQueryFromLocation(location)
        const queryParams = new URLSearchParams(location.search)

        const fetchData = (filterForm: any = {}, paginationTable: TablePaginationConfig = {}) => {
            if (!fetchApiService) return
            setLoading(true)

            Object.keys(filterForm).forEach((k) => (filterForm[k] === null || filterForm[k] === '') && delete filterForm[k])

            const defaultFilter: ITableCommonFilter = {
                offset: paginationTable.current ? (Number(paginationTable.current) - 1) * Number(paginationTable.pageSize) : 0,
                limit: paginationTable.pageSize ? Number(paginationTable.pageSize) : rowPerPage,
                sort: 'createdAt:desc',
                project,
            }

            if (removeProjectDefault) {
                delete defaultFilter.project
            }

            const filter: ITableCommonFilter = {
                ...defaultFilter,
                ...queryParamsFilter,
                ...filterForm,
            }

            fetchApiService(filter)
                .then(({ headers, data }: AxiosResponse<any>) => {
                    const dataFiltered = filterData ? data.filter(filterData) : data

                    if (dataSource) {
                        setDataSource(dataFiltered)
                    } else {
                        setData(dataFiltered)
                    }

                    const p = {
                        current: Number(headers['x-page-number']) + 1,
                        total: Number(headers['x-total-count']),
                        pageSize: Number(headers['x-page-size']),
                    }

                    setPagination(p)
                    setPaginationExtenal(p)
                })
                .catch((err: AxiosError) => {
                    setErrorCode(err.response?.status || 502)
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        const onChange = (pagination: TablePaginationConfig) => {
            fetchData(undefined, pagination)
        }

        useEffect(() => {
            fetchData()
        }, [queryParams.toString()])

        useImperativeHandle(
            ref,
            (): TableCommonRefMethod => ({
                refreshData: () => {
                    fetchData()
                },
                fetchData,
            })
        )

        const title = tableTitle ? (
            <div className="flex items-center">
                <span>{tableTitle}</span> {<span className={'total'}>{pagination.total || 0}</span>}
            </div>
        ) : undefined

        return (
            <>
                {renderFilterForm && renderFilterForm({ handleFilter: fetchData })}
                <div className={!divClassName ? (!!renderFilterForm ? 'list-data' : undefined) : divClassName}>
                    <Table
                        title={() =>
                            (title || actions) && (
                                <div className={`flex ${title ? 'justify-content-between pb-3' : 'justify-content-end pb-2'}  items-center`}>
                                    {title}
                                    <div className="flex gap-3">{actions}</div>
                                </div>
                            )
                        }
                        loading={loading}
                        dataSource={dataSource || data}
                        rowKey={(row) => row.id || row.code}
                        onChange={onChange}
                        locale={{
                            emptyText: (
                                <TableEmpty
                                    emptyText={emptyText || 'No Data'}
                                    loading={undefined}
                                    errorCode={errorCode}
                                />
                            ),
                        }}
                        scroll={{ x: columns?.length > 4 ? 1100 : 675 }}
                        {...props}
                        pagination={
                            !noPagination && {
                                ...pagination,
                                pageSizeOptions: [10, 25, 50, 100],
                                showSizeChanger: true,
                                hideOnSinglePage: true,
                                // showTotal: (total) => `${lang.conclusion.total} ${total} ${lang.conclusion.record}`,
                                showTotal: (total, range) =>
                                    trans('table.total', {
                                        range0: range[0],
                                        range1: range[1],
                                        total: total,
                                    }),
                                locale: { items_per_page: '/ trang' },
                                ...props.pagination,
                            }
                        }
                    />
                </div>
            </>
        )
    }
)
