import DocumentTitle from 'react-document-title'
import { Layout, notification } from 'antd'
import Header from './Header'
import { FC, ReactNode, useEffect, useState } from 'react'
import storageUtil from '../../../utils/LocalStore/LocalStores'
import { useHistory, useLocation } from 'react-router'
import TenantApi from '../../../repository/tenant'
import ChangePin from '../Modal/ChangePin'
import AccountApi from '../../../repository/account'
import lang from '../../../resources/localization/Localization'
import NewSideBar from './NewSideBar'
import { defaultAppContextValue } from '../../../context/AppContext'
import Utils from 'src/utils'
import ClaimApi from '../../../repository/ticket'

const { Content } = Layout

interface IProps {
    children?: ReactNode
    loading?: boolean
    title?: string
}

const MainLayout: FC<IProps> = ({ children, loading = false, title = '' }) => {
    const [collapsed, setCollapsed] = useState(true)
    const [isShowChangePin, setShowChangePin] = useState(false)
    const [loadingChangePin, setLoadingChangePin] = useState(false)
    const [loadingResetPin, setLoadingResetPin] = useState(false)
    const [assignedProjects, setAssignedProjects] = useState([])
    const [selectedProject, setSelectedProject] = useState('')
    const [pageTitle] = useState(title ? `${title}` : '')
    const location = useLocation()
    const [loadingProjects, setLoadingProjects] = useState(true)
    const [claimStates, setClaimStates] = useState();
    const [totalClaims, setTotalClaims] = useState<number>(0);



    useEffect(() => {
        getProjects();
    }, [])

    const getProjects = () => {
        setLoadingProjects(true)
        TenantApi.getAssignedProjects().then((res) => {
            setAssignedProjects(res.data)

            let projectCode = storageUtil.getItem('selectedProject') || ''
            const projectSavedIndex = res.data?.findIndex((p: any) => p.project === projectCode)

            if (projectSavedIndex === -1) {
                projectCode = res.data[0].project
            }
            getClaimState(projectCode);
            setSelectedProject(projectCode)
            storageUtil.setItem('selectedProject', projectCode)
            storageUtil.setJson('projects', res.data)
        }).finally(() => {
            setLoadingProjects(false)
        })
    }

    const getClaimState = (project: any) => {
        ClaimApi.getStatesByProject(project).then(res => {
            setClaimStates(res.data || []);
            // console.log(res.data)
            let total = 0;
            res.data.map((x: any) => total += x.numberOfClaim)
            setTotalClaims(total)
        }).catch(e => {
            console.log("e", e)
        })
    }

    const onShowChangePin = () => {
        setShowChangePin(true)
    }

    const onChangePin = (values: any) => {
        setLoadingChangePin(true)
        AccountApi.changePin(values)
            .then((res) => {
                setShowChangePin(false)
                notification.success({ message: lang.update_pin.update_success })
            })
            .catch((err) => {
                if (err?.response?.data?.title === 'invalid_pin') {
                    notification.error({ message: lang.update_pin.incorrect_pin })
                } else {
                    notification.error({ message: lang.update_pin.update_failed })
                }
            })
            .finally(() => {
                setLoadingChangePin(false)
            })
    }

    const onResetPin = (values: any) => {
        setLoadingResetPin(true)
        AccountApi.resetPin(values)
            .then(() => {
                notification.success({ message: lang.update_pin.message_after_success })
            })
            .catch((err) => {
                notification.error({ message: lang.update_pin.reset_pin_failed })
            })
            .finally(() => {
                setLoadingResetPin(false)
            })
    }

    useEffect(() => {
        window.addEventListener('click', (event) => {
            // console.log(event)
        })
    }, [])

    return (
        <div>
            <DocumentTitle title={pageTitle}>
                <Layout className={'main-layout'}>
                    <NewSideBar
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                        totalClaims={totalClaims}
                        claimStates={claimStates}
                    />
                    <Layout>
                        <Header
                            setCollapsed={setCollapsed}
                            title={pageTitle}
                            projects={assignedProjects}
                            selectedProject={selectedProject}
                            onShowChangePin={onShowChangePin}
                            onShowResetPin={onResetPin}
                            loadingResetPin={loadingResetPin}
                            loading={loading}
                        />
                        <Content className={'main-content height100'}>
                            <div className='content-container'>
                                {!loadingProjects && children}
                                <ChangePin
                                    visible={isShowChangePin}
                                    onCancel={() => setShowChangePin(false)}
                                    loading={loadingChangePin}
                                    onOk={onChangePin}
                                />
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </DocumentTitle>
        </div>
    )
}
export default MainLayout
