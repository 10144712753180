import { notification, Select } from "antd";
import { useEffect, useState } from "react";
import ClaimApi from "../../../repository/ticket";
import lang from "../../../resources/localization/Localization";

const SelectMistake = ({ data, setData = () => null, onChange, value, disabled, uniqueKey = "code" }) => {
    const [loading, setLoading] = useState(false)
    const [mistakes, setMistake] = useState([])
    useEffect(() => {
        if (data?.length > 0) {
            setMistake(data)
            return

        }
        setLoading(true)
        ClaimApi.getMistakes({
            limit: 200,
            offset: 0,
            sort: 'code:asc'
        }).then(r => {
            setMistake(r.data)
            setData(r.data)
        }).catch((e) => {
            notification.error({ message: e.message })
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return <Select disabled={disabled} onChange={onChange}
        value={loading ? undefined : value}
        placeholder={lang.mistake_selector.select} loading={loading}
        className={"_select-mistake width100"}>
        {mistakes.map(mistake =>
            <Select.Option key={mistake[uniqueKey]} value={mistake[uniqueKey]} className={"_mistake _mistake" + mistake[uniqueKey]}>
                {mistake.name}
            </Select.Option>)}
    </Select>
}

export default SelectMistake
