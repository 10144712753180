import UserApi from '../../../repository/user'
import lang, { trans } from '../../../resources/localization/Localization'
import { ILog, StaffLogEvents } from '../../../interface/Log'
import { get, uniqueId } from 'lodash'
import { IStaff, IStaffAssignment } from '../../../interface/Staff'
import { FC, useMemo } from 'react'
import { useProjects } from '../../../hooks/useProjects'
import Utils from '../../../utils'
import { LogModal } from 'src/components/Logs/LogModal'
import { ChangedIcon, FromText } from 'src/components/Logs/ChangedIcon'

interface IStaffLog extends ILog<StaffLogEvents> { }

export const StaffModificationLog = ({ isShow, ...props }: any) => {
    const { projectNameMapping } = useProjects()

    const renderLogItem = (row: IStaffLog) => {
        switch (row.activity) {
            case StaffLogEvents.CREATED:
                return (
                    <LogCreated
                        logData={row}
                        projectNameMapping={projectNameMapping}
                    />
                )
            case StaffLogEvents.UPDATED_ASSIGNMENT:
                return (
                    <LogUpdated
                        logData={row}
                        projectNameMapping={projectNameMapping}
                    />
                )
            case StaffLogEvents.STAFF_LOGIN_UPDATED:
            case StaffLogEvents.STAFF_LOGIN_CREATED:
                return (
                    <>
                        {trans('staff.log_active')} <FromText /> <strong>{trans('staff.inactive_staff')}</strong> <ChangedIcon />
                        <strong>{trans('staff.active_staff')}</strong>
                    </>
                )
            default:
                return null
        }
    }

    const renderActions = (row: IStaffLog) => {
        return <div className={'_action'}>{renderLogItem(row)}</div>
    }

    return (
        <LogModal
            open={isShow}
            title={
                <div className={'txt-color-green4 bold text-16'}>
                    {lang.log.staff_change_log} <span className="txt-lowercase">{props.staffId} </span>
                </div>
            }
            fetchApiService={() => UserApi.getLogs(props.staffId)}
            onCancel={props.onCancel}
            renderActions={renderActions}></LogModal>
    )
}

interface ILogContentProps {
    logData: IStaffLog
    projectNameMapping: { [key in string]: string }
}

const LogCreated: FC<ILogContentProps> = ({ logData, projectNameMapping }) => {
    const newObject: IStaff = get(logData, 'change.newObject[0]', {} as any)

    const assignments = newObject.assignments || []

    return (
        <div>
            {trans('staff.log_add_user', { email: newObject.email }, 'span')}
            {!!assignments.length && (
                <>
                    <br />
                    {trans('staff.assign_project')}:{' '}
                    {assignments?.map((assignment: IStaffAssignment, index: number) => (
                        <span key={uniqueId()}>
                            <span className="txt-nowrap">
                                {projectNameMapping[assignment.project]}: <span className="font-medium">{Utils.currencyFormatVND(assignment.threshold)}</span>
                            </span>
                            {index < assignments.length - 1 && ', '}
                        </span>
                    ))}
                </>
            )}
        </div>
    )
}

type ThresholdsLogType = {
    [key in string]: {
        prev: number | null
        next: number | null
    }
}

const LogUpdated: FC<ILogContentProps> = ({ logData, projectNameMapping }) => {
    const prevAssignments: IStaffAssignment[] = get(logData, 'change.valueChange.changedValues[0].left', [])
    const nextAssignments: IStaffAssignment[] = get(logData, 'change.valueChange.changedValues[0].right', [])

    // tự lấy ra danh sách dự án cũ và mới để compare
    const { prevProjects, nextProjects, thresholds } = useMemo(() => {
        const thresholds: ThresholdsLogType = {}

        const prevProjects: string[] = prevAssignments.map(({ threshold, project }) => {
            thresholds[project] = { prev: threshold, next: null }
            return project
        })

        const nextProjects: string[] = nextAssignments.map(({ project, threshold }) => {
            thresholds[project] = thresholds[project] || { prev: null, next: threshold }

            if (thresholds[project]?.prev === threshold) {
                delete thresholds[project]
                return project
            }

            thresholds[project].next = threshold
            return project
        })

        return { prevProjects, nextProjects, thresholds }
    }, [prevAssignments])

    const isShowUpdateAssignment: boolean = useMemo((): boolean => {
        if (prevAssignments.length !== nextAssignments.length) return true

        let isShow = false
        prevProjects.every((prevProject) => {
            if (!nextProjects.includes(prevProject)) {
                isShow = true
                return false
            }
            return true
        })
        return isShow
    }, [prevAssignments, nextAssignments])

    const isShowThres: boolean = useMemo((): boolean => {
        let isShow = false
        let allRightNull = false

        Object.keys(thresholds).forEach((nextThresholdKey) => {
            const prev = thresholds[nextThresholdKey].prev
            const next = thresholds[nextThresholdKey].next

            // nếu tất cả giá trị cũ và mới đều null thì ko hiện
            if (prev !== null || next !== null) {
                isShow = true
            }

            // và nếu tất cả giá trị mới đều null thì ko hiện
            if (next !== null) {
                allRightNull = true
            }
        })

        return isShow && allRightNull
    }, [thresholds])

    const renderAssignments = (assignments: IStaffAssignment[]) => {
        if (assignments?.length < 1) return '---'

        return assignments.map((assignment, index: number) => (
            <span
                key={uniqueId()}
                className="font-medium">
                {projectNameMapping[assignment.project]}
                {index < assignments.length - 1 && ', '}
            </span>
        ))
    }

    const renderThres = () => {
        if (!Object.keys(thresholds).length) return null

        return (
            <span>
                <strong>{trans('staff.log_update_threshold')}: </strong>
                {Object.keys(thresholds).map((nextThresholdKey) => {
                    const prev = thresholds[nextThresholdKey].prev
                    const next = thresholds[nextThresholdKey].next
                    if (prev === null && next === null) return null

                    if (next === null) return null

                    return (
                        <span key={uniqueId()}>
                            <br />
                            <span className="txt-nowrap">
                                <strong> {projectNameMapping[nextThresholdKey]}</strong> <FromText />{' '}
                                {<span className="font-medium">{Utils.currencyFormatVND(prev)}</span>}
                                <ChangedIcon />
                                {<span className="font-medium">{Utils.currencyFormatVND(next)}</span>}
                            </span>
                        </span>
                    )
                })}
            </span>
        )
    }

    return (
        <div>
            {isShowUpdateAssignment && (
                <>
                    <strong>{trans('staff.log_update_projects')}</strong> <FromText /> {renderAssignments(prevAssignments)} <ChangedIcon /> {renderAssignments(nextAssignments)}
                    <br />
                </>
            )}
            {isShowThres && renderThres()}
        </div>
    )
}
