import { Col, Row } from 'antd'
import lodash from 'lodash'
import { FC } from 'react'
import { IOrder } from 'src/interface/Orders'
import { ITicket } from 'src/interface/Ticket'
import lang from 'src/resources/localization/Localization'
import Utils from 'src/utils'
import { Content, Label, RowContainer, RowInfo } from '../components/Row'

interface IProps {
    detail: ITicket
    targetUrl: string
    order: IOrder
}

export const PaymentRequireInfo: FC<IProps> = ({ detail, targetUrl, order }) => {
    return (
        <div className="mgt-16">
            {detail.ticketType === 'peerpayment' ? (
                <>
                    <div className={'flex flex-row align-items-center'}>
                        <div className={'text-14 font-medium txt-color-green4 mgr15'}>{lang.ticket_detail.peerpayment_info}</div>
                        <div
                            className={'border-line width100'}
                            style={{ flex: 4 }}
                        />
                    </div>

                    <RowContainer>
                        <RowInfo label={lang.ticket_detail.fullName}>
                            <a
                                target={targetUrl && lodash.get(order, 'customer.username') ? '_blank' : '_self'}
                                href={
                                    targetUrl && lodash.get(order, 'customer.username')
                                        ? `${targetUrl}/#/customers/${lodash.get(order, 'customer.username')}`
                                        : '#'
                                }
                                className={'txt-color-blue2 txt-size-h7 robotoregular _customer-name'}>
                                {lodash.get(order, 'customer.fullname', '---') + ' (' + lodash.get(order, 'customer.username', '---') + ')'}
                            </a>
                        </RowInfo>

                        <RowInfo
                            label={lang.ticket_detail.phone}
                            className="_customer-phone">
                            {lodash.get(order, 'customer.phone', '---')}
                        </RowInfo>

                        <RowInfo
                            label="Email"
                            className="_customer-email">
                            {lodash.get(order, 'customer.email', '---')}
                        </RowInfo>

                        <RowInfo
                            label={lang.ticket_detail.customer_code}
                            className="_customerId _customer-code">
                            {(lodash.get(order, 'customer.code', '---') || '---') + '/' + lodash.get(order, 'customer.level.name', '---')}
                        </RowInfo>

                        <RowInfo
                            label={lang.ticket_detail.exchange_rate}
                            className="_exchangeRate">
                            {Utils.currencyFormat(lodash.get(order, 'exchangeRate', '---')) + 'đ'}
                        </RowInfo>

                        <RowInfo
                            label={lang.claim_detail.amount_alipay}
                            className="_totalPaid">
                            {Utils.currencyFormat(lodash.get(order, 'totalPaid', '---')) + 'đ'}
                        </RowInfo>

                        <RowInfo label={lang.ticket_detail.fee}>{Utils.currencyFormat(lodash.get(order, 'totalFee', '--')) + 'đ'}</RowInfo>
                    </RowContainer>
                </>
            ) : (
                <>
                    <div className={'flex flex-row align-items-center'}>
                        <div className={'text-14 font-medium txt-color-green4 mgr15'}>{lang.claim_detail.order_info}:</div>
                        <div
                            className={'border-line width100'}
                            style={{ flex: 4 }}
                        />
                    </div>
                    <RowContainer>
                        <RowInfo label={lang.claim_detail.customer_name}>
                            <a
                                target={targetUrl && lodash.get(order, 'customer.username') ? '_blank' : '_self'}
                                href={
                                    targetUrl && lodash.get(order, 'customer.username')
                                        ? `${targetUrl}/#/customers/${lodash.get(order, 'customer.username')}`
                                        : '#'
                                }
                                className={'txt-color-blue2 txt-size-h7 robotoregular _customer-name'}>
                                {lodash.get(order, 'customer.fullname', '---') + ' (' + lodash.get(order, 'customer.username', '---') + ')'}
                            </a>
                        </RowInfo>

                        <RowInfo
                            label={lang.ticket_detail.phone}
                            className="_customer-phone">
                            {lodash.get(order, 'customer.phone', '---')}
                        </RowInfo>

                        <RowInfo
                            label={'Email'}
                            className="_customer-email">
                            {lodash.get(order, 'customer.email', '---')}
                        </RowInfo>
                        <RowInfo
                            label={lang.ticket_detail.customer_code}
                            className="_customerId _customer-code">
                            {lodash.get(order, 'customer.code', '---') || '---'} / {order?.customerLevelView || '---'}
                        </RowInfo>

                        <RowInfo
                            label={lang.ticket_add.product_code}
                            className="_customer-code">
                            {lodash.get(detail, 'relatedProduct', '---') || '---'}
                        </RowInfo>
                    </RowContainer>
                </>
            )}
        </div>
    )
}
