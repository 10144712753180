import { useQuery } from '@tanstack/react-query'
import TenantApi from 'src/repository/tenant'

export const useProjectsQuery = () => {
    return useQuery({
        queryKey: ['getTenantProjects'],
        queryFn: async () => {
            const response = await TenantApi.getProjects()
            return response.data
        },
        refetchOnWindowFocus: false,
    })
}
