import { Badge, Button, Col, DatePicker, Input, Row, Select } from 'antd'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import FloatLabel from 'src/components/v2/CustomInput/FloatingLabel'
import { ClaimCriteriaExtended } from 'src/interface/ClaimInterface'
import lang from 'src/resources/localization/Localization'
import Utils from 'src/utils'
import { QUICKFILTER_ITEMS, advancedParams, fromCriteraValues, toCriteriaValues } from '../ClaimUtils'
import SelectFastTime from 'src/components/v2/Select/SelectFastTime'
import AdvancedFilter from './AdvancedFilter'

const beginDateFormat: string = 'YYYY-MM-DD 00:00:00';
const endDateFormat: string = 'YYYY-MM-DD 23:59:59';

function QuickFilter() {
    const location = useLocation();
    const history = useHistory();
    const [quickFilter, setQuickFilter] = useState<ClaimCriteriaExtended>({});
    const [showAdvancedFilter, setShowAdvancedFilter] = useState<boolean>(false)

    useEffect(() => {
        let searchParams = Utils.getQueryFromLocation(location);
        setQuickFilter({
            ...searchParams,
            claimStatus: searchParams.claimStatus || 'createdAt',
            orderBy: searchParams.orderBy || 'desc'
        })
    }, [location])

    const handleChangeCriteriaValue = (criteria: string, value: any) => {
        let temp = { ...quickFilter }
        switch (criteria) {
            case 'claimStatus': {
                temp['claimStatus'] = value
                break;
            };
            case 'orderBy': {
                temp['orderBy'] = value;
                break;
            }
            default: return;
        }
        handleQuicksearch(temp)
    }

    const handleChangeDateTimeValue = (position: string, value: any) => {
        let currentSearch = { ...quickFilter }
        // console.log(currentSearch)
        if (currentSearch && currentSearch.claimStatus) {
            if (position === 'from') {
                let fromDateTime = value ? moment(value.format(beginDateFormat)).toISOString() : undefined
                switch (currentSearch.claimStatus) {
                    case 'deadline': currentSearch = { ...currentSearch, deadlineFrom: fromDateTime }; break;
                    case 'createdAt': currentSearch = { ...currentSearch, createdAtFrom: fromDateTime }; break;
                    case 'processingAt': currentSearch = { ...currentSearch, processingAtFrom: fromDateTime }; break;
                    case 'acceptedAt': currentSearch = { ...currentSearch, acceptedAtFrom: fromDateTime }; break;
                    case 'refundedAt': currentSearch = { ...currentSearch, refundedAtFrom: fromDateTime }; break;
                    case 'rejectedAt': currentSearch = { ...currentSearch, rejectedAtFrom: fromDateTime }; break;
                }
            } else {
                let toDateTime = value ? moment(value.format(endDateFormat)).toISOString() : undefined
                switch (currentSearch.claimStatus) {
                    case 'deadline': currentSearch = { ...currentSearch, deadlineTo: toDateTime }; break;
                    case 'createdAt': currentSearch = { ...currentSearch, createdAtTo: toDateTime }; break;
                    case 'processingAt': currentSearch = { ...currentSearch, processingAtTo: toDateTime }; break;
                    case 'acceptedAt': currentSearch = { ...currentSearch, acceptedAtTo: toDateTime }; break;
                    case 'refundedAt': currentSearch = { ...currentSearch, refundedAtTo: toDateTime }; break;
                    case 'rejectedAt': currentSearch = { ...currentSearch, rejectedAtTo: toDateTime }; break;
                }
            }
        }
        handleQuicksearch(currentSearch)
    }

    const handleChangeQuickDateTimeSelect = (value: any) => {
        let temp = { ...quickFilter }
        function getPastDate(days: any) {
            const pastDate = moment().subtract(days, 'days');
            return pastDate;
        }
        if (value) {
            temp[temp.claimStatus + 'From'] = moment(getPastDate(value).format(beginDateFormat)).toISOString();
            temp['suggestTimeSelect'] = value
        } else {
            delete temp[temp.claimStatus + 'From']
            temp['suggestTimeSelect'] = undefined
        }
        handleQuicksearch(temp)
    }

    const handleQuicksearch = (filter: any) => {
        Utils.pushCleanQueryToHistory(history, location, filter)
    }

    const dateTimeValueMemo = useMemo(() => {
        const params = Utils.getQueryFromLocation(location)
        let fromValue: any = Object.keys(params).find((key: any) => fromCriteraValues.includes(key))
        let toValue: any = Object.keys(params).find((key: any) => toCriteriaValues.includes(key))
        return {
            fromValue: fromValue || undefined,
            toValue: toValue || undefined,
        }
    }, [location])

    const advanceDotMemo = useMemo(() => {
        let searchParams = Utils.getQueryFromLocation(location);
        let searchCriterias: any = []
        Object.keys(searchParams).forEach((key: string) => {
            searchCriterias.push(key)
        });

        function checkIfAnyElementExistsInArray(A: any[], B: any[]) {
            for (const elementA of A) {
                if (B.includes(elementA)) {
                    return true;
                }
            }
            return false;
        }
        return checkIfAnyElementExistsInArray(searchCriterias, advancedParams)
    }, [location])

    const handleDisplayAdvancedFilter = (visible: boolean) => {
        setShowAdvancedFilter(visible)
    }

    const displayResetFilter = useMemo(() => {
        let temp = Utils.getQueryFromLocation(location);
        delete temp.orderBy
        delete temp.page;
        delete temp.size;
        // console.log(!Object.values(temp).every((x) => !x))
        return !Object.values(temp).every((x) => !x)
    }, [location])

    const handleResetFilter = () => {
        let searchParams = Utils.getQueryFromLocation(location);
        Utils.pushCleanQueryToHistory(history, location, {
            stateUnchanged: searchParams.stateUnchanged
        })
    }

    const { claimStatus } = quickFilter

    return (
        <React.Fragment>
            <Row gutter={[12, 24]} className='my-2'>
                <Col xl={4} lg={12} md={12} xs={24}>
                    <FloatLabel label={lang.ticket_list.quick_filter} value={claimStatus} className='!mb-0'>
                        <Select
                            allowClear
                            size='large'
                            style={{ width: '100%' }}
                            onChange={(value) => handleChangeCriteriaValue('claimStatus', value)}
                            options={QUICKFILTER_ITEMS}
                            value={claimStatus}
                            suffixIcon={<i className="fal fa-angle-down"></i>}
                        />
                    </FloatLabel>
                </Col>
                <Col xl={6} flex={1} lg={12} md={12} xs={24}>
                    <Input.Group compact className='w-full'>
                        <FloatLabel label={lang.list_ticket.from} value={quickFilter[dateTimeValueMemo.fromValue]} className='w-1/2 !mb-0'>
                            <DatePicker
                                value={quickFilter[dateTimeValueMemo.fromValue] ? moment(quickFilter[dateTimeValueMemo.fromValue]) : undefined}
                                onChange={(value) => handleChangeDateTimeValue('from', value)}
                                className='w-full'
                                placeholder=''
                                size='large'
                                disabled={!claimStatus}
                                format={'DD-MM-YYYY'}
                                allowClear
                                suffixIcon={<i className="fas fa-calendar-alt"></i>}
                            />
                        </FloatLabel>
                        <FloatLabel label={lang.list_ticket.to} value={quickFilter[dateTimeValueMemo.toValue]} className='w-1/2 !mb-0'>
                            <DatePicker
                                value={dateTimeValueMemo.toValue ? moment(quickFilter[dateTimeValueMemo.toValue]) : undefined}
                                onChange={(value) => handleChangeDateTimeValue('to', value)}
                                className='w-full'
                                placeholder=''
                                size='large'
                                disabled={!claimStatus}
                                disabledDate={(value) => value < moment(quickFilter[dateTimeValueMemo.fromValue])}
                                format={'DD-MM-YYYY'}
                                allowClear
                                suffixIcon={<i className="fas fa-calendar-alt"></i>}
                            />
                        </FloatLabel>
                    </Input.Group>
                </Col>
                <Col xl={4} md={8} lg={8} xs={24}>
                    <FloatLabel label={lang.fast_time_selector.quick_select} value={quickFilter.suggestTimeSelect} className='!mb-0'>
                        <SelectFastTime onChange={(value: any) => handleChangeQuickDateTimeSelect(value)}
                            isFuture={true}
                            value={quickFilter.suggestTimeSelect}
                        />
                    </FloatLabel>
                </Col>
                <Col xl={3} md={8} lg={8} xs={24}>
                    <FloatLabel label={lang.ticket_detail.sortBy} value={quickFilter.orderBy} className='mb-0'>
                        <Select
                            className='w-full'
                            size='large'
                            onChange={(value) => handleChangeCriteriaValue('orderBy', value)}
                            value={quickFilter.orderBy}
                            options={[{
                                key: "desc",
                                value: 'desc',
                                label: lang.ticket_detail.newest,
                            }, {
                                label: lang.list_ticket.oldest,
                                value: 'asc',
                                key: "asc"
                            }]}
                            suffixIcon={<i className="fal fa-angle-down"></i>}
                        />
                    </FloatLabel>
                </Col>
                {
                    displayResetFilter && <Col xl={2} md={8} lg={8} xs={24}>
                        <div className='lg:absolute lg:top-5'>
                            <span className='ant-btn-link cursor-pointer' onClick={() => handleResetFilter()}>
                                <Badge dot={false} className='ant-btn ant-btn-link !px-0 text-secondary-2 hover:text-gray-400 text-xs'>
                                    <i className="fa-solid fa-rotate-right mr-2"></i>Làm mới
                                </Badge>
                            </span>
                        </div>
                    </Col>
                }
                <Col xl={3} md={8} lg={8} xs={24}>
                    <div className='lg:absolute lg:top-5'>
                        <span className={'ant-btn-link'} onClick={() => setShowAdvancedFilter(!showAdvancedFilter)}>
                            <Badge dot={advanceDotMemo} className='ant-btn ant-btn-link text-blue-500 hover:text-blue-400 text-xs !pl-0 !pr-0'>
                                Tìm kiếm nâng cao
                            </Badge>
                        </span>
                    </div>
                </Col>

            </Row>
            {
                <AdvancedFilter
                    open={showAdvancedFilter}
                    onClose={() => handleDisplayAdvancedFilter(false)}
                    destroyOnClose
                    placement='right'
                    handleDisplayAdvancedFilter={handleDisplayAdvancedFilter}
                />
            }
        </React.Fragment>
    )
}

export default QuickFilter