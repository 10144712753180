import { Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import { useHistory, useParams } from 'react-router'
import { SubjectEnums } from 'src/interface/Orders'
import lang from 'src/resources/localization/Localization'
import ListFinancialType from './DataList'
import { Tab } from 'rc-tabs/lib/interface'

export const Financials = () => {
    const params: { financeActiveTab?: string } = useParams()
    const history = useHistory()

    const onChange = (activeKey: string) => {
        history.push(`/configurations/finance/${activeKey}`)
    }

    const items: Tab[] = [
        {
            key: SubjectEnums.Order,
            label: lang.config.order_type,
            children: <ListFinancialType orderType={SubjectEnums.Order} />,
        },
        {
            key: SubjectEnums.Shipment,
            label: lang.type_order_selector.type_shipment,
            children: <ListFinancialType orderType={SubjectEnums.Shipment} />,
        },
        {
            key: SubjectEnums.Peerpayment,
            label: lang.type_order_selector.type_peerpayment,
            children: <ListFinancialType orderType={SubjectEnums.Peerpayment} />,
        },
    ]

    return (
        <Tabs
            items={items}
            type="card"
            activeKey={params.financeActiveTab || SubjectEnums.Order}
            onChange={onChange}
        />
    )
}
