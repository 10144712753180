import React, {useEffect, useState} from "react";
import _ from "lodash";

const defaultActiveTab = -1;

export default function ConfigMenu({children, items, width, isDropDown, visible, onVisible}) {
    const [isMoved, setIsMoved] = useState(false);
    const [activeTab, setActiveTab] = useState(defaultActiveTab);

    useEffect(() => {
        document.addEventListener('click', _onClickOut);
    }, []);

    const isTrue = item => {
        let class_name = item.className;
        if (_.isEmpty(item.className))
            return false;

        return ["m3-config-menu", "m3-dropdown", "m3-dropdown--main"].some(item => class_name.search(item) > -1);
    }

    function _onClickOut(e) {
        let elements = e.path;
        if (elements && elements.length > 0 && !elements.some(isTrue) && typeof onVisible === "function")
            onVisible(false);
    }

    function _onMove(index) {
        setIsMoved(!isMoved);
        if (!isMoved)
            setActiveTab(index);
        else
            setTimeout(() => setActiveTab(index), 300);
    }

    function _onHide(e) {
        if (e.target.tagName === "A" && typeof onVisible === "function")
            onVisible(false);
    }

    return (
        <div className={`m3-config-menu txt-nowrap ${isDropDown ? "is-drop-down" : ""}`}>
            {children}
            <div
                onClick={_onHide}
                className={`m3-dropdown z-999  ${visible ? "m3-dropdown--show" : "m3-dropdown--hide"}`}
                style={{width: width ? width : 250}}
            >
                <div className={`m3-dropdown--container ${isMoved ? "m3-dropdown--container--moved" : ""}`}>
                    <div className="m3-dropdown--main">
                        <ul>
                            {
                                items && items.length > 0 &&
                                items.map((item, index) => {
                                    return item.componentTab ? (
                                        <li key={index}
                                            className={(item.mobile ? "mobile " : "") + (items[index - 1]?.mobile && "border-isMobile-0")}>
                                            <span className={"cursor-pointer"}
                                                  onClick={() => _onMove(index)}>{item.item}</span></li>
                                    ) : (
                                        <li key={index}
                                            className={(item.mobile ? "mobile " : "") + (items[index - 1]?.mobile && "border-isMobile-0")}>{item.item}</li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                    {
                        items && items.length > 0 &&
                        items.map((item, index) => {
                            if (!item.componentTab)
                                return null;
                            return activeTab === index && (
                                <div key={index}
                                     className={`m3-dropdown--item ${activeTab === index ? "m3-dropdown--active" : ""}`}>
                                    <div className="cursor-pointer justify-center flex-col pd15"
                                         onClick={() => _onMove(defaultActiveTab)}><i
                                        className="fas fa-long-arrow-left"/>
                                    </div>

                                    {item.componentTab}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}
