import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button, notification } from 'antd'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import Utils from 'src/utils'
import MainLayout from '../../../components/v2/Layout/MainLayout'
import ClaimApi from '../../../repository/ticket'
import lang from '../../../resources/localization/Localization'
import storageUtil from '../../../utils/LocalStore/LocalStores'
import ClaimDataHeader from './ClaimDataHeader'
import ClaimDataView from './ClaimDataView'
import QuickFilter from './Filter/QuickFilter'
import SecurityService from '../../../utils/SecurityService'
import { TICKETS_SCOPES } from '../../../utils/api/Scopes'
import ClaimCreate from '../../../screens/Tickets/CreateModal'
import ScrollButton from 'src/components/v2/ScrollButton'

function Tickets() {
    const queryClient = useQueryClient()
    const location = useLocation()
    const history = useHistory()
    const [pagination, setPagination] = useState()
    const [displayColumnsConfig, setDisplayColumnsConfig] = useState(false)
    const [filter, setFilter] = useState({})
    const [currentClaims, setCurrentClaims] = useState([])
    const [createIsOpen, setCreateIsOpen] = useState(false)

    useEffect(() => {
        let searchParams = Utils.getQueryFromLocation(location)
        let filterTemp = {
            ...searchParams,
            offset: searchParams.page ? (+searchParams.page - 1) * searchParams.size : 0,
            limit: searchParams.size ? searchParams.size : 25,
            orderBy: searchParams.orderBy || 'desc',
            project: storageUtil.getItem('selectedProject'),
        }
        delete filterTemp.page
        delete filterTemp.size
        queryClient.invalidateQueries({ queryKey: ['claim-query', filterTemp], exact: true })
        setFilter(filterTemp)
    }, [location])

    const claimQueryKey = {
        filterQuery: (filter) => ['claims', { ...filter }],
    }

    const claimQuery = useQuery({
        queryKey: claimQueryKey.filterQuery(filter),
        queryFn: async ({ queryKey }) => {
            // console.log('claim query called',queryKey)
            const response = await ClaimApi.getTickets(queryKey[1])
            if (response && response.data) {
                let temp = response.data.map((ele) => ele.relatedOrder)
                setCurrentClaims(temp)
            }
            setPagination({
                current: response.data.length > 0 ? +_.get(response, 'headers.x-page-number') + 1 : 0,
                total: +_.get(response, 'headers.x-total-count'),
                pageSize: +_.get(response, 'headers.x-page-size'),
            })
            return response.data
        },
        refetchOnWindowFocus: false,
        enabled: filter && !_.isEmpty(filter),
    })

    useEffect(() => {
        if (claimQuery.error) {
            console.log('claim-fetching-error: ', claimQuery.error)
        }
    }, [claimQuery])

    const onChangePage = (page, pageSize) => {
        Utils.pushCleanQueryToHistory(history, location, {
            ...Utils.getQueryFromLocation(location),
            page: page,
            size: pageSize,
        })
    }

    const handleExportCSVFile = useMutation({
        mutationKey: ['export-claims'],
        mutationFn: () => ClaimApi.exportCSV({ ...Utils.getQueryFromLocation(location), project: storageUtil.getItem('selectedProject') }),
        onSuccess: (response, payload) => {
            notification.success({ message: lang.list_ticket.export_csv_message })
        },
        onError: (error, payload) => {
            notification.error({ message: e.message })
        },
    })

    const handleChangeVisibleModal = (visible) => {
        setDisplayColumnsConfig(visible)
    }

    const columnsConfigQuery = useQuery({
        queryKey: ['get-claims-hidden-columns'],
        queryFn: async () => {
            const response = await ClaimApi.getDisplayColumnsConfig()
            return response.data
        },
        refetchOnWindowFocus: false,
    })

    const updateHiddenColumns = useMutation({
        mutationKey: ['patch-claims-hidden-columns'],
        mutationFn: (body) => ClaimApi.updateDisplayColumnsConfig(body),
        onSuccess: (response, payload) => {
            notification['success']({
                message: lang.claims.config_table_columns_succcess,
            })
            columnsConfigQuery.refetch()
            handleChangeVisibleModal(false)
        },
        onError: (error, variables) => {
            notification['error']({
                message: lang.claims.config_table_columns_failed,
            })
        },
    })

    const fetchOtherInfomationCondition = useMemo(() => {
        let hasOrdersCode = false
        if (filter && !_.isEmpty(filter)) {
            if (currentClaims && currentClaims.length > 0) {
                hasOrdersCode = true
            }
        }
        let alipayIsHidden = columnsConfigQuery.data && columnsConfigQuery.data.hideColumns.includes('alipay')
        let complaintLogIsHidden = columnsConfigQuery.data && columnsConfigQuery.data.hideColumns.includes('knnb-log')
        return {
            enableFetchAlipay: hasOrdersCode && !alipayIsHidden,
            enableFetchComplaintLog: hasOrdersCode && !complaintLogIsHidden,
        }
    }, [currentClaims, columnsConfigQuery.data, filter])

    const otherInfomationQueryKey = {
        filterQuery: (baseFilter, orderCodes) => {
            let temp = {
                orderCodes: orderCodes.toString(),
            }
            Object.keys({ ...baseFilter }).forEach((key) => {
                if (['limit', 'project', 'stateUnchanged', 'orderBy'].includes(key)) {
                    temp[key] = filter[key]
                }
            })
            return [
                ['alipay-info', { ...temp }],
                ['complaint-log', { ...temp }],
            ]
        },
    }

    const othersInformationQueries = useQueries({
        queries: [
            {
                queryKey: otherInfomationQueryKey.filterQuery(filter, currentClaims)[0],
                queryFn: async ({ queryKey }) => {
                    // console.log('fn alipay', queryKey)
                    const response = await ClaimApi.getAlipayInformations(queryKey[1])
                    return response.data
                },
                enabled: fetchOtherInfomationCondition.enableFetchAlipay,
                refetchOnWindowFocus: false,
                retry: 0,
            },
            {
                queryKey: otherInfomationQueryKey.filterQuery(filter, currentClaims)[1],
                queryFn: async ({ queryKey }) => {
                    const response = await ClaimApi.getKNNBInformation(queryKey[1])
                    return response.data
                },
                enabled: fetchOtherInfomationCondition.enableFetchComplaintLog,
                refetchOnWindowFocus: false,
                retry: 0,
            },
        ].map(({ queryKey, queryFn, enabled, refetchOnWindowFocus, ...queryItem }) => {
            return {
                queryKey,
                queryFn,
                enabled,
                refetchOnWindowFocus,
                ...queryItem,
            }
        }),
    })

    useEffect(() => {
        // console.log(othersInformationQueries)
        othersInformationQueries.forEach(({ isError }) => {
            if (isError) queryClient.invalidateQueries(['alipay-info', 'komodo-complain-info'])
        })
    }, [othersInformationQueries])

    const handleChangeCreateModalVisible = (visible) => {
        if (SecurityService.can(TICKETS_SCOPES.TICKETS_CREATE_FOR_CUSTOMER)) {
            setCreateIsOpen(visible)
        }
    }

    return (
        <>
            <MainLayout
                title={lang.list_ticket.claim_list_title}
                route={'tickets'}
                loading={claimQuery.isLoading}>
                <div className="px-3 py-3 rounded-lg bg-white">
                    <ClaimDataHeader
                        total={pagination?.total}
                        actions={[
                            <Button
                                icon={<i className="fa-regular fa-gear mr-2"></i>}
                                key={'btn-config-column'}
                                onClick={() => handleChangeVisibleModal(true)}>
                                <span className="capitalize">{lang.claims.config_table_columns}</span>
                            </Button>,
                            <Button
                                key={'btn-export-csv'}
                                icon={<i className="fa-regular fa-file-csv"></i>}
                                loading={handleExportCSVFile.isLoading}
                                onClick={() => handleExportCSVFile.mutate()}>
                                <span className="ml-2">{lang.button.csv}</span>
                            </Button>,
                            SecurityService.can(TICKETS_SCOPES.TICKETS_CREATE_FOR_CUSTOMER) && (
                                <Button
                                    className={'buttonm3 _create-report'}
                                    onClick={() => handleChangeCreateModalVisible(true)}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    // loading={loading}
                                    type="primary">
                                    <i className="fa-solid fa-plus"></i>
                                    <span className={'mgl-10 only-show-from-sm'}>{lang.ticket_add.create_claims}</span>
                                </Button>
                            ),
                        ]}
                    />
                    <QuickFilter />
                    <ClaimDataView
                        claimQuery={claimQuery}
                        pagination={{
                            ...pagination,
                            onChange: onChangePage,
                            position: ['topRight'],
                        }}
                        displayColumnsConfig={displayColumnsConfig}
                        changeConfigModalVisible={handleChangeVisibleModal}
                        columnsConfigQuery={columnsConfigQuery}
                        updateHiddenColumns={updateHiddenColumns}
                        othersInformationQueries={othersInformationQueries}
                    />
                </div>
                <ScrollButton />
                {createIsOpen && (
                    <ClaimCreate
                        createIsOpen={createIsOpen}
                        handleChangeCreateVisible={handleChangeCreateModalVisible}
                        refetchData={claimQuery.refetch}
                    />
                )}
            </MainLayout>
        </>
    )
    // }
}

export default Tickets
