import { get } from 'lodash'
import moment from 'moment'
import { FC, useMemo } from 'react'
import { ITicket } from '../../../../interface/Ticket'
import lang from '../../../../resources/localization/Localization'
import { RowInfo } from '../components/Row'

interface IProps {
    ticket: ITicket
}

export const HistoryState: FC<IProps> = ({ ticket }: IProps) => {
    const historyState: any = useMemo(() => {
        const historyStateTemp = []
        if (ticket.createdAt) {
            historyStateTemp.push({
                state: 'NEW',
                timestamp: ticket.createdAt,
            })
        }

        if (ticket.processingAt) {
            historyStateTemp.push({
                state: 'PROCESSING',
                timestamp: ticket.processingAt,
            })
        }

        if (ticket.acceptedAt) {
            historyStateTemp.push({
                state: 'ACCEPTED',
                timestamp: ticket.acceptedAt,
            })
        }

        if (ticket.refundedAt) {
            historyStateTemp.push({
                state: 'REFUND',
                timestamp: ticket.refundedAt,
            })
        }

        if (ticket.rejectedAt) {
            historyStateTemp.push({
                state: 'REJECT',
                timestamp: ticket.rejectedAt,
            })
        }

        if (ticket.archiveAt) {
            historyStateTemp.push({
                state: 'CLOSE',
                timestamp: ticket.archiveAt,
            })
        }
        return historyStateTemp
    }, [ticket])

    return (
        <>
            {historyState.map(({ state, timestamp }: any) => (
                <RowInfo
                    key={state + '-state'}
                    label={get(lang.state_ticket, state)}
                    className={'_history-state-' + state}>
                    {timestamp ? moment(timestamp).format('HH:mm DD/MM/YYYY') : '---'}
                </RowInfo>
            ))}
        </>
    )
}
