import { Component } from 'react'
import { RouteChildrenProps } from 'react-router'
import DefaultComponent from '../../../components/v2/DefaultComponent'
import { StaffList } from '../components/StaffList'
import { StaffModificationLog } from '../components/StaffModificationLog'

interface IProps extends RouteChildrenProps {}

export default class StaffTab extends Component<IProps, any> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            staff: null,
            showLog: false,
        }
    }

    showLogClick = (staff: any) => {
        this.setState({
            showLog: true,
            staff: staff,
        })
    }

    closeLog = () => {
        this.setState({
            showLog: false,
            staff: undefined,
        })
    }

    render() {
        return (
            <div>
                <StaffList
                    onShowLogClick={this.showLogClick}
                    {...this.props}
                />

                {this.state.staff ? (
                    <StaffModificationLog
                        staffId={this.state.staff?.email}
                        isShow={!!this.state.staff}
                        onCancel={this.closeLog}
                    />
                ) : null}
            </div>
        )
    }
}
