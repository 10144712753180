import { get } from 'lodash'
import { useContext } from 'react'
import storageUtil from 'src/utils/LocalStore/LocalStores'
import { AppContext } from '../context/AppContext'

export type ProjectNameMappingType = { [key in string]: string }

export const useProjectAssigned = (projectCode?: string) => {
    const context = useContext(AppContext)
    const assignedProjects = get(context, 'state.assignedProjects', [])
    const currentProjectCode = projectCode || storageUtil.getItem('selectedProject');
    let currentProject: any = {}

    assignedProjects.forEach((project: any) => {
        if (project.project === currentProjectCode) {
            currentProject = project
        }
    })

    return { assignedProjects, currentProjectCode, currentProject }
}
