import {getToken} from '../helpers/localStorage';
import storageUtil from "./LocalStore/LocalStores";

class SecurityService {

    static can = (permission) => {
        const permissions = storageUtil.getJson('permissions') || [];
        return permissions.find(per => per === permission);
    };

    static isLogged = () => {
        const accessToken = getToken()
        return !!accessToken
    };

    static getUser = () => {
        if (SecurityService.isLogged()) {
            return storageUtil.getJson('loggedUser');
        }
    };
}

export default SecurityService;
