import {Select} from "antd";
import lang from "../../../resources/localization/Localization";

const SelectStatusClaims = ({onChange, value, isFuture}) => {
    const data = [
        {
            key: 1,
            value: '1',
            name: "1 " + (!isFuture ? lang.fast_time_selector.day_after : lang.fast_time_selector.day_before)
        },{
            name: "3 " + (!isFuture ? lang.fast_time_selector.day_after : lang.fast_time_selector.day_before),
            key: 3,
            value: '3'
        },{
            name: "7 " + (!isFuture ? lang.fast_time_selector.day_after : lang.fast_time_selector.day_before),
            key: 7,
            value: '7'
        },{
            name: "15 " + (!isFuture ? lang.fast_time_selector.day_after : lang.fast_time_selector.day_before),
            key: 15,
            value: '15'
        },{
            name: "30 " + (!isFuture ? lang.fast_time_selector.day_after : lang.fast_time_selector.day_before),
            key: 30,
            value: '30'
        },
    ]

    return <Select onChange={onChange} value={value}
                //    placeholder={`--- ${lang.fast_time_selector.quick_select} ---`}
                   className={"_select-status w-full"} allowClear
                   size="large"
                   suffixIcon={<i className="fal fa-angle-down"></i>}
    >
        {data.map(status =>
            <Select.Option key={status.key} value={status.value} className={"_status-claims _status-claims-" + status.key}>
                {status.name}
            </Select.Option>)}
    </Select>
}

export default SelectStatusClaims
