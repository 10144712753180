import MainLayout from '../../components/v2/Layout/MainLayout'
import Footer from '../../components/v2/Layout/Footer'
import { Component } from 'react'
import { TICKETS_SCOPES } from '../../utils/api/Scopes'
import Component403 from '../../components/v2/Error/403'
import lang from '../../resources/localization/Localization'
import SecurityService from '../../utils/SecurityService'
import { ConfigurationTabs } from './Tabs'

class Configuration extends Component<any, any> {
    render() {
        if (!SecurityService.can(TICKETS_SCOPES.CONFIG_GENERAL)) {
            return (
                <MainLayout title={lang.sidebar.configured}>
                    <Component403 />
                </MainLayout>
            )
        }

        return (
            <MainLayout title={lang.sidebar.configured}>
                <ConfigurationTabs />
                <Footer />
            </MainLayout>
        )
    }
}

export default Configuration
