import { useEffect, useState } from 'react'
import { IFinancialType } from 'src/interface/FinancialType'
import ClaimApi from 'src/repository/ticket'

export const useOfficers = (type: string) => {
    const [officers, setOfficers] = useState<IFinancialType[]>([])
    const [officersNameMapping, setOfficersNameMapping] = useState<{ [key in string]: string }>({})

    const fetchFinanceTypes = () => {
        const query = {
            sort: "createdAt:desc",
            order: "DESC",
            offset: 0,
            limit: 1000,
            type: "COLLECTIVE",
        }

        ClaimApi.getStaffMistake(query).then((r) => {
            const nameMapping: { [key in string]: string } = {}

            r.data.forEach((financeType: IFinancialType) => {
                nameMapping[financeType.id] = financeType.name
            })

            setOfficers(r.data)
            setOfficersNameMapping(nameMapping)
        })
    }

    useEffect(() => {
        if (!type) return 
        fetchFinanceTypes()
    }, [type])

    return { officers, officersNameMapping }
}
