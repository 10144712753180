import image404 from "../resources/images/404.png";
import MainLayout from "../components/v2/Layout/MainLayout";
import {useHistory} from "react-router";
import lang from "../resources/localization/Localization";

const Page404 = () => {
    const history = useHistory()
    // @ts-ignore
    return <MainLayout>
        <div className={"flex flex-row justify-center"}>
            <img src={image404} alt={"403"}/>
        </div>
        <div className={"flex flex-row justify-center mgt-45"}>
            <div className={"robotomedium text-36"}>
                ERROR 404!
            </div>
        </div>
        <div className={"flex flex-row justify-center mgt-4"}>
            <div className={"text-14 txt-color-black3"}>
                {lang.login._404_not_found}
            </div>
        </div>
        <div className={"flex flex-row justify-center align-items-center mgt-40"}>
            <div className={"text-14 txt-color-black3 "}>
                <span>{lang.login.contact_hotline_and_try_again}<span><a href={"/"}>{lang.login.home_page}</a></span></span>
            </div>
        </div>
    </MainLayout>
}
export default Page404
