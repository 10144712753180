import { TabPaneProps, Tabs } from 'antd'
import { FC, useContext, useState } from 'react'
import lang from 'src/resources/localization/Localization'
import ComplaintSeller from './ComplaintSeller'
import ComplaintService from './ComplaintService'
import { ClaimDetailContext } from '../context'
import { useProjectAssigned } from 'src/hooks/useProjectAssigned'

interface IProps { }

interface Tab extends Omit<TabPaneProps, 'tab'> {
    key: string
    label: React.ReactNode
}

export const ComplaintTabs: FC<IProps> = () => {
    const [complaintSellerTotal, setComplaintSellerTotal] = useState(0)
    const [complaintServiceTotal, setComplaintServiceTotal] = useState(0)
    const { detail, project } = useContext(ClaimDetailContext)
    const { currentProject } = useProjectAssigned(project as string)

    const items: Tab[] = []

    // if (detail?.ticketType === 'order') {
    if (detail?.ticketType === 'order' && currentProject.komodoComplaintDetailUrl) {
        items.push({
            key: 'complaintSeller',
            label: (
                <>
                    {lang.claim_detail.complaintSeller_similars}
                    <span className={'bag'}>{complaintSellerTotal}</span>
                </>
            ),
            children: <ComplaintSeller setComplaintSellerTotal={setComplaintSellerTotal as any} />,
        })
    }

    items.push({
        key: 'complaintService',
        forceRender: true,
        label: (
            <>
                {lang.claim_detail.ticket_similars}
                <span className={'bag'}>{complaintServiceTotal}</span>
            </>
        ),
        children: <ComplaintService setComplaintServiceTotal={setComplaintServiceTotal as any} />,
    })

    return <div className={'box'}><Tabs animated items={items} /></div>
}
