import apiClientV2 from "../utils/ApiClient";

export default class AccountApi {
    static changePin = (data) => {
        return apiClientV2.patch(`/profile/change_pin`, data);
    };
    static resetPin = (data) => {
        return apiClientV2.patch(`/profile/reset_pin`, data);
    };
}
