import { notification, Select, SelectProps } from 'antd'
import { FC, useEffect, useState } from 'react'
import ClaimApi from '../../../repository/ticket'
import { checkPermission } from '../../../utils/helper'
import { TICKETS_SCOPES } from '../../../utils/api/Scopes'
import lang from '../../../resources/localization/Localization'
import { FinanceOrderTypes, SubjectEnums } from '../../../interface/Orders'
import storageUtil from '../../../utils/LocalStore/LocalStores'

interface IProps extends SelectProps {
    subject: SubjectEnums
    typeValue?: 'string' | 'object'
    classname?: string
    onChange?: any
    currentProject?: string
}

const SelectFinanceAccount: FC<IProps> = ({ onChange, currentProject, disabled, subject = FinanceOrderTypes.order, classname = '', typeValue = 'string' }) => {
    const [loading, setLoading] = useState(false)
    const [financeTypes, setFinanceTypes] = useState<any>([])
    const project = currentProject || storageUtil.getItem('selectedProject')

    useEffect(() => {
        setLoading(true)
        const query = {
            subject,
            project,
        }

        ClaimApi.getFinanceAccount(query)
            .then((r) => {
                setFinanceTypes(r.data)
            })
            .catch((e) => {
                notification.error({ message: e.message })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return (
        <Select
            disabled={disabled}
            placeholder={lang.finance_account_selector.select}
            onChange={(value) => onChange(typeValue === 'object' ? financeTypes.find((t: any) => value === t.code) : value)}
            loading={loading}
            className={classname + ' _select-type'}>
            {financeTypes?.map((type: any) => (
                <Select.Option
                    key={type.code}
                    value={type.code}
                    className={'_type _type-' + type.id}>
                    {type.name}
                </Select.Option>
            ))}
        </Select>
    )
}

export default SelectFinanceAccount
