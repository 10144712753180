import { Row, Col } from 'antd'
import _ from 'lodash'
import { FC, useMemo } from 'react'
import { useProjectsQuery } from 'src/hooks/query/useProjectsQuery'
import { IOrder, SubjectEnums } from 'src/interface/Orders'
import { ITicket } from 'src/interface/Ticket'
import lang from 'src/resources/localization/Localization'
import Utils from 'src/utils'
import { currencyUnitFormat } from 'src/utils/currency'

interface IProps {
    detail: ITicket
    order: IOrder
    project: string | any
}

export const OrderInfo: FC<IProps> = ({ detail, order, project }) => {
    const tenantProjects = useProjectsQuery();

    const currencyCondition = useMemo(() => {
        const projectList = _.cloneDeep(tenantProjects?.data)
        const currentProject = projectList?.find((p: any) => p.code === project)
        // console.log(currentProject)
        if (currentProject) {
            // console.log('currency', currentProject?.currency)
            return currentProject?.currency
        }
        else return ''
    }, [tenantProjects, project])


    const orderDetailUrl =
        detail.orderDetailUrl && detail.relatedOrder
            ? `${detail.orderDetailUrl}/#/${detail.ticketType === SubjectEnums.Shipment ? 'shipments' : 'orders'}/${detail.relatedOrder}`
            : undefined

    return (
        <>
            <Row
                gutter={24}
                className="mt-4">
                <Col
                    xs={12}
                    lg={6}
                    className={'mb-mgt-16 mgbt10'}>
                    <div className={'txt-color-black3'}>
                        {detail.ticketType === 'peerpayment' ? lang.type_order_selector.type_peerpayment : lang.ticket_add.order}
                    </div>

                    <div className={'mgt-2 txt-size-h6'}>
                        <a
                            href={orderDetailUrl}
                            className={'_relatedOrder'}
                            target={orderDetailUrl ? '_blank' : '_self'}>
                            {detail.relatedOrder || '--'}
                        </a>
                    </div>
                </Col>

                <Col
                    xs={12}
                    lg={6}
                    className={'mb-mgt-16'}>
                    <div className={'txt-color-black3'}>{lang.claim_detail.customer_request}</div>
                    <div>
                        {detail.solutionView ? (
                            <span className="txt-size-h6">
                                {detail.suggest ? (
                                    <span className={'txt-color-red2 font-medium _customer-suggest'}>{currencyUnitFormat(Utils.currencyFormat(detail.suggest), currencyCondition)}</span>
                                ) : (
                                    <span className={'txt-color-red2 font-medium _customer-suggest'}>{detail.solutionView?.name}</span>
                                )}
                            </span>
                        ) : (
                            '---'
                        )}
                    </div>
                </Col>
                <Col
                    xs={12}
                    lg={6}
                    className={'mb-mgt-16'}>
                    <div className={' txt-color-black3'}>{lang.claim_detail.sd_suggest}</div>
                    <div>
                        {detail.estimatedRefundValue ? (
                            <div className={'txt-color-red2 txt-size-h6 font-medium _estimatedRefundValue'}>
                                {currencyUnitFormat(Utils.currencyFormat(detail.estimatedRefundValue), currencyCondition)}
                            </div>
                        ) : (
                            '---'
                        )}
                    </div>
                </Col>
                <Col
                    xs={12}
                    lg={6}
                    className={'mb-mgt-16'}>
                    <div className={' txt-color-black3'}>
                        {lang.claim_detail.order_status} {detail.ticketType === 'peerpayment' ? lang.ticket_add.type_PEERPAYMENT : lang.ticket_add.order}
                    </div>
                    <div className={'dpl-flex _row-state txt-size-h6'}>
                        <span className={'txt-nowrap txt-color-black robotoregular _order-status-name'}>{order?.status?.name || '---'}</span>
                    </div>
                </Col>
            </Row>
        </>
    )
}
