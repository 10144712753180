import {notification, Select} from "antd";
import {useEffect, useState} from "react";
import ClaimApi from "../../../repository/ticket";
import lang from "../../../resources/localization/Localization";
import lodash from "lodash";
import storageUtil from "../../../utils/LocalStore/LocalStores";

const SelectSolution = ({onChange, value, disabled, type}) => {
    const [loading, setLoading] = useState(false);
    const [solutions, setSolution] = useState([]);
    const project = storageUtil.getItem('selectedProject');

    useEffect(() => {
        let query;
        if (typeof type === 'string' || type instanceof String) {
            query = lodash.pickBy({ticketTypes: (type || "").toUpperCase()}, lodash.identity);
        } else {
            query = lodash.pickBy({ticketTypes: type?.toString()}, lodash.identity);
        }
        if (type && project) {
            setLoading(true)
            ClaimApi.getSolution({...query, project}).then(r => {
                let data = r.data
                if (type && type.toUpperCase() !== "ORDER") {
                    data = data.filter(d => d.code !== "redelivery")
                }
                setSolution(data);
            }).catch((e) => {
                notification.error({message: e.message})
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [type])


    return <Select disabled={disabled} onChange={onChange} value={value} placeholder={lang.ticket_add.solutions_select}
                   loading={loading} className={"_select-solution"}>
        {solutions.map((solution, index) =>
            <Select.Option key={solution.code + '_' + index} value={solution.code}
                           className={"_solution _solution-" + solution.code}>
                {solution.name}
            </Select.Option>)}
    </Select>
}

export default SelectSolution
