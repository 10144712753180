export enum StaffLogEvents {
  CREATED = 'STAFF_CREATED',
  UPDATED_ASSIGNMENT = 'STAFF_ASSIGNMENT_ALL_UPDATED',
  STAFF_LOGIN_UPDATED = 'STAFF_LOGIN_UPDATED',
  STAFF_LOGIN_CREATED = 'STAFF_LOGIN_CREATED'
}

export enum ClaimLogEvents {
    CLAIM_CREATED = "CLAIM_CREATED",
    CLAIM_UPDATED_STATE = "CLAIM_UPDATED_STATE",
    CLAIM_UPDATED_DEADLINE = "CLAIM_UPDATED_DEADLINE",
    CLAIM_UPDATED_REASON = "CLAIM_UPDATED_REASON",
    CLAIM_CREATED_ASSIGNEE = "CLAIM_CREATED_ASSIGNEE",
    CLAIM_UPDATED_ASSIGNEE = "CLAIM_UPDATED_ASSIGNEE",
    CLAIM_CREATED_CONCLUSION = "CLAIM_CREATED_CONCLUSION",
    CLAIM_UPDATED_CONCLUSION = "CLAIM_UPDATED_CONCLUSION",
    CLAIM_DELETED_CONCLUSION = "CLAIM_DELETED_CONCLUSION",
    CLAIM_CREATED_FINANCE = "CLAIM_CREATED_FINANCE",
    CLAIM_UPDATED_FINANCE = "CLAIM_UPDATED_FINANCE",
    CLAIM_DELETED_FINANCE = "CLAIM_DELETED_FINANCE",
    CLAIM_ARCHIVED = "CLAIM_ARCHIVED",
    CLAIM_RATING = "CLAIM_RATING",
    CLAIM_CUSTOMER_ARCHIVED = "CLAIM_CUSTOMER_ARCHIVED",
  }

export interface IChangedValues {
  key: string
  left: any
  right: any
}

export interface ILog<EventType = string, Details = {}> {
  id: string
  application: string
  module: string
  tenant: string
  subTenant: string
  activity: EventType
  timestamp: string
  actor: {
      grn: string
      username: string
      namespace: string
      module: string
      role: string
      tenant: string
      ip: string
      agent: string
      metadatda: {}
  }
  tracing: {
      tracer: string
      traceId: string
      spanId: string
  }
  change: {
      type: 'NEW_OBJECT'
      newObject: [Details]
      removedObject: [{}]
      valueChange: {
          changedValues: IChangedValues[]
      }
      mapChange: {
          addedEntries: [
              {
                  key: string
                  value: string
              }
          ]
          removedEntries: [
              {
                  key: string
                  value: string
              }
          ]
          changedEntries: [
              {
                  key: string
                  left: {}
                  right: {}
              }
          ]
      }
      containerChange: {
          key: string
          addedElements: [
              {
                  value: string
              }
          ]
          removedElements: [
              {
                  value: string
              }
          ]
      }
  }
  target: {
      id: string
      grn: string
      metadata: Details
  }
}
