import { Tag } from 'antd'
import { useClaimStates } from 'src/hooks/useClaimStates'


const ORDER_TYPES = [
    {
        code: 'order',
        name: 'Mua hộ',
        color: 'cyan'
    },
    {
        code: 'shipment',
        name: 'Ký gửi',
        color: 'volcano'
    },
    {
        code: 'peerpayment',
        name: "YCCT",
        color: 'geekblue'
    }
]



export const OrderTypeTag = ({ orderType }: { orderType: string | any }) => {
    const subject = ORDER_TYPES.find((type: any) => type.code === orderType)
    return <>
        <Tag color={subject?.color} style={{ fontSize: 12, padding: '0 6px' }} className='rounded-lg'>
            {subject?.name}
        </Tag>
    </>
}

export const STATES_COLOR = [
    {
        state: 'PROCESSING',
        color: '#b21cce',
        dotColor: 'bgprocessing'
    },
    {
        state: 'CREATED',
        color: '#008df9',
        dotColor: 'bgcreated'
    },
    {
        state: 'WAITING',
        color: '#d7c314',
        dotColor: 'bgwaiting'
    },
    {
        state: 'RECEIVE',
        color: '#f6ae2d',
        dotColor: 'bgreceive'
    },
    {
        state: 'COMPLAINT_COMMENT',
        color: '#b10f2e',
        dotColor: 'bgcomplaintcomment'
    },
    {
        state: 'ADD_TRANSACTION',
        color: '#008df9',
        dotColor: 'bgaddtransaction'
    },
    {
        state: 'ORIGIN_CODE',
        color: '#2ec4b6',
        dotColor: 'bgorigincode'
    },
    {
        state: 'RENEW',
        color: '#8367c7',
        dotColor: 'bgrenew'
    },
    {
        state: 'UPDATE_REASON',
        color: '#417b5a',
        dotColor: 'bgupdatereason'
    },
    {
        state: 'UPDATE_NOTE',
        color: '#540fda',
        dotColor: 'bgupdatenote'
    },
    {
        state: 'DELETE_TRANSACTION',
        color: '#ff4559',
        dotColor: 'bgdeletetransaction'
    },
    {
        state: 'FAILURE',
        color: 'red',
        dotColor: 'bgfailure'
    },
    {
        state: 'SUCCESS',
        color: '#28a745',
        dotColor: 'bgsuccess'
    },
    {
        state: 'NEW',
        color: '#e9d315',
        dotColor: 'bgnew'
    },
    {
        state: 'ACCEPTED',
        color: '#52c41a',
        dotColor: 'bgaccepted'
    },
    {
        state: 'REJECT',
        color: 'red',
        dotColor: 'bgreject'
    },
    {
        state: 'REFUND',
        color: '#52c41a',
        dotColor: 'bgrefund'
    }
]

export const ClaimStateTag = ({ state, children }: { state: string, children: any }) => {
    // const subject = STATES_COLOR.find((ele: any) => ele.state === state);
    const claimStateQuery = useClaimStates();
    const subject = claimStateQuery.data?.find((s: any) => s.code === state)
    return <>
        <div
            style={{ backgroundColor: `${subject?.color || subject?.dotColor}` }}
            className='claim-state-tag'
        >
            <span>{children}</span>
        </div>
    </>
}