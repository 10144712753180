import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import "../node_modules/antd/dist/antd.less";
import App from './App';
import './App.scss';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import '../src/resources/localization/i18n';
import { store } from './helpers';

const queryClient = new QueryClient;

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider>
            <QueryClientProvider client={queryClient}>
                <App/>
                <ReactQueryDevtools initialIsOpen/>
            </QueryClientProvider>
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
);
