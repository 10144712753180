import { Spin } from 'antd'
import axios from 'axios'
import { useEffect } from 'react'
import appConfig from '../../config/App'
import lang from '../../resources/localization/Localization'
import Utils from '../../utils'
import storageUtil from '../../utils/LocalStore/LocalStores'

export default function Login() {
    useEffect(() => {
        const fetchCurrentTenant = async () => {
            try {
                const rs = await axios.get(`${appConfig.apiUrl}/tenants/current`)
                storageUtil.setJson('currentTenant', rs.data)
                setTimeout(() => {
                    // window.location.href = appConfig.loginUrl + '/vn' + '?redirect_uri=' + window.location.origin + '/authentication'
                    window.location.href = `${appConfig.loginUrl}/${rs.data.code}?redirect_uri=${window.location.origin}/authentication`
                }, 500)
            } catch (err) {
                console.debug(err)
            }
        }
        fetchCurrentTenant()
        const redirectBackUrl = Utils.getUrlQueryByName('redirect_uri', this.props.location)
        if (redirectBackUrl) {
            storageUtil.setItem('redirect_uri', redirectBackUrl)
        }
    }, [])

    return (
        <>
            <div className={'mg-t-10 mg-l-10'}>
                {lang.login.redirect_page} <Spin />
            </div>
        </>
    )
}
