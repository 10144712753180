import {Component} from 'react';
import queryString from "query-string";
import lodash from 'lodash';

 class DefaultComponent extends Component {

    getQueryFromLocation = () => {
        return queryString.parse(this.props.location.search, {arrayFormat: 'bracket'});
    };

    getUrlQueryByName = (name, _default) => {
        return lodash.get(this.getQueryFromLocation(), name, _default);
    };

    pushQueryToHistory = (filter = {}) => {
        this.props.history.push(this.props.location.pathname + '?' + queryString.stringify(filter, {arrayFormat: 'bracket'}));
    };

    pushCleanQueryToHistory = (filter = {}) => {
        filter = lodash.omitBy(filter, value => value === '');
        this.pushQueryToHistory(filter);
    };
}

export default DefaultComponent;