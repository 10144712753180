import React from 'react'
import { IOrder } from 'src/interface/Orders'
import { ITicket } from 'src/interface/Ticket'

export interface IClaimDetailContext {
    detail: ITicket
    order: IOrder
    code: string
    project: string | null
}

export const defaultClaimContext: IClaimDetailContext = {
    detail: {} as ITicket,
    order: {} as IOrder,
    code:'',
    project: ''
}

export const ClaimDetailContext = React.createContext(defaultClaimContext)
