import { Tabs } from 'antd'
import React from 'react'
import { RouteChildrenProps, useHistory, useParams } from 'react-router'
import Component403 from '../../components/v2/Error/403'
import MainLayout from '../../components/v2/Layout/MainLayout'
import lang from '../../resources/localization/Localization'
import { TICKETS_SCOPES } from '../../utils/api/Scopes'
import SecurityService from '../../utils/SecurityService'
import StaffTab from './tabs/StaffTab'

const Staffs: React.FC<RouteChildrenProps> = (props: RouteChildrenProps) => {
    const params: { activeTab: string } = useParams()
    const history = useHistory()

    if (!SecurityService.can(TICKETS_SCOPES.CONFIG_STAFF_VIEW)) {
        return (
            // @ts-ignore
            <MainLayout title={`${lang.staff.list_staff as string} - `}>
                <Component403 />
            </MainLayout>
        )
    }

    return (
        // @ts-ignore
        <MainLayout title={`${lang.staff.list_staff}`}>
            <div className={'staffs-container'}>
                <StaffTab
                    {...props}
                />
            </div>
        </MainLayout>
    )
}

export default Staffs
