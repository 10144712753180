import { Input, InputProps } from 'antd';
import { useEffect } from 'react';
import { currencyLocaleFormatter, currencyParser } from 'src/utils/currency';

type CurrencyInputProps = {
} & InputProps

function CurrencyInput(props: CurrencyInputProps) {
  const {
    value
  } = props;

  return (
    <section>
      <Input
        {...props}
        value={currencyLocaleFormatter(currencyParser(value as string))}
        onChange={(e) => {
          if (e.target.value.length > 0) {
            if (/^[0-9]\d*(\.\d{0,2})?$/.test(currencyParser(e.target.value))) {
              props.onChange && props.onChange(e)
            } else return
          }
          else if (!e.target.value || e.target.value.length === 0) {
            props.onChange && props.onChange(e)
          }
          else return
        }}
      />
    </section>
  )
}

export default CurrencyInput;

//^(\d+(\.\d*)?|\.\d*)$ - số thập phân, số nguyên dương
//^[1-9]\d*(\.\d{0,2})?$ - số thập phân giới hạn 2 chữ số thập 

//^(0*[1-9]\d*(\.\d+)?|0*\.\d+)$