import { Form } from "antd";
import { Button, Input } from "antd";
import { useEffect, useRef } from "react";
import { ModalCommon } from "src/components/base/ModalCommon";
import lang from "../../../resources/localization/Localization";

const ChangePin = ({ onCancel, onOk, visible, loading }) => {
    const formRef = useRef();
    useEffect(() => {
        if (!visible) {
            formRef.current?.resetFields();
        }
    }, [visible])
    const onFinish = () => {
        if (!loading) {
            formRef.current?.validateFields().then(values => {
                delete values.rePIN
                onOk(values)
            })
        }
    }
    return <ModalCommon open={visible} onOk={onOk} onCancel={onCancel} footer={null} header={null}
        closable={false}
        closeIcon={<i className="fal fa-times-circle" />}>
        <div>
            <Form ref={formRef} onFinish={onFinish} labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}>

                <div className={"flex flex-row space-between mgbt8 align-items-center"}>
                    <div className={"bold text-16 txt-color-primary"}>{lang.update_pin.text}</div>
                    <i className="fal fa-times-circle txt-color-gray2 cursor-pointer" onClick={onCancel} />
                </div>
                <div className={"border-line width100"} />
                <div className={"mgt-24 mgb-24"}>
                    <Form.Item name={"currentPin"} label={lang.update_pin.old_pin} rules={[{
                        required: true,
                        message: lang.update_pin.input_old_pin
                    }]}>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item name={"newPin"} label={lang.update_pin.new_pin} rules={[{
                        required: true,
                        message: lang.update_pin.input_new_pin
                    }, {
                        min: 6,
                        message: lang.update_pin.least_six_characters
                    }, {
                        max: 99,
                        message: lang.update_pin.max_99_characters
                    }, {
                        pattern: /^[0-9]+$/,
                        message: lang.update_pin.allow_number_from_1_to_9
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('currentPin') !== value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(lang.update_pin.coincident_pin));
                        },
                    }),
                    ]}>
                        <Input.Password />
                    </Form.Item>
                    
                    <Form.Item name={"rePIN"} label={lang.update_pin.retype_pin} rules={[{
                        required: true,
                        message: lang.update_pin.retype_pin
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('newPin') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(lang.update_pin.incorrect_repin));
                        },
                    }),
                    ]}>
                        <Input.Password />
                    </Form.Item>
                </div>
                <div className={"flex flex-row width100 flex-content-end"}>
                    <Button disabled={loading}
                        onClick={() => !loading && onCancel()}
                        className={"buttonm3__reset mgr-8 _btn-cf-cancel"}>
                            <i className="fas fa-xmark mgr-5" />
                        {lang.update_pin.cancel}
                    </Button>
                    <Button disabled={loading} type="primary" className={"buttonm3 _btn-cf-submit"}
                        htmlType={"submit"}>
                        <i className="fas fa-check mgr-5" />
                        {lang.button.yes}
                    </Button>
                </div>
            </Form>

        </div>
    </ModalCommon>
}
export default ChangePin
