const currencyUnitMapping: { [key: string]: string } = {
    CNH: '¥',
    CNY: '¥',
}

export const currencyUnitFormat = (value: any, currency: string) => {
    if (value) {
        return currency ? currencyUnitMapping[currency] + value : value + '₫'
    } else return currencyUnitMapping[currency] || ''
}

export const currencyFomartter = (value: any) => {
    return value ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
}
export const currencyParser = (value: string) => {
    return value ? value.replace(/\$\s?|(,*)/g, '') : ''
}

export const currencyLocaleFormatter = (value: any) => {
    // Chuyển số thành chuỗi
    let numberString = value.toString()

    // Tìm vị trí của dấu chấm
    let decimalIndex = numberString.indexOf('.')

    // Lấy phần nguyên
    let integerPart = decimalIndex === -1 ? numberString : numberString.slice(0, decimalIndex)

    // Lấy phần thập phân (nếu có)
    let decimalPart = decimalIndex === -1 ? '' : numberString.slice(decimalIndex)

    // Thêm dấu phẩy ngăn cách hàng nghìn
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    // Kết hợp phần nguyên và phần thập phân để tạo số định dạng
    let formattedNumber = integerPart + decimalPart

    // console.log('formatter', formattedNumber)
    return formattedNumber
}
