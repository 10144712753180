import React from 'react'
import ScaleLoader from '../Spinner/ScaleLoader'
export default class index extends React.Component {
    render() {
        return (
            <div className="spinner utmhelve-regular" style={{
                padding: 10,
                color: "#333333",
                fontWeight: "bold"
            }}>
                <ScaleLoader
                    sizeUnit={"px"}
                    size={30}
                    color={'#008080'}
                    loading={true}
                />
                {/*<p style={{*/}
                    {/*padding: 5*/}
                {/*}}/>*/}
                {/*Loading...*/}
            </div>
        );
    }
}