import { FC, useEffect, useMemo, useRef } from 'react'
import ClaimApi from '../../../repository/ticket'
import Utils from '../../../utils'
import moment from 'moment'
import lang, { trans } from '../../../resources/localization/Localization'
import { TableCommon, TableCommonRef } from '../../../components/base/TableCommon'
import { ColumnsType } from 'antd/lib/table'
import _ from 'lodash'
import { useProjectsQuery } from 'src/hooks/query/useProjectsQuery'
import { useLocation } from 'react-router'
import { currencyUnitFormat } from 'src/utils/currency'

interface IProps {
    code: string
    state: string
}

const RefundList: FC<IProps> = ({ code, state }) => {
    const tableRef: TableCommonRef = useRef()

    const tenantProjects = useProjectsQuery();
    const location = useLocation();

    const currencyCondition = useMemo(() => {
        const searchParams = Utils.getQueryFromLocation(location);
        const projectList = _.cloneDeep(tenantProjects?.data)
        const currentProject = projectList?.find((p: any) => p.code === searchParams.project)
        // console.log(currentProject)
        if (currentProject) {
            // console.log('currency', currentProject?.currency)
            return currentProject?.currency
        }
        else return ''
    }, [tenantProjects, location])

    const columns: ColumnsType<any> = [
        {
            title: lang.ticket_detail.refunder,
            dataIndex: 'createdBy',
            render: (text: string) => {
                return <div className={'_refunder'}>{text || '---'}</div>
            },
        },
        {
            title: lang.ticket_detail.refund_reason,
            dataIndex: 'reason',
            render: (text: string) => {
                return <div className={'_reason'}>{text || '---'}</div>
            },
        },
        {
            title: lang.ticket_detail.refund_time,
            dataIndex: 'createdAt',
            className: '_table-item-createdAt',
            key: 'createdAt',
            render: (text: string) => <span>{text ? moment(text).format('HH:mm DD/MM/YYYY') : '---'}</span>,
        },
        {
            title: lang.edit_config_finance.finance_type,
            dataIndex: 'financeAccount',
            width: 150,
            className: '_table-item-financialAccount',
            key: 'financeAccount',
            render: (text: any) => <span>{text ? text.name : '---'}</span>,
        },
        {
            title: trans('ticket_detail.transaction_reason'),
            dataIndex: 'financeType',
            width: 150,
            className: '_table-item-financeType',
            key: 'financeType',
            render: (text: any) => <span>{text ? text.name : '---'}</span>,
        },
        {
            title: lang.ticket_detail.cashback,
            dataIndex: 'value',
            align: 'right',
            render: (text: string) => {
                return <span className={'_refundValue'}>{currencyUnitFormat(Utils.currencyFormat(text), currencyCondition)}</span>
            },
        },
    ]

    useEffect(() => {
        if (state === 'REFUND') {
            tableRef.current?.refreshData()
        }
    }, [state])

    return (
        <div>
            <div className={'box-title mgb-16'}>{lang.ticket_detail.cashback_list}</div>
            {state === 'REFUND' ? (
                <TableCommon
                    ref={tableRef}
                    emptyText={lang.ticket_detail.cashback_empty}
                    columns={columns}
                    className={'_table-refund-list'}
                    fetchApiService={({ project }) => ClaimApi.getTicketResultDetail(code, project)}
                />
            ) : (
                <TableCommon
                    emptyText={lang.ticket_detail.cashback_empty}
                    columns={columns}
                    className={'_table-refund-list'}
                    fetchApiService={undefined}
                />
            )}
        </div>
    )
}
export default RefundList
