import { Col, Row } from 'antd'
import lodash from 'lodash'
import { FC } from 'react'
import { IOrder } from 'src/interface/Orders'
import lang from 'src/resources/localization/Localization'
import { Content, Label, RowContainer, RowInfo } from '../components/Row'

interface IProps {
    order: IOrder
}

export const OrtherInfo: FC<IProps> = ({ order }) => {
    const getServices = () => {
        let serviceName = '---'
        lodash.orderBy(order.services, ['name'], ['asc']).map((service, index) => {
            if (index === 0) {
                serviceName = service.name
            } else {
                serviceName = serviceName + ' | ' + service.name
            }
        })
        return serviceName
    }
    return (
        <div className={'mgt16'}>
            <div className={'flex flex-row align-items-center mb-1'}>
                <div className={'text-14 font-medium txt-color-green4 mgr15'}>{lang.claim_detail.other_info}</div>
                <div
                    className={'border-line width100'}
                    style={{ flex: 4 }}
                />
            </div>

            <RowContainer>
                <RowInfo
                    label={lang.claim_detail.staff_buy}
                    className="_purchasingStaff">
                    {lodash.get(order, 'purchasingStaff', '---') || '---'}
                </RowInfo>

                <RowInfo
                    label={lang.claim_detail.buy_account}
                    className="_purchaseAccount">
                    {lodash.get(order, 'purchaseAccount', '---') || '---'}
                </RowInfo>

                <RowInfo
                    label={lang.claim_detail.order_warehouse}
                    className="_warehouse">
                    {lodash.get(order, 'warehouse', '---')}
                </RowInfo>

                <RowInfo label={lang.claim_detail.delivery_address}>{lodash.get(order, 'address', '---')}</RowInfo>

                <RowInfo label={lang.claim_detail.order_services}>{getServices()}</RowInfo>
            </RowContainer>
        </div>
    )
}
