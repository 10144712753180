import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { FC, useContext } from 'react'
import Utils from '../../../../utils'
import lang from '../../../../resources/localization/Localization'
import moment from 'moment'
import { renderStateDotColor } from '../../../../utils/helper'
import ClaimApi from '../../../../repository/ticket'
import { get } from 'lodash'
import { TableCommon } from '../../../../components/base/TableCommon'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { ClaimDetailContext } from '../context'
import { SubjectEnums } from 'src/interface/Orders'

interface IProps {
    setComplaintSellerTotal: (total: number | undefined) => void
}

const ComplaintSeller: FC<IProps> = ({ setComplaintSellerTotal }) => {
    const { code, detail } = useContext(ClaimDetailContext)

    const columns: ColumnsType<any> = [
        {
            title: lang.claim_detail.ticket_code,
            dataIndex: 'id',
            render: (id: any, record: any) => {
                return (
                    <a
                        target="_blank"
                        className={'_code text-normal '}
                        href={record.komodoComplaintDetailUrl ? record.komodoComplaintDetailUrl.replace(/{id}/g, id) : '#'}>
                        #{id}
                    </a>
                )
            },
        },
        {
            title: lang.ticket_add.claim_reason_select_title,
            dataIndex: 'reason',
            render: (text: any) => <div className={'flex text-14 _reason align-self-center'}>{text.title}</div>,
        },
        {
            title: lang.claim_detail.order_status,
            dataIndex: 'status',
            render: (text: any) => {
                return (
                    <div className={'dpl-flex _row-state'}>
                        <div className={'align-self-center mgr-5 status-dot ' + renderStateDotColor(text)} />
                        <span className={'txt-nowrap text-14'}>{get(lang.state_ticket, text)}</span>
                    </div>
                )
            },
        },
        {
            title: lang.list_ticket.created_time,
            dataIndex: 'createdAt',
            render: (text: any) => <div className={'_createdAt'}>{text ? <span className={'text-14'}>{moment(text).format('DD/MM/YYYY')}</span> : '--'}</div>,
        },
        {
            title: lang.claim_detail.alipay_refund,
            dataIndex: 'alipay',
            align: 'right',
            render: (text: any, row: any) => {
                return (
                    <div className={'txt-right _row-alipay text-14'}>
                        <p style={{ color: '#FF4559' }}>{row.exchangedAmountEarned ? Utils.currencyFormat(row.exchangedAmountEarned) : '---'} ₫</p>
                        <p style={{ color: '#333333' }}>{row.amountEarned || '---'} ¥</p>
                    </div>
                )
            },
        },
    ]

    const komodoComplaintCreateUrl = detail ? detail.komodoComplaintCreateUrl : ''

    return (
        <div className='mt-3 mb-3'>
            <div className={'flex flex-row space-between'}></div>

            <div>
                {code && (
                    <TableCommon
                        actions={
                            detail.ticketType !== SubjectEnums.Shipment &&
                            !!komodoComplaintCreateUrl && [
                                <div>
                                    <Button
                                        className={'button buttonm3 _create-complain-seller'}
                                        onClick={() =>
                                            komodoComplaintCreateUrl ? window.open(komodoComplaintCreateUrl.replace(/{code}/g, detail.relatedOrder)) : {}
                                        }
                                        style={{ display: 'flex', alignItems: 'center' }}>
                                        <PlusOutlined />
                                        {lang.claim_detail.add}
                                    </Button>
                                </div>,
                            ]
                        }
                        rowPerPage={5}
                        emptyText={lang.merchants.empty}
                        columns={columns}
                        className={'_table-complain-seller'}
                        fetchApiService={(...p) => ClaimApi.getRelativeComplaintSeller(code, ...p)}
                        setPaginationExtenal={({ total }: TablePaginationConfig) => setComplaintSellerTotal(total)}
                    />
                )}
            </div>
        </div>
    )
}
export default ComplaintSeller
