import apiClientV2 from '../utils/ApiClient'
import storageUtil from '../utils/LocalStore/LocalStores'

export default class ClaimApi {
    static getStates = () => {
        return apiClientV2.get(`/categories/states`, {
            params: {sort: 'sort:desc', offset: 0, limit: 99},
        })
    }

    static getStatesByProject(project) {
        return apiClientV2.get(`/categories/states_current`, {
            params: {project, sort: 'sort:desc', offset: 0, limit: 99},
        })
    }

    static getClaimType(project) {
        return apiClientV2.get(`/subjects`, {
            params: {offset: 0, limit: 99, sort: 'id:asc', project},
        })
    }

    static getTickets = (query) => {
        let temp = {...query}
        if (query.state) {
            temp.state = query.state.toUpperCase()
        }
        return apiClientV2.get(`/claims`, {
            params: {
                ...temp,
            }
        });
    }

    static getTicketDetail = (id, params) => {
        return apiClientV2.get(`/claims/${id}`, {
            params
        })
    }

    static getOrder = (code, params) => {
        return apiClientV2.get(`/claims/${code}/related`,{
            params
        })
    }

    static getPerPayment = (code) => {
        return apiClientV2.get(`/claims/${code}/related_peer_payment`)
    }

    static getChangeHistoryState = (code, customerUsername) => {
        return apiClientV2.get(`/claims/${code}/histories`, {
            params: {customerUsername}
        })
    }

    static getLogs = (code, project) => {
        return apiClientV2.get(`/claims/${code}/logs`, {
            params: {project},
        })
    }

    static getUsersOfIssue = (code) => {
        return apiClientV2.get(`claims/${code}/assignments`)
    }

    static getTicketResult = (code, project) => {
        return apiClientV2.get(`/claims/${code}/ticket_result?project=${project}`)
    }

    static getTicketResultDetail = (code, project) => {
        return apiClientV2.get(`/claims/${code}/refund-details?project=${project}`)
    }

    static getListFinancials = (code, params) => {
        return apiClientV2.get(`/claims/${code}/finances`,{
            params
        })
    }

    static getConclusion = (code, project) => {
        return apiClientV2.get(`/claims/${code}/conclusions?sort=createdAt:desc&project=${project}`)
    }

    /**
     * Call Api lấy danh sách loại Finance
     * @param claimTenant
     * @param query
     */

    static getFinanceTypes = (params) => {
        return apiClientV2.get(`/finance_types`, {
            params,
        })
    }

    static getFinanceTypesNotPermission = (params) => {
        return apiClientV2.get(`/finance_types/current`, {
            params,
        })
    }

    static getFinanceAccount = (params) => {
        return apiClientV2.get(`/finance_types/finance_account`, {
            params: params,
        })
    }

    static getReasons = (query) => {
        return apiClientV2.get(`/reasons`, {
            params: {
                ...query,
                offset: 0,
                limit: 200,
            },
        })
    }

    static getMistakes = (filter) => {
        return apiClientV2.get(`/mistakes/`, {
            params: filter
        });
    }

    //Call repository lấy danh sách người gây lỗi
    static getStaffMistake = (params) => {
        return apiClientV2.get(`/officers`, {
            params: params,
        })
    }

    static getStaffMistakeNoPermission = (params) => {
        return apiClientV2.get(`/officers/current`, {
            params: params,
        })
    }

    static getSupplySources = (filter) => {
        return apiClientV2.get(`/supply_source`, {
            params: filter
        });
    }

    static getRelativeComplaintSeller = (code, query) => {
        return apiClientV2.get(`/claims/${code}/komodo_complaints`, {
            params: query,
        })
    }

    static getRelative = (clamsId,  query) => {
        return apiClientV2.get(`/claims/${clamsId}/similars`, {
            params: query,
        })
    }

    static getAlipayTransaction = (code, params) => {
        return apiClientV2.get(`/claims/${code}/komodo_transactions`, {params})
    }

    static exportCSV = (query) => {
        return apiClientV2.get(`/claims/export`, {
            params: query,
        })
    }

    static getSolution = (query) => {
        return apiClientV2.get(`/solutions`, {
            params: {
                ...query,
                offset: 0,
                limit: 200,
            },
        })
    }

    // static getConnect = () => {
    //     const currentTenant = storageUtil.getJson("currentTenant");
    //     return apiClientV2.get(`/projects/current/project_mappings/${currentTenant?.tenant}`);
    // }

    //create tickets
    static createTicket = (data) => {
        const currentTenant = storageUtil.getJson('currentTenant')
        return apiClientV2.post(`/claims`, data)
    }

    //update status ticket
    static updateTicket = (data, code) => {
        return apiClientV2.patch(`/claims/${code}`, data, {
            timeout: 60000,
        })
    }

    //update status deadline
    static updateTicketDeadline = (data, code) => {
        return apiClientV2.patch(`/claims/${code}/deadline`, data)
    }
    

    //update status ticket reason
    static updateTicketReason = (data, code) => {
        return apiClientV2.patch(`/claims/${code}/reason`, data)
    }

    // Archive ticket
    static archiveTicket = (code, projectId) => {
        return apiClientV2.patch(`/claims/${code}/archive?project=${projectId}`)
    }

    // //assign user ticket
    // static assignUserToMe = (code) => {
    //     return apiClientV2.post(`/claims/${code}/assignments?assignee=${currentLoggedUser.username}&replace=true`)
    // }

    static assignUserToIssue = (code, params) => {
        return apiClientV2.post(`/claims/${code}/assignments`, params)
    }

    //configuration
    //update finance type

    static editFinancialType = (data) => {
        return apiClientV2.patch(`/financeTypes/${data.code}`, data)
    }

    static removeFinancialType = (id) => {
        return apiClientV2.delete(`/financeTypes/${id}`)
    }

    //create người gây ra lỗi
    static createStaffMistake = (data) => {
        return apiClientV2.post(`officers`, data)
    }

    // Call Api tạo 1 tài chính khiếu nại
    static createClaimFinance = (code, data, project) => {
        return apiClientV2.post(`/claims/${code}/finances?project=${project}`, data)
    }

    //Sửa tài chính khiếu nại
    static editFinancial = (code, data, project) => {
        return apiClientV2.patch(`/claims/${code}/finances/${data.id}?project=${project}`, data)
    }
    // xóa tài chính khiếu nại
    static deleteFinancial = ({code, id, project}) => {
        return apiClientV2.delete(`/claims/${code}/finances/${id}`, {params: {project}})
    }

    // Call Api create the conclusion of ticket
    static addConclusion = (code, data) => {
        return apiClientV2.post(`/claims/${code}/conclusions`, data)
    }

    // Call Api create the conclusion of ticket
    static editConclusion = (code, id, data) => {
        return apiClientV2.patch(`/claims/${code}/conclusions/${id}`, data)
    }

    // Call Api delete the conclusion of ticket
    static deleteConclusion = ({ code, id, project }) => {
        return apiClientV2.delete(`/claims/${code}/conclusions/${id}`, {params: {project}})
    }

    // Call Api xóa người tiếp nhận
    static unAssignUserToIssue = ({ code, username }) => {
        return apiClientV2.delete(`/claims/${code}/assignments?assignee=${username}`)
    }

    static getDisplayColumnsConfig = () => {
        return apiClientV2.get(`/display_configs`,{
            params: {entity: 'COMPLAINT_LIST'}
        })
    }

    static updateDisplayColumnsConfig = (body) => {
        return apiClientV2.patch(`/display_configs`,body)
    }

    static getAlipayInformations = (payload) => {
        return apiClientV2.get(`/claims/alipay`,{
            params: payload
        })
    }

    static getKNNBInformation = (payload) => {
        return apiClientV2.get(`/claims/komodo_log`,{
            params: payload
        })
    }
}
