import { notification, Select, SelectProps } from 'antd'
import { FC, useEffect, useState } from 'react'
import { IFinancialType } from 'src/interface/FinancialType'
import { removeAccents } from 'src/utils/stringUtils'
import ClaimApi from '../../../repository/ticket'
import lang from '../../../resources/localization/Localization'
import storageUtil from '../../../utils/LocalStore/LocalStores'

interface IProps extends SelectProps {
    typeValue?: 'string' | 'object'
    subject?: string
    uniqueKey?: 'code' | 'id'
    shipment?: string
    classname?: string
    onChange: any
    callToApiNotHavePermission?: boolean
    currentProject?: string
}

const SelectFinanceType: FC<IProps> = ({
    onChange,
    value,
    disabled,
    shipment,
    classname = '',
    typeValue = 'string',
    subject = undefined,
    uniqueKey = 'code',
    callToApiNotHavePermission = false,
    currentProject,
}: IProps) => {
    const [loading, setLoading] = useState(false)
    const [financeTypes, setFinanceTypes] = useState([])

    const project = currentProject || storageUtil.getItem('selectedProject')

    useEffect(() => {
        setLoading(true)
        const query = {
            offset: 0,
            limit: 1000,
            sort: 'position:asc',
            project,
            subject,
        }

        const callApiService = callToApiNotHavePermission ? ClaimApi.getFinanceTypesNotPermission : ClaimApi.getFinanceTypes

        callApiService(query)
            .then((r) => {
                setFinanceTypes(r.data)
            })
            .catch((e) => {
                notification.error({ message: e.message })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [shipment])

    return (
        <Select
            style={{ maxWidth: loading ? 130 : undefined }}
            disabled={disabled}
            onChange={(value) => onChange(typeValue === 'object' ? financeTypes.find((t) => value === t[uniqueKey]) : value)}
            value={loading ? undefined : value}
            placeholder={lang.finance_account_selector.select}
            loading={loading}
            className={classname + ' _select-type'}
            filterOption={(input, option) =>
                removeAccents(option?.label ?? '')
                    ?.toLowerCase()
                    .includes(removeAccents(input).toLowerCase())
            }
            showSearch
            options={financeTypes.map((type: IFinancialType) => ({
                label: type.name,
                value: type[uniqueKey],
            }))}
        />
    )
}

export default SelectFinanceType
