import { CaretRightOutlined, DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { Avatar, Layout, Select } from 'antd'
import i18n from 'i18next'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import en from '../../../resources/images/en.svg'
import vi from '../../../resources/images/vi.svg'
import lang from '../../../resources/localization/Localization'
import userService from '../../../services/user.service'
import Utils from '../../../utils'
import storageUtil from '../../../utils/LocalStore/LocalStores'
import SecurityService from '../../../utils/SecurityService'
import { TICKETS_SCOPES } from '../../../utils/api/Scopes'
import ConfigMenu from './ConfigMenu'

const Option = Select.Option

const Header = ({
    title,
    setCollapsed,
    loading,
    projects,
    selectedProject,
    onShowChangePin,
    onShowResetPin,
    loadingResetPin
}) => {
    const [user, setUser] = useState({ avatar: '', name: '' })
    const location = useLocation()
    const history = useHistory()
    const languages = [{
        key: 'vi',
        img: vi,
        name_vi: 'Việt Nam',
        name_en: 'Vietnamese'
    }, {
        key: 'en',
        img: en,
        name_vi: 'Tiếng Anh',
        name_en: 'English'
    }]

    const [createIsOpen, setCreateIsOpen] = useState(false)

    useEffect(() => {
        const currentLoggedUser = storageUtil.getJson('currentUser')
        setUser(currentLoggedUser)
    }, [])

    const handleChange = (value) => {
        const project = projects.find(p => p.project === value)
        storageUtil.setItem('selectedProject', project.project)
        const searchParams = Utils.getQueryFromLocation(location);
        Utils.pushCleanQueryToHistory(history, location, {
            ...searchParams
        })
        window.location.reload()
    }

    const [isVisible, setIsVisible] = useState(false)

    function _onClick(e) {
        e.preventDefault()
        setIsVisible(!isVisible)
    }

    const language = storageUtil.getItem('language')

    const changeLanguage = (lang) => {
        if (lang === language) return
        storageUtil.setItem('language', lang)
        window.onbeforeunload = null
        window.scrollTo(0, 0)
        i18n.changeLanguage(lang)
    }

    const states = {
        NEW: lang.state_ticket.NEW,
        REFUND: lang.state_ticket.REFUND,
        PROCESSING: lang.state_ticket.PROCESSING,
        ACCEPTED: lang.state_ticket.ACCEPTED,
        REJECT: lang.state_ticket.REJECT
    }

    let menuConfig = [
        {
            item: <span className='dpl-block width100'> <i
                className='fas fa-server txt-color-black3 mgr8 txt-size-h6' /><span
                    className='txt-size-h7'>Tenant</span> <CaretRightOutlined
                    className='mr-0 flr mgt5' /></span>,
            mobile: true,
            componentTab: <div className='tenant-box'>
                {
                    projects.map((p, index) => {
                        return (
                            <p key={index} onClick={() => handleChange(p.code)}>
                                <a className='flex items-center'
                                    style={{ fontWeight: selectedProject === p.code ? 'bold' : 'normal' }}>
                                    {p.name}
                                </a>
                            </p>
                        )
                    })
                }
            </div>
        },
        {
            item: <span className='dpl-block width100'> <i
                className='fas fa-globe-asia txt-color-black3 mgr8 txt-size-h6' /><span
                    className='txt-size-h7'>{lang.footer.language}</span> <CaretRightOutlined
                    className='mr-0 flr mgt5' /></span>,
            componentTab: <div className='language-box'>
                {
                    languages.map((item, index) => {
                        return (
                            <p key={index}>
                                <a className='flex items-center'
                                    onClick={() => changeLanguage(item.key)}>
                                    <img className={'mgr8 mgt1'} style={{ width: 25, height: 15 }} alt={item.key}
                                        src={item.img} /> {item['name_' + language]}
                                </a>
                            </p>
                        )
                    })
                }
            </div>
        },
        {
            item: <a href='#' className='txt-size-h7'><i
                className='fas fa-user txt-color-black3 mgr8 txt-size-h6' /> {lang.header.account_info}</a>
        },
        {
            item: <span className='dpl-block width100'><i
                className='fas fa-key txt-color-black3 mgr8 txt-size-h6' /><span
                    className='txt-size-h7 cursor-pointer'>{lang.header.change_pin}</span>
                <CaretRightOutlined
                    className='mr-0 flr mgt5' />
            </span>,
            componentTab: <div className='pas-pin'>
                <p onClick={() => !loadingResetPin && onShowResetPin()}>
                    <a className='flex items-center flex-row'>
                        {loadingResetPin ? <LoadingOutlined className={'mgr-7 mgt1 txt-color-gray2'} /> :
                            <i className='fal fa-exclamation mgr-12 mgl-4 mgt1' />}
                        <div className={loadingResetPin ? 'txt-color-gray2' : ''}>
                            {lang.update_pin.forgot_pin}
                        </div>
                    </a>
                </p>
                <p onClick={() => onShowChangePin()}>
                    <a className='flex items-center flex-row'>
                        <i className='fal fa-cog mgr8 mgt1' />
                        <div>
                            {lang.update_pin.text}
                        </div>
                    </a>
                </p>
            </div>
        },
        {
            item: <a className='text-red txt-size-h7' href='#' onClick={() => {
                userService.logout()
            }}><i className='fas fa-arrow-alt-from-left txt-color-black3 mgr8 txt-size-h6'
                /> {lang.login.logout_btn}</a>
        }
    ]

    const params = Utils.getQueryFromLocation(location)

    const handleChangeCreateModalVisible = (visible) => {
        if (SecurityService.can(TICKETS_SCOPES.TICKETS_CREATE_FOR_CUSTOMER)) {
            setCreateIsOpen(visible)
        }
    }

    const handleHideSelectProject = () => {
        if (!location.pathname.startsWith('/tickets/')) {
            let arr = ['staffs']
            return !arr.includes(location.pathname.replace('/', ''));
        }
        else {
            return !location.pathname.startsWith('/tickets/');
        }

    }

    return (
        <Layout.Header className={'header-v2'}>
            <div className={'left-header'}>
                <span className={'pdr-20 expandAction text-primary'} onClick={() => setCollapsed(false)}><i
                    className='fas fa-bars menu' /></span>
                <div className={'title mgl16'}>
                    <span className={'txt-size-h5 robotomedium txt-color-black3 header-title'}>{title || 'Trang chủ'}</span> {location.pathname === '/tickets' &&
                        <span className={'title-state txt-size-h5 robotomedium txt-color-black only-show-from-lg'}> / {params.stateUnchanged ? states[params.stateUnchanged] : lang.list_ticket.all}</span>}
                </div>
            </div>
            <div className={'right-header'}>
                <div className='flex items-center'>
                    {handleHideSelectProject() && <Select value={selectedProject}
                        onChange={handleChange} className={'_select-tenant select-box w-full min-w-[150px]'}>
                        {projects.map((project, index) => {
                            return (
                                <Option key={project.project + '-' + index}
                                    classname={'_tenant-' + project.project}
                                    value={project.project}>{project.project}</Option>)
                        }
                        )}
                    </Select>}
                </div>
                <div className='user-info'>
                    {
                        <ConfigMenu items={menuConfig} isDropDown={true} visible={isVisible}
                            onVisible={setIsVisible}>
                            <a className='ant-dropdown-link user-info-web txt-size-h7' onClick={_onClick}>
                                <Avatar
                                    size={32}
                                    shape='square' src={user.avatar} /><span className="only-show-from-lg ml-2"> {user.name || user.username}</span> <DownOutlined className='mx-1' />
                            </a>
                            <a className='ant-dropdown-link user-info-mobile txt-size-h7'
                                onClick={_onClick}>
                                <Avatar shape='square'
                                    src={user.avatar} />
                            </a>
                        </ConfigMenu>
                    }
                </div>
            </div>
        </Layout.Header>
    )
}
export default Header
