/**
 * created by Taobao dev team - 2/2/2018
 */
import {cookieReadObject, cookieSaveObject} from "./cookie";
import _ from "lodash";

/**
 * Singleton LocalStore
 * @type {{getInstance}}
 */
export const LocalStore  = (function () {
    let instance;

    function createInstance() {
        let object = {};

        if (isLocalStorageAvailable()) {
            object.save = localStorageSaveObject;
            object.read = localStorageReadObject;
        } else {
            object.save = cookieSaveObject;
            object.read = cookieReadObject;
        }

        return object;
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

/**
 * check if we can use localStorage
 * @returns {boolean}
 */
function isLocalStorageAvailable() {
    try {
        localStorage.setItem("test", "test");
        localStorage.setItem("test", undefined);

        return true;
    } catch (e) {
        return false;
    }
}

/**
 * save object to local storage
 * @param key
 * @param data
 */
function localStorageSaveObject(key, data) {
    try {
        localStorage.setItem(key, _.isObject(data) ? JSON.stringify(data) : data);
    } catch (e) {
        console.log("Cannot save data to local storage", e);
    }
}

/**
 * read localStorage
 * @param key
 * @returns {any}
 */
function localStorageReadObject(key) {
    if (localStorage == null || typeof key !== 'string') {
        throw new Error("fail to read object to localStorage");
    }

    let result = localStorage.getItem(key);

    try {
        return JSON.parse(result);
    } catch (e) {
        return result;
    }
}
