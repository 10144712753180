import { PlusOutlined } from '@ant-design/icons'
import { InputMoney } from '@gobizvn/homo'
import { Button, Form, Input, notification, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import _ from 'lodash'
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useProjectsQuery } from 'src/hooks/query/useProjectsQuery'
import TableEmpty from '../../../components/v2/Error/TableEmpty'
import ModalConfirm from '../../../components/v2/Modal/Confirm'
import SelectFinanceType from '../../../components/v2/Select/SelectFinanceType'
import ClaimApi from '../../../repository/ticket'
import lang from '../../../resources/localization/Localization'
import Utils from '../../../utils'
import { TICKETS_SCOPES } from '../../../utils/api/Scopes'
import { checkPermission } from '../../../utils/helper'
import { ClaimDetailContext } from './context'
import { currencyLocaleFormatter, currencyParser, currencyUnitFormat } from 'src/utils/currency'
import CurrencyInput from 'src/components/CurrencyInput'


const Financial = ({ code, detail, order, loadDetail, loadOrder, errorCode }: any) => {
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [isShowConfirm, setShowConfirm] = useState(false)
    const [indexConfirm, setIndexConfirm] = useState<number | string>('')
    const [form]: [any] = Form.useForm()

    const { project } = useContext(ClaimDetailContext)

    const ticketState = detail.stateViewClaim?.code

    const tenantProjects = useProjectsQuery();

    const currencyCondition = useMemo(() => {
        const projectList = _.cloneDeep(tenantProjects?.data)
        const currentProject = projectList?.find((p: any) => p.code === project)
        // console.log(currentProject)
        if (currentProject) {
            // console.log('currency', currentProject?.currency)
            return currentProject?.currency
        }
        else return ''
    }, [tenantProjects, project])

    useEffect(() => {
        if (checkPermission(TICKETS_SCOPES.TICKET_FINANCE_VIEW)) {
            getListFinancials()
        }
    }, [code])

    const getListFinancials = () => {
        setLoading(true)
        const filter = {
            sort: 'createdAt:desc',
            project,
        }
        ClaimApi.getListFinancials(code, filter)
            .then((res) => {
                setData(res.data)
            })
            .catch((err) => { })
            .finally(() => {
                setLoading(false)
            })
    }
    const columns: ColumnsType<any> = [
        {
            title: lang.claim_detail.type_financial,
            dataIndex: 'type',
            width: isMobile ? 185 : undefined,
            fixed: isMobile ? 'left' : undefined,
            render: (type: any, record: any, index: number) => {
                return record.edit ? (
                    <div>
                        <SelectFinanceType
                            classname={'width100'}
                            typeValue={'object'}
                            uniqueKey="id"
                            loading={record.loading}
                            value={record?.data?.finance}
                            disabled={record.loading}
                            onChange={(value: any) => onChangeFinanceType(value, index)}
                            shipment={detail.shipment}
                            subject={detail.ticketType}
                            callToApiNotHavePermission
                            currentProject={project as string}
                        />
                        {record?.data?.finance && parseInt(record.data.maxValue || '0') > 0 && (
                            <div className={'txt-color-yellow _max-value mgt-5'}>
                                {lang.claim_detail.maxValue} : {currencyUnitFormat(Utils.currencyFormat(record.data.maxValue), currencyCondition)}
                            </div>
                        )}
                        {record?.valid?.finance?.message && <div className={'txt-color-red2 _message-valid mgt-5'}>{record?.valid?.finance?.message}</div>}
                    </div>
                ) : (
                    <div className={'_type'}>{type?.name || '---'}</div>
                )
            },
        },
        {
            title: lang.edit_config_finance.finance_type,
            dataIndex: 'financialAccount',
            render: (_: string, record: any) => {
                return <div className={'_financialAccount'}>{record?.type?.financialAccount?.name || record?.financialAccount?.name || '---'}</div>
            },
        },
        {
            title: lang.ticket_detail.finance_reason,
            dataIndex: 'reason',
            render: (reason: string, record: any, index: number) => {
                return record.edit ? (
                    <div>
                        <Input
                            value={record?.data?.reason}
                            placeholder={lang.ticket_detail.finance_reason_input}
                            className={'_input-financial-reason'}
                            disabled={!record?.data?.finance || record.loading}
                            onPressEnter={() => onSubmit(index)}
                            maxLength={255}
                            onChange={(e) => onChangeInput(e.target.value, index, 'reason')}
                        />
                        {record?.valid?.reason?.message && <div className={'txt-color-red2 _message-valid mgt-5'}>{record?.valid?.reason?.message}</div>}
                    </div>
                ) : (
                    <div>
                        {reason ? (
                            <div
                                className={`_reason`}
                                dangerouslySetInnerHTML={{ __html: Utils.replaceURLs(reason) }}
                            />
                        ) : (
                            <div>--</div>
                        )}
                        <span className={' _createdBy_'}>
                            <a className={'_createdBy_' + record.createdBy}>{record.createdBy}</a>{' '}
                            <a className={'_created_time'}>{moment(record.createdAt).format('HH:mm DD/MM/YYYY')}</a>
                        </span>
                    </div>
                )
            },
        },
        {
            title: lang.claim_detail.amount_financial,
            dataIndex: 'value',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                return record.edit ? (
                    <div>
                        {
                            ['CNY', 'CNH'].includes(currencyCondition) ? <CurrencyInput
                                className={"_input-financial-countMoney w-full rounded-lg"}
                                value={currencyLocaleFormatter(currencyParser(record?.data?.countMoney))}
                                disabled={!record?.data?.finance || record.loading}
                                onPressEnter={() => onSubmit(index)}
                                onChange={(e: any) => {
                                    onChangeInput(currencyParser(e.target.value), index, 'countMoney');
                                }}
                            />
                                : <InputMoney
                                    value={record?.data?.countMoney}
                                    disabled={!record?.data?.finance || record.loading}
                                    loading={record.loading}
                                    // @ts-ignore
                                    decimalSeparator={','}
                                    maxLength={255}
                                    className={'_input-financial-countMoney'}
                                    onPressEnter={() => onSubmit(index)}
                                    onChange={(value) => onChangeInput(value.replace(/[^0-9]/g, ''), index, 'countMoney')}
                                />
                        }

                        {record?.valid?.countMoney?.message && (
                            <div className={'txt-color-red2 _message-valid mgt-5'}>{record?.valid?.countMoney?.message}</div>
                        )}
                    </div>
                ) : (
                    <div className={'_financialValue'}>{currencyUnitFormat(Utils.currencyFormat(text), currencyCondition)}</div>
                )
            },
        },
        {
            title: '',
            dataIndex: 'action',
            align: 'right',
            render: (_: string, record: any, index: number) => {
                if (ticketState === 'REFUND' || ticketState === 'REJECT') {
                    return null
                }

                return record.edit ? (
                    <div className={'flex flex-row flex-content-end'}>
                        <div
                            className={'mgr-12 txt-color-primary text-12 font-medium cursor-pointer _btn-financial-save'}
                            onClick={() => !record.loading && onSubmit(index)}>
                            {lang.button.save}
                        </div>
                        <div
                            className={'txt-color-red2 text-12 font-medium cursor-pointer _btn-financial-cancel'}
                            onClick={() => !record.loading && removeRow(index)}>
                            {lang.button.cancel}
                        </div>
                    </div>
                ) : (
                    <div className={'flex flex-row flex-content-end'}>
                        {checkPermission(TICKETS_SCOPES.TICKET_FINANCE_UPDATE) && (
                            <div
                                className={'mgr-12 txt-color-primary text-12 font-medium cursor-pointer _btn-financial-edit'}
                                onClick={() => !record.loading && editRow(index)}>
                                {lang.button.edit}
                            </div>
                        )}

                        {checkPermission(TICKETS_SCOPES.TICKET_FINANCE_DELETE) && (
                            <div
                                className={'txt-color-red2 text-12 font-medium cursor-pointer _btn-financial-delete'}
                                onClick={() => !record.loading && deleteRecord(index)}>
                                {lang.button.delete}
                            </div>
                        )}
                    </div>
                )
            },
        },
    ]
    const editRow = (index: number) => {
        const newData: any = [].concat(data)
        const values: any = data[index]
        values.edit = true
        values.data = {
            ...values.data,
            countMoney: values.value?.toString(),
            finance: values.type.id,
            reason: values.reason,
            maxValue: order.maxRefund,
        }

        newData[index] = values
        setData(newData)
    }
    const deleteRecord = (index: number) => {
        setShowConfirm(true)
        setIndexConfirm(index)
    }
    const onSubmit = (index: number) => {
        const values: any = data[index]
        const newData: any = [].concat(data)
        if (values?.data?.finance && parseFloat(values?.data?.countMoney) > 0 && values?.data?.countMoney) {
            let payload: any = {
                financeTypeId: values?.data?.finance,
                value: values?.data?.countMoney,
                reason: values?.data?.reason,
            }
            setLoading(true)
            if (values.local) {
                ClaimApi.createClaimFinance(code, payload, project)
                    .then((res) => {
                        notification.success({ message: lang.message.success })
                        const newData = data.map((d: any, i: number) => (i === index ? res.data[0] : d))
                        setData(newData)
                        if (loadDetail) loadDetail()
                        if (loadOrder) loadOrder()
                    })
                    .catch((err) => {
                        if (err?.response?.status === 400) {
                            if (err?.response?.data?.title === 'claim_finance_wrong') {
                                notification.error({ message: lang.claim_detail.financial_empty })
                            } else if (err?.response?.data?.title === 'exceed_allowable_refund_amount') {
                                notification.error({ message: lang.error.order_refund_over_maximum })
                            } else if (err?.response?.data?.title === 'claim_finance_value') {
                                notification.error({ message: lang.error.money_maximum })
                            } else if (err?.response?.data?.title === 'claim_closed') {
                                notification.error({ message: lang.conclusion.cannot_manipulation })
                            } else if (err?.response?.data?.title === 'invalid_financial_value') {
                                notification.error({ message: lang.message.warning_value })
                            } else if (lang.error[err?.response?.data?.title]) {
                                notification.error({ message: lang.error[err?.response?.data?.title] })
                            } else {
                            }
                        } else {
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            } else {
                payload.id = values.id
                newData[index].loading = true
                ClaimApi.editFinancial(code, payload, project)
                    .then((res) => {
                        notification.success({ message: lang.message.success })
                        const newData = data.map((d: any, i: number) => (i === index ? res.data : d))
                        setData(newData)
                        if (loadDetail) loadDetail()
                        if (loadOrder) loadOrder()
                    })
                    .catch((err) => {
                        newData[index].loading = false
                        setData(newData)
                        if (err?.response?.status === 400) {
                            if (err?.response?.data?.title === 'claim_finance_wrong') {
                                notification.error({ message: lang.claim_detail.financial_empty })
                            } else if (err?.response?.data?.title === 'exceed_allowable_refund_amount') {
                                notification.error({ message: lang.error.order_refund_over_maximum })
                            } else if (err?.response?.data?.title === 'claim_finance_value') {
                                notification.error({ message: lang.error.money_maximum })
                            } else if (err?.response?.data?.title === 'claim_closed') {
                                notification.error({ message: lang.conclusion.cannot_manipulation })
                            } else if (err?.response?.data?.title === 'invalid_financial_value') {
                                notification.error({ message: lang.message.warning_value })
                            } else if (lang.error[err?.response?.data?.title]) {
                                notification.error({ message: lang.error[err?.response?.data?.title] })
                            } else {
                            }
                        } else {
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        } else {
            if (!values?.data?.finance) {
                values.valid = { ...values.valid, finance: { message: lang.message.not_empty_allowed } }
            }
            if (!values?.data?.countMoney) {
                values.valid = { ...values.valid, countMoney: { message: lang.message.not_empty_allowed } }
            } else if (values?.data?.countMoney && parseFloat(values?.data?.countMoney) <= 0) {
                values.valid = { ...values.valid, countMoney: { message: lang.message.warning_value } }
            }
            newData[index] = values
            setData(newData)
        }
    }
    const removeRow = (index: number) => {
        const newData: any = [].concat(data)
        if (newData[index].changeValue) {
            if (!newData[index].local) {
                newData[index].edit = false
                setData(newData)
                return
            }
            setShowConfirm(true)
            setIndexConfirm(index)
            return
        }
        if (!newData[index].local) {
            newData[index].edit = false
            setData(newData)
            return
        }
        newData.splice(index, 1)
        setData(newData)
    }

    const onRemoveFinancial = () => {
        const newData: any = [].concat(data)
        if (newData[indexConfirm].local) {
            newData.splice(parseInt(indexConfirm as string), 1)
            setData(newData)
            setShowConfirm(false)
        } else {
            newData[indexConfirm].loading = true
            ClaimApi.deleteFinancial({ code, id: newData[indexConfirm].id, project })
                .then(() => {
                    notification.success({ message: lang.message.success })
                    newData.splice(parseInt(indexConfirm as string), 1)
                    setData(newData)
                    setShowConfirm(false)
                    if (loadOrder) loadOrder()
                    if (loadDetail) loadDetail()
                })
                .catch((err) => {
                    newData[indexConfirm].loading = false
                    setData(newData)
                    if (err?.response?.status === 400) {
                        if (err?.response?.data?.title === 'claim_finance_wrong') {
                            notification.error({ message: lang.claim_detail.financial_empty })
                            getListFinancials()
                            setShowConfirm(false)
                        } else if (err?.response?.data?.title === 'claim_closed') {
                            notification.error({ message: lang.conclusion.cannot_manipulation })
                        } else if (lang.error[err?.response?.data?.title]) {
                            notification.error({ message: lang.error[err?.response?.data?.title] })
                        } else if (err?.response?.data?.title === 'can_not_delete_finance') {
                            notification['error']({
                                message: 'Phải tồn tại ít nhất 1 loại giao dịch tài chính khi khiếu nại ở trạng thái Chấp nhận'
                            })
                        }
                        else {

                        }
                    } else {
                    }
                })
                .finally(() => { })
        }
    }

    const onChangeInput = (value: string, index: number, field: string) => {
        const newData: any = [].concat(data)
        newData[index]['data'][field] = parseInt(newData[index].maxValue) < parseInt(value) ? newData[index].maxValue.toString() : value
        newData[index].changeValue = true
        if (newData[index]?.valid?.[field]?.message.length > 0) {
            delete newData[index]['valid'][field]
        }
        setData(newData)
    }
    const onChangeFinanceType = (type: any, index: number) => {
        // let fees = order.fees || [];
        // let mappingFinancialType = fees.find(x => x.type === type.code);
        // let maxValue = 9999999999999;
        const newData: any = [].concat(data)
        // if (type.code === 'total_value') {
        //     maxValue = order.totalValue;
        // } else if (type.code === 'indemnify') {
        //     let valueIndemnify = Utils.currencyFormat(order.merchantShippingCost) * Utils.currencyFormat(order.exchangeRate) + Utils.currencyFormat(order.totalFee) + Utils.currencyFormat(order.totalValue);
        //     maxValue = order.shipment ? order.totalPaid : valueIndemnify;
        // } else {
        //     // nếu có service trong đơn thì lấy amount làm giá trị tối đa
        //     if (mappingFinancialType && mappingFinancialType.actualAmount !== null) {
        //         maxValue = mappingFinancialType.actualAmount
        //     } else {
        //         maxValue = order.maxRefund
        //     }
        // }
        let maxValue: any = detail.shipment ? 9999999999 : order.maxRefund
        // newData[index]["data"].finance = type.code
        // newData[index]["data"].maxValue = maxValue
        newData[index].changeValue = true
        newData[index].data = {
            ...newData[index].data,
            finance: type.id,
            maxValue: maxValue,
        }
        if (newData[index] && newData[index]['countMoney'] > maxValue) {
            newData[index]['data']['countMoney'] = maxValue
        }
        newData[index]['financialAccount'] = type.financialAccount ? type.financialAccount : '---'
        if (newData[index]?.valid?.finance?.message.length > 0) {
            delete newData[index]['valid']['finance']
        }
        setData(newData)
    }
    const addRow = () => {
        setData([{ edit: true, id: data.length + 1, local: true }].concat(data))
    }

    return (
        <div className={'bg-white pd-24 box'}>
            <div className={'flex flex-row space-between'}>
                <div className={'box-title m-0'}>{lang.claim_detail.title_financial}</div>
                {checkPermission(TICKETS_SCOPES.TICKET_FINANCE_CREATE) && ticketState !== 'REFUND' && ticketState !== 'REJECT' && (
                    <div>
                        <Button
                            className={'button buttonm3 _create-financial'}
                            onClick={addRow}
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <PlusOutlined />
                            {lang.claim_detail.add}
                        </Button>
                    </div>
                )}
            </div>
            <div className={'mgt16'}>
                <Form ref={form}>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={data}
                        className={'_table-financial'}
                        rowKey={(row) => row.code}
                        scroll={{ x: isMobile ? 'calc(620px + 50%)' : '' }}
                        locale={{
                            emptyText: (
                                <TableEmpty
                                    loading={loading}
                                    errorCode={errorCode}
                                    emptyText={lang.claim_detail.financial_empty}
                                />
                            ),
                        }}
                        pagination={{
                            current: 1,
                            total: data.length,
                            pageSize: 100,
                            hideOnSinglePage: true,
                        }}
                    />
                </Form>
            </div>

            <ModalConfirm
                visible={isShowConfirm}
                onOk={onRemoveFinancial}
                content={lang.conclusion.confirm_delete}
                title={lang.conclusion.warning}
                onCancel={() => {
                    setShowConfirm(false)
                }}
            />
        </div>
    )
}
export default Financial
