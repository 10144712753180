import { Select, SelectProps } from 'antd'
import { FC } from 'react'
import { useProjects } from '../../../hooks/useProjects'
import { trans } from '../../../resources/localization/Localization'

interface IProps extends SelectProps { }

export const SelectProject: FC<IProps> = (props: IProps) => {
    const { projects } = useProjects()

    return (
        <Select
            allowClear
            style={{ width: '100%' }}
            placeholder={trans('common.select_project')}
            mode={'multiple'}
            className={'_select-tenant width100'}
            {...props}>
            {projects.map((project: any) => (
                <Select.Option
                    key={project?.code}
                    value={project?.code}
                    className={'_tenant _tenant-' + project?.code}>
                    {project.name}
                </Select.Option>
            ))}
        </Select>
    )
}
