import apiClientV2 from '../utils/ApiClient'

export default class UserApi {
    static getCurrentUser = () => {
        return apiClientV2.get(`/profile`).then((response) => response)
    }

    static getListStaff = (params = {}) => {
        return apiClientV2.get(`/staffs`, {
            params,
        })
    }

    static getListStaffNotPermission = (params = {}) => {
        return apiClientV2.get(`/staffs/autocomplete`, {
            params,
        })
    }

    static saveThreshold = (userId, threshold) => {
        return apiClientV2.patch(`/staffs/${userId}`, {
            threshold: threshold,
        })
    }

    static getLogs = (email) => {
        return apiClientV2.get(`/staffs/${email}/logs`)
    }

    static create = (data) => {
        return apiClientV2.post(`/staffs`, data)
    }

    static updateAssignment = (data) => {
        return apiClientV2.post(`/staffs/assignment`, data)
    }

    static remove = (email) => {
        return apiClientV2.delete(`/staffs/${email}`)
    }
}
