import { get, uniqueId } from 'lodash'
import { FC, ReactNode, useCallback, useContext, useMemo } from 'react'
import { useLocation } from 'react-router'
import { ChangedIcon, FromText } from 'src/components/Logs/ChangedIcon'
import { LogModal } from 'src/components/Logs/LogModal'
import { useProjectsQuery } from 'src/hooks/query/useProjectsQuery'
import { useFinanceType } from 'src/hooks/useFinanceType'
import { useMistakes } from 'src/hooks/useMistakes'
import { useOfficersNoPermission } from 'src/hooks/useOfficersNoPermission'
import { ClaimLogEvents, IChangedValues, ILog } from 'src/interface/Log'
import { IClaimConclusion, IClaimFinancial, ITicketLog, TicketStateEnum } from 'src/interface/Ticket'
import ClaimApi from 'src/repository/ticket'
import lang, { trans } from 'src/resources/localization/Localization'
import Utils from 'src/utils'
import { TimeUtils } from 'src/utils/timeUtils'
import { ClaimDetailContext } from '../context'

interface IClaimLog extends ILog<ClaimLogEvents> { }

interface IProps {
    code: string
    isShow: boolean
    subject: string
    onCancel: () => void
}

type NewObjectType = ITicketLog | IClaimFinancial | IClaimConclusion | any

export const TicketLogs: FC<IProps> = ({ code, isShow, subject, onCancel }: IProps) => {
    const { project } = useContext(ClaimDetailContext)
    const { financeTypeNameMapping } = useFinanceType(subject)
    const { officersCodeMapping } = useOfficersNoPermission(subject)
    const { mistakesNameMapping } = useMistakes()
    const location = useLocation()
    const queryParams = Utils.getQueryFromLocation(location)
    const { data: allProjects } = useProjectsQuery()

    const ticketProject = useMemo(() => {
        return allProjects?.find((p: any) => p.code === (queryParams.project))
    }, [queryParams, allProjects])

    const getValueByCurrency = useCallback((value: any) => {
        if (['CNH', 'CNY'].includes(ticketProject.currency)) {
            return Utils.currencyFormatCNY(value)
        }
        else return Utils.currencyFormatVND(value)
    }, [ticketProject])

    const renderActions = ({ activity, ...row }: IClaimLog) => {
        const newObject: NewObjectType = get(row, 'change.newObject[0]') || ({} as NewObjectType)
        const changedValues: IChangedValues[] = get(row, 'change.valueChange.changedValues') || []
        const removedObject: NewObjectType = get(row, 'change.removedObject[0]') || ({} as NewObjectType)

        const renderLogItem = () => {
            const right = changedValues[0]?.right
            switch (activity) {
                case ClaimLogEvents.CLAIM_CREATED:
                    return renderClaimCreated({ newObject: newObject as ITicketLog })

                case ClaimLogEvents.CLAIM_UPDATED_STATE:
                    return (
                        <>
                            {trans(
                                'log_detail.update_state',
                                { left: lang.state_ticket[changedValues[0]?.left], right: lang.state_ticket[changedValues[0]?.right] },
                                'span'
                            )}
                            {right === TicketStateEnum.REFUND && (
                                <>
                                    <br />
                                    {trans(
                                        'log_detail.update_state_refund',
                                        {
                                            right: getValueByCurrency(get(newObject, 'totalRefund')),
                                        },
                                        'span'
                                    )}
                                </>
                            )}
                        </>
                    )

                case ClaimLogEvents.CLAIM_UPDATED_DEADLINE:
                    return trans(
                        'log_detail.update_deadline',
                        { left: TimeUtils.showDateTime(changedValues[0]?.left), right: TimeUtils.showDateTime(changedValues[0]?.right) },
                        'span'
                    )

                case ClaimLogEvents.CLAIM_UPDATED_REASON:
                    return 'CLAIM_UPDATED_REASON'

                case ClaimLogEvents.CLAIM_CREATED_ASSIGNEE:
                    return 'CLAIM_CREATED_ASSIGNEE'
                case ClaimLogEvents.CLAIM_UPDATED_ASSIGNEE:
                    return renderClaimUpdatedAssignee({ changedValues })

                case ClaimLogEvents.CLAIM_CREATED_CONCLUSION:
                    return renderCreatedConlusion({ newObject: newObject as IClaimConclusion, valueFomatterFnc: getValueByCurrency })

                case ClaimLogEvents.CLAIM_UPDATED_CONCLUSION:
                    return renderUpdatedConlusion({ newObject, changedValues, officersCodeMapping, mistakesNameMapping, valueFomatterFnc: getValueByCurrency })

                case ClaimLogEvents.CLAIM_DELETED_CONCLUSION:
                    return trans(
                        'log_detail.remove_conclusion_mistake',
                        {
                            mistake: removedObject.mistakeView?.name,
                        },
                        'span'
                    )

                case ClaimLogEvents.CLAIM_CREATED_FINANCE:
                    return trans(
                        'log_detail.create_claim_finance',
                        {
                            title: newObject.type?.name,
                            // amount: Utils.currencyFormatVND(newObject.value),
                            amount: getValueByCurrency(newObject.value),
                            reason: get(newObject, 'reason')
                        },
                        'span'
                    )

                case ClaimLogEvents.CLAIM_UPDATED_FINANCE:
                    return renderUpdatedFinance({ newObject, changedValues, financeTypeNameMapping, valueFomatterFnc: getValueByCurrency })

                case ClaimLogEvents.CLAIM_DELETED_FINANCE:
                    return trans(
                        'log_detail.remove_claim_finance',
                        {
                            title: financeTypeNameMapping[removedObject.financeTypeId],
                        },
                        'span'
                    )

                case ClaimLogEvents.CLAIM_RATING:
                    return (
                        <>
                            {trans(
                                'log_detail.rating_star',
                                {
                                    stars: newObject.rating / 2,
                                },
                                'span'
                            )}<br />
                            {newObject.ratingComment &&
                                trans(
                                    'log_detail.rating_comment',
                                    {
                                        comment: newObject.ratingComment,
                                    },
                                    'span'
                                )}
                        </>
                    )

                case ClaimLogEvents.CLAIM_ARCHIVED:
                    return trans('log_detail.close')

                case ClaimLogEvents.CLAIM_CUSTOMER_ARCHIVED:
                    return trans('log_detail.customer_close')
                default:
                    return null
            }
        }

        return <div className={'_action'}>{renderLogItem()}</div>
    }

    return (
        <LogModal
            title={lang.ticket_detail.claim_logs}
            fetchApiService={() => ClaimApi.getLogs(code, project)}
            open={isShow}
            onCancel={onCancel}
            renderActions={renderActions}
        />
    )
}

interface ILogItem {
    newObject?: NewObjectType
    changedValues?: IChangedValues[]
    officersCodeMapping?: NewObjectType
    mistakesNameMapping?: NewObjectType
    valueFomatterFnc?: (...arg: any) => any
}

const renderClaimCreated = ({ newObject }: ILogItem) => {
    if (!!newObject.relatedProduct) {
        return trans(
            'log_detail.create_claim',
            {
                relatedOrder: newObject.relatedOrder,
                relatedProduct: newObject.relatedProduct,
            },
            'span'
        )
    }

    return trans(
        'log_detail.create_claim_not_relatedProduct',
        {
            relatedOrder: newObject.relatedOrder,
        },
        'span'
    )
}

const renderClaimUpdatedAssignee = ({ changedValues }: ILogItem) => {
    const fieldNameMapping: any = {
        assignee: 'log_detail.update_assignee',
        state: 'log_detail.update_state',
        deadline: 'log_detail.update_deadline',
    }

    return (
        <>
            {changedValues?.map((changedValue, index: number) => {
                if (changedValue.key === 'state') {
                    changedValue.right = lang.state_ticket[changedValue.right]
                    changedValue.left = lang.state_ticket[changedValue.left]
                }

                if (changedValue.key === 'deadline') {
                    changedValue.right = TimeUtils.showDateTime(changedValue.right)
                }
                return (
                    <span key={uniqueId()}>
                        {trans(
                            fieldNameMapping[changedValue.key],
                            {
                                left: changedValue.left,
                                right: changedValue.right,
                            },
                            'span'
                        )}
                        {index < changedValues.length - 1 && <br />}
                    </span>
                )
            })}
        </>
    )
}

const renderCreatedConlusion = (props: ILogItem) => {
    const newObject: IClaimConclusion = props.newObject
    return trans(
        'log_detail.create_conlusion',
        {
            mistakeFrom: newObject.mistakeView?.name,
            mistakePerson: newObject.officerView?.code,
            damage: newObject.damage ? trans('common.yes') : trans('common.no'),
            description: newObject.description,
            // damageValue: newObject.damageValue && (Utils.currencyFormatVND(newObject.damageValue)),
            damageValue: newObject.damageValue && (props.valueFomatterFnc && props.valueFomatterFnc(newObject.damageValue))
        },
        'span'
    )
}

const renderUpdatedConlusion = ({ newObject, changedValues, officersCodeMapping, mistakesNameMapping, valueFomatterFnc }: ILogItem) => {
    const fieldNameMapping: any = {
        damage: lang.conclusion.damaged,
        state: lang.state_ticket,
        damageValue: lang.conclusion.damage_countMoney,
        description: lang.conclusion.error_description,
        officerId: lang.conclusion.offender,
        mistake: lang.conclusion.mistake,
    }

    let conclusionChanged = get(newObject, 'mistakeView.name')

    const logItems = changedValues?.map((changedValue) => {
        if (changedValue.key === 'mistake') {
            conclusionChanged = mistakesNameMapping[changedValue.left]
            changedValue.left = mistakesNameMapping[changedValue.left]
            changedValue.right = mistakesNameMapping[changedValue.right]
        }

        if (changedValue.key === 'officerId') {
            changedValue.left = officersCodeMapping[changedValue.left]
            changedValue.right = officersCodeMapping[changedValue.right]
        }

        if (changedValue.key === 'damage') {
            changedValue.left = changedValue.left ? trans('common.yes') : trans('common.no')
            changedValue.right = changedValue.right ? trans('common.yes') : trans('common.no')
        }

        if (changedValue.key === 'damageValue') {
            // changedValue.left = changedValue.left === null ? null : Utils.currencyFormatVND(changedValue.left)
            // changedValue.right = changedValue.right === null ? null : Utils.currencyFormatVND(changedValue.right)
            changedValue.left = changedValue.left === null ? null : (valueFomatterFnc && valueFomatterFnc(changedValue.left))
            changedValue.right = changedValue.right === null ? null : (valueFomatterFnc && valueFomatterFnc(changedValue.right))
        }

        return (
            <span key={uniqueId()}>
                <br />
                <span className="txt-nowrap">
                    <strong>{fieldNameMapping[changedValue.key]}</strong> <FromText /> <span className="font-medium"> {changedValue.left || '---'}</span>
                    <ChangedIcon />
                    <span className="font-medium">{changedValue.right || '---'}</span>
                </span>
            </span>
        )
    })

    return (
        <>
            Cập nhật lỗi xuất phát <strong>{conclusionChanged}</strong>:{logItems}
        </>
    )
}

const renderUpdatedFinance = ({ newObject, changedValues, financeTypeNameMapping, valueFomatterFnc }: ILogItem & { financeTypeNameMapping: any }) => {
    const fileNameMapping: any = {
        financeTypeId: lang.claim_detail.type_financial,
        reason: lang.ticket_detail.finance_reason,
        value: trans('log_detail.amount'),
    }

    let oldFinanceTypeId = get(newObject, 'type.id')

    const logRenderElements: ReactNode[] = []
    changedValues?.forEach((changedValue) => {
        if (changedValue.key === 'financeTypeId') {
            oldFinanceTypeId = changedValue.left
            changedValue.left = financeTypeNameMapping[changedValue.left]
            changedValue.right = financeTypeNameMapping[changedValue.right]
        }

        if (changedValue.key === 'value') {
            changedValue.left = (valueFomatterFnc && valueFomatterFnc(changedValue.left))
            changedValue.right = (valueFomatterFnc && valueFomatterFnc(changedValue.right))
        }

        logRenderElements.push(
            <span key={uniqueId()}>
                <br />
                <span className="txt-nowrap">
                    {fileNameMapping[changedValue.key]} <FromText /> <span className="font-medium"> {changedValue.left || '---'}</span>
                    <ChangedIcon />
                    <span className="font-medium">{changedValue.right || '---'}</span>
                </span>
            </span>
        )
    })

    return (
        <>
            {trans('log_detail.update_claim_finance')} <span className="font-medium">{financeTypeNameMapping[oldFinanceTypeId]}</span>:{logRenderElements}
        </>
    )
}
