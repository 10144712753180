import _ from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import { TClaimProductView, TClaimProductViewExtended, TClaimView } from "src/interface/Ticket";
import Utils from "src/utils";
import { currencyUnitFormat } from "src/utils/currency";
import { ClaimProductItem } from "./ClaimProductItem";
import { formatterNumberUsingComma } from "src/utils/stringUtils";

type Props = {
  claimDetail: TClaimView
}

export const ClaimProducts: React.FC<Props> = (props) => {
  const { claimDetail } = props;
  const [showAllSku, setShowAllSku] = useState<boolean>(false)
  const containerRef = useRef<any>(null)

  const groupProductSKUByOriginalId = useMemo(() => {
    const temp: TClaimProductView[] = claimDetail?.claimProductViews
    if (temp) {
      const groupedByOriginalId = temp.reduce((acc: any, current) => {
        const originalId = current.dataSnapshot.originalId;
        const { name, productImage } = current.dataSnapshot;

        if (!acc[originalId]) {
          acc[originalId] = {
            originalId,
            name,
            productImage,
            skus: []
          };
        }

        acc[originalId].skus.push(current);

        return acc;
      }, {});
      const result = Object.values(groupedByOriginalId);
      return result;
    }
    else return []
  }, [claimDetail])

  const getSkuQuantity = useCallback<(...arg: any) => number>((sku: TClaimProductView) => {
    const reason = claimDetail?.reasonView?.code;
    switch (reason) {
      case 'not_received': return sku.notReceived
      case 'faulty_product': return sku.numbersFaultyProduct
      default: return sku.dataSnapshot.purchasedQuantity
    }
  }, [claimDetail])

  const renderQuantityLabelCondition = useMemo(() => {
    switch (claimDetail?.reasonView?.code) {
      case 'not_received': return 'Sản phẩm thiếu';
      case 'faulty_product': return 'Sản phẩm lỗi';
      default: return 'Số lượng đặt'
    }
  }, [claimDetail])

  const { totalNotReceived, totalFaultyProduct, totalSkuPrice, currency } = useMemo(() => {
    const totals = groupProductSKUByOriginalId?.reduce((acc: any, current: any) => {
      acc.totalNotReceived += _.sumBy(current.skus, 'notReceived') || 0;
      acc.totalFaultyProduct += _.sumBy(current.skus, 'numbersFaultyProduct') || 0;
      acc.totalSkuPrice += _.sumBy(current.skus, (s: any) => s.dataSnapshot.exchangedSalePrice * getSkuQuantity(s))
      acc.currency = current.dataSnapshot?.currency || 'CNY'
      return acc;
    }, {
      totalNotReceived: 0,
      totalFaultyProduct: 0,
      totalSkuPrice: 0,
    });

    return totals as { totalNotReceived: number, totalFaultyProduct: number, totalSkuPrice: number, currency: string }
  }, [groupProductSKUByOriginalId])

  return (
    <>
      <section className="grid grid-cols-1 lg:grid-cols-5 gap-0 mt-3">
        <div className="col-span-1 lg:col-span-2 flex items-center">
          <h5 className="text-sm text-woodsmoke-950 leading-6 capitalize">Sản phẩm khiếu nại</h5>
          <span className="flex-1 h-[1px] text-gallery-100 bg-gallery-100 ml-2"></span>
        </div>
        <div className="col-span-1 hidden lg:!inline-block">
          <p className="text-xs text-dovegray-500 text-right">Đơn giá</p>
        </div>
        <div className="col-span-1 hidden lg:!inline-block">
          <p className="text-xs text-dovegray-500 text-center">{renderQuantityLabelCondition}</p>
        </div>
        <div className="col-span-1 hidden lg:!inline-block">
          <p className="text-xs text-dovegray-500 text-right">Tổng giá trị</p>
        </div>
        <div className={`col-span-5 ${showAllSku ? '' : 'max-h-[575px] lg:max-h-[520px] overflow-hidden'}`} ref={containerRef}>
          {
            groupProductSKUByOriginalId && groupProductSKUByOriginalId.map((ele: any) => <ClaimProductItem
              key={ele.originalId}
              item={ele as TClaimProductViewExtended}
              getSkuQuantity={getSkuQuantity}
              renderQuantityLabelCondition={renderQuantityLabelCondition}
            />)
          }
        </div>
        {
          containerRef.current?.clientHeight >= 515 && <div className={`text-xs font-normal text-chicago-600 flex items-center lg:mt-4 cursor-pointer col-span-5 lg:col-span-2 w-full justify-center hover:opacity-80`}
            onClick={() => setShowAllSku(!showAllSku)}
          >
            {showAllSku ? <span><i className="fa-regular fa-angle-up mr-1.5"></i> Thu gọn</span> : <span><i className="fa-regular fa-angle-down mr-1.5"></i> Xem thêm sản phẩm</span>}
          </div>
        }
        <div className=" grid grid-cols-1 col-span-1 lg:col-span-5 lg:grid-cols-5 mt-3 lg:mt-4">
          <div className="col-span-1 lg:col-end-6 lg:col-span-5 flex">
            <span className="flex-1 h-[1px] text-silver-400 bg-gallery-100"></span>
          </div>
          <div className="mt-3 lg:mt-4 lg:col-end-6 lg:col-span-2 col-span-1 grid grid-cols-2">
            <p className="text-sm lg:!text-lg text-woodsmoke-950 font-medium leading-4 lg:!flex-row lg:flex-wrap gap-3 md:gap-1 lg:gap-0 2xl:gap-1 flex flex-col lg:justify-end">
              <span className="after:content-[':'] lg:after:content-['']">Tổng số tiền</span>
              <span className="font-normal lg:after:content-[':']">
                (<span className="text-redribbon-600">{formatterNumberUsingComma(totalNotReceived || totalFaultyProduct || 0)}</span>
                <span className="ml-1">sản phẩm</span>)
              </span>
            </p>
            <div className="col-span-1 text-left lg:!text-right self-center text-redribbon-600 font-medium text-lg">
              {currencyUnitFormat(Utils.currencyFormat(totalSkuPrice), currency)}
            </div>
          </div>
        </div>

      </section>
    </>
  )
}