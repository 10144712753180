import React from 'react'
import { Form, Input, message, Modal, ModalProps, notification, Spin } from 'antd'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import lang, { trans } from '../../../resources/localization/Localization'
import SelectFinanceAccount from '../../../components/v2/Select/SelectFinanceAccount'
import { SubjectEnums, subjectMapping } from '../../../interface/Orders'
import { FinanceTypesService } from '../../../repository/financeTypes'
import { IFinancialType, IFinancialTypeCreate, IFinancialTypeUpdate } from '../../../interface/FinancialType'
import storageUtil from '../../../utils/LocalStore/LocalStores'
import { AxiosError } from 'axios'
import { ModalCommon } from 'src/components/base/ModalCommon'

interface IProps extends ModalProps {
    subject: SubjectEnums
    financeTypeToEdit?: IFinancialType
    onCancel?: () => void
    onSuccess?: () => void
}

export const AddOrUpdateFinancialType: FC<IProps> = ({ subject, financeTypeToEdit, onSuccess = () => null, ...props }: IProps) => {
    const { open, onCancel = () => null } = props

    const [loading, setLoading] = useState<boolean>(false)
    const formRef: any = useRef()
    const project = storageUtil.getItem('selectedProject')

    const modalTitle = useMemo(() => {
        if (!financeTypeToEdit) {
            return `${trans('config_finance.create')} - ${subjectMapping[subject]}`
        }

        return `${trans('config_finance.update')} #${financeTypeToEdit.code.toLowerCase()}`
    }, [financeTypeToEdit, subject])

    useEffect(() => {
        if (open && financeTypeToEdit) {
            formRef.current?.setFieldsValue({
                name: financeTypeToEdit.name,
                position: financeTypeToEdit.position || 0,

                financeAccountCode: financeTypeToEdit.financialAccount?.id,
                code: financeTypeToEdit.code,
                description: financeTypeToEdit.description,
            })

        }
        return () => {
            formRef.current?.resetFields()
        }
    }, [open])

    const onCreate = (form: any) => {
        const payload: IFinancialTypeCreate = {
            ...form,
            subject,
            project,
        }
        return FinanceTypesService.create(payload)
    }

    const onUpdate = (form: any) => {
        const payload: IFinancialTypeUpdate = {
            ...form,
            subject,
            project,
        }
        return FinanceTypesService.update(financeTypeToEdit?.id as string, payload)
    }

    const onFinish = () => {
        formRef.current?.validateFields().then((res: any) => {
            setLoading(true)
            const onCallService = !!financeTypeToEdit ? onUpdate : onCreate

            onCallService(res)
                .then(() => {
                    onSuccess()
                    onCancel()
                })
                .catch((error: AxiosError) => {
                    if (error.response?.data?.title === 'finance_type_duplicate') {
                        notification.error({
                            message: trans('config_finance.code_exist'),
                        })
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }

    const enterToSubmit = (e: React.KeyboardEvent<HTMLFormElement>) => {
        if (e.code === 'Enter') {
            onFinish()
        }
    }

    return (
        <ModalCommon
            onOk={() => onFinish()}
            closeIcon={<i className="fal fa-times-circle" />}
            title={modalTitle}
            cancelText={lang.button.no}
            okText={lang.button.yes}
            {...props}>
            <Spin spinning={loading}>
                <Form
                    ref={formRef}
                    labelCol={{ xs: 8 }}
                    initialValues={{ position: 0 }}
                    onKeyDown={enterToSubmit}
                    labelAlign="left">
                    <Form.Item
                        name={'financeAccountCode'}
                        label={lang.edit_config_finance.finance_type}
                        hidden={!!financeTypeToEdit}
                        rules={[
                            {
                                required: true,
                                message: lang.edit_config_finance.finance_type_select,
                            },
                        ]}>
                        <SelectFinanceAccount subject={subject} />
                    </Form.Item>

                    <Form.Item
                        name={'name'}
                        label={trans('config_finance.name')}
                        rules={[
                            {
                                required: true,
                                message: trans('config_finance.name_required'),
                            },
                        ]}>
                        <Input autoFocus={!!financeTypeToEdit} />
                    </Form.Item>

                    <Form.Item
                        name={'code'}
                        label={trans('config_finance.code')}
                        hidden={!!financeTypeToEdit}
                        rules={[
                            {
                                required: true,
                                message: trans('config_finance.code_required'),
                            },
                            {
                                pattern: new RegExp(/^[a-z0-9_]*$/g),
                                message: trans('config_finance.name_invalid'),
                            },
                        ]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name={'description'}
                        hidden={!!financeTypeToEdit}
                        label={trans('config_finance.description')}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name={'position'}
                        label={lang.edit_config_finance.prioty}
                        className={''}
                        rules={[
                            {
                                required: true,
                                message: lang.edit_config_finance.prioty_input,
                            },
                            {
                                pattern: new RegExp(/^[0-9]*$/g),
                                message: lang.edit_config_finance.input_number,
                            },
                        ]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Spin>
        </ModalCommon>
    )
}
