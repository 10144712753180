import lang from "../resources/localization/Localization";

/**
 * created by Taobao dev team - 15/1/2018
 */
const HTTP_NO_CONTENT = 204;

/**
 * function fetch repository from server
 * @param endpoint
 * @param payload
 * @returns {Promise<*>}
 */
export default async(endpoint, payload) => {

    if (!global.fetchOverwrite) {
        global.fetch = null;
        require('whatwg-fetch-timeout');
        global.fetchOverwrite = true;
    }

    let fullURL = endpoint;

    if (!endpoint.startsWith('http')) {
        // validate endpoint
        endpoint = endpoint.startsWith('/') ? endpoint : '/' + endpoint;

        const appApiUrl = process.env.REACT_APP_API_URL || window.location.hostname;

        // calc url
        fullURL = endpoint.indexOf(appApiUrl) === -1 ? appApiUrl + endpoint : endpoint;
    }

    let res;
    // Nếu có mất mạng thì vẫn trả về mã lỗi như bình thường
    if(!navigator.onLine) {
        return {
            statusCode: "000",
            name: "Error",
            message: "No network connection"
        }
    }

    try {
        res = await fetch(fullURL, {...payload, timeout: 15000});
    } catch (err) {
        let error  = new Error('Có lỗi xảy ra, vui lòng thử lại!');
        error.statusCode = 'timeout';
        error.detail = lang.error.timeout;
        error.message = lang.error.timeout;
        throw error;
    }

    // check status HTTP_NO_CONTENT
    if (res.status === HTTP_NO_CONTENT) {
        return true
    }

    try {
        const json = await res.json();
        if (!res.ok) {
            if (json.status || json.code || json.statusCode) throw json;
            throw typeof json.error === 'object' ? json.error : res;
        }
        return json;
    } catch (e) {
        if (e.status || e.code || e.statusCode) {
            throw e
        } else if (res.status >= 300) {
            throw res
        }
    }

    return res
};

