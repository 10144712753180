import { get } from 'lodash'
import { useContext } from 'react'
import storageUtil from 'src/utils/LocalStore/LocalStores'
import { AppContext } from '../context/AppContext'

export type ProjectNameMappingType = { [key in string]: string }

export const useProjects = () => {
    const context = useContext(AppContext)
    const projects = get(context, 'state.projects', [])
    const currentProjectId = storageUtil.getItem('selectedProject');
    let currentProject = {}

    const projectNameMapping: ProjectNameMappingType = {}

    projects.forEach((project: any) => {
        projectNameMapping[project.code] = project.name
        if (project.code === currentProjectId) {
            currentProject = project
        }
    })

    return { projects, projectNameMapping, currentProjectId, currentProject }
}
