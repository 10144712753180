import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react'

type DivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

interface IProps extends DivProps {
    children?: ReactNode
    label?: ReactNode
}

export const Label: FC<IProps> = ({ children, ...props }) => {
    return (
        <span
            className="d-inline-block txt-color-black3"
            style={{ width: 150, minWidth: 150 }}
            {...props}>
            {children}
        </span>
    )
}

export const Content: FC<IProps> = ({ children, ...props }) => {
    return <span {...props}>{children}</span>
}

export const RowInfo: FC<IProps> = ({ label, children, ...props }) => {
    return (
        <div className="flex">
            <Label>{label}: </Label>
            <Content {...props}>{children}</Content>
        </div>
    )
}

export const RowContainer: FC<DivProps> = (props: DivProps) => (
    <div
        className={'mgt-8 flex flex-col gap-3'}
        {...props}>
        {props.children}
    </div>
)
