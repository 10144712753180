import React, { useEffect, useState } from 'react'
import Routes from './Routes'
import SplashScreen from './screens/SplashScreen'
import storageUtil from './utils/LocalStore/LocalStores'
import lang from './resources/localization/Localization'
import { getToken } from './helpers/localStorage'
import { AppContext, defaultAppContextValue } from './context/AppContext'
import TenantApi from './repository/tenant'
import UserApi from './repository/user'
import 'moment/locale/vi'

const STATE_SUCCESS = 'success'
const STATE_FAILURE = 'failure'
const STATE_LOADING = 'loading'

function App() {
    const [initialAppStatus, setInitialAppStatus] = useState(STATE_LOADING)
    const [projects, setProjects] = useState([])
    const [assignedProjects, setAssignedProjects] = useState([])

    const fetchProjects = () => {
        TenantApi.getProjects().then((response) => {
            setProjects(response.data)
        })
        TenantApi.getAssignedProjects().then((res) => {
            setAssignedProjects(res.data)
        })
    }

    useEffect(() => {
        if (!storageUtil.getItem('language')) {
            storageUtil.setItem('language', 'vi')
        }
        if (!getToken()) {
            setInitialAppStatus(STATE_SUCCESS);
        }
        else {
            UserApi.getCurrentUser().then((response) => {
                fetchProjects()
                storageUtil.setJson('currentUser',response.data);
                setInitialAppStatus(STATE_SUCCESS);
            }).catch((err) => {
                setInitialAppStatus(STATE_FAILURE);
            })
        }
    }, [])

    const getContextValue = () => {
        const value = { ...defaultAppContextValue }
        value.state.projects = projects
        value.state.assignedProjects = assignedProjects
        return { ...value }
    }

    const initializeApp = () => {
        switch (initialAppStatus) {
            case STATE_SUCCESS:
                return(<div className="app-main">
                        <Routes/>
                    </div>);
            case STATE_FAILURE:
                // TODO: change content of failure page
                return <div className="txt-center pdt15">{lang.login.access_failed}</div>
            default:
                // show loading page
                return <SplashScreen />
        }
    }

    return (
        <AppContext.Provider value={getContextValue()}>
            {initializeApp()}
        </AppContext.Provider>
    );

}


export default App;
