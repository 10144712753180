import { useEffect, useState } from 'react'
import { Col, Image, Row } from 'antd'
import lang from '../../../resources/localization/Localization'
import { quantityFormat } from '../../../utils/stringUtils'
import { RowContainer, RowInfo } from './components/Row'

const ReasonComplaint = ({ data }) => {
    const reasonView = data?.reasonView || {}
    const notReceived = data?.notReceived

    const [attachmentsUrl, setAttachmentsUrl] = useState([])

    useEffect(() => {
        if (data && data.attachments && data.attachments?.length > 0) {
            const attachments = (data.attachments || []).map((att) => att.presignedUrl)
            setAttachmentsUrl(attachments)
        }
    }, [data])

    function getUrlExtension(url) {
        return url.split(/[#?]/)[0].split('.').pop().trim()
    }

    return (
        <div className={'mt-4'}>
            <div className={'box-title'}>{lang.ticket_add.claim_reason_select_title}</div>
            <RowContainer>
                <RowInfo
                    label={lang.ticket_add.claim_reason_select_title}
                    className={'_reason-name'}>
                    {reasonView.name || '---'}
                </RowInfo>

                <RowInfo
                    label={lang.claim_detail.description}
                    className={'_description'}>
                    {data?.description ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data.description || '--',
                            }}></div>
                    ) : (
                        '---'
                    )}
                </RowInfo>

                <RowInfo
                    label={lang.ticket_add.missing_number}
                    className={'_conclusionNotReceived'}>
                    {notReceived === '0' || notReceived === 0
                        ? lang.list_ticket.all
                        : notReceived
                        ? quantityFormat(notReceived) + ' ' + lang.ticket_add.product
                        : '---'}
                </RowInfo>

                <RowInfo
                    className={'mgt-6'}
                    label={lang.log_detail.product_image}>
                    <div className={'flex flex-row flex-wrap'}>
                        <Image.PreviewGroup>
                            {attachmentsUrl.map((att, index) => {
                                if (['mp4', 'ogg', 'avi', '3gp'].includes(getUrlExtension(att)))
                                    return (
                                        <video
                                            controls
                                            width={128}
                                            style={{ objectFit: 'cover' }}
                                            src={att}
                                            playsInline>
                                            {/*<source src={att} type={'video/mp4'}/>*/}
                                        </video>
                                    )
                                else
                                    return (
                                        <Image
                                            alt={index}
                                            src={att}
                                            style={{ width: 72, height: 72, marginLeft: 6 }}
                                            className={'cursor-pointer border-radius6 flex align-items-center'}
                                        />
                                    )
                            })}
                        </Image.PreviewGroup>
                    </div>
                </RowInfo>
            </RowContainer>
        </div>
    )
}
export default ReasonComplaint
