import axios from 'axios';
import lodash from 'lodash';
import appConfig from '../config/App';
import storageUtil from "./LocalStore/LocalStores";
import {handleStatusHttps} from "./helper";
import {getToken,setToken} from '../helpers/localStorage';
import {notification} from "antd";

class ApiClient {

    getInstance = () => {
        return this.initialize();
    };

    get = (url, config) => {
        return this.getInstance().get(url, config);
    };

    put = (url, config) => {
        return this.getInstance().put(url, config);
    };

    post = (url, config) => {
        return this.getInstance().post(url, config);
    };

    patch = (url, data, config) => {
        return this.getInstance().patch(url, data, config);
    };

    delete = (url, config) => {
        return this.getInstance().delete(url, config);
    };

    initialize = () => {
        const instance = axios.create({
            baseURL: appConfig.apiUrl
        });

        const token = getToken()

        instance.defaults.headers.common['Authorization'] = 'Bearer ' + token
        // instance.defaults.headers.common['X-Tenant'] = lodash.get(storageUtil.getJson("currentTenant"), 'code') || 'vn';

        instance.defaults.timeout = 15000;

        instance.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (lodash.get(error, "response.status") === 401) {
                setToken(null)
                notification.error({
                    message: "Phiên làm việc hết hạn. Vui lòng làm mới trình duyệt",
                    key: "TOKEN_EXPIRED",
                })
                if (!lodash.startsWith(window.location.pathname, "/login") || !lodash.startsWith(window.location.pathname, "/authentication")) {
                    storageUtil.setItem('redirect_uri', window.location.href)
                    setTimeout(() => {
                        window.location.href= `${appConfig.loginUrl}/${lodash.get(storageUtil.getJson("currentTenant"), 'code')}/?redirect_uri=${window.location.origin}/authentication`
                    }, 1000)
                }
            } else if (lodash.get(error, "response.status") === 403) {

            } else if (lodash.get(error, "response.status") >= 500) {
                notification.error({
                    message: "Máy chủ gặp sự cố. Vui lòng thử lại sau",
                    key: "server_error",
                })
            }
            return Promise.reject(handleStatusHttps(error));

        });
        return instance
    }
}


const apiClientV2 = new ApiClient();

export default apiClientV2;
