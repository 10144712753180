import { Modal, ModalProps } from 'antd'
import { FC, ReactNode } from 'react'

interface IProps extends ModalProps {
    children?: ReactNode
}

export const ModalCommon: FC<IProps> = ({ children, centered, okButtonProps, closeIcon, cancelButtonProps, ...props }) => {
    return (
        <Modal
            centered={true}
            closeIcon={<i className="fa-solid fa-xmark" />}
            okButtonProps={{
                ...okButtonProps,
                type: 'primary',
                icon: <i className="fa-solid fa-check mr-2" />,
            }}
            cancelButtonProps={{
                ...cancelButtonProps,
                type: 'default',
                icon: <i className="fa-solid fa-xmark mr-2" />,
            }}
            {...props}>
            {children}
        </Modal>
    )
}
