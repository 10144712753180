import {Col, Form, notification, Row, Upload} from "antd";
import {Button, Input} from "antd";
import {useEffect, useRef, useState} from "react";
import SelectReason from "../Select/SelectReason";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import lang from "../../../resources/localization/Localization";
import { ModalCommon } from "src/components/base/ModalCommon";

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const UPLOAD_MAX_SIZE = 10 * 1024 * 1024;
const ChangeReasonComplaint = ({onCancel, onOk, visible, loading, data}) => {
    const formRef = useRef();
    const [fileList, setFileList] = useState([]);
    const [previewTitle, setPreviewTitle] = useState("");
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [changeEditReason, setChangeEditReason] = useState(false);
    const [changeEditUpload, setChangeEditUpload] = useState(false);
    const [changeEditDescription, setChangeEditDescription] = useState(false);
    const [changeEditNotReceived, setChangeEditNotReceived] = useState(false);
    useEffect(() => {
        if (!visible) {
            formRef.current?.resetFields();
            setChangeEditReason(false)
            setChangeEditUpload(false)
            setChangeEditDescription(false)
            setChangeEditNotReceived(false)
        } else {
            formRef.current?.setFieldsValue({
                reason: data?.reason?.code,
                description: data?.description,
                notReceived: data?.reasonData?.notReceived,
                upload: (data?.attachments || []).map((d, index) => ({...d, index, url: d.presignedUrl})),
                fileList: (data?.attachments || []).map((d, index) => ({...d, index, url: d.presignedUrl})),
            })
            data = (data?.attachments || []).map((d, index) => ({...d, index, url: d.presignedUrl}))
            setFileList(data)
        }
    }, [visible])

    const onFinish = () => {
        if (!loading) {
            formRef.current?.validateFields().then(values => {
                if (!changeEditReason) {
                    delete values.reason
                }
                if (!changeEditUpload) {
                    delete values.upload
                }
                if (!changeEditDescription) {
                    delete values.description
                }
                if (!changeEditNotReceived) {
                    delete values.notReceived
                }
                onOk(values)
            })
        }
    }
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
        setPreviewVisible(true)
        setPreviewImage(file.url || file.preview)
    };
    const handleChange = ({fileList, file}) => {
        if (!changeEditUpload) {
            setChangeEditUpload(true)
        }
    };
    const onRemoveImage = (e) => {
        if (fileList.length > 1) {
            setChangeEditUpload(true)
            const newFileList = fileList.filter(file => file.index !== e.index)
            setFileList(newFileList)
            formRef.current?.setFieldsValue({
                upload: newFileList
            })
        } else {

        }

    }
    const beforeUpload = async (file, list) => {
        let newFileList = []
        for (const l of list) {
            if (l.size <= UPLOAD_MAX_SIZE && l.name.match(/.(jpg|jpeg|png|gif)$/i)) {
                l.url = await getBase64(l);
                newFileList.push(l)
            } else {
                if (!l.name.match(/.(jpg|jpeg|png|gif)$/i)) {
                    notification.error({
                        message: lang.ticket_add.image_wrong_type
                    })
                } else {
                    notification.error({
                        message: lang.ticket_add.oversize_10mb
                    })
                }
                return Upload.LIST_IGNORE
            }
        }

        newFileList = newFileList.concat(fileList).map((file, index) => {
            file.index = index;
            return file
        })
        setFileList(newFileList)
        formRef.current?.setFieldsValue({
            upload: newFileList
        })
        return false;
    };
    const uploadButton = (
        <div className={"_upload-btn"}>
            <PlusOutlined/>
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    );
    return <div>
        <ModalCommon open={visible} onOk={onOk} onCancel={onCancel} footer={null} header={null}
               closable={false}
               width={600}
               closeIcon={<i className="fal fa-times-circle"/>}>
            <div>
                <Form ref={formRef} onFinish={onFinish}
                      labelCol={{span: 6}}
                      wrapperCol={{span: 18}}
                >
                    <div className={"flex flex-row space-between mgbt8 align-items-center"}>
                        <div className={"bold text-16 txt-color-primary"}>{lang.ticket_add.claim_reason_edit}</div>
                        <i className="fal fa-times-circle txt-color-gray2 cursor-pointer" onClick={onCancel}/>
                    </div>
                    <div className={"border-line width100"}/>
                    <div className={"mgt-24 mgb-24"}>
                        <Form.Item
                            name="reason"
                            label={lang.ticket_add.claim_reason_select_title}
                            rules={
                                [{required: true, message: lang.ticket_add.claim_reason_select}]}
                        >
                            <SelectReason type={data.ticketType} orderCode={data.relatedOrder}
                                          productCode={data.relatedProduct}
                                          onChange={() => !changeEditReason && setChangeEditReason(true)}
                                          disabled={loading}/>
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.reason !== currentValues.reason}
                        >
                            {({getFieldValue, setFieldsValue}) =>
                                (getFieldValue('reason') === 'shipping_not_received' || getFieldValue('reason') === "not_received") ? (
                                    <div className={"flex flex-col mgb-18"}>
                                        <Form.Item name="notReceived" className={"mgb-0"} label="Số lượng còn thiếu"
                                                   rules={[
                                                       {
                                                           required: true,
                                                           message: lang.ticket_add.missing_number_input
                                                       }, {
                                                           pattern: /^[0-9]+$/,
                                                           message: lang.ticket_add.missing_number_input_type
                                                       },
                                                       {
                                                           type: "number",
                                                           max: 9999,
                                                           message: lang.ticket_add.missing_number_type
                                                       },
                                                   ]}>
                                            <Input
                                                className={"_notReceived"}
                                                disabled={loading}
                                                onChange={(e) => {
                                                    if (!changeEditNotReceived) {
                                                        setChangeEditNotReceived(true)
                                                    }
                                                    setFieldsValue({notReceived: parseInt(e.target.value || "") >= 0 ? parseInt(e.target.value || "") : ""})
                                                }}
                                                placeholder={lang.ticket_add.missing_number_type}/>

                                        </Form.Item>
                                        <Row justify={"end"}>
                                            <Col span={18}>
                                                <span className={'txt-size-h8 txt-color-black3'}>
                                                    <span className={'txt-color-red'}>* </span>{lang.ticket_add.entirely_missing}
                                                </span>
                                            </Col>
                                        </Row>
                                    </div>
                                ) : null
                            }
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.reason !== currentValues.reason}
                        >
                            {({getFieldValue}) =>
                                getFieldValue('reason') === 'other' ? (
                                    <Form.Item name="description" label={lang.ticket_add.descriptions}
                                               disabled={loading}
                                               rules={[{required: true, message: lang.ticket_add.descriptions_input},
                                                   {
                                                       pattern: new RegExp("^[^\\s]+(\\s+[^\\s]+)*$"),
                                                       message: lang.ticket_add.descriptions_error
                                                   }
                                               ]}>
                                        <Input.TextArea className={"_description"} placeholder={lang.ticket_add.descriptions_input}
                                                        onChange={() => !changeEditDescription && setChangeEditDescription(true)}/>
                                    </Form.Item>
                                ) : <Form.Item name="description" label={lang.ticket_add.descriptions} rules={[
                                    {
                                        pattern: new RegExp("^[^\\s]+(\\s+[^\\s]+)*$"),
                                        message: lang.ticket_add.descriptions_error
                                    }
                                ]}>
                                    <Input.TextArea className={"_description"} disabled={loading}
                                                    onChange={() => !changeEditDescription && setChangeEditDescription(true)}
                                                    placeholder={lang.ticket_add.descriptions_input}/>
                                </Form.Item>
                            }
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.reason !== currentValues.reason}
                        >
                            {({getFieldValue}) =>

                                <Form.Item
                                    name="upload"
                                    label={lang.ticket_add.description_img_upload}
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    rules={[{
                                        required: getFieldValue('reason') === "shipping_faulty_product" || getFieldValue('reason') === "faulty_product",
                                        message: lang.ticket_add.description_img_upload_required
                                    }]}
                                >
                                    <Upload listType="picture-card"
                                            action={null}
                                            multiple={true}
                                            fileList={fileList}
                                            disabled={loading}
                                            showUploadList={{showRemoveIcon: fileList.length > 1}}
                                            accept=".png, .jpg"
                                            className={"_upload"}
                                            onPreview={handlePreview}
                                            beforeUpload={beforeUpload}
                                            onRemove={onRemoveImage}
                                            onChange={handleChange}>
                                        {fileList.length >= 10 ? null : uploadButton}
                                    </Upload>
                                    <div>
                                            <span className={'txt-size-h8 txt-color-black3'}>
                                           <span className={'txt-color-red'}>* </span> {lang.ticket_add.description_img_upload_required}
                                        </span>
                                    </div>
                                    <span className={'txt-size-h8 txt-color-black3'}>
                                            <span className={'txt-color-red'}>* </span>{lang.ticket_add.description_img_upload_size_limit}
                                        </span>
                                </Form.Item>}
                        </Form.Item>
                    </div>
                    <div className={"flex flex-row width100 flex-content-end"}>
                        <Button disabled={loading}
                                onClick={() => !loading && onCancel()}
                                className={"buttonm3__reset mgr-8 _btn-cf-cancel"}>
                            {loading && <LoadingOutlined/>}
                            {lang.button.cancel}
                        </Button>
                        <Button
                            disabled={loading || (!changeEditReason && !changeEditDescription && !changeEditNotReceived && !changeEditUpload)}
                            className={"buttonm3 _btn-cf-submit"}
                            htmlType={"submit"}>
                            {loading ? <LoadingOutlined/> :
                                <i className="fas fa-check mgr-5"/>}
                            {lang.button.yes}
                        </Button>
                    </div>
                </Form>

            </div>
        </ModalCommon>
        <ModalCommon
            open={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={() => setPreviewVisible(false)}
        >
            <img alt="example" style={{width: '100%'}} src={previewImage}/>
        </ModalCommon>
    </div>
}
export default ChangeReasonComplaint
