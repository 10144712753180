import {Form, Modal} from "antd";
import {useEffect, useRef} from "react";
import {Input} from "antd/lib/index";
import SelectOfficers from "../../../components/v2/Select/SelectOfficers";
import lang from "../../../resources/localization/Localization";
import { ModalCommon } from "src/components/base/ModalCommon";

const CreateStaffMistake = ({visible, onCreate, onCancel}) => {
    const formRef = useRef()
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 18},
        },
    };
    useEffect(() => {
        if (!visible) {
            formRef.current?.resetFields()
        }
    }, [visible])
    const onFinish = () => {
        formRef.current?.validateFields().then(res => {
            onCreate(res)
        })
    }

    return <ModalCommon open={visible}
                  onOk={() => onFinish()}
                  onCancel={onCancel}
                  okText={lang.button.yes}
                  okButtonProps={{className: "_btn-submit"}}
                  cancelButtonProps={{className: "_btn-cancel"}}
                  cancelText={lang.button.cancel}
                  title={<p>{lang.conclusion.offender}</p>}
                  closeIcon={<i className="fal fa-times-circle"/>}>
        <Form ref={formRef}>
            <Form.Item {...formItemLayout} name={"name"} className={""} label={lang.staff.staff_name} rules={[{
                required: true,
                message: lang.staff.staff_name_input,
            }, {
                max: 32,
                message: lang.staff.staff_name_input_validation
            }]}>
                <Input className={"_name"}/>
            </Form.Item>

            <Form.Item {...formItemLayout} name={"code"} label={lang.staff.staff_code} className={""} rules={[{
                required: true,
                message: lang.staff.staff_code_input
            }, {
                pattern: new RegExp(/^[0-9a-zA-Z]+$/i),
                message: lang.staff.staff_code_input_validation
            }, {
                max: 15,
                message: lang.staff.staff_code_input_max_length
            }]}>
                <Input className={"_code"}/>
            </Form.Item>
            <Form.Item {...formItemLayout} name={"departmentId"} label={lang.staff.staff_office} className={""} rules={[{
                required: true,
                message: lang.officer_selector.select
            }]}>
                <SelectOfficers/>
            </Form.Item>
        </Form>
    </ModalCommon>
}

export default CreateStaffMistake
