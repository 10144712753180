import { Button, Col, DatePicker, Form, Input, Modal, Row } from 'antd'
import { FC, useContext, useEffect, useRef, useState } from 'react'
import { checkPermission, renderStateDotColor } from '../../../../utils/helper'
import { debounce, get, range } from 'lodash'
import moment, { Moment } from 'moment'
import ClaimApi from '../../../../repository/ticket'
import { TICKETS_SCOPES } from '../../../../utils/api/Scopes'
import lang from '../../../../resources/localization/Localization'
import { useHistory } from 'react-router'
import ModalConfirm from '../../../../components/v2/Modal/Confirm'
import storageUtil from '../../../../utils/LocalStore/LocalStores'
import { HistoryState } from './HistoryState'
import { TicketLogs } from 'src/screens/Tickets/Detail/Informations/TicketLogs'
import { TimeUtils } from 'src/utils/timeUtils'
import { OrtherInfo } from 'src/screens/Tickets/Detail/Informations/Orther'
import { PaymentRequireInfo } from 'src/screens/Tickets/Detail/Informations/PaymentRequire'
import { StateInfo } from 'src/screens/Tickets/Detail/Informations/StateInfo'
import { Content, Label, RowContainer, RowInfo } from '../components/Row'
import { OrderInfo } from './OrderInfo'
import { ModalCommon } from 'src/components/base/ModalCommon'
import { isMobile } from 'react-device-detect'
import { ClaimDetailContext } from '../context'
import { ClaimProducts } from '../Product/ClaimProducts'
import ReasonComplaint from '../ReasonComplaint'

interface IProps {
    order: any
    detail: any
    changeStatus: any
    archiveTicket: any
    loading: any
    assignUserToMe: any
    assignUserToIssue: any
    onChangeDateline: any
}

const Informations: FC<IProps> = ({ order, detail, changeStatus, archiveTicket, loading, assignUserToMe, assignUserToIssue, onChangeDateline }) => {
    const [form] = Form.useForm()
    const datePickerRef: any = useRef(null)
    const [collapse, setCollapse] = useState(true)
    const [targetUrl, setTargetUrl] = useState('')
    const [showLogs, setShowLogs] = useState(false)
    const [changeDateline, setChangeDatelineStatus] = useState(false)
    const [issueLoading, setIssueLoading] = useState(false)
    const [isShowConfirmChangeUser, setShowConfirmChangeUser] = useState(false)
    const [ticketStates, setTicketStates] = useState([])
    const [isShowConfirmRefund, setShowConfirmRefund] = useState(false)
    const [inventoryPassword, setInventoryPassword] = useState('')
    const [userAssign, setUserAssign] = useState('')

    const passwordRef: any = useRef()

    const { project } = useContext(ClaimDetailContext)

    const assignmentView = get(detail, 'assignmentView')

    const ticketState = get(detail, 'stateViewClaim.code')

    const getStates = () => {
        setIssueLoading(true)
        ClaimApi.getStates()
            .then((res) => {
                setTicketStates(res.data || [])
            })
            .finally(() => setIssueLoading(false))
    }

    useEffect(() => {
        if (detail?.code) {
            const currentTenant = storageUtil.getJson('currentTenant')
            setTargetUrl(currentTenant?.tenantDomain || '')
            getStates()
        }
    }, [detail?.code])

    useEffect(() => {
        if (!assignmentView?.assignee) return
        form.setFieldsValue({
            staff: assignmentView.assignee,
        })
    }, [assignmentView])

    const onChangeDatePicker = (value: any) => {
        onChangeDateline(value)
        setChangeDatelineStatus(false)
    }

    const disabledDate = (current: Moment) => {
        // Can not select days before today and today
        return current && current < moment().subtract(1, 'days').endOf('day')
    }

    const disabledTime: any = (current: Moment) => {
        if (moment(current).diff(moment(detail.createdAt)) < 60000) {
            return {
                disabledMinutes: () => range(0, parseInt(moment().add(1, 'minute').format('mm'))),
            }
        }

        if (moment().isSame(current, 'days')) {
            return {
                disabledHours: () => range(0, 24).splice(0, parseInt(moment().format('HH'))),
                disabledMinutes: () => (moment().isSame(current, 'hours') ? range(0, parseInt(moment().format('mm'))) : undefined),
            }
        }
        return undefined
    }

    const checkStatusRequirePassword = (status: string) => {
        ticketStates.map((item: any) => {
            if (item.code === status) {
                if (item.requirePassword === true) {
                    setShowConfirmRefund(true)
                } else {
                    changeStatus(status)
                }
            }
        })
    }

    const onAssignUserToIssue = (user: any) => {
        if (!!assignmentView?.assignee) {
            setShowConfirmChangeUser(true)
            setUserAssign(user)
        } else {
            assignUserToIssue(user)
        }
        // TicketApi.assignUserToIssue(detail.code, user)
    }

    const checkShowCloseBtn = () => {
        let currentLoggedUser: any = storageUtil.getCurrentUser()
        if (!detail) return false
        if (detail.archived === true) return false
        // show khi trạng thái là REJECT và phải có quyền CLOSE ticket (Close All hoặc Close Created và đúng người tạo)

        return (
            checkPermission(TICKETS_SCOPES.TICKETS_ARCHIVE_ALL) ||
            (checkPermission(TICKETS_SCOPES.TICKETS_ARCHIVE_CREATED) && currentLoggedUser?.username === detail.createdBy)
        )
    }

    useEffect(() => {
        if (isShowConfirmRefund) {
            setTimeout(() => {
                passwordRef.current?.focus()
            }, 200)
        } else {
            setInventoryPassword('')
        }
    }, [isShowConfirmRefund])

    const isAllowEditDeadline = ticketState !== 'REFUND' && ticketState !== 'REJECT' && checkPermission(TICKETS_SCOPES.TICKETS_UPDATE_DEADLINE)

    return (
        <div className={'box'}>
            <div className={'flex justify-content-between flex-wrap items-center'}>
                <h2 className="box-title m-0">{lang.claim_detail.info_claim}</h2>

                <div className={'flex flex-row align-items-center'}>
                    <div className={'mgr8 txt-size-h7 text-secondary-2'}>{lang.ticket_detail.claim_status}:</div>
                    <div className={'flex flex-row align-items-center _state-' + ticketState}>
                        <div className={'align-self-center mgr-5 status-dot ' + renderStateDotColor(ticketState)} />
                        <span className={'txt-nowrap txt-size-h7 txt-color-black _state-value-' + ticketState}>{lang.state_ticket[ticketState]}</span>
                    </div>

                    {checkPermission(TICKETS_SCOPES.TICKETS_LOG_VIEW) && (
                        <Button
                            className={'buttonm3 mgl-8 _btn-log'}
                            onClick={() => setShowLogs(true)}>
                            <i className="fa-regular fa-flag" />
                            <span className="only-show-from-sm mgl-6">Log</span>
                        </Button>
                    )}
                </div>
            </div>

            <Form
                form={form}
                className="mt-3">
                <StateInfo
                    detail={detail}
                    project={project as string}
                    assignmentView={assignmentView}
                    ticketState={ticketState}
                    issueLoading={issueLoading}
                    archiveTicket={archiveTicket}
                    ticketStates={ticketStates}
                    assignUserToMe={assignUserToMe}
                    loading={loading}
                    checkShowCloseBtn={checkShowCloseBtn}
                    onAssignUserToIssue={onAssignUserToIssue}
                    checkStatusRequirePassword={checkStatusRequirePassword}
                />

                <OrderInfo
                    detail={detail}
                    order={order}
                    project={project}
                />
                <ReasonComplaint data={detail} />
                {
                    detail?.claimProductViews && detail?.claimProductViews?.length > 0 && <ClaimProducts claimDetail={detail} />
                }
                {!collapse && (
                    <>
                        <Row gutter={[16, 16]}>
                            <Col
                                xs={24}
                                lg={checkPermission(TICKETS_SCOPES.TICKETS_MILESTONE_VIEW) ? 12 : 24}>
                                <PaymentRequireInfo
                                    detail={detail}
                                    order={order}
                                    targetUrl={targetUrl}
                                />
                            </Col>

                            {checkPermission(TICKETS_SCOPES.TICKETS_MILESTONE_VIEW) && (
                                <Col
                                    xs={24}
                                    lg={12}>
                                    <div className={'flex flex-row align-items-center mgt-16 mb-1'}>
                                        <div className={'text-14 font-medium txt-color-green4 mgr15'}>{lang.claim_detail.time}:</div>
                                        <div
                                            className={'border-line width100'}
                                            style={{ flex: isMobile ? 6 : 4 }}
                                        />
                                    </div>

                                    <RowContainer>
                                        <HistoryState ticket={detail} />

                                        <RowInfo label={lang.list_ticket.deadline}>
                                            <div className={'_deadline txt-size-h7 txt-color-black robotoregular'}>
                                                {changeDateline ? (
                                                    <DatePicker
                                                        showTime={{ format: 'HH:mm' }}
                                                        allowClear={false}
                                                        disabledDate={disabledDate}
                                                        disabledTime={disabledTime}
                                                        format="HH:mm DD/MM/YYYY"
                                                        ref={datePickerRef}
                                                        onBlur={() => {
                                                            setChangeDatelineStatus(false)
                                                        }}
                                                        defaultValue={detail.deadline ? moment(detail.deadline) : moment(new Date())}
                                                        className={'_deadlinePicker'}
                                                        onChange={onChangeDatePicker}
                                                    />
                                                ) : (
                                                    <div className={'flex flex-row  items-center'}>
                                                        <div>{TimeUtils.showDateTime(detail.deadline)}</div>

                                                        {isAllowEditDeadline && (
                                                            <i
                                                                className="far fa-edit mgl8 cursor-pointer txt-color-green4 _selectChangeDeadline"
                                                                onClick={() => {
                                                                    setChangeDatelineStatus(true)
                                                                    setTimeout(() => {
                                                                        datePickerRef?.current?.focus()
                                                                    }, 500)
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </RowInfo>
                                    </RowContainer>
                                </Col>
                            )}

                            <Col
                                xs={24}
                                lg={checkPermission(TICKETS_SCOPES.TICKETS_LOG_VIEW) ? 24 : 12}>
                                <OrtherInfo order={order} />
                            </Col>
                        </Row>
                    </>
                )}

                <div className={'border-line mgt-16 mgb-16'} />
                <div>
                    {!collapse ? (
                        <span
                            className={'cursor-pointer _hidden-info'}
                            onClick={() => setCollapse(true)}>
                            {' '}
                            <i className="fas fa-chevron-up txt-color-black txt-size-h8" />{' '}
                            <span className={'txt-color-black txt-size-h7 mgl5'}>{lang.button.collapse_info}</span>
                        </span>
                    ) : (
                        <span
                            className={'cursor-pointer _show-info'}
                            onClick={() => setCollapse(false)}>
                            <i className="fas fa-chevron-down txt-color-black txt-size-h8" />
                            <span className={'txt-color-black txt-size-h7 mgl5'}>{lang.button.expand_info}</span>
                        </span>
                    )}
                </div>
            </Form>

            <TicketLogs
                isShow={showLogs}
                onCancel={() => setShowLogs(false)}
                code={detail.code}
                subject={detail.ticketType}
            />

            <ModalConfirm
                visible={isShowConfirmChangeUser}
                onOk={() =>
                    assignUserToIssue(userAssign, () => {
                        setShowConfirmChangeUser(false)
                        setUserAssign('')
                    })
                }
                // @ts-ignore
                okButtonProps={{ className: '_submitConfirmChangeUser', loading, disabled: loading }}
                cancelButtonProps={{ className: '_cancelConfirmChangeUser', loading, disabled: loading }}
                content={lang.message.assign_to_me_confirm}
                title={lang.conclusion.warning}
                onCancel={() => {
                    setUserAssign('')
                    setShowConfirmChangeUser(false)
                }}
            />
            <ModalCommon
                title={lang.ticket_detail.input_pin}
                open={isShowConfirmRefund}
                okText={lang.button.yes}
                cancelText={lang.button.cancel}
                onOk={() => !loading && changeStatus('REFUND', inventoryPassword, () => setShowConfirmRefund(false))}
                cancelButtonProps={{ className: '_cancelConfirmRefund', disabled: loading }}
                okButtonProps={{ className: '_submitConfirmRefund', loading, disabled: loading }}
                onCancel={() => !loading && setShowConfirmRefund(false)}>
                <Form>
                    <Form.Item
                        label={lang.ticket_detail.pin_code}
                        rules={[{ required: true, message: lang.ticket_detail.input_pin }]}>
                        <Input.Password
                            autoComplete='password'
                            ref={passwordRef}
                            className={'_input-password'}
                            value={inventoryPassword}
                            disabled={loading}
                            onPressEnter={(e: any) => changeStatus('REFUND', e.target.value, () => setShowConfirmRefund(false))}
                            onChange={(e) => setInventoryPassword(e.target.value)}
                        />
                    </Form.Item>
                </Form>
            </ModalCommon>
        </div>
    )
}
export default Informations
