import apiClientV2 from '../utils/ApiClient'

export default class CommentApi {
    static getInternalComments = (code, query = {}) => {
        return apiClientV2.get(`/claims/${code}/comments`, {
            params: { ...query, internal: true },
        })
    }

    static getComments = (code, query = {}) => {
        return apiClientV2.get(`/claims/${code}/comments`, {
            params: { ...query, internal: false },
        })
    }

    static createComment = (code, data) => {
        return apiClientV2.post(`/claims/${code}/comments`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

}
