import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import ClaimApi from "src/repository/ticket";

export function useClaimStates () {
    return useQuery({
        queryKey: ['claim-states'],
        queryFn: async () => {
            const response = await ClaimApi.getStates();
            return response.data
        },
        refetchOnWindowFocus: false
    })
}