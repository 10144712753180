import { Layout, Menu, MenuProps } from 'antd'
import { default as _, default as lodash } from 'lodash'
import React, { SetStateAction } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import Utils from 'src/utils'
import logo from '../../../resources/images/logoibz.png'
import lang from '../../../resources/localization/Localization'
import { default as SecurityService, default as securityService } from '../../../utils/SecurityService'
import { TICKETS_SCOPES } from '../../../utils/api/Scopes'
import useWindowDimensions from './useWindowDimensions'

type MenuItem = Required<MenuProps>['items'][number];

type NewSideBarProps = {
    collapsed: boolean,
    setCollapsed: SetStateAction<any>;
    totalClaims: any
    claimStates: any
}

function NewSideBar(props: NewSideBarProps) {
    const history = useHistory()
    const location = useLocation()
    const {
        collapsed, setCollapsed, totalClaims, claimStates
    } = props
    const { height, width } = useWindowDimensions()

    const getItem = (
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
        className?: string,
        permission?: any
    ): MenuItem => {
        return {
            key,
            icon,
            children,
            label,
            type,
            className,
            permission
        } as MenuItem
    }

    const onClick: MenuProps['onClick'] = e => {
        // console.log('click ', e);
        handleChangeMenuItem(e, e.key)
    }

    const handleChangeMenuItem = (event: any, navigate: string) => {
        if (event?.domEvent?.ctrlKey || event?.domEvent?.metaKey) {
            window.open(`/#/${navigate}`, '_blank')
            return
        }
        if (['NEW', 'PROCESSING', 'ACCEPTED', 'REJECT', 'REFUND'].find((x) => x === event.key)) {
            getTicketsByState(event, event.key)
        } else if (event.key === '' || event.key === 'all') {
            getTicketsByState(event, undefined)
        } else {
            history.push(`/${navigate}`)
        }
    }

    const getTicketsByState = (event: any, state: any) => {
        let searchParams = Utils.getQueryFromLocation(location);
        // console.log(searchParams, event, state)
        if (event.keyPath[1] === 'tickets') {
            if (event.key !== 'all') {
                if (event.key === 'NEW') {
                    let temp = { ...searchParams };
                    delete temp.assignee
                    history.push(`/tickets?${new URLSearchParams({ ...temp, stateUnchanged: state })}`)
                }
                else history.push(`/tickets?${new URLSearchParams({ ...searchParams, stateUnchanged: state })}`);
            } 
            else {
                // console.log('vo day');
                let temp = {...searchParams};
                delete temp.stateUnchanged;
                history.push(`/tickets?${new URLSearchParams({ ...temp })}`);
            } 
        }
        else {
            history.push(`/tickets`)
        }
    }


const getCurrentMenuKey = () => {
    let searchString = location.search.substring(1)
    let searchParam: any = {}
    if (searchString && searchString.length > 0) {
        searchParam = JSON.parse('{"' + decodeURI(searchString).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
    }
    if (history && history.location.pathname) {
        if (lodash.startsWith(history.location.pathname, '/tickets')) {
            if (!searchParam.stateUnchanged) {
                return ['all', 'tickets', 'claims']
            } else {
                return [searchParam.stateUnchanged, 'tickets', 'claims']
            }
        }
        if (lodash.startsWith(history.location.pathname, '/staffs')) {
            return ['staffs']
        }
    }
    return [history.location.pathname.replace('/', '')]
}

const handleCountClaimEachState = (data: any, state: string) => {
    let temp = data?.find((d: any) => d.code === state)
    return new Intl.NumberFormat().format(_.get(temp, 'numberOfClaim') || 0)
}

const items: MenuProps['items'] = [
    // getItem(lang.login.home_page, '', <i className="fa-regular fa-house"></i>, undefined, undefined, '_link-menu-home-page',true),
    getItem(lang.sidebar.claim_t, 'tickets', <i className='fa-regular fa-memo-pad'></i>, [
        getItem(lang.sidebar.list_claims, 'claims', null, [
            getItem(<><span>{lang.sidebar.all}</span><span
                className={'item-badge'}>{new Intl.NumberFormat().format(totalClaims)}</span></>, 'all', null, undefined, undefined, '_link-menu-all'),
            getItem(<><span>{lang.state_ticket.NEW}</span><span
                className={'item-badge'}>{handleCountClaimEachState(claimStates, 'NEW')}</span></>, 'NEW', null, undefined, undefined, '_link-menu-new'),
            getItem(<><span>{lang.state_ticket.PROCESSING}</span><span
                className={'item-badge'}>{handleCountClaimEachState(claimStates, 'PROCESSING')}</span></>, 'PROCESSING', null, undefined, undefined, '_link-menu-processing'),
            getItem(<><span>{lang.state_ticket.ACCEPTED}</span><span
                className={'item-badge'}>{handleCountClaimEachState(claimStates, 'ACCEPTED')}</span></>, 'ACCEPTED', null, undefined, undefined, '_link-menu-accepted'),
            getItem(<><span>{lang.state_ticket.REJECT}</span><span
                className={'item-badge'}>{handleCountClaimEachState(claimStates, 'REJECT')}</span></>, 'REJECT', null, undefined, undefined, '_link-menu-rejected'),
            getItem(<><span>{lang.state_ticket.REFUND}</span><span
                className={'item-badge'}>{handleCountClaimEachState(claimStates, 'REFUND')}</span></>, 'REFUND', null, undefined, undefined, '_link-menu-refund')
        ], 'group')
    ], undefined, '', SecurityService.can(TICKETS_SCOPES.TICKETS_VIEW_ALL)),
    getItem(lang.sidebar.menu_config, 'configurations', <i
        className='fa-regular fa-gear'></i>, undefined, undefined, '_link-menu-config', SecurityService.can(TICKETS_SCOPES.CONFIG_GENERAL)),
    getItem(lang.sidebar.list_staff, 'staffs', <i
        className='fa-regular fa-user'></i>, undefined, undefined, '_link-menu-staffs', securityService.can(TICKETS_SCOPES.CONFIG_STAFF_VIEW)),
    getItem(lang.sidebar.reporter, 'report', <i
        className='fa-regular fa-file-chart-column'></i>, undefined, undefined, '_link-menu-report')
]


return (
    <Layout.Sider
        trigger={null}
        theme={'light'}
        className={`new-side-bar ${width <= 1024 ? (collapsed ? 'new-side-bar_collapsed' : 'new-side-bar_expand') : ''}`}
    >
        <div className={'nav-bar'}>
            <span className={'flex pd10 justify-center text-center mgt10 mgbt10 logos'}>
                {width <= 1025 && <span className={'mgb-10'} onClick={() => setCollapsed(true)}><i
                    className='fa-regular fa-angles-left'></i></span>}
                <img src={logo} alt={'logo'} className={''} width={38} height={'auto'} />
            </span>
            <Menu
                selectedKeys={getCurrentMenuKey()}
                onClick={(e) => {
                    {
                        onClick(e)
                        // console.log(e)
                    }
                }}
                className={'custom-menu'}
                items={items.filter((i: any) => i.permission)}
            />
        </div>
    </Layout.Sider>
)
}

export default NewSideBar
