import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom'
import DashboardV2 from './screens/Dashboard'
import Page404V2 from './screens/Page404.tsx'

// Advance
import TicketDetailV2 from './screens/Tickets/Detail'
import TicketsV2 from './screens/Tickets/List'

// import StyleGuider from './containers/StyleGuider';
import ConfigurationV2 from './screens/Configuration'
import ReportV2 from './screens/Report'
import StaffsV2 from './screens/Staffs'

//Authentication
import AuthenticateRoute from './components/v2/AuthenticateRoute'
import Claim from './containers/Claim'
import AuthenticationV2 from './screens/Authentication'
import Login from './screens/Login'
import { Redirect } from 'react-router'

function Routes() {
    return (
        <BrowserRouter>
            <Route path={'/authentication'} exact={true} component={AuthenticationV2} />
            <HashRouter>
                <Switch>
                    {/* <AuthenticateRoute exact={true} path="/" component={DashboardV2} /> */}
                    <AuthenticateRoute exact={true} path="/" component={()=> <Redirect to={'/tickets'} />} />
                    <AuthenticateRoute exact={true} path="/tickets/:id" component={TicketDetailV2} RouteKey={true} />

                    <AuthenticateRoute exact={true} path="/tickets" component={TicketsV2} />
          
                    <AuthenticateRoute exact={true} path="/staffs" component={StaffsV2} />
                    <AuthenticateRoute exact={true} path="/configurations/:activeTab?/:financeActiveTab?" component={ConfigurationV2} />
                    <AuthenticateRoute exact={true} path="/report" component={ReportV2} RouteKey={true} />
                    <AuthenticateRoute exact={true} path="/report" component={ReportV2} />
                    <Route path={'/login'} exact={true} component={Login} />
                    <Route exact={true} component={Page404V2} />
                </Switch>
            </HashRouter>
        </BrowserRouter>
    );

}

export default Routes;
