import { useEffect, useState } from 'react'
import { IFinancialType } from 'src/interface/FinancialType'
import ClaimApi from 'src/repository/ticket'
import { useProjects } from './useProjects'

export const useOfficersNoPermission = (type: string) => {
    const [officers, setOfficers] = useState<IFinancialType[]>([])
    const [officersCodeMapping, setOfficersCodeMapping] = useState<{ [key in string]: string }>({})
    const { currentProjectId } = useProjects()

    const fetchFinanceTypes = () => {
        const query = {
            sort: 'createdAt:desc',
            offset: 0,
            limit: 1000,
            project: currentProjectId,
        }

        ClaimApi.getStaffMistakeNoPermission(query).then((r) => {
            const nameMapping: { [key in string]: string } = {}

            r.data.forEach((financeType: IFinancialType) => {
                nameMapping[financeType.id] = financeType.code
            })

            setOfficers(r.data)
            setOfficersCodeMapping(nameMapping)
        })
    }

    useEffect(() => {
        if (!type) return
        fetchFinanceTypes()
    }, [type])

    return { officers, officersCodeMapping }
}
