import React from 'react'
import DocumentTitle from "react-document-title"
import userService from "../../services/user.service";

import loadingGif from '../../resources/images/loading.gif'
import errorPng from '../../resources/images/error.png'

import {parseQueryStringToObject} from "../../utils/stringUtils";
import lodash from "lodash";
import storageUtil from "../../utils/LocalStore/LocalStores";
import lang from "../../resources/localization/Localization";
import {setToken} from '../../helpers/localStorage';
import apiClientV2 from '../../utils/ApiClient';
import jwt_decode from 'jwt-decode'
import { localStorageKeys } from 'src/constants/keyName';

class Authentication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isNotPermission: false,
            msg: lang.login.auth_message,
        }
    }

    componentDidMount() {
        // Hủy sự kiện cảnh báo khi load trang
        window.onbeforeunload = null;
        const params = parseQueryStringToObject(this.props.location.search);
        setToken(null);
        // clear old session
        let redirectBackUrl = storageUtil.getItem('redirect_uri') || '/';

        const token = params['access_token']
        if (token) {
            setToken(token)
            this.setState({msg: lang.login.redirect_after_auth_message}, () => {
                apiClientV2.get('/profile')
                    .then((response) => {
                        apiClientV2.get('/tenants/current').then( res => {
                            storageUtil.setJson("currentUser", response.data);
                            storageUtil.setJson('permissions',lodash.get(jwt_decode(token),'permissions',[]))
                            this.setState({msg: `${lang.login.redirectToDashboard.toString().replace('%name%',response.data.fullname)}`}, () => {
                                setTimeout(() => {
                                    window.location.href = redirectBackUrl
                                }, 1000)
                            });
                        }).catch(err => {
                            storageUtil.removeItem(localStorageKeys.currentUser);
                            storageUtil.removeItem('redirect_uri');

                            this.setState({
                                isNotPermission: true,
                                msg: lodash.get(err, 'response.data.message')
                            })
                        })
                    })
                    .catch(error => {
                        storageUtil.removeItem(localStorageKeys.currentUser);
                        storageUtil.removeItem('currentProjectInfo');
                        storageUtil.removeItem('redirect_uri');
                        this.setState({
                            isNotPermission: true,
                            msg: lodash.get(error, 'response.data.message')
                        })
                    });
            })
        } else {
            this.setState({msg:lang.login.auth_message}, () => {
                // setTimeout(() => {
                //     window.location.href = appConfig.loginUrl
                // }, 1500)
            });
        }
    }


    logout = () => {
        userService.logout()
    };

    render() {

        return (
            <DocumentTitle title={`Xác thực | Ibizan`}>
                <div className={'splash-container'}>
                    {
                        !this.state.isNotPermission
                            ?
                            <div className={'text-center margin-auto'}>
                                <img src={loadingGif} alt=""/>
                                <p className="splash-text txt-color-black2 mgt25">
                                    {this.state.msg}
                                </p>
                            </div>
                            :
                            <div className={'text-center margin-auto utmhelve-regular'}>
                                <div>
                                    <img src={errorPng} alt=""/>
                                </div>
                                <div className="txt-center">
                                    <p className="splash-text txt-color-black2 mgt20">
                                        Bạn không có quyền truy cập.
                                    </p>
                                    <p className="splash-text txt-color-black2 mgt20">
                                        Bạn có thể thử lại hoặc liên hệ quản lý để được hỗ trợ.
                                    </p>
                                    <div className={'mgt50'}>
                                        <a href="#" className="splash-text txt-color-green32 ">
                                            <i className="fas fa-undo-alt mgr5"/> <span>Thử lại</span>
                                        </a>
                                        <a onClick={this.logout}
                                           className="splash-text cursor-pointer txt-color-green32 mgl25">
                                            <i className="fas fa-sign-out-alt mgr5"/> <span>Đăng xuất</span>
                                        </a>
                                    </div>

                                </div>
                            </div>
                    }
                </div>
            </DocumentTitle>
        );
    }
}

export default Authentication;
