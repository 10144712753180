import { useEffect, useRef, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { List, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import Utils from "../../../utils";
import CommentFooter from "./CommentFooter";
import CopyToClipboard from "../Button/CopyToClipboard";
import { checkKeyboardMap } from "../../../utils/keyboardMap";
import lang from "../../../resources/localization/Localization";
import { ModalCommon } from "src/components/base/ModalCommon";

const CommentBox = ({
    isCloseIcon = false,
    isCollapse = false,
    disableInput = false,
    shortcut = undefined,
    title = "comment",
    data = [],
    loading = false,
    showFull = true,
    keyValue = "",
    iconUnder = false,
    iconCollapse = false,
    onLoadData = () => { },
    pagination = {},
    onCollapse = () => { },
    onSubmitComment = () => { },
    onClose = () => { }
}) => {
    const [collapse, setCollapse] = useState(false)
    const [heightList, setHeightList] = useState(0)
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewTitle, setPreviewTitle] = useState("")
    const [previewImage, setPreviewImage] = useState("")
    const { height, ref } = useResizeDetector()
    const listRef = useRef()
    useEffect(() => {
        setHeightList(height - 100)
        document.addEventListener('mousedown', function (event) {
            if (event.detail > 1) {
                // event.preventDefault();
                // of course, you still do not know what you prevent here...
                // You could also check event.ctrlKey/event.shiftKey/event.altKey
                // to not prevent something useful.
            }
        }, false);
    }, [])
    useEffect(() => {
        setCollapse(!showFull)
    }, [showFull])
    useEffect(() => {
        if (collapse) {
            setHeightList(0)
        } else if (height !== heightList) {
            setHeightList(height - 100)
        }
    }, [height])

    const onDownload = (url) => {
        window.open(url, "_blank")
    }
    const renderIconCollapse = () => {
        if (isCloseIcon) {
            return <i className="fal fa-times" onClick={onClose} />
        }
        if (!isCollapse) {
            return;
        }
        if (collapse) {
            if (iconUnder) {
                if (iconCollapse) {
                    return <i className="fal fa-chevron-up" />
                } else {
                    return <i className="fal fa-chevron-down" />
                }
            } else {
                if (iconCollapse) {
                    return <i className="fal fa-chevron-down" />
                } else {
                    return <i className="fal fa-chevron-up" />
                }
            }
        } else {
            if (iconUnder) {
                if (iconCollapse) {
                    return <i className="fal fa-chevron-up" />
                } else {
                    return <i className="fal fa-chevron-down" />
                }
            } else {
                if (iconCollapse) {
                    return <i className="fal fa-chevron-down" />
                } else {
                    return <i className="fal fa-chevron-up" />
                }
            }
        }
    }
    return <div className={"comment-box"} ref={ref} style={!collapse ? { flex: 1 } : { height: 40 }}>
        <div className={"header-comment"}>
            <div className={"title"}>
                {title}
            </div>
            <div
                className={"icon"}
                onClick={() => {
                    if (showFull) {
                    } else {
                        setCollapse(!collapse)
                    }
                    onCollapse(!collapse, keyValue)

                }}>
                {renderIconCollapse()}
            </div>
        </div>
        {!collapse && <div style={{}}>
            <div className={"comment-line"} />
            <CommentFooter collapse={collapse}
                disableInput={disableInput}
                shortcut={shortcut}
                listRef={listRef}
                title={title}
                loading={loading}
                onSubmitComment={onSubmitComment} />
            <div className={"comment-content"}

                id={"scrollableDiv-" + keyValue}
                style={{
                    maxHeight: heightList || undefined,
                    overflow: 'auto',
                    display: "inline-block",
                    overFollow: "hidden",
                    width: "100%",
                }}>
                {data.length > 0 ? <InfiniteScroll
                    dataLength={loading ? 0 : data.length}
                    ref={listRef}
                    next={() => onLoadData(pagination.currentPage + 1)}
                    hasMore={loading ? false : data.length < pagination.total}
                    loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                    scrollableTarget={"scrollableDiv-" + keyValue}
                >
                    <List
                        loading={loading}
                        itemLayout="horizontal"
                        dataSource={loading ? [] : data}
                        renderItem={(comment) => {
                            let images = []
                            let files = []
                            if (comment.attachments && comment.attachments.length > 0) {
                                files = comment.attachments.filter(att => !att.name.match(/.(jpg|jpeg|png|gif|webp)$/i))
                                images = comment.attachments.filter(att => att.name.match(/.(jpg|jpeg|png|gif|webp)$/i))
                            }
                            return (<div key={comment.id}
                                className={comment?.right ? "comment-item-right" : "comment-item-left"}>
                                {comment?.right ?
                                    <div className={"comment-item-right-wrapper"}>
                                        <div className={"comment-item-header"}>
                                            <div className={"comment-time"}>
                                                {moment(comment.createdAt).format("DD/MM/YYYY - HH:mm")}
                                            </div>
                                            <div className={"comment-user"}>
                                                {comment?.createdBy}
                                            </div>
                                        </div>
                                        <div className={"comment-item-media"}>
                                            {images && images.length > 0 && <div className={"image-list"}>{
                                                images.map(att => <img className={"image"} alt={""} src={att.url}
                                                    onClick={() => {
                                                        setPreviewVisible(true)
                                                        setPreviewTitle(att.name)
                                                        setPreviewImage(att.url)
                                                    }} />)
                                            }</div>}
                                            {files && files.length > 0 &&
                                                <div className={"file-list " + (comment.content && "mgb-5")}>
                                                    {files.map((att, index) => <div key={`file_${index}`} className={"file"}>
                                                        <div className={"btn"} onClick={() => onDownload(att.url)}>Tải về
                                                        </div>
                                                        <div className={"file-wrap"}>
                                                            <a href={att.url} className={"file-name"} download={att.name}>
                                                                {att.name}
                                                            </a>
                                                        </div>
                                                    </div>)}
                                                </div>}
                                        </div>
                                        {comment.content && <div className={"comment-item-content"}>
                                            {Utils.detectUrl(comment.content) &&
                                                <CopyToClipboard text={Utils.detectUrl(comment.content)} />}
                                            <div
                                                className={`_comment-content-user content` + (Utils.detectUrl(comment.content) ? "-link" : "")}
                                                dangerouslySetInnerHTML={{ __html: Utils.replaceURLs(comment.content) }} />
                                        </div>
                                        }
                                    </div> : <div className={"comment-item-left-wrapper"}>
                                        <div className={"comment-item-header"}>
                                            <div className={"comment-user"}>
                                                {comment?.createdBy}
                                            </div>
                                            <div className={"comment-time"}>
                                                {moment(comment.createdAt).format("DD/MM/YYYY - HH:mm")}
                                            </div>
                                        </div>
                                        <div className={"comment-item-media"}>
                                            {images && images.length > 0 && <div className={"image-list"}>{
                                                images.map(att => <img className={"image"} alt={""} src={att.url}
                                                    onClick={() => {
                                                        setPreviewVisible(true)
                                                        setPreviewTitle(att.name)
                                                        setPreviewImage(att.url)
                                                    }} />)
                                            }</div>}
                                            {files && files.length > 0 &&
                                                <div className={"file-list " + (comment.content && "mgb-5")}>
                                                    {files.map((att) => <div className={"file"}>
                                                        <div className={"file-wrap"}>
                                                            <a href={att.url} className={"file-name"} download={att.name}>
                                                                {att.name}
                                                            </a>
                                                        </div>
                                                        <div className={"btn"} onClick={() => onDownload(att.url)}>Tải về
                                                        </div>
                                                    </div>)}
                                                </div>}

                                        </div>
                                        {comment.content && <div className={"comment-item-content"}>
                                            <div
                                                className={`_comment-content-customer content` + (Utils.detectUrl(comment.content) ? "-link" : "")}
                                                dangerouslySetInnerHTML={{ __html: Utils.replaceURLs(comment.content) }} />
                                            {Utils.detectUrl(comment.content) &&
                                                <CopyToClipboard text={Utils.detectUrl(comment.content)} />}
                                        </div>
                                        }
                                    </div>}
                            </div>)
                        }} />
                </InfiniteScroll> : <div className={"empty-data"}>
                    <div className={"empty-content"}>
                        <i className="fas fa-comments icon mgbt10" />
                        <div className={"text-empty"}>{lang.message.comment_empty}</div>
                        <div
                            className={"text-empty-shortcut"}>{lang.button.press_button + " " + checkKeyboardMap(shortcut) + lang.button.press_to + " " + title.toLowerCase()}</div>
                    </div>
                </div>}
            </div>


        </div>}
        <ModalCommon
            open={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={() => setPreviewVisible(false)}
        >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </ModalCommon>
    </div>
}
export default CommentBox
