export class ObjectUtils {
    static removeEmptyValues(obj: { [key in string]: string | null | undefined }) {
        Object.keys(obj).forEach((k) => {
            if (typeof obj[k] === 'string') {
                obj[k] = obj[k]?.trim()
            }

            if (obj[k] === null || obj[k] === '') {
                delete obj[k]
            }
        })

        return obj
    }
}
