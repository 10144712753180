import { notification, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import ClaimApi from "../../../repository/ticket";
import lodash from "lodash";
import lang from "../../../resources/localization/Localization";
import storageUtil from "../../../utils/LocalStore/LocalStores";
import _ from 'lodash'
import { removeAccents } from "src/utils/stringUtils";

const SelectReason = ({ onChange, value, disabled, type, relatedProduct }) => {
    const [loading, setLoading] = useState(false);
    const [reasons, setReason] = useState([]);
    const project = storageUtil.getItem('selectedProject');
    const [query, setQuery] = useState()

    const fetchReason = useCallback((filter) => {
        setLoading(true)
        ClaimApi.getReasons({ ...filter, project }).then(r => {
            setReason(r.data)
        }).catch((e) => {
            if (e.response?.data?.title === "order_not_found") {
                notification.error({ message: lang.error.order_not_found })
            } else {
                notification.error({ message: e.message })
            }
        }).finally(() => {
            setLoading(false)
        })
    }, [type, relatedProduct, query])

    useEffect(() => {
        let filterTemp;
        if (!relatedProduct) {
            if (typeof type === 'string' || type instanceof String) {
                filterTemp = lodash.pickBy({ ticketTypes: (type || "").toUpperCase() }, lodash.identity);
            } else {
                filterTemp = lodash.pickBy({ ticketTypes: type?.toString() }, lodash.identity);
            }
        }
        else if (relatedProduct) {
            if (typeof type === 'string' || type instanceof String) {
                switch (type) {
                    case 'order': filterTemp = lodash.pickBy({ ticketTypes: 'order_product'.toUpperCase() }, lodash.identity); break;
                    case 'shipment': filterTemp = lodash.pickBy({ ticketTypes: 'shipment_product'.toUpperCase() }, lodash.identity); break;
                    default:
                }
            }
        }
        fetchReason({ ...filterTemp, name: query });
    }, [type, relatedProduct, query]);

    return (
        <div>
            <Select disabled={disabled} onChange={onChange} value={value} placeholder={lang.ticket_add.claim_reason}
                loading={loading} className={"_select-reason"}
                showSearch
                filterOption={(input, option) =>
                    removeAccents(option?.label ?? '')?.toLowerCase().includes(removeAccents(input).toLowerCase())
                }
                allowClear
                options={
                    reasons.map((reason) => ({ label: reason.name, value: reason.code }))
                }
            />
        </div>
    )
}


export default SelectReason
