import { useEffect, useState } from 'react'
import { IFinancialType } from 'src/interface/FinancialType'
import ClaimApi from 'src/repository/ticket'

export const useMistakes = () => {
    const [mistakes, setMistakes] = useState<IFinancialType[]>([])
    const [mistakesNameMapping, setMistakesNameMapping] = useState<{ [key in string]: string }>({})

    const fetchMistakeList = () => {
        ClaimApi.getMistakes({
            limit: 200,
            offset: 0,
            sort: 'code:asc'
        }).then((r) => {
            const nameMapping: { [key in string]: string } = {}
            r.data.forEach((mistake: IFinancialType) => {
                nameMapping[mistake.code] = mistake.name
            })

            setMistakes(r.data)
            setMistakesNameMapping(nameMapping)
        })
    }

    useEffect(() => {
        fetchMistakeList()
    }, [])

    return { mistakes, mistakesNameMapping }
}
