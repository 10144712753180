import {useEffect, useRef, useState} from "react";
import {notification, Upload} from "antd";
import {useResizeDetector} from 'react-resize-detector';
import {checkKeyboardMap} from "../../../utils/keyboardMap";
import lang, { trans } from "../../../resources/localization/Localization";
import { ModalCommon } from "src/components/base/ModalCommon";

const UPLOAD_MAX_SIZE = 10 * 1024 * 1024;

const CommentFooter = ({shortcut, onSubmitComment, disableInput, loading, title}) => {
    const wrapperInputRef = useRef(null)
    const refInput = useRef(null)
    const refWarning = useRef(null)
    const [heightInput, setHeightInput] = useState(0)
    const [defaultHeight, setDefaultHeight] = useState(0)
    const [showExpandIcon, setShowExpandIcon] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [comment, setComment] = useState("")
    const {height, ref} = useResizeDetector()
    const [fileList, setFileList] = useState([])
    const [files, setFiles] = useState([])
    const [images, setImages] = useState([])
    const [previewImage, setPreviewImage] = useState(undefined)

    useEffect(() => {
        setHeightInput(wrapperInputRef?.current?.clientHeight)
        setDefaultHeight(wrapperInputRef?.current?.clientHeight)
        document.addEventListener("keydown", _handleKeyDown);
    }, [])

    useEffect(() => {
        if (!expanded && wrapperInputRef?.current?.clientHeight === defaultHeight) {
            setShowExpandIcon(false)
            return;
        }
        if ((heightInput > defaultHeight) || (wrapperInputRef?.current?.clientHeight > heightInput)) {
            setShowExpandIcon(true)
        }

    }, [heightInput, wrapperInputRef?.current?.clientHeight, refInput?.current?.clientHeight, expanded])
    useEffect(() => {
        const newFileList = [].concat(fileList)
        let files = []
        let images = []

        newFileList.forEach(file => {
            if (file.typeFile === "file") {
                files.push(file)
            } else {
                images.push(file)
            }
        })
        setFiles(files)
        setImages(images)
    }, [fileList])
    const _handleKeyDown = (event) => {
        if (event.keyCode === shortcut) {
            refInput?.current?.focus()
        }

    }
    const onKeyPress = (e) => {
        if (e.charCode === 13 && !e.shiftKey) {
            onSubmit()
            e.preventDefault();
        }
    }
    const onSubmit = () => {
        let newComment = comment;
        if (newComment.length > 0) {
            newComment = comment.replaceAll("<br><br>", '')
            newComment = newComment.replace(/&nbsp;/g, ' ').trim();
        }
        const data = {
            comment: newComment,
            files: fileList
        }

        if (((newComment.length > 0) || fileList.length > 0) && !disableInput && !loading) onSubmitComment(data, resetData)
    }
    const resetData = () => {
        if (refInput.current) {
            refInput.current.innerText = ""
        }
        setFileList([])
        setComment('')
    }
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const onChangeUpload = (e) => {
        refInput?.current?.focus()
    }
    const beforeUpload = async (_, list) => {
        let newFiles = [];
        if (list.length > 10) {
            notification.error({
                message: trans('error.upload_file_max_count'),
                key: 'max_file_uploaded'
            })
            return null
        }

        for (const l of list) {
            if (l.size <= UPLOAD_MAX_SIZE) {
                const url = await getBase64(l)
                if (!l.name.match(/.(jpg|jpeg|png|gif)$/i)) {
                    l.typeFile = "file"
                    l.url = url
                } else {
                    l.typeFile = "image"
                    l.url = url
                }
                await newFiles.push(l)
            } else {
                notification.error({
                    message: l.name + ` ${lang.error.upload_file}`,
                    key: l.name
                })
            }
        }
        if (newFiles.length > 0) {
            refInput?.current?.focus()
            setFileList(newFiles.concat(fileList))
        }
        return null
    }
    const removeFileItem = (index) => {
        const newFileList = [].concat(fileList)
        newFileList.splice(index, 1)
        setFileList(newFileList)
    }
    const onPaste = (e) => {
        e.preventDefault()
        let text = (e.originalEvent || e).clipboardData.getData('text/plain');
        if (text && text.length > 0) {
            let currentText = text;
            if (refInput.current) {
                currentText = refInput.current.innerText + text
            }
            let range = document.createRange();
            const sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                range = sel.getRangeAt(0);
                range.deleteContents();

                let textNode = document.createTextNode(text);
                range.insertNode(textNode);
                // Preserve the selection
                range = range.cloneRange();
                range.setStartAfter(textNode);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
            }
            setComment(currentText)
            return;
        }
        let cbPayload = [...(e.clipboardData || e.originalEvent.clipboardData).items];     // Capture the ClipboardEvent's eventData payload as an array
        cbPayload = cbPayload.filter(i => /image/.test(i.type));                       // Strip out the non-image bits
        if (!cbPayload.length || cbPayload.length === 0) return false;
        const newFile = cbPayload[0].getAsFile()
        // If no image was present in the collection, bail.
        getBase64(newFile).then(url => {
            newFile.typeFile = "image"
            newFile.url = url
            return setFileList([newFile].concat(fileList))
        });
    }

    return <div className={"comment-footer"} ref={ref} style={expanded ? {bottom: 18} : {}}>
        < div className={"content"}>
            <div className={"file"}>
                <Upload
                    className={"_upload upload"}
                    itemRender={() => null}
                    multiple={true}
                    beforeUpload={beforeUpload}
                    customRequest={() => {}}
                    onKeyPress={onKeyPress}
                    onChange={onChangeUpload}
                    disabled={disableInput}
                >
                    <i className="fal fa-paperclip"/>
                </Upload>

            </div>
            <div className={"content-input"}>
                <div className={"wrapper-input"} ref={wrapperInputRef}
                     id={"input"}
                     onClick={() => refInput?.current?.focus()}
                     style={expanded ? {height: height - 6} : {maxHeight: 60}}>
                    {
                        images.length > 0 && <div className={"image-list"}>
                            {fileList.map((file, index) => file.typeFile === "image" &&
                                <div key={file.uid + "-" + index} className={"image-preview"}>
                                    <img src={file.url} alt="" className={"img"}/>
                                    <div className={"blur-bg"}  onClick={() => {
                                        setPreviewImage(file)
                                    }}/>
                                    <i className="fal fa-times-circle close-icon"
                                       onClick={() => removeFileItem(index)}/>
                                </div>)}
                        </div>
                    }
                    {files.length > 0 && <div className={"file-list"}>
                        {fileList.map(((file, index) => file.typeFile === "file" &&
                            <div className={"file-preview"} key={file.uid + "-" + index}>
                                <a className={"file-name"} href={file.url} download={file.name}>
                                    {file.name}
                                </a>
                                <i className="fal fa-times-circle close-icon" onClick={() => removeFileItem(index)}/>
                            </div>))}
                    </div>}
                    <div contentEditable={!disableInput ? "true" : "false"} className={"input"}
                         ref={refInput}
                         onPaste={onPaste}
                         onDoubleClick={() => setTimeout(() => {
                             window.getSelection().getRangeAt(0)
                         }, 500)}
                         placeholder={`${checkKeyboardMap(shortcut)} để trao đổi`}
                         onKeyPress={onKeyPress}
                        // dangerouslySetInnerHTML={{__html: comment}}
                         onInput={(e) => {
                             setComment(e?.target?.innerHTML)
                             if (wrapperInputRef?.current?.clientHeight !== heightInput) {
                                 setHeightInput(wrapperInputRef?.current?.clientHeight)
                             }

                         }}>
                        {/*<span/>*/}
                    </div>


                </div>
                {
                    showExpandIcon ?
                        <div className={"comment-input-icon-expand"} style={expanded ? {bottom: 14} : {bottom: 4}}>
                            <i className="fal fa-expand-alt icon-expanded" onClick={() => {
                                setExpanded(!expanded)
                            }}/>
                            <i className="fas fa-location-arrow icon" onClick={() => !loading && onSubmit()}/>
                        </div> :
                        <div className={"comment-input-icon "}>
                            <i className="fas fa-location-arrow icon" onClick={() => !loading && onSubmit()}/>
                        </div>
                }

                {/*<div className={"warning"} ref={refWarning}>*/}
                {/*    Dung lượng tải tệp tối đa 10Mb*/}
                {/*</div>*/}
            </div>
        </div>

        <ModalCommon
            open={!!previewImage}
            title={previewImage?.name}
            footer={null}
            onCancel={() => setPreviewImage(undefined)}
        >
            <img alt="example" style={{ width: '100%' }} src={previewImage?.url} />
        </ModalCommon>
    </div>
}
export default CommentFooter
