import { useEffect, useState } from 'react'
import { IFinancialType } from 'src/interface/FinancialType'
import ClaimApi from 'src/repository/ticket'
import { useProjects } from './useProjects'

export const useFinanceType = (subject: string) => {
    const { currentProjectId } = useProjects()
    const [financeTypes, setFinanceTypes] = useState<IFinancialType[]>([])
    const [financeTypeNameMapping, setFinanceTypeNameMapping] = useState<{ [key in string]: string }>({})

    const fetchFinanceTypes = () => {
        const query = {
            offset: 0,
            limit: 1000,
            sort: 'position:asc',
            project: currentProjectId,
            subject,
        }

        ClaimApi.getFinanceTypesNotPermission(query).then((r) => {
            const nameMapping: { [key in string]: string } = {}

            r.data.forEach((financeType: IFinancialType) => {
                nameMapping[financeType.id] = financeType.name
            })

            setFinanceTypes(r.data)
            setFinanceTypeNameMapping(nameMapping)
        })
    }

    useEffect(() => {
        if (!subject) return 
        fetchFinanceTypes()
    }, [subject])

    return { financeTypes, financeTypeNameMapping }
}
