import image403 from "../../../resources/images/403.png";
import {useHistory} from "react-router";
import lang from "../../../resources/localization/Localization";

const Component403 = () => {
    const history = useHistory()
    return <div className={""}>
        <div className={"flex flex-row justify-center"}>
            <img src={image403} alt={"403"}/>
        </div>
        <div className={"flex flex-row justify-center mgt-45"}>
            <div className={"robotomedium text-36"}>
                ERROR 403!
            </div>
        </div>
        <div className={"flex flex-row justify-center mgt-4"}>
            <div className={"text-14 txt-color-black3"}>
                {lang.login.notPermission}
            </div>
        </div>
        <div className={"flex flex-row justify-center align-items-center mgt-40"}>
            <div className={"text-14 txt-color-black3 "}>
                <span>{lang.login.contact_hotline_and_try_again} <span><a href={"/"}>{lang.login.home_page}</a></span></span>
            </div>
        </div>
    </div>
}
export default Component403
