import storageUtil from "./LocalStore/LocalStores";
import lodash from "lodash";

/**
 * Parse an object to http_query_string
 * @param obj
 * @returns {string}
 */
export function httpBuildQueryString(obj) {
    let keys = Object.keys(obj);
    let queryArray = [];
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let value = obj[key];
        queryArray.push(`${key}=${value}`);
    }

    return queryArray.join('&');
}

/**
 * Kiểm tra hiển thị đánh dấu ticket|claim
 * @param item
 * @return boolean
 */
export const isUpdateTicket = (item) => {
    if (item.lastView !== null) {
        let lastView = new Date(item.lastView);
        let lastActivity = new Date(item.lastActivity);
        if (lastActivity > lastView) {
            return true
        }
        return false
    }
    return false
};

export const checkPermission = (permissions) => {
    let permissionToCheck = [];

    if (!Array.isArray(permissions)) {
        if (typeof permissions === 'object') {
            for (let key in permissions) {
                if (permissions.hasOwnProperty(key))
                    permissionToCheck.push(permissions[key]);
            }
        } else {
            permissionToCheck = [permissions];
        }
    } else {
        permissionToCheck = permissions;
    }
    let currentPermission = storageUtil.getJson("permissions") || [];

    if (Array.isArray(currentPermission)) {
        for (let permission of permissionToCheck) {
            if (currentPermission.indexOf(permission) >= 0) {
                return true;
            }
        }
    }

    return false;
}

export const renderStateDotColor = (state) => {
    switch (state) {
        case "PROCESSING" :
            return "bgprocessing";
        case "CREATED" :
            return "bgcreated";
        case "WAITING":
            return "bgwaiting";
        case "RECEIVE":
            return "bgreceive";
        case "COMPLAINT_COMMENT":
            return "bgcomplaintcomment";
        case "ADD_TRANSACTION":
            return "bgaddtransaction";
        case "ORIGIN_CODE":
            return "bgorigincode";
        case "RENEW":
            return "bgrenew";
        case "UPDATE_REASON":
            return "bgupdatereason";
        case "UPDATE_NOTE":
            return "bgupdatenote";
        case "DELETE_TRANSACTION":
            return "bgdeletetransaction";
        case "FAILURE" :
            return "bgfailure"
        case "SUCCESS" :
            return "bgsuccess";
        case "NEW" :
            return "bgnew";

        case "ACCEPTED" :
            return "bgaccepted";
        case "REJECT" :
            return "bgreject";
        case "REFUND" :
            return "bgrefund";

        default :
            return "#333"
    }
}

export function handleStatusHttps(error) {
    const status = error?.response?.status
    let next = false;
    switch (status) {
        case 401:
            error.message = "Phiên làm việc hết hạn. Vui lòng làm mới trình duyệt"
            break;

        case 403:
            error.message = "Bạn không có quyền thực hiện thao tác này"
            break;
        case 404:
            // error.message = "Api không tồn tại!";
            break;
        case 500:
            error.message = "Máy chủ gặp sự cố. Vui lòng thử lại sau"
            break;
        case undefined:
            // error.message = "Không có internet"
            break;
        default :
            break;
    }
    return error

}
