import { Button, Checkbox, DatePicker, Divider, Drawer, DrawerProps, Input, InputNumber, Radio, Rate, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useWindowDimension } from 'src/hooks/useWindowDimension';
import { ClaimCriteriaExtended, IClaimCriteria } from 'src/interface/ClaimInterface';
import { CLAIM_STATES, CURRENCY_TYPES, RATING_DATA } from '../ClaimUtils';
import FloatLabel from 'src/components/v2/CustomInput/FloatingLabel';
import lang from 'src/resources/localization/Localization';
import moment from 'moment';
import { useQueries } from '@tanstack/react-query';
import storageUtil from 'src/utils/LocalStore/LocalStores';
import ClaimApi from 'src/repository/ticket';
import lodash from 'lodash';
import { useHistory, useLocation } from 'react-router';
import Utils from 'src/utils';

const beginDateFormat: string = 'YYYY-MM-DD 00:00:00';
const endDateFormat: string = 'YYYY-MM-DD 23:59:59';
interface AdvancedFilterProps extends DrawerProps {
  handleDisplayAdvancedFilter: (visible: any) => void
}

function AdvancedFilter(props: AdvancedFilterProps) {
  const location = useLocation();
  const history = useHistory();
  const { width } = useWindowDimension();
  const [advancedFilter, setAdvancedFilter] = useState<ClaimCriteriaExtended>({});
  const {
    handleDisplayAdvancedFilter
  } = props;

  useEffect(() => {
    let searchParams = Utils.getQueryFromLocation(location);
    setAdvancedFilter({
      ...searchParams
    })
  }, [location])

  const advancedSearchQueries = useQueries({
    queries: [
      {
        queryKey: ['fetch-reasons', { project: storageUtil.getItem('selectedProject') }],
        queryFn: async ({ queryKey }: any) => {
          const response = await ClaimApi.getReasons(queryKey[1]);
          return response.data
        },
        // enabled: true,
        refetchOnWindowFocus: false
      },
      {
        queryKey: ['fetch-solutions', {
          ticketTypes: ['order', 'shipment', 'peerpayment'].toString(),
          project: storageUtil.getItem('selectedProject')
        }],
        queryFn: async ({ queryKey }: any) => {
          const response = await ClaimApi.getSolution(queryKey[1]);
          return response.data
        },
        // enabled: true,
        refetchOnWindowFocus: false
      },
      {
        queryKey: ['fetch-mistakes'],
        queryFn: async () => {
          const response = await ClaimApi.getMistakes({
            limit: 200,
            offset: 0,
            sort: 'code:asc'
          });
          return response.data
        },
        refetchOnWindowFocus: false
      },
      {
        queryKey: ['fetch-subject-types'],
        queryFn: async () => {
          const response = await ClaimApi.getClaimType(storageUtil.getItem('selectedProject'));
          return response.data
        },
        refetchOnWindowFocus: false
      },
      {
        queryKey: ['fetch-supply-sources'],
        queryFn: async () => {
          const response = await ClaimApi.getSupplySources({
            sort: 'id:asc',
            limit: 200,
            offset: 0,
            project: storageUtil.getItem('selectedProject')
          });
          return response.data
        },
        refetchOnWindowFocus: false
      }
    ].map(({ queryKey, queryFn, refetchOnWindowFocus }) => {
      return {
        queryKey,
        queryFn,
        // enabled,
        refetchOnWindowFocus
      }
    })
  })

  const handleSelectStateTag = (value: string) => {
    let temp = { ...advancedFilter };
    temp['state'] = value;
    setAdvancedFilter(temp)
  }

  const handleChangeSelectValue = (criteria: string, value: any) => {
    let temp: any = { ...advancedFilter };
    temp[criteria] = value ? value : undefined;

    if (criteria === 'stateFrom') {
      temp['stateFrom'] = moment(value).startOf('day').toISOString()
    }

    if (criteria === 'stateTo') {
      temp['stateTo'] = moment(value).endOf('day').toISOString()
    }

    if (criteria === 'supplySourceCode' || criteria === 'ticketType') {
      let newValue: any = [...value];
      temp[criteria] = value ? newValue.toString() : undefined;
    }

    // console.log(temp)
    setAdvancedFilter(temp)
  }

  const handleChangeClaimMoney = (value: any) => {
    let temp: any = { ...advancedFilter };
    temp['claimMoney'] = value;
    if (value === 'totalSuggest') {
      delete temp.totalDamageFrom
      delete temp.totalDamageTo
      delete temp.totalRefundFrom
      delete temp.totalRefundTo
    }
    if (value === 'totalDamage') {
      delete temp.totalSuggestFrom
      delete temp.totalSuggestTo
      delete temp.totalRefundFrom
      delete temp.totalRefundTo
    }
    if (value === 'totalRefund') {
      delete temp.totalSuggestFrom
      delete temp.totalSuggestTo
      delete temp.totalDamageFrom
      delete temp.totalDamageTo
    }
    // console.log(temp)
    setAdvancedFilter(temp)
  }

  const handleChangeInputClaimMoney = (value: any, position: any) => {
    let temp: any = { ...advancedFilter };
    if (position === 'from') {
      temp[`${temp.claimMoney}From`] = value;
    }
    else if (position === 'to') {
      temp[`${temp.claimMoney}To`] = value;
    }
    setAdvancedFilter(temp)
  }

  const handleChangeInputValue = (criteria: string, value: any) => {
    let temp: any = { ...advancedFilter };
    temp[criteria] = value;
    setAdvancedFilter(temp)
  }

  const handleSubmit = () => {
    // if(Object.values(advancedFilter).every((x) => !x )){
    Utils.pushCleanQueryToHistory(history, location, {
      ...advancedFilter,
      page: undefined
    })
    handleDisplayAdvancedFilter(false)
    // }
  }

  const handleClearFilter = () => {
    let searchParams = Utils.getQueryFromLocation(location);
    setAdvancedFilter({});
    Utils.pushCleanQueryToHistory(history, location, {
      stateUnchanged: searchParams.stateUnchanged
    })
  }

  const claimMoneyValueMemo = useMemo(() => {
    let temp = { ...advancedFilter };
    let fromValue: any = temp.claimMoney ? `${temp.claimMoney}From` : ''
    let toValue: any = temp.claimMoney ? `${temp.claimMoney}To` : ''
    return {
      fromValue: fromValue || '',
      toValue: toValue || ''
    }
  }, [advancedFilter])

  const allowFilterMemo = useMemo(() => {
    let allowSubmit: boolean = false;
    let temp = { ...advancedFilter };
    let searchParams = { ...Utils.getQueryFromLocation(location) }
    delete temp.orderBy;
    delete temp.page;
    delete temp.size;
    delete temp.claimStatus;
    if (!lodash.isEmpty(temp)) {
      if (temp.claimMoney && (!temp[claimMoneyValueMemo.fromValue]) && (!temp[claimMoneyValueMemo.toValue])) {
        allowSubmit = Object.values(temp).every((x) => !x)
        if (!allowSubmit) {
          allowSubmit = Object.values(searchParams).every((x) => !x)
        }
      }
      else if ((temp.state && !temp.stateFrom) && temp.state) {
        allowSubmit = false

      }
      else {
        allowSubmit = !Object.values(temp).every((x) => !x)
        if (!allowSubmit) {
          allowSubmit = !Object.values(searchParams).every((x) => !x)
        }
      }
    }
    // else {
    // allowSubmit = Object.values(temp).every((x) => !x)
    // }
    // console.log(temp)
    return allowSubmit
  }, [advancedFilter])

  const {
    state, stateFrom, stateTo, code, customer, assignee, refunder, merchant,
    archived, hasCollect, damage, inspectionService, complaintState, rating,
    mistake, supplySourceCode, ticketType, solutionCode, reasonCode, claimMoney,
    stateUnchanged
  } = advancedFilter

  return (
    <Drawer
      {...props}
      title={'Bộ lọc nâng cao'}
      width={width > 1024 ? 'calc(35vw)' : undefined}
      closeIcon={<i className="fa-solid fa-xmark"></i>}
      footer={<div className='flex justify-end'>
        {
          (allowFilterMemo || advancedFilter.state) && <Button type='link' className='mr-2 text-xs hover:text-primary-400'
            icon={<i className="fa-regular fa-arrow-rotate-left mr-2"></i>}
            onClick={handleClearFilter}
          >
            Làm mới bộ lọc
          </Button>
        }

        <Button
          type='primary'
          icon={<i className="fa-solid fa-magnifying-glass mr-2"></i>}
          onClick={handleSubmit}
          disabled={!allowFilterMemo || Object.values(advancedFilter).every((x) => !x)}
        >
          Tìm kiếm
        </Button>
      </div>}
    >
      <div>
        {
          !stateUnchanged && <>
            <div className='_state-select flex items-center flex-wrap gap-3 mb-3'>
              {CLAIM_STATES.map((s: any, index) => <span key={index}
                className={`claim-state-tag-filter ${s.value === advancedFilter?.state ? 'claim-state-tag-filter-selected' : ''} `}
                onClick={() => handleSelectStateTag(s.value)}
              >{s.label}</span>)}
            </div>
            <div className='_state-time-select mb-3'>
              <Input.Group compact className='w-full'>
                <FloatLabel label={lang.list_ticket.from} value={stateFrom} className='w-1/2 !mb-0'>
                  <DatePicker
                    value={stateFrom ? moment(stateFrom) : undefined}
                    onChange={(value) => handleChangeSelectValue('stateFrom', value)}
                    className='w-full _stateFrom-input'
                    placeholder=''
                    size='large'
                    disabled={!state}
                    format={'DD-MM-YYYY'}
                    allowClear
                    suffixIcon={<i className="fas fa-calendar-alt"></i>}
                  />
                </FloatLabel>
                <FloatLabel label={lang.list_ticket.to} value={stateTo} className='w-1/2 !mb-0'>
                  <DatePicker
                    value={stateTo ? moment(stateTo) : undefined}
                    onChange={(value) => handleChangeSelectValue('stateTo', value)}
                    className='w-full _stateTo-input'
                    placeholder=''
                    size='large'
                    disabled={!state}
                    format={'DD-MM-YYYY'}
                    disabledDate={(value) => value < moment(advancedFilter?.stateFrom)}
                    allowClear
                    suffixIcon={<i className="fas fa-calendar-alt"></i>}
                  />
                </FloatLabel>
              </Input.Group>
            </div>
          </>
        }

        <div>
          <FloatLabel label={lang.list_ticket.code_input_placeholder} value={code}>
            <Input size='large' className='_code-input !h-[40px] !leading-10'
              value={code}
              onChange={(event) => handleChangeInputValue('code', event?.target.value)} allowClear
              onPressEnter={() => allowFilterMemo && handleSubmit()}
            />
          </FloatLabel>
          <FloatLabel label={lang.list_claim.search_customer} value={customer}>
            <Input size='large' className='_customer-input !h-[40px] !leading-10'
              value={customer}
              onChange={(event) => handleChangeInputValue('customer', event?.target.value)} allowClear
              onPressEnter={() => allowFilterMemo && handleSubmit()}
            />
          </FloatLabel>

          {stateUnchanged !== 'NEW' && <>
            <FloatLabel label={lang.list_claim.search_receivedStaff} value={assignee}>
              <Input size='large' className='_assignee-input !h-[40px] !leading-10'
                value={assignee}
                onChange={(event) => handleChangeInputValue('assignee', event?.target.value)} allowClear
                onPressEnter={() => allowFilterMemo && handleSubmit()}
              />
            </FloatLabel>
          </>}

          <FloatLabel label={lang.list_ticket.shope_name} value={merchant}>
            <Input size='large' className='_merchant-input !h-[40px] !leading-10'
              value={merchant}
              onChange={(event) => handleChangeInputValue('merchant', event?.target.value)} allowClear
              onPressEnter={() => allowFilterMemo && handleSubmit()}
            />
          </FloatLabel>
          <FloatLabel label={lang.list_ticket.refunder_placeholder} value={refunder}>
            <Input size='large' className='_refunder-input !h-[40px] !leading-10'
              value={refunder}
              onChange={(event) => handleChangeInputValue('refunder', event?.target.value)} allowClear
              onPressEnter={() => allowFilterMemo && handleSubmit()}
            />
          </FloatLabel>
        </div>
        <div className='grid grid-cols-5 gap-2'>
          <FloatLabel label={lang.type_currency_selector.select} value={claimMoney}
            className='col-span-2'>
            <Select size='large' options={CURRENCY_TYPES} className='w-full _currency-type-select'
              onChange={(value) => handleChangeClaimMoney(value)}
              value={claimMoney}
              allowClear
              suffixIcon={<i className="fal fa-angle-down"></i>}
            />
          </FloatLabel>
          <Input.Group className='w-full col-span-3 inline-flex flex-no-wrap'>
            <FloatLabel label={'Từ'} value={advancedFilter[claimMoneyValueMemo.fromValue]}>
              <InputNumber size='large' controls={false} className='w-full _currency-input-from'
                disabled={!claimMoney}
                onChange={(value) => handleChangeInputClaimMoney(value, 'from')}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                value={advancedFilter[claimMoneyValueMemo.fromValue]}
                onPressEnter={() => allowFilterMemo && handleSubmit()}
              />
            </FloatLabel>
            <FloatLabel label={'Đến'} value={advancedFilter[claimMoneyValueMemo.toValue]}>
              <InputNumber size='large' controls={false} className='w-full _currency-input-to'
                disabled={!claimMoney}
                onChange={(value) => handleChangeInputClaimMoney(value, 'to')}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                value={advancedFilter[claimMoneyValueMemo.toValue]}
                onPressEnter={() => allowFilterMemo && handleSubmit()}
              />
            </FloatLabel>
          </Input.Group>
        </div>
        <div>
          <FloatLabel label={lang.ticket_add.claim_reason} value={reasonCode}>
            <Select size={'large'} className='w-full'
              value={reasonCode}
              options={advancedSearchQueries[0].data?.map((reason: any) => ({ id: reason.id, label: reason.name, value: reason.code }))}
              onChange={(value) => handleChangeSelectValue('reasonCode', value)}
              allowClear
              suffixIcon={<i className="fal fa-angle-down"></i>}
            />
          </FloatLabel>
          <FloatLabel label={lang.ticket_add.solutions} value={solutionCode}>
            <Select size={'large'} className='w-full'
              value={solutionCode}
              options={lodash.uniqBy(advancedSearchQueries[1].data, 'code').map((solution: any) => ({ label: solution.name, value: solution.code }))}
              onChange={(value) => handleChangeSelectValue('solutionCode', value)}
              allowClear
              suffixIcon={<i className="fal fa-angle-down"></i>}
            />
          </FloatLabel>
        </div>
        <Divider />
        <div className='flex flex-col gap-3'>
          <span className='text-xs font-medium'>{lang.mistake_selector.select}</span>
          <Radio.Group
            value={mistake}
            className='flex flex-wrap gap-2'
            options={advancedSearchQueries[2].data?.map((mistake: any) => ({ id: mistake.id, label: mistake.name, value: mistake.code }))}
            onChange={(event) => handleChangeSelectValue('mistake', event.target.value)}
          />
        </div>
      </div>
      <Divider />

      {
        stateUnchanged !== 'NEW' && <>
          <div className='flex flex-col justify-center gap-3'>
            <span className='text-xs font-medium'>{lang.rating.rating}</span>
            <Radio.Group
              className='grid grid-cols-2'
              value={(+rating) || ''}
              options={RATING_DATA.map((rating: any) => ({
                ...rating,
                label: <div key={rating.value}>
                  {rating.value && <Rate key={rating.key} defaultValue={rating.value / 2} disabled />}
                  <span className={rating.value ? 'ml-2' : ''}>{rating.name}</span>
                </div>,
              }))}
              onChange={(event) => handleChangeSelectValue('rating', event?.target.value)}
            />
          </div>
          <Divider />
        </>
      }

      <div className='flex flex-col justify-center gap-3'>
        <span className='text-xs font-medium'>{lang.list_ticket.ticket_type}</span>
        <Checkbox.Group
          value={ticketType ? ticketType?.split(',') : ticketType}
          onChange={(value) => handleChangeSelectValue('ticketType', value)}
          options={advancedSearchQueries[3].data?.map((type: any) => ({ id: type.id, label: type.name, value: type.code }))}
        />
      </div>
      <Divider />
      <div className='flex flex-col justify-center gap-3'>
        <span className='text-xs font-medium'>{lang.list_claim.source_good}</span>
        <Checkbox.Group
          value={supplySourceCode ? supplySourceCode.split(',') : supplySourceCode}
          onChange={(value) => handleChangeSelectValue('supplySourceCode', value)}
          options={advancedSearchQueries[4].data?.map((source: any) => ({ id: source.id, label: <span className='capitalize'>{source.name}</span>, value: source.code }))}
        />
      </div>
      <Divider />
      <div className='flex flex-col justify-center gap-3'>
        <span className='text-xs font-medium'>{lang.list_ticket.finance_damaged}</span>
        <Radio.Group
          value={damage || ''}
          onChange={(event) => handleChangeSelectValue('damage', event.target.value)}
          options={[
            { label: lang.list_ticket.all, value: '' },
            { label: `${lang.conclusion.yes}`, value: 'true' },
            { label: `${lang.conclusion.no}`, value: 'false' },
          ]}
        />
      </div>
      <Divider />
      <div className='flex flex-col justify-center gap-3'>
        <span className='text-xs font-medium'>{lang.claim_detail.order_services}</span>
        <Radio.Group
          value={inspectionService || ''}
          onChange={(event) => handleChangeSelectValue('inspectionService', event.target.value)}
          options={[{ label: lang.list_ticket.all, value: '' }, { label: lang.list_claim.inspection, value: 'true' }, { label: lang.list_claim.uninspected, value: 'false' }]}
        />
      </div>
      <Divider />
      <div className='flex flex-col justify-center gap-3'>
        <span className='text-xs font-medium'>{lang.merchants.title}</span>
        <Radio.Group
          value={complaintState || ''}
          onChange={(event) => handleChangeSelectValue('complaintState', event.target.value)}
          options={[{ label: lang.list_ticket.all, value: '' }, { label: lang.merchant_state.SUCCEED, value: 'true' }, { label: lang.merchant_state.FAILED, value: 'false' }]}
        />
      </div>
      <Divider />
      <div className='flex flex-col justify-center gap-3'>
        <span className='text-xs font-medium'>Alipay</span>
        <Checkbox
          checked={hasCollect}
          onChange={(event) => handleChangeSelectValue('hasCollect', event.target.checked)}>{lang.list_ticket.alipay_status_done}</Checkbox>
      </div>
      <Divider />
      <div className='flex flex-col justify-center gap-3'>
        <span className='text-xs font-medium'>{lang.merchants.status}</span>
        <Checkbox
          checked={archived}
          onChange={(event) => handleChangeSelectValue('archived', event.target.checked)}
        >{lang.state_ticket.CLOSE}</Checkbox>
      </div>
    </Drawer>
  )
}

export default AdvancedFilter;