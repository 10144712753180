import { TClaimProductViewExtended } from "src/interface/Ticket"
import Utils from "src/utils"
import { currencyUnitFormat } from "src/utils/currency"
import { formatterNumber, formatterNumberUsingComma, quantityFormat } from "src/utils/stringUtils"

type Props = {
  item: TClaimProductViewExtended
  renderQuantityLabelCondition: React.ReactNode | string
  getSkuQuantity: (...arg: any) => any
}

export const ClaimProductItem: React.FC<Props> = ({ item, renderQuantityLabelCondition, getSkuQuantity }) => {
  const skusTemp = item?.skus

  return <div className="grid grid-cols-5 gap-0 col-span-5 mt-3 lg:!mt-4">
    <div className="col-span-5 flex">
      <img src={item?.productImage} alt={item?.name} className="h-16 w-16 rounded-lg object-cover" />
      <p className="px-3 pb-3 lg:!p-3 text-woodsmoke-950 text-sm leading-5 flex items-center">
        {item?.name}
      </p>
    </div>
    {/* <div className="max-h-96 overflow-auto col-span-5"> */}
    {
      skusTemp && Array.isArray(skusTemp) && skusTemp.map((sku, idx, arr) => {
        const variants = sku.dataSnapshot?.variantProperties
        const currency = sku.dataSnapshot.currency

        return (<div key={sku.id}
          className={`col-span-5 lg:mt-4 grid grid-cols-1 lg:grid-cols-5 bg-windsand-50 lg:bg-white rounded backdrop-opacity-75 gap-1 lg:gap-0 transition-all duration-200 ease-out mb-3 lg:!mb-0`}>
          <div className="col-span-1 lg:col-span-2 flex items-start lg:items-center p-2 lg:!p-0">
            <span className="px-1.5 text-sm text-woodsmoke-950 lg:!block hidden w-8 text-center">{idx + 1}</span>
            <img src={sku.imageUrl || '/imgs/default-sku-img.png'} className="w-8 h-8 object-cover rounded-lg mr-2" />
            <p className="flex flex-col gap-2 lg:px-2 lg:py-1.5 bg-windsand-50 rounded-[4px] ml-2 lg:!ml-0 flex-1">
              {
                variants && variants.map((v) => <span className="leading-[18px] text-xs">
                  <span className="text-dovegray-500">{v.name}: </span>
                  <span>{v.value}</span>
                </span>)
              }
            </p>
          </div>
          <div className="lg:col-span-1 text-right self-center flex w-full  px-2 lg:px-0">
            <p className="text-sm text-left text-chicago-600 lg:text-woodsmoke-950 leading-[22px] lg:hidden w-1/2 font-normal">Đơn giá</p>
            <p className="w-1/2 lg:w-full text-start lg:text-right text-woodsmoke-950 leading-5 lg:leading-none">{currencyUnitFormat(Utils.currencyFormat(sku.dataSnapshot?.exchangedActualPrice), currency)}</p>
            {/* {sku.dataSnapshot?.exchangedSalePrice} */}
          </div>
          <div className="col-span-1 text-center self-center text-redribbon-600 px-2 lg:px-0 flex w-full">
            <p className="text-sm text-left text-chicago-600 lg:text-woodsmoke-950 leading-[22px] lg:hidden w-1/2 font-normal">{renderQuantityLabelCondition}:</p>
            <p className="w-1/2 lg:w-full text-start lg:text-center leading-5 lg:leading-none">{formatterNumberUsingComma(getSkuQuantity(sku))}</p>
          </div>
          <div className="col-span-1 text-right self-center text-redribbon-600 px-2 lg:px-0 flex w-full pb-2 lg:pb-0">
            {/* <p className="text-sm text-left text-chicago-600 lg:text-woodsmoke-950 leading-[22px] lg:hiddexn w-1/2 font-normal">Tổng giá trị:</p> */}
            <p className="w-1/2 lg:w-full text-start lg:text-right leading-5 lg:leading-none">{currencyUnitFormat(Utils.currencyFormat(sku.dataSnapshot?.exchangedActualPrice * getSkuQuantity(sku)), currency)}</p>
          </div>
        </div>
        )
      })
    }
    {/* </div> */}
    {/* {
      skusTemp.length > 3 && <div
        className={`cursor-pointer text-sm text-primary col-span-full lg:col-span-2 text-center lg:!text-right lg:mt-2`}
        onClick={() => { setShowAllSku(!showAllSku) }}>
        {
          showAllSku ? <span>Thu gọn</span> : <span>Xem thêm</span>
        }
      </div>
    } */}
  </div>
}