import MainLayout from '../../../components/v2/Layout/MainLayout'
import DefaultComponent from '../../../components/v2/DefaultComponent'
import Informations from './Informations'
import { notification, Spin, Tabs } from 'antd'
import AlipayTransactions from './AlipayTransactions'
import RefundList from './RefundList'
import ReasonComplaint from './ReasonComplaint'
import Conclusion from './Conclusion'
import ClaimApi from '../../../repository/ticket'
import Financial from './Financial'
import { TICKETS_SCOPES } from '../../../utils/api/Scopes'
import { checkPermission } from '../../../utils/helper'
import lodash, { get } from 'lodash'
import ModalConfirm from '../../../components/v2/Modal/Confirm'
import storageUtil from '../../../utils/LocalStore/LocalStores'
import Component403 from '../../../components/v2/Error/403'
import Component404 from '../../../components/v2/Error/404'
import Footer from '../../../components/v2/Layout/Footer'
import ChangeReasonComplaint from '../../../components/v2/Modal/ChangeReasonComplaint'
import TenantApi from '../../../repository/tenant'
import lang, { trans } from '../../../resources/localization/Localization'
import { localStorageKeys } from '../../../constants/keyName'
import { FinanceOrderTypes } from 'src/interface/Orders'
import { ComplaintTabs } from './ComplaintTabs'
import { ClaimDetailContext } from './context'
import Comments from './Comment'
import Utils from '../../../utils'
import numeral from 'numeral'
class DetailTicket extends DefaultComponent {
    project: string | null = ''
    constructor(props: any) {
        super(props)
        this.state = {
            errorCode: null,
            initialLoading: true,
            loading: false,
            detail: {},
            order: {},
            isShowConfirmCloseTicket: false,
            currentLoggedUser: {},
            isShowConfirmUnAssign: false,
            alipayData: [],
            alipayLoading: false,
            isModalEditReasonComplaint: false,
            loadingEditReasonComplaint: false,
            tenants: [],
            claimTenant: '',
        }

        const queryParams = Utils.getQueryFromLocation(this.props.location)
        this.project = queryParams.project || storageUtil.getItem('selectedProject')
    }

    componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(prevProps: any) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search) ||
            JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.loadData()
        }
    }

    loadData = () => {
        this.loadDetail()
        this.loadTenants()

        if (checkPermission(TICKETS_SCOPES.TICKETS_SIMILAR_VIEW_ALL)) {
            this.loadDataRelative({})
        }
        const currentLoggedUser = storageUtil.getJson(localStorageKeys.currentUser)
        this.setState({ currentLoggedUser })
    }

    loadTenants = () => {
        TenantApi.getCurrent()
            .then((r) => {
                this.setState({ tenants: r.data })
            })
            .catch(() => {
                notification.error({ message: lang.error.get_tenant_failed })
            })
    }

    loadDetail = () => {
        this.setState({ loading: true })
        const params = {
            project: this.project,
        }

        ClaimApi.getTicketDetail(this.getCode(), params)
            .then((res) => {
                // 1 số thông tin lấy từ hệ thống khác, ticketType có thể dạng uppercase, hệ thống m3 là lowerCase
                res.data.ticketType = res.data.ticketType?.toLowerCase()

                if (res.data.ticketType === 'order') {
                    if (checkPermission(TICKETS_SCOPES.TICKETS_SIMILAR_VIEW_ALL)) {
                        this.loadDataComplaintSeller({})
                    }
                }
                this.setState({ detail: res.data, errorCode: null, claimTenant: res.data.tenant })
                if (res.data.ticketType === 'peerpayment') {
                    this.loadPerPayment()
                } else {
                    this.loadOrder()
                }
            })
            .catch((err) => {
                if (err?.response?.status) {
                    this.setState({ errorCode: err?.response?.status })
                } else {
                    this.setState({ errorCode: 502 })
                }
            })
            .finally(() => {
                this.setState({ initialLoading: false, loading: false })
            })
    }

    loadOrder = () => {
        this.setState({ loading: true })
        ClaimApi.getOrder(this.getCode(), { project: this.project })
            .then((res) => {
                this.setState({ order: res.data })
            })
            .catch((err) => {
                // if (err?.response?.status) {
                //     this.setState({ errorCode: err?.response?.status })
                // } else {
                //     this.setState({ errorCode: 502 })
                // }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    loadPerPayment = () => {
        this.setState({ loading: true })
        ClaimApi.getPerPayment(this.getCode())
            .then((res) => {
                this.setState({ order: res.data })
            })
            .catch(() => { })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    loadDataComplaintSeller = ({ page = 0, size = 5 }) => {
        const filter = {
            offset: page ? (Number(page) - 1) * Number(size) : 0,
            limit: size ? Number(size) : 10,
            sort: 'createdAt:desc',
            project: this.project,
        }
        ClaimApi.getRelativeComplaintSeller(this.getCode(), filter)
            .then((res) => {
                this.setState({
                    dataComplaintSeller: res.data,
                    paginationComplaintSeller: {
                        currentPage: Number(res.headers['x-page-number']),
                        total: Number(res.headers['x-total-count']),
                        pageSize: Number(res.headers['x-page-size']),
                    },
                })
            })
            .catch((err) => {
                // if (err?.response?.status) {
                //     this.setState({ errorCode: err?.response?.status })
                // } else {
                //     this.setState({ errorCode: 502 })
                // }
            })
    }

    loadDataRelative = ({ page = 0, size = 5 }) => {
        const filter = {
            offset: page ? (Number(page) - 1) * Number(size) : 0,
            limit: size ? Number(size) : 10,
            sort: 'createdAt:desc',
            project: this.project,
        }
        ClaimApi.getRelative(this.getCode(), filter)
            .then((res) => {
                this.setState({
                    dataComplaintService: res.data,
                    paginationComplaintService: {
                        currentPage: Number(res.headers['x-page-number']),
                        total: Number(res.headers['x-total-count']),
                        pageSize: Number(res.headers['x-page-size']),
                    },
                })
            })
            .catch((err) => {
                // if (err?.response?.status) {
                //     this.setState({ errorCode: err?.response?.status })
                // } else {
                //     this.setState({ errorCode: 502 })
                // }
            })
    }

    getCode = () => {
        return this.props.match.params.id
    }

    onChangeTab = (tab: string) => {
        if (tab === 'complaintSeller') {
            this.loadDataComplaintSeller({})
        } else {
            this.loadDataRelative({})
        }
    }

    updateTicket = (data: any, cb: () => void) => {
        this.setState({ loading: true })
        data = lodash.pickBy(data, lodash.identity)

        ClaimApi.updateTicket({ ...data, project: this.project }, this.getCode())
            .then(() => {
                notification.success({ message: lang.message.update_success })
                this.setState({ isShowConfirmCloseTicket: false })
                this.loadDetail()
                if (cb) cb()
            })
            .catch((err) => {
                if (err?.response?.status === 400) {
                    const title = get(err, 'response.data.title');
                    const detail = get(err, 'response.data.detail')
                    if (title === 'incorrect_password') {
                        notification.error({ message: lang.error.incorrect_password })
                    } else if (title === 'conclusion_required') {
                        notification.error({ message: trans('error.conclusion_required') })
                    } else if (title === 'claim_finance_must_not_be_empty' || title === 'financial_required') {
                        notification.error({ message: lang.message.claim_finance_must_not_be_empty })
                    } else if (title.replace(/"/g, '') === 'order_refund_amount_exceeded') {
                        notification.error({ message: lang.error.order_refund_amount_exceeded })
                    } else if (title.replace(/"/g, '') === 'invalid_financial_account') {
                        notification.error({ message: lang.ticket_detail.invalid_financial_account })
                    } else if (title.replace(/"/g, '') === 'exceed_refund_amount_threshold') {
                        notification.error({ message: lang.error.order_refund_amount_over_permission })
                    } else if (err.response?.data?.title.replace(/"/g, '') === 'order_not_found') {
                        notification.error({ message: lang.error.order_not_found })
                    } else if (err.response?.data?.title.replace(/"/g, '') === 'financial_account_not_found') {
                        notification.error({ message: lang.error.transaction_not_exist })
                    } else if (title === 'update_deadline_problem') {
                        notification.error({ message: lang.error.cannot_select_range_time })
                    } else if (title === 'deadline_invalid') {
                        notification.error({ message: lang.error.deadline_invalid })
                    } else if (title === 'exceed_allowable_refund_amount') {
                        notification.error({ message: lang.error.order_refund_over_maximum })
                    } else if (title === 'ticket_reached_last_state') {
                        notification.error({ message: lang.conclusion.cannot_manipulation })
                    } else if (title === 'insufficient_permission') {
                        notification.error({ message: lang.error.update_state_access_denied })
                    } else if (title === 'claim_refunded') {
                        notification.error({ message: lang.error.claim_refunded })
                    } else if (title === 'claim_updating') {
                        notification.error({ message: lang.error.claim_updating })
                    } else if (title === 'refund_data_not_correct') {
                        notification['error']({
                            message: lang.error.claim_refund_not_correct,
                            description: `Số tiền đã được hoàn thực tế là ${detail && numeral(detail.split(/'/)[3]).format('0,0')}`
                        })
                    }
                    else if (title === 'update_claim_state_failed') {
                        notification['error']({
                            message: lang.message.ticket_has_change_state
                        })
                    }
                    else {
                        // notification.error({ message: lang.message.ticket_has_change_state })
                        notification.error(
                            {
                                message: 'Đã có lỗi xảy ra với hệ thống quản lý tài chính. Vui lòng thử lại sau',
                                // description: ''

                            })
                    }
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    changeStatus = (state: any, password: string, cb: () => void) => {
        let data = {
            code: this.getCode(),
            state,
            password,
        }
        this.updateTicket(data, cb)
    }

    changeDateline = (deadline: string) => {
        let data = {
            deadline,
            project: this.project,
        }
        this.setState({ loading: true })
        ClaimApi.updateTicketDeadline(data, this.getCode())
            .then(() => {
                notification.success({ message: lang.message.update_success })
                this.loadDetail()
            })
            .catch((err) => {
                if (err.response.data.title === 'update_deadline_problem') {
                    notification.error({ message: trans('message.update_deadline_problem') })
                    return
                }

                if (err.response.data.title === 'deadline_invalid') {
                    notification.error({ message: trans('error.deadline_invalid') })
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    archiveTicket = () => {
        this.setState({ loading: true })
        ClaimApi.archiveTicket(this.getCode(), this.project)
            .then(() => {
                notification.success({ message: lang.message.success })
                this.loadDetail()
                this.setState({
                    isShowConfirmCloseTicket: false,
                })
            })
            .catch((err) => {
                if (err?.response.status === 400) {
                    notification.error({ message: lang.message.ticket_closed })
                } else if (err?.response?.data?.title === 'ticket_reached_last_state') {
                    notification.error({ message: lang.conclusion.cannot_manipulation })
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    onArchiveTicket = () => {
        this.setState({
            isShowConfirmCloseTicket: true,
        })
    }

    assignUserToMe = () => {
        const { currentLoggedUser } = this.state
        this.assignUserToIssue(currentLoggedUser.username)
    }

    assignUserToIssue = (username: string, cb = () => null) => {
        this.setState({ loading: true })
        const payload = {
            assignee: username,
            project: this.project,
        }
        ClaimApi.assignUserToIssue(this.getCode(), payload)
            .then(() => {
                notification.success({ message: lang.message.change_employee })
                if (cb) cb()
                this.loadDetail()
            })
            .catch((e) => {
                if (e.response?.data.status === 'over_quantity_assignment') {
                    notification.error({ message: lang.error.message_error })
                } else if (e.response?.data.status === 'ticket_reached_last_state') {
                    notification.error({ message: lang.conclusion.cannot_manipulation })
                }
                else if (e.response?.data.title === 'assignment_duplicate') {
                    notification['error']({
                        message: 'Khiếu nại đã có người tiếp nhận. Vui lòng kiểm tra lại'
                    })
                }
                else {
                    notification.error({ message: e.message })
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    unAssignUserToggle = () => {
        this.setState({ isShowConfirmUnAssign: true })
    }

    unAssignUser = () => {
        this.setState({ loading: true })
        ClaimApi.unAssignUserToIssue({ code: this.getCode(), username: get(this.state.detail, 'assignmentView.assignee') })
            .then(() => {
                notification.success({ message: lang.message.cancel_receiver })
                this.setState({ isShowConfirmUnAssign: false })
                this.loadDetail()
            })
            .catch((err) => {
                notification.error({ message: err.message })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    onShowEditReasonComplaint = () => {
        this.setState({
            isModalEditReasonComplaint: true,
        })
    }

    urlToFile = (url: string, filename: string, mimeType: string) => {
        return fetch(url)
            .then(function (res) {
                return res.arrayBuffer()
            })
            .then(function (buf) {
                return new File([buf], filename, { type: mimeType })
            })
    }

    editReasonComplaint = async (values: any) => {
        this.setState({ loadingEditReasonComplaint: true })
        const payload = { ...values }
        let formData = new FormData()

        payload.reasonData = {
            notReceived: values.notReceived,
        }
        if (payload.upload && payload.upload.length > 0) {
            const files = await Promise.all(
                payload.upload.map(async (file: any) => {
                    const fileX = await this.urlToFile(file.url, file.name, file.type)
                    return fileX
                    // formData.append('attachments', new Blob([file]), file.name);
                })
            )
            files.forEach((file) => {
                formData.append('attachments', new Blob([file]), file.name)
            })
        } else if (payload.upload && payload.upload.length === 0 && Array.isArray(payload.upload)) {
            formData.append('attachments', [] as any)
        }
        delete payload.upload
        formData.append('claim', new Blob([JSON.stringify(payload)], { type: 'application/json' }))
        ClaimApi.updateTicketReason(formData, this.getCode())
            .then(() => {
                notification.success({ message: lang.message.update_claim_reason })
                this.loadDetail()
                this.setState({
                    isModalEditReasonComplaint: false,
                })
            })
            .catch((e) => {
                if (e.response?.data?.title === 'number_of_files') {
                    notification.error({ message: lang.error.max_10_file })
                } else {
                    notification.error({ message: lang.error.update_claim_reason_failed })
                }
            })
            .finally(() => this.setState({ loadingEditReasonComplaint: false }))
    }

    render() {
        const { order, loading, detail, initialLoading, errorCode, loadingEditReasonComplaint, isModalEditReasonComplaint, claimTenant } = this.state

        if (errorCode === 403 || errorCode === '403') {
            return (
                <MainLayout title={'#' + this.getCode() + ` - ${lang.sidebar.claim_t} `}>
                    <Component403 />
                </MainLayout>
            )
        }
        if (errorCode === 404 || errorCode === '404') {
            return (
                <MainLayout title={'#' + this.getCode() + ` - ${lang.sidebar.claim_t} `}>
                    <Component404 />
                </MainLayout>
            )
        }

        return (
            <MainLayout title={loading ? 'Loading' : '#' + this.getCode() + ` - ${lang.sidebar.claim_t}` + ` - ${this.project}`}>
                <>
                    <ClaimDetailContext.Provider value={{ detail: detail || {}, order: order || {}, code: this.getCode(), project: this.project }}>
                        <Spin spinning={loading}>
                            <div className={'detail-content'}>
                                <div className={'information'}>
                                    <Informations
                                        detail={detail}
                                        onChangeDateline={this.changeDateline}
                                        changeStatus={this.changeStatus}
                                        order={order}
                                        archiveTicket={this.onArchiveTicket}
                                        assignUserToMe={this.assignUserToMe}
                                        assignUserToIssue={this.assignUserToIssue}
                                        loading={loading}
                                    />

                                    {/* <ReasonComplaint data={detail} /> */}

                                    {detail?.code && checkPermission(TICKETS_SCOPES.TICKETS_SIMILAR_VIEW_ALL) && <ComplaintTabs />}

                                    {detail.ticketType !== FinanceOrderTypes.peerpayment && (
                                        ![FinanceOrderTypes.peerpayment, FinanceOrderTypes.shipment].includes(detail.ticketType) && (
                                            <>
                                                <AlipayTransactions />
                                            </>
                                        ))}

                                    <div className={'box'}>
                                        <RefundList
                                            code={this.getCode()}
                                            state={detail.stateViewClaim?.code}
                                        />
                                    </div>

                                    {checkPermission(TICKETS_SCOPES.TICKET_FINANCE_VIEW) && (
                                        <Financial
                                            loadDetail={this.loadDetail}
                                            errorCode={errorCode}
                                            loadOrder={this.loadOrder}
                                            code={this.getCode()}
                                            detail={detail}
                                            order={order}
                                        />
                                    )}

                                    {checkPermission(TICKETS_SCOPES.TICKET_CONCLUSION_VIEW) && (
                                        <>
                                            <Conclusion
                                                code={this.getCode()}
                                                errorCode={errorCode}
                                                detail={detail}
                                            />
                                        </>
                                    )}
                                </div>

                                <Comments {...this.props} />
                            </div>

                            {checkPermission(TICKETS_SCOPES.TICKETS_UPDATE) && (
                                <ChangeReasonComplaint
                                    visible={isModalEditReasonComplaint}
                                    data={detail}
                                    loading={loadingEditReasonComplaint}
                                    onCancel={() =>
                                        this.setState({
                                            loadingEditReasonComplaint: false,
                                            isModalEditReasonComplaint: false,
                                        })
                                    }
                                    onOk={this.editReasonComplaint}
                                />
                            )}
                        </Spin>
                    </ClaimDetailContext.Provider>

                    <ModalConfirm
                        visible={this.state.isShowConfirmUnAssign}
                        onOk={this.unAssignUser}
                        content={lang.confirm.cancel_receiver_comfirm}
                        title={lang.conclusion.warning}
                        onCancel={() => this.setState({ isShowConfirmUnAssign: false })}
                    />

                    <ModalConfirm
                        visible={this.state.isShowConfirmCloseTicket}
                        onOk={this.archiveTicket}
                        content={lang.confirm.close_ticket}
                        title={lang.conclusion.warning}
                        onCancel={() => this.setState({ isShowConfirmCloseTicket: false })}
                    />
                </>
                <Footer />
            </MainLayout>
        )
    }
}

export default DetailTicket
