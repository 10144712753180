import {Select} from "antd";
import lang from "../../../resources/localization/Localization";

const SelectTrueFalse = ({onChange, value, disabled, name, placeholder, className}) => {
    const data = [
        {
            code: true,
            name: lang.conclusion.yes,
        },
        {
            code: false,
            name: lang.conclusion.no,
        }
    ];

    return <Select disabled={disabled} onChange={onChange} value={value} placeholder={placeholder || lang.true_false_selector.select}
                   className={"width100 _select-" + name + " " + className}>
        {data.map(d =>
            <Select.Option key={d.code} value={d.code} className={`_${name} _${name}-` + d.code}>
                {d.name}
            </Select.Option>)}
    </Select>
}

export default SelectTrueFalse
