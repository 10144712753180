import {LocalStore} from "../../utils/LocalStore/index";
import _, { get } from 'lodash';
import React from "react";

const localStore = LocalStore.getInstance();
let language = localStore.read("language");

if(_.isEmpty(language)){
    language = 'vi';
}


let lang = (function lang(_language) {
    switch (_language){
        case 'en' : return require('./locals/en/en.json');
        case 'vi' : return require('./locals/vn/vn.json');
        default : return require('./locals/vn/vn.json');
    }
})(language);

export const trans = (key: string, params: {[key in string]: any} = {}, elementType?: 'span' | 'div') => {
    let text: string = get(lang, key)

    if (!text) {
        console.error(`Key not found: ${key}`)
    }

    Object.keys(params).forEach((paramKey: string) => {
        text = text?.replaceAll(`%${paramKey}%`, String(params[paramKey] || '---'))
    })

    if (elementType) {
        const element = React.createElement(elementType, {dangerouslySetInnerHTML: {__html: text || key}})
        return element as any
    }

    return text || key
}

export const localization = language;

export default lang;
