import React, {Component} from 'react';
import {Redirect, Route} from 'react-router-dom';
import lodash from 'lodash';
import SecurityService from "../../../utils/SecurityService";

class AuthenticateRoute extends Component {
    render() {
        const renderComponent = (_props) => {
            if (!SecurityService.isLogged()) {
                if (!lodash.startsWith(window.location.pathname, '/login') || !lodash.startsWith(window.location.pathname, '/authentication')) {
                    let redirectBackUrl = window.location.href
                    return <Redirect to={`/login?redirect_uri=${redirectBackUrl}`}/>
                }
                return <Redirect to={'/login'}/>
            }

            const OwnComponent = _props.component;
            return (
                <OwnComponent
                    {..._props}
                />
            );
        };

        return (
            <Route
                {...lodash.omit(this.props, 'component')}
                render={routeProps => {
                    return renderComponent({
                        ...this.props,
                        ...routeProps,
                        permission: this.props.permission
                    });
                }}
            />
        )
    }
}

export default AuthenticateRoute;
